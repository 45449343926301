
//common
import {Packages} from '../../../components/Dashboard';

const PackagesPage = (props) => {
    return (
        <Packages {...props}/>
    );
};

export default PackagesPage;