
//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Skeleton from '@material-ui/lab/Skeleton';

//common

const useStyles = makeStyles(theme => ({
    content: {
        padding: '24px 0',
        width: '90%',
        margin: '0 auto',
        '&>*':{
            margin: '24px 0'
        },
    },
    top: {
        '&>*:first-child>*':{
            margin: '16px 0 36px 0',
            '&>h5':{
                fontWeight:600
            },
            '& img':{
                transform: 'translateX(-68px)',
                width: '90%'
            }
        },
    },
    section: {
        justifyContent: 'space-between',
        '&>*:nth-child(2)':{
            width: '100%',
            '&>img':{
                width: '100%',
            }
        },
        '&>*:nth-child(1)':{
            marginTop: 36
        },
        '& h6':{
            fontWeight: 600,
            margin: '8px 0'
        }
    },
    alternateSection: {
        '&>*:nth-child(2)':{
            order: 1,
            [theme.breakpoints.down('sm')]: {
                order: 2,
            },
        },
        '&>*:nth-child(1)':{
            order: 2,
            [theme.breakpoints.down('sm')]: {
                order: 1,
            },
        },
    },
    games:{
        margin: '36px 0',
        '& h5':{
            fontWeight: 600,
            marginBottom: 16
        },
        '& button':{
            marginTop: 24
        }
    }
}));

const Main = ({  }) => {

    const classes = useStyles();

    return (
        <main className={classes.main}>
            <Grid container className={classes.content}>
                
            </Grid>
        </main>
        
    );
};

export default Main;
