// import {useState, useEffect} from 'react';
//import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 36,
    '&>*:nth-child(2)':{
      margin: '24px 0'
    }
  },
  supportAction: {
    '&>*:nth-child(1)': {
      margin: '8px 0'
    }
  }
}));

const PackagesBottom = (props) => {

  const location = useLocation();

  const classes = useStyles();

  const [isUnRegistered, setIsUnregistered] = useState(false);

  useEffect(() => {
    if(location.pathname === '/labs/unregistered/packages'){
      setIsUnregistered(true);
    }
  },[location])


  return (
    <Grid container className={classes.container} style={{ margin: isUnRegistered && '0px 100px 0px 100px', width: isUnRegistered && '90%' }} >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Contact Support</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} className={classes.supportAction}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/mail.svg`}/>
            <Typography variant="body2">
              For any assistance or enquiry please contact us at <a href="#">info@eduzo.com</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.supportAction}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/call.svg`}/>
            <Typography variant="body2">
              Call us at this tollfree number<br/><a href="#">1800-271-38203</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.supportAction}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/bookmark.svg`}/>
            <Typography variant="body2">
              Get familiar with the ar.labs products and their features using <a href="#">ar.labs docs</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.supportAction}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/message.svg`}/>
            <Typography variant="body2">
              Chat with us for anything immediate. <a href="#">Go to chat</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PackagesBottom;
