import {combineReducers} from 'redux';
import {mainReducer} from './mainReducer';
import {authReducer} from './authReducer';
import {gameReducer} from './gameReducer';
import {studentReducer} from './studentReducer';

export default combineReducers({
    main: mainReducer,
    auth: authReducer,
    game: gameReducer,
    student: studentReducer
})