// import {useState, useEffect} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';

//common
import Top from './Top';
import Bottom from './Bottom';


const useStyles = makeStyles((theme) => ({
}));

const Packages = (props) => {

  const classes = useStyles();

  return (
    <div>
        <div className={classes.content}>
            <Top {...props}/>
            <Bottom {...props}/>
        </div>
    </div>
  );
}

export default Packages;
