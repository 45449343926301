
//common
import {Login} from '../../components/Auth';

const LoginPage = () => {
    return(
        <Login/>
    )
}

export default LoginPage;