import {useState} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

//material-ui lab imports
import Skeleton from '@material-ui/lab/Skeleton';

//material-ui icon imports
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//common


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '48px',
        '& h1':{
            fontSize: '1.5rem',
            color: 'var(--text-primary)',
            fontWeight: 700
        }
    },
    primaryText: {
        color: 'var(--primary)'
    },
    divider: {
        margin: '36px 0',
    },
    button:{
        textTransform: 'none',
        fontSize: '1rem',
        color: 'var(--primary)'
    },
    containedButton:{
        textTransform: 'none',
        fontSize: '1rem',
        backgroundColor: 'var(--text-primary)',
        color: '#fff',
        '&:hover, &:focus': {
            backgroundColor: 'var(--text-primary)',
            filter: 'brightness(105%)'
        }
    },
    textField: {
        width: '100%'
    },
    section: {
        margin: '48px 0',
        '&>*':{
            margin: '12px 0',
            '&>*':{
                margin: '6px 0'
            }
        }
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h2':{
            fontSize: '1.3rem',
            fontWeight: 500
        },
    },
    appSecuritySettings: {
        '&>*:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            '&>*':{
                width: '49%',
                marginRight: '4px'
            }
        }
    },
    accountsManager: {
        '&>*':{
            marginTop: 12
        }
    },
    accountCardContent: {
        position: 'relative',
        '&>*:nth-child(1)':{
            display: 'flex',
            alignItems: 'center',
            '&>*:nth-child(2)':{
                margin: '0 24px',
                fontSize: '1.3rem',
                fontWeight: 500
            }
        },
    },
    accountEditButton: {
        position: 'absolute',
        top: -8,
        right: -8,
        padding: 0,
    },
    accountAddButton: {
        width: '100%',
        height: 240,
        '&>div':{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },

    faqManager: {
        //none
    },
    faqs: {
        margin: '32px 0',
        '&>*':{
            padding: '12px'
        }
    },
    faqHeading: {
        color: 'var(--primary)',
        fontWeight: 500,
        letterSpacing: 1,
        wordSpacing: 3,
        fontSize: '1rem'
    },
    helpManager: {
        '&>div':{
            margin: '12px 0',
            '&>*':{
                margin: '4px 0'
            }
        }
    },
    modalCloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'var(--primary)',
        textTransform: 'none',
        fontSize: '1rem'
    },
    modalImageUpload: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&>*:nth-child(1)':{
            width: 80,
            height: 80,
            marginBottom: 8
        }
    },
    addInstructorForm: {
        margin: '24px 0'
    },
    addInstructorFormAction: {
        margin: '24px 0 12px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    }
}));

const Manage = ({}) => {

  const classes = useStyles();
  const [instructorModal, setInstructorModal] = useState(false);

  const handleInstructorTriggerModal = () => {
      setInstructorModal(prev => !prev)
  }

  const handleAddInstructor = (e) => {
      e.preventDefault();
  }

  return (
    <div className={classes.root}>
        <div className={classes.appSettings}>
            <Typography variant="h1">App Settings</Typography>

            <div className={`${classes.appSecuritySettings} ${classes.section}`}>
                <div className={classes.sectionHeader}>
                    <Typography variant="h2">Security</Typography>
                </div>
                <div>
                    <Skeleton variant="rect" animation="wave"/>
                    <Skeleton variant="rect" animation="wave"/>
                </div>
            </div>
            
            <div className={`${classes.appContactSettings} ${classes.section}`}>
                <div className={classes.sectionHeader}>
                    <div>
                        <Typography variant="h2">Contact Details</Typography>
                        <Typography variant="subtitle1" color="textSecondary">We use this to get in touch with you regarding the latest updates and new games in the market</Typography>
                    </div>
                    <Button className={classes.button}>Edit</Button>
                </div>
                <div>
                    <Typography variant="body1">+91 1234567890</Typography>
                    <Typography variant="body1">email@host.com</Typography>
                </div>
            </div>
        </div>

        <Divider className={classes.divider}/>

        <div className={classes.accountsManager}>
            <Typography variant="h1">Accounts(3)</Typography>
            <Grid container spacing={2}>
                {Array.from({length:4}, (_,i) => 
                <Grid item xs={12} md={3} key={i}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} className={classes.accountCardContent}>
                                <Grid item xs={12}>
                                    <Avatar>A</Avatar>
                                    <Typography variant="h5">{i===0 ? 'Admin (You)' : 'Instructor'+i}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography variant="body1">Name:</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography variant="body1">Random name</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="body1">Created on:</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography variant="body1">19-02-2021</Typography>
                                </Grid>

                                <Button size="small" className={`${classes.button} ${classes.accountEditButton}`}>Edit</Button>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button size="small" className={classes.button}>Activity</Button>
                        </CardActions>
                    </Card>
                </Grid>
                )}
                <Grid item xs={12} md={3}>
                    <Button className={classes.accountAddButton} onClick={handleInstructorTriggerModal}>
                        <div>
                            <AddIcon style={{fontSize:'4rem'}}/>
                            <Typography variant="h6">Add instructor</Typography>
                        </div>
                    </Button>
                </Grid>
            </Grid>
        </div>
        
        <Divider className={classes.divider}/>

        <div className={classes.faqManager}>
            <Typography variant="h1" gutterBottom>FAQs</Typography>
            <Typography variant="body1">Here are some of the frequently asked questions</Typography>

            <div className={classes.faqs}>
                {Array.from({length:3}, (_,i) => 
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography className={classes.faqHeading}>A frequently asked question will be placed here</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        </div>

        <Divider className={classes.divider}/>

        <div className={classes.helpManager}>
            <Typography variant="h1" gutterBottom>Help</Typography>
            <div>
                <Typography variant="body1">
                    In case of any assistance or enquiry, please contact us at <span className={classes.primaryText}>info@eduzo.com</span>
                </Typography>
                <Typography variant="body1">
                    or call us at this toll-free number <span className={classes.primaryText}>1800-271-37129</span>
                </Typography>
            </div>
        </div>

        <Dialog 
            onClose={handleInstructorTriggerModal} 
            aria-labelledby="add-instructor-dialog" 
            open={instructorModal}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle disableTypography>
                <Typography variant="h6">Add Instructor</Typography>
                <Button aria-label="close" className={classes.modalCloseButton} onClick={handleInstructorTriggerModal}>Close</Button>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleAddInstructor} className={classes.addInstructorForm}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} className={classes.modalImageUpload}>
                            <Avatar>A</Avatar>
                            <Button className={classes.button}>Upload</Button>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField className={classes.textField} variant='outlined' label='First name'/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField className={classes.textField} variant='outlined' label='Last name'/>
                                </Grid>
                                
                                <Grid item xs={2}>
                                    <TextField className={classes.textField} variant='outlined' placeholder='+91' type="number"/>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField className={classes.textField}  variant='outlined' label='Phone number' type='number'/>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <TextField InputLabelProps={{ shrink: true }} className={classes.textField} variant='outlined' label='Date of birth' type='date'/>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <TextField className={classes.textField} variant='outlined' label='Password' type='password'/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.addInstructorFormAction}>
                            <Button variant='contained' type="submit" className={classes.containedButton}>Add Instructor</Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    </div>
  );
}

export default Manage;
