import React, { useEffect } from 'react'
import { Home, Login, NotFound, PlayGame3, PlayGame2, DashboardPackages } from './pages'
// import { PlayGame3 } from './pages/PlayGame'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'

import AppProvider from './components/AppProvider'
import DashboardLayout from './layouts/Dashboard'
import store from './redux/store'
import setAuthToken from "../src/redux/utils/setAuthToken"
import { loadUser } from '../src/redux/actions/authAction'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { withOneTabEnforcer } from "react-one-tab-enforcer"


import './App.css'
import UnregisteredGameStore from './components/Dashboard/Unregistered/Gamestore'
import UnregisteredGameDetail from './components/Dashboard/Unregistered/Gamestore/GameDetail'
// import UnregisteredGamePackage from './components/Dashboard/Unregistered/Gamestore/GamePackage/index'


import CreateCustomGameForm from './pages/sample'
import CommingSoon from './pages/miscellaneous/CommingSoon'
import UnregisteredLayout from './layouts/UnregisteredLayout'

if (localStorage.token) {
  setAuthToken(localStorage.token);

}


const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);


  return (
    <Provider store={store}>
      <AppProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Home} />
           
            {/* <Route exact path='/unregistered' component={UnregisteredGameStore} />
            <Route exact path='/unregistered/:id' component={UnregisteredGameDetail} /> */}
            {/* <Route exact path='/unregistered/packages' component={UnregisteredGamePackage} /> */}
            <Route exact path='/404' component={NotFound} />
            <Route exact path='/login' component={Login} />
            <PrivateRoute exact path='/labs/play-game2/:id' component={PlayGame2} />
            <PrivateRoute exact path='/labs/play-game3/:id' component={PlayGame3} />
            <Route
              exact
              path={'/labs/unregistered/packages'}
              component={DashboardPackages}
            />
            <Route
              exact
              path={'/commingsoon'}
              component={CommingSoon}
            />
            <Route
              render={(props) => <DashboardLayout window={window} {...props} />}
            />
             {/* <Route
              render={(props) => <UnregisteredLayout window={window} {...props} />}
            /> */}

         


            <Redirect to='/404' />
          </Switch>
        </BrowserRouter>
      </AppProvider>
    </Provider>
  )
}

// export default App
export default withOneTabEnforcer({ appName: "eduzo" })(App)
