import axios from "axios";
import {
 
    LOGOUT,
    LOGIN_FAIL,
    AUTH_ERROR,
    USER_LOADED,
    LOGIN_SUCCESS,
} from "../types";

import setAuthToken from '../utils/setAuthToken'
import {API_URL} from '../../config';

export const loadUser = () => async (dispatch) => {

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`${API_URL}/auth/login`);
    // console.log(res)
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const authLogin = (inputs) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    const body = JSON.stringify(inputs);

    try {
      const res = await axios.post(`${API_URL}/auth/login`, body, config);
      await dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (errors) {
          alert("Your Institute Code is invalid.")
          console.log(errors)

          dispatch({
            type: LOGIN_FAIL,
          });
      }
};



export const authLoginEmail = (inputs) => async (dispatch) => {
  console.log(inputs)
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(inputs);

  try {
    const res = await axios.post(`${API_URL}/auth/owner/login`, body, config);
    await dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (errors) {
        alert("Your Institute Code or Password is invalid.")
        console.log(errors)

        dispatch({
          type: LOGIN_FAIL,
        });
    }
};


export const logout = () => (dispatch) => {
  console.log("LOGIN_FAIL")
  console.log('action')
  dispatch({
    type: LOGOUT,
  });
};