import {useState, Fragment, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

//material-ui lab imports
import Skeleton from '@material-ui/lab/Skeleton';

//material-ui icon imports
import PlaysIcon from '@material-ui/icons/SportsEsports';
import StudentsIcon from '@material-ui/icons/PeopleAlt';
import CertificatesIcon from '@material-ui/icons/Stars';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    chip: {
        marginTop : '0.2vh',
        minHeight : '28px !important',
        minWidth : '62px',
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '&>*':{
            marginTop: 8
        },
        '&>*:nth-child(2)':{
            display: 'flex',
            alignItems: 'center',
            '&>*':{
                margin: '0 4px'
            }
        }
    },
    game: {
        backgroundColor: 'transparent',
        boxShadow : '0 0 4px #ffffff',
        padding: '0 12px',
        margin: '12px 0',
        border : '1px solid #B2B9D1',
        padding : '20px', 
        borderRadius: '20px', 
        marginBottom: '-10px',
    },
    gameImage: {
        '&>img':{
            width: '65%',
            height: '120px',
            borderRadius: '12px'
        }
    },
    gameInfo: {
        // marginLeft : '-42px',
        // marginTop : '-5px',
        marginBottom: 5, 
        textTransform: 'capitalize',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '95%',
        '&>*:nth-child(3)': {
            '&>*':{
                marginRight: 12
            },
            '&>*:nth-child(1)':{
                border: '1px solid #A0A7E5',
                textTransform: 'none',
                color: '#4250CB'
            },
            '&>*:nth-child(2)':{
                border: '1px solid #E5B1A0',
                textTransform: 'none',
                color: '#CB6342'
            },
            '&>*:nth-child(3)':{
                border: '1px solid #98DDB0',
                textTransform: 'none',
                color: '#2DB35B'
            }
        }
    },
    gameTitle: {
        // height: '150%',
        display: 'flex',
        alignItems: 'center',
        '&>*:nth-child(1)':{
            textDecoration: 'none',
            color: '#000'
        },
        '&>*:nth-child(2)':{
            marginLeft: 8
        }
    },
    Description : {
        color : '#02113F',
        fontSize : '14px', 
        maxWidth : '100%',
        opacity : 0.6, 
        margin : '0px 0px 4px 0px'
    }
}));

const MyGamesBottom = ({match, games}) => {
    const [sortedGames, setSortedGames] = useState([])

    const [,setUpdates] = useState()
    const forceUpdate = useCallback(()=> setUpdates({}), [])
  
    useEffect(() => {
        setSortedGames(games)
    }, [games])


    var array = []
    sortedGames && sortedGames?.map((game) =>array.push(game.platform?.toLowerCase()) )
   
    var games_platform = [...new Set(array)]
    console.log('gamessplatttMYGAMESSS-->',games_platform)

    const displayText = {
        "ios" : "iOS",
        "kinect": "Kinect"
      }

  const classes = useStyles();
  var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];
  const [app, setApp] = useState("");
  const [filterGames, setFilterGames] = useState([]);

useEffect(() => {
    setApp("All")
}, [])

useEffect(() => {
    // setApp("All")
    setFilterGames(games)
}, [games])

  const handleChangeApp = (event) => {
      console.log('filterddgmss-->',filterGames); 
      
      console.log('1    event.target.value -->',event.target.value); 
    setApp(event.target.value );
    var valSelected = event.target.value;
    if(valSelected === "All") {
        setFilterGames(games);
    } else {
        // console.log('event.target.value-->',event.target.value.toUpperCase()); 
        var filteredGames = games.filter(game => {
            return game.platform === valSelected
        })
        console.log('after filterddgmss-->',filteredGames);
        setFilterGames(filteredGames);
    }
  };

  
  
  const sort_by =(e)=> {

    if (e.target.value == 'A-Z'){
      var x = games.sort((a, b) => a.name.localeCompare(b.name))
      // console.log(x)
      setSortedGames(x);
      forceUpdate()
    } else if (e.target.value == 'Z-A'){
      var x = games.sort((a, b) => a.name.localeCompare(b.name)).reverse()
      // console.log(x)
      setSortedGames(x);
      forceUpdate()
    }
}



  return (
    <div className={classes.root}>
        <div className={classes.top}>
            <Typography variant="h5">All Games</Typography>
            <div>
            <div style={{minWidth : '5vw'}}>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
                    <Select
                     style={{ width : '160px',backgroundColor : 'white', height : '55px'}}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      variant="outlined"
                      value={app}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      defaultValue={"All"}
                      onChange={handleChangeApp}  
                    // handleChange={handleChangeApp} 
                      label="Discover"
                    >  
                      <MenuItem value='All'>All Applications</MenuItem>
                      {games_platform.map(game =>
                        // console.log('gamessorteddd--->',games,games.platform),
                    //   <MenuItem style={{textTransform: 'capitalize'}} value={game}>{game}</MenuItem>
                    <MenuItem value={displayText[game] }>{displayText[game]}</MenuItem>
                       )}
                    </Select>  
                  </FormControl>
                     </div>
                {/* <FormControl variant="outlined">
                    <InputLabel htmlFor="filter">Filter</InputLabel>
                    <Select 
                       style={{width : '12vw'}}
                        value={app} 
                        label="Filter"
                        inputProps={{
                            id: 'filter',
                        }}
                        onChange={handleChangeApp}
                    >
                        <MenuItem value="All Applications" selected>All Applications</MenuItem>
                        <MenuItem value="ios">ios</MenuItem>
                        <MenuItem value="kinnect">Kinnect</MenuItem>
                    </Select>
                </FormControl> */}
                <FormControl variant="outlined">
                    <InputLabel htmlFor="sortBy">Sort by</InputLabel>
                    <Select 
                        value="Recently Installed" 
                        label="Sort by"
                        inputProps={{
                            id: 'sortBy',
                        }}
                    >
                        <MenuItem value="Recently Installed" selected>Recently Installed</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>

        <br/>
        {/* <Divider className={classes.divider}/> */}

        <div className={classes.bottom}>
            <Grid container spacing={3}>
                {filterGames && filterGames.map(g =>
                    <Grid key={g._id} item xs={12}>
                        <Paper elevation={2} className={classes.game}>
                            <div className='row'>
                            <div className='col-sm-2'>
                                <div item xs={2} className={classes.gameImage}>
                                    <img src={g?.image} alt="game"/>
                                </div>
                            </div>
                            <div className='col-sm-8' style={{marginLeft : '-4vw'}}>
                                <div  className={classes.gameInfo}>
                         
                                    <div className={classes.gameTitle}>
                                        <Link to={`${match.path}/${g._id}`}><Typography variant="h6">{g.name}</Typography></Link>
                                        <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} label={g.subjects[0]}  className={classes.chip}/>
                                    </div>
                                    <Typography className={classes.Description}>
                                    {g.long_description.substring(0,99)}...
                                    </Typography>  
                                    <div >
                                        <Button 
                                            variant="outlined" 
                                            startIcon={<PlaysIcon />}
                                        >0 Plays</Button>
                                        <Button
                                            variant="outlined" 
                                            startIcon={<StudentsIcon />}
                                        >0 Students</Button>
                                        <Button
                                            variant="outlined" 
                                            startIcon={<CertificatesIcon />}
                                        >0 Certificates</Button>
                                    </div>
                                </div>
                                </div>
                            {/* </Grid> */}
                            </div>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </div>

        
    </div>
  );
}

export default MyGamesBottom;
