
//common
import Header from '../../../components/Dashboard/Header'

const Home = ({  }) => {
    return (
        <div>
        <Header/>
        </div>
    );
};

export default Home;
