import React, { useEffect, useState } from "react";
import "./notifications.css";
import SearchBar from "material-ui-search-bar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotifications,
  markAllNotificationRead,
  markNotificationRead,
} from "../../../redux/actions/studentAction";
import moment from "moment";

const Notifications = () => {
  const notifications = useSelector((state) => state.student.notifications);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!notifications && user) {
      dispatch(
        getNotifications({
          institute: localStorage.getItem("institute"),
          user_id: user._id,
        })
      );
    }
  }, [user]);

  const readNotification = (id) => {
    dispatch(
      markNotificationRead({
        institute: localStorage.getItem("institute"),
        id,
        user_id: user._id,
      })
    );
  };

  const readAllNotification = () => {
    const ids = notifications.map((note) => note._id);
    console.log(ids);
    dispatch(
      markAllNotificationRead({
        institute: localStorage.getItem("institute"),
        ids,
        user_id: user._id,
      })
    );
  };

  return (
    <div
      id="view_institute_page"
      className="container"
      style={{ width: "95%" }}
    >
      <ol
        className="breadcrumb"
        style={{ marginTop: "5px", backgroundColor: "transparent" }}
      >
        <li className="breadcrumb-item">
          <Link style={{ color: "#4335d2" }} to={`/labs/gamestore`}>
            Game Store /
          </Link>
        </li>
        {/* <li className="breadcrumb-item active" aria-current="page"> {institute.name}</li> */}
      </ol>
      <div className="container-fluid row headerBar">
        <div>
          <h4 style={{ fontWeight: 600, letterSpacing: "0.4px" }}>
            Notifications
          </h4>
        </div>

        <div className="d-flex">
          <SearchBar />
          <button
            className="read-button ml-3"
            onClick={() => readAllNotification()}
          >
            Mark all as read
          </button>
        </div>
      </div>

      <div className="container">
        <ul class="list-group list-group-flush">
          {notifications?.map(({ message, _id, read, createdAt }) => (
            <li className="d-flex align-items-center">
              <div className={read ? "" : "notification-dot-1"}></div>
              <div class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                <div>
                  <span>{message}</span>
                  <p className="time">
                    {moment(createdAt).format("DD-MM-YYYY h:mm a")}
                  </p>
                </div>
                {!read && (
                  <button
                    className="read-button"
                    onClick={() => readNotification(_id)}
                  >
                    Mark as read
                  </button>
                )}
              </div>
            </li>
          ))}

          <li class="list-group-item list-group-item-action">
            <span id="older_notifications">
              Show Older Notifications{" "}
              <span class="iconify" data-icon="ant-design:down-outlined"></span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
  // }
};

export default Notifications;
