import {useEffect, useState} from 'react';

//components
import Top from './Top';
import Bottom from './Bottom';

const ViewStudent = (props) => {
  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
 
    // const resultsScoresTemp = await axios.get();

  },[])
  
  const [shareClicked, setShareClicked] = useState(false);

  const [feedbackList, setFeedbackList] = useState([]);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});

  return (
    <div>
      <Top {...props} shareClicked={shareClicked} setShareClicked={setShareClicked} feedbackModal={feedbackModal} setFeedbackModal={setFeedbackModal} selectedReport={selectedReport}/>
      <Bottom {...props} shareClicked={shareClicked} setShareClicked={setShareClicked} feedbackModal={feedbackModal} setFeedbackModal={setFeedbackModal} setSelectedReport={setSelectedReport}/>
    </div>
  );
}
export default ViewStudent;
