import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//common
import Header from '../Header'
import Top from './Top';
import Bottom from './Bottom';

import {getGroups, createGroup} from '../../../redux/actions/studentAction';

const Groups = (props) => {
  // console.log('studemnts')

  const dispatch = useDispatch();
  const student = useSelector(state => state.student);

  var institute_id = localStorage.getItem('institute')

  useEffect(() => {
    if(!student.groups){
      dispatch(getGroups(institute_id));
    }
  },[])

  const handleCreateGroup = async (inputs) => {
    await dispatch(createGroup(inputs))
  }


  const [filterGroup, setFilterGroup] = useState();

  const searchGroup = (e) => {

    var xyz = student.groups?.filter(group => group.name.toLowerCase().includes(e.toLowerCase()))
    setFilterGroup(xyz)
  }

  const cancelSearchGroup = (e) => {
    setFilterGroup()
  }


  return (
    <div>
        <Header title="Student Groups" search={searchGroup} cancelSearch={cancelSearchGroup}/>
        <Top {...props} groups={filterGroup?.length > 0 ? filterGroup : filterGroup  ?  [] :  student.groups}  createGroup={handleCreateGroup}/>
        <Bottom {...props} groups={filterGroup?.length > 0 ? filterGroup : filterGroup  ?  [] :  student.groups} fetchError={student.groupsFetchError}/>
    </div>
  );
}

export default Groups;
