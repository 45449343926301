
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

//material-ui lab imports
import Skeleton from '@material-ui/lab/Skeleton';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        '& h5':{
            fontWeight: 500
        }
    },
    subjects : {
        color: '#02113F',
        fontSize :14,
        opacity : 0.6,
        marginTop : '5px',
        textTransform: 'capitalize'
    },
    linkButton: {
        textDecoration: 'none',
        padding: 0,
    },
    games: {
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0',
    },
    frequentlyUsed: {
        borderRadius: 16,
        width: '85%',
        boxShadow: '0px 4px 16px rgba(24, 24, 88, 0.1)',
        // margin:'20px 12px 0px 0px',
        '&>*:nth-child(1)':{
            borderRadius: '12px',
            
            width: '100%',
            height: 230
        },
        '&>*:nth-child(2)':{
            overflow: 'hidden',
            margin: '12px 0',
            '&>*:nth-child(1)':{
                fontSize : '22px',
                whiteSpace: 'nowrap',
                // overflow: 'hidden',
                textDecoration: 'none',
                textTransform: 'Capitalize',
                color : '#02113F',
                color: '#000'
            }
        },
        padding: '12px 12px 12px 12px'
    },
}));

const MyGamesTop = ({match, games}) => {


   var x = games.slice(); // make copy of array
   x = x.slice(0,4) 
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Frequently Used</Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {x.map(g => 
                    
                        <Grid item xs={6} md={3}>
                            <Card key={g._i} className={classes.frequentlyUsed} elevation={2}>

                                {g.illustration_img ? 
                                    <img src={g.illustration_img} /> : <Skeleton variant="rect"/>
                         
                                }
                                
                                <div>
                                    <Link to={`${match.path}/${g._id}`}>
                                        <Typography variant="h6">{g.name}</Typography>
                                    </Link>
                                    <Typography className={classes.subjects}>{g?.subjects[0]}</Typography>
                                </div>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
}

export default MyGamesTop
