import React, { useEffect, useState, Fragment } from 'react'
import { Grid, Modal } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {API_URL} from '../../../config/index';
import swal from 'sweetalert2'; 
import axios from 'axios';
import user_image from "../../../components/Dashboard/Icons/user.png"
import inst1 from "../../../components/Dashboard/Icons/inst1.png"
import inst2 from "../../../components/Dashboard/Icons/inst2.png"
import inst3 from "../../../components/Dashboard/Icons/inst3.png"
import inst4 from "../../../components/Dashboard/Icons/inst4.png" 
import inst5 from "../../../components/Dashboard/Icons/inst5.png"
import inst6 from "../../../components/Dashboard/Icons/inst6.png"
import inst7 from "../../../components/Dashboard/Icons/inst7.png"
import inst8 from "../../../components/Dashboard/Icons/inst8.png"
import inst9 from "../../../components/Dashboard/Icons/inst9.png"
import inst10 from "../../../components/Dashboard/Icons/inst10.png"
import inst11 from "../../../components/Dashboard/Icons/inst11.png"
import inst12 from "../../../components/Dashboard/Icons/inst12.png"
import inst13 from "../../../components/Dashboard/Icons/inst13.png"
import inst14 from "../../../components/Dashboard/Icons/inst14.png"


import firebase from '../../../config/firebase';
import $ from 'jquery'; 
import { DropzoneArea } from 'material-ui-dropzone';
import validator from 'validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button} from "react-bootstrap";
import Header from '../../../components/Dashboard/Header'
// import './manage.css'

const Manage2 = () => {

  var randomImages = [inst1,inst2,inst3,inst4,inst5,inst6,inst7,inst8,inst9,inst10,inst11,inst12,inst13,inst14];  


  const [users , setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const institute_id = localStorage.getItem('institute')
  const institute_code = localStorage.getItem('institute_code')
  // const profilePic = localStorage.getItem('profile_pic')
  const profile_pic = localStorage.getItem('profile_pic') === "" || localStorage.getItem('profile_pic') == 'undefined' || localStorage.getItem('profile_pic') == undefined   ? user_image :  localStorage.getItem('profile_pic')   ;
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  useEffect(async() => {
    await axios.get(`${API_URL}/instructors/${institute_id}`)
    .then(response => {
      // console.log(response.data)
      setUsers(response.data);

    })
    .catch(() => {
      setUsers([]);
    })
    .finally(() => { 
      setIsFetching(false)
    })
  }, []); 

  const admin =  users?.filter(user => user.role === 'Admin');
  const instructors =  users?.filter(user => user.role === 'Instructor');


  const [inputs, setInputs] = useState({
    name: '',
    phone_number : "",
    email_id :"",
    institute : localStorage.getItem('institute'),
    password : "",
    profile_pic : "",
    // profile_pic: localStorage.getItem('profile_pic'),
    role : 'Instructor'

})

const handleChange = (e) => {
  setInputs({ ...inputs, [e.target.name]: e.target.value })
}


const [error, setError] = useState(null);


const handleEditSubmit = async(e)=> {

  e.preventDefault();

    if(editData.email_id){
    if (validator.isEmail(editData.email_id) == false){
      swal.fire({
        title: 'Error',
        text: "Please enter a valid email address",
        icon: 'error',
        confirmButtonText: 'Ok'
    });
      return
    }
    }

    let data = new FormData();
    data.append("image", editData.profile_pic);


    var allData = editData;

    setLoading(true);
    if (editData.profile_pic && editData.profile_pic != ""){
      // console.log('profile_pic')

      let data = new FormData();
      data.append("image", editData.profile_pic );
  
      await axios.post(`${API_URL}/custom-games/upload`, data).then((result) => {
        allData.profile_pic = result.data;
      })
  }


  console.log('eddiittt-->',edit_user)  

  axios.post(`${API_URL}/auth/user/${edit_user._id}`, allData)
  .then(res => {
    setLoading(false)
      console.log(res)
      swal.fire({
          title: 'Success',
          text: 'User Updated successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
      }).then(function () {
        window.location.reload();
          // localStorage.setItem("profile_pic", allData.profile_pic)
        if(edit_user.role === "Admin"){
        localStorage.setItem("profile_pic", allData.profile_pic)
        }else{
          localStorage.setItem("inst_profile_pic", allData.profile_pic)
        }
    });
       
      for(const key of Object.keys(edit_user)) set_edit_user(prev => ({...prev, [key]:''}))
      // setLoading(false);
  }).catch(e => { 
      swal.fire({
          title: 'Error',
          text: e.message || e || 'Failed to Update. Try again!',
          icon: 'error',
          confirmButtonText: 'Ok'
      });
  });

}

const [loading, setLoading] = useState(false);

async function sendOtp() {
  const recaptcha = new firebase.auth.RecaptchaVerifier('send_otp', {
      'size': 'invisible',
    })
  const phoneNumber = `+91${admin[0]?.phone_number}`;

  firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
      .then((confirmationResult) => {
        // console.log('hello')

        window.confirmationResult = confirmationResult;

      }).catch((error) => {
          console.log(error)

      });
}


const adminAuthenticated = (result) => {
          $('#otp').hide()
            set_edit_user(admin[0])
          $('#edit_user_modal').show()
}



const verifyOtp = async() => {

  var submitted_otp = document.getElementById('phone_otp').value;

  try{
        const confirmationResult = window.confirmationResult;

        confirmationResult.confirm(submitted_otp).then((result) => {
          adminAuthenticated(result)

          }).catch((error) => {
            // console.log(error)
              alert('Otp not valid')
          
          });
    
  }
  catch(e){
      setError(e);
  }
  finally{
      setLoading(false);
  }
}


var var_profile_pic;

const imageChange = async(files) => {
  setInputs({ ...inputs, profile_pic: files[0]})
}

const imageChangeEdit = async(files) => {
  setEditData({ ...editData, profile_pic: files[0]})
}



const [edit_user, set_edit_user] = useState()

const editUser = (user) => {
  set_edit_user(user)
}

const [editData, setEditData] = useState({})


const clearUser = (e) => {
}

const handleChangeEdit = (e) => {
  setEditData({ ...editData, [e.target.name]: e.target.value })
}
const handleDeleteUser = (id) => {
  axios.delete(`${API_URL}/auth/user/${id}`)
  .then(res => {
    setLoading(false)
      console.log(res)
      swal.fire({
          title: 'Success',
          text: 'User Deleted successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
      }).then(function () {
        window.location.reload();
    });
  }).catch(e => {
      setLoading(false)
      swal.fire({
          title: 'Error',
          text: e.message || e || 'Failed to delete. Try again!',
          icon: 'error',
          confirmButtonText: 'Ok'
      });
  });

}
const handleSubmit = async(e) => {
  e.preventDefault();

  if(inputs.email_id){
  if (validator.isEmail(inputs.email_id) == false){
    // document.getElementById('email_alert').style.display = 'block'
    swal.fire({
      title: 'Error',
      text: "Please enter a valid email address",
      icon: 'error',
      confirmButtonText: 'Ok'
  });
    return
  }
}


    // let data = new FormData();
    // data.append("image", inputs.profile_pic);


    var allData = inputs;

    setLoading(true);

    if (inputs.profile_pic && inputs.profile_pic != ""){
      console.log('profile_pic')

      let data = new FormData();
      data.append("image", inputs.profile_pic );
  
      await axios.post(`${API_URL}/custom-games/upload`, data).then((result) => {
        allData.profile_pic = result.data;
      })

  }

  axios.post(`${API_URL}/auth/register`, inputs)
  .then(res => {
    setLoading(false)
      console.log(res)
      swal.fire({
          title: 'Success',
          text: 'User added successfully',
          icon: 'success',
          confirmButtonText: 'Ok'
      }).then(function () {
        window.location.reload();
    });
      for(const key of Object.keys(inputs)) setInputs(prev => ({...prev, [key]:''}))
      // setLoading(false);
  }).catch(e => {
      setLoading(false);
      swal.fire({
          title: 'Error',
          text: e.response.data || e.message || e || 'Failed to create. Try again!',
          icon: 'error',
          confirmButtonText: 'Ok'
      });
  });

}
  return (
    <div className='manage_page' style={{ marginTop : '-25px',  marginLeft : '-25px'}}>
      <div className='page_title_holder'>
      <Header title="Manage" />
        {/* <h3 className='page_title'>Manage</h3> */}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6}>
          <div className='page_sub_title'>
            <h5>
              Your Account <span>Institute Code: {institute_code}</span>
              <button
                type='button'
                data-toggle='modal'
                data-target='#otp'
                id='send_otp'
                onClick={()=> sendOtp()}
                className='edit-btn'>
                Edit
              </button>
            </h5>
            <p>
              We will use this to get in touch with you regarding the latest
              updates and new games in the market
            </p>
          </div> 
   
          <div className='profile_card'>

          <Fragment>
          <img src={admin[0]?.profile_pic} className='profile_image' alt='' />
            {/* <img src={profilePic  !== "" ?  profilePic : user_image} className='profile_image' alt='' /> */}
            {/* <img src={admin?.profile_pic && admin?.profile_pic != "" ? admin?.profile_pic : user_image} className='profile_image' alt='' /> */}
            <div className='profile_content'>
              <h3>
                {admin[0]?.name}<span>{admin[0]?.role}</span>
              </h3>
              <p>{admin[0]?.email_id}</p>
              <p>{admin[0]?.phone_number}</p>
            </div>
            </Fragment>
          </div>
   
        </Grid>

        <Grid item xs={6} sm={6}>
          <div className='page_sub_title'>
            <h5>Preferences</h5>
            <p>
              Enable or disable notifications, messages and other emails
              regarding to marketing according to your requirements
            </p>
          </div>
          <div className='preferences'>
            <div className='preference'>
              <FormControlLabel
                control={<Switch color="primary"/>}
                label="Send me desktop notifications"
              />
            </div>
            <div className='preference'>
              <FormControlLabel
                control={<Switch color="primary"/>}
                label="Get emails regarding updates and games"
              />
            </div>
            <div className='preference'>
              <FormControlLabel
                control={<Switch color="primary"/>}
                label="Keep me updated on my phone"
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <div className='instructor_holder'>
        <div className='instructor_title_holder'>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <div className='page_sub_title'>
                <h5>Other Accounts</h5>
                <p>
                  Configure and manage other accounts from here. All in one
                  place
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <button
                type='button'
                data-toggle='modal'
                data-target='#admin_details'
                className=' add_instructor_btn'>
                + Add Instructor
              </button>
            </Grid>
          </Grid>
        </div>

        <div className='other_account_container'>
          <Grid container spacing={3}>
          {admin.slice(1).map((admin,i) =>
            <Grid item xs={12} sm={3}>
       
              <div className='other_card_holder'>
                <button className='edit_btn'   
                data-toggle='modal'
                data-target='#edit_user_modal'
                onClick={() => editUser(admin)}
                >
                  <span className='fa fa-edit'></span>
                </button>
                <img
                  src={admin.profile_pic && admin.profile_pic != "" ? admin.profile_pic : user_image}
                  alt=''
                  className='profile_image'
                />
                <div className='content'>
                  <h1>{admin.name}</h1>
                  <span>{admin.role}</span>
                  <p style={{wordWrap : "break-word"}}> 
                  {admin.email_id?.substring(0,16) + "..." }
                  </p> 
                  <p>
                    {admin?.phone_number}
                  </p>
                  <a href='#' className=' show_activity_btn'>
                    Show Activity
                  </a>
                  <a className=' show_activity_btn'>
                    Delete
                  </a>
                </div>
              </div>
         
            </Grid>
               )}
          {instructors.map((instructor,i) =>
            <Grid item xs={12} sm={3}>
       
              <div className='other_card_holder'>
              <button className='edit_btn'   
                data-toggle='modal'
                data-target='#edit_user_modal'
                onClick={() => editUser(instructor)}
                >
                  <span className='fa fa-edit'></span>
                </button>
                <img
                  src={instructor.profile_pic && instructor.profile_pic != "" ? instructor.profile_pic : randomImages[Math.floor(Math.random() * randomImages.length)]}
                  alt=''
                  className='profile_image'
                />
                <div className='content'>
                  <h1>{instructor.name}</h1>
                  <span>{instructor.role}</span>
                  <p style={{wordWrap : "break-word"}}> 
                  {instructor.email_id.length > 17 ? 
                  instructor.email_id.substring(0,16) + "..." :
                  instructor.email_id}
                  </p>
                  
                  <p>{instructor.phone_number}</p>
                  <a href='#' className=' show_activity_btn'>
                    Show Activity
                  </a>
                  <button href='#' className=' show_activity_btn' onClick={(e) => handleDeleteUser(instructor._id)} style={{cursor: "pointer"}}>
                    Delete
                  </button>
                </div>
              </div>
         
            </Grid>
               )}
          </Grid> 
        </div>
      </div>

      <div className='custom_modal modal' id='otp'>
        <div className='modal-dialog modal-dialog-centered modal0sm'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-custom-content'>
                <h3 className='modal_title'>Please verify its you</h3>

                <div className='form-group'>
                  <label htmlFor=''>Enter OTP sent to Phone Number</label>
                  <input
                    type='password'
                    id='phone_otp'
                    className='form-control'
                  />
                </div>

                <button onClick={verifyOtp} type='submit' className=' btn-login btn-blue'>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    <form>
      <div className='custom_modal modal' id='admin_details'>
        <div className='modal-dialog modal-dialog-centered modal0sm'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-custom-content'>
                <h3 className='modal_title'>Add User</h3>

                <div className='form-group'>
                  <label htmlFor=''>Name</label>
                  <input
                  value={inputs.name} onChange={handleChange} 
                    type='text'
                    placeholder="sarah"
                    name='name'
                    id='name'
                    className='form-control'
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor=''>Phone Number</label>
                  <input
                  value={inputs.phone_number} onChange={handleChange} 
                    type='number'
                    // maxLength = "10"
                    placeholder="99999-99999"
                    name='phone_number'
                    id='phone_number'
                    className='form-control'
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor=''>Email Address</label>
                  <input
                  value={inputs.email_id} onChange={handleChange} 
                    type='email'
                    placeholder="sarah@abcschools.in"
                    // value=''
                    name='email_id'
                    id='email_id'
                    className='form-control'
                  />

                    <p id='email_alert' className='text text-danger' style={{display: 'none'}}>
                    Please enter a valid email address
                    </p>

                </div>
                <div className='form-group'>
                  <label htmlFor=''>Password</label>
                  <input
                  value={inputs.password} onChange={handleChange} 
                    type='password'
                    placeholder="password"
                    name='password'
                    id='password'
                    className='form-control'
                  />
                </div>

                <div className='form-group'>
                {/* <Form.Group> */}
                  <label htmlFor="role">Role</label>
                  <select className="form-control" id="roles" name="role" value={inputs.role} onChange={handleChange} required>
                  <option value="">Select one</option>
                      <option value='Admin'>Admin</option>
                      <option value='Instructor'>Instructor</option>
                
                  </select>
                </div>
                <div>
                <div   style={{width : '15vw'}}  >

                    <label htmlFor="image">User Image</label><br/>
                    < DropzoneArea
                        useChipsForPreview={true}
                        filesLimit = {1}
                        maxFileSize={50000000}
                        acceptedFiles={['image/*']}
                        dropzoneText={"Drag and drop image or Browse"}
                        onChange={(files)=>imageChange(files)}
                        />
                </div>
                </div>

                <div className='row button_container'>
                  <button type='submit' onClick={handleSubmit} className=' btn-login btn-blue' disabled={loading}>
                  {/* <Button id="button5" onClick={handleSubmit} disabled={loading}>{loading ? <CircularProgress size={24}/> : 'Create'} */}
                  {loading ? <CircularProgress color='inherit' size={18}/> : 'Add'}
                  </button>
                  <button
                    style={{backgroundColor : 'white', border : '1px solid lightgrey'}}
                    data-dismiss='modal'
                    type='button'
                    className='btn-login btn-white'
                    >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>



      <form>
      <div className='custom_modal modal' id='edit_user_modal'>
        <div className='modal-dialog modal-dialog-centered modal0sm'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-custom-content'>
                <h3 className='modal_title'>Edit User</h3>

                <div className='form-group'>
                  <label htmlFor=''>Your Name</label>
                  <input
                  defaultValue={edit_user?.name} onChange={handleChangeEdit} 
                  // value={editData?.name}
                    type='text'
                    placeholder="sarah"
                    name='name'
                    id='name'
                    className='form-control'
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor=''>Your Phone Number</label>
                  <input
                  defaultValue={edit_user?.phone_number} onChange={handleChangeEdit}
                  // value={editData?.phone_number}

                    type='number'
                    placeholder="99999-99999"
                    name='phone_number'
                    id='phone_number'
                    className='form-control'

                    
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor=''>Your Email Address</label>

                  <input
                  defaultValue={edit_user?.email_id} onChange={handleChangeEdit} 
                  // value={editData?.email_id}

                    type='email'
                    placeholder="sarah@abcschools.in"
                    // value=''
                    name='email_id'
                    id='email_id'
                    className='form-control'
                  />
                  <p id='email_alert' className='text text-danger' style={{display: 'none'}}>
                    Please enter a valid email address
                    </p>
                </div>
                <div className='form-group'>
                  <label htmlFor=''>Your password</label>
                  <input
                  defaultValue={edit_user?.password} onChange={handleChangeEdit} 
                    type= {passwordShown? 'text': 'password'}
                    placeholder="password"
                    name='password'
                    id='password'
                    className='form-control'
                  />
                  <input type="checkbox" onClick={togglePassword} style={{marginTop: "10px", marginLeft: "4px", width: "16px", height: "16px"}}/> <label htmlFor=''>Show Password</label>
                </div>

                <div className='form-group'>
                {/* <Form.Group> */}
                  <label htmlFor="role">Role</label>
                  <select className="form-control" id="roles" name="role" value={editData?.role || edit_user?.role} onChange={handleChangeEdit} required>
                  <option value="">Select one</option>
                      <option value='Admin'>Admin</option>
                      <option value='Instructor'>Instructor</option>
                
                  </select>
                </div>
                    <div   style={{width : '15vw'}}  >

                <label htmlFor="image">User Image</label><br/>
                <DropzoneArea
                    useChipsForPreview={true}
                    filesLimit = {1}
                    acceptedFiles={['image/*']}
                    maxFileSize={50000000}
                    dropzoneText={"Drag and drop image or Browse"}
                    onChange={(files)=>imageChangeEdit(files)}
                    />
                </div>


                <div className='row button_container'>
                  <button type='submit' onClick={handleEditSubmit} className=' btn-login btn-blue' disabled={loading}>
                  {loading ? <CircularProgress color='inherit' size={18}/> : 'Submit'}
                  </button>
                  <button
                    style={{backgroundColor : 'white', border : '1px solid lightgrey'}}
                    data-dismiss='modal'
                    type='button'
                    className=' btn-login btn-white'
                    // onClick={()=>clearUser()}
                    >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  )
}

export default Manage2
