// import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

//Carousel imports
import Carousel from 'react-multi-carousel';

//material-ui core imports
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

//material-ui style imports
import { makeStyles } from '@material-ui/core/styles';

import { useState, useEffect } from 'react';

//material-ui core imports
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

//material-ui lab imports
import Alert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { API_URL } from '../../../../../config/index';
import swal from 'sweetalert2';
import axios from 'axios';
import Slider from 'react-slick';
import compatibility_icon from '../../../Icons/Vector.png'




//common
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1224 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1224, min: 760 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 760, min: 0 },
        items: 1
    }
};

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '24px 0'
    },
    chip: {
        backgroundColor: '#BFC6FF',
        marginTop: '0.2vh',
        minHeight: '28px !important',
        minWidth: '62px',
        borderRadius: 4,
        opacity: 1,
        height: 'auto',
        '&>*:nth-child(1)': {
            padding: '3px 5px'
        }
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        textTransform: 'capitalize',
        marginTop: 24,
        '&>div:nth-child(1)': {
            width: '40%',
            display: 'flex',
            // alignItems: 'center',
            '&>img': {
                width: 80,
                height: 80,
                marginRight: 20,
                borderRadius: 12
            },
            '&>div': {
                '& h5': {
                    marginTop: '1vh',
                    fontSize: '1.4rem',
                    margin: '0 8px 4px 0',
                    fontWeight: 500
                },
                '& p': {
                    fontSize: '0.9rem',
                    marginTop: 4,
                    opacity: 0.6,
                    fontSize: 14
                },
                '&>div': {
                    display: 'flex',
                    alignItems: 'center',
                }
            }
        },
        '&>button': {
            textTransform: 'none',
            fontSize: '16px',
            padding: '5px 20px'

        }

    },
    gameProperties: {
        marginBottom: 8,
        '&>*': {
            textAlign: 'center',
            '&>h6': {
                fontSize: '14px',
                fontWeight: 400,
                opacity: 0.6,
                color: '#02113F',
                marginBottom: '0.6vh'
            },
            '&>*:nth-child(2)': {
                fontSize: '18px',
                fontWeight: 500,
                color: '#02113F',
                marginBottom: '0.6vh'
            }
            ,
            '&>*:nth-child(3)': {
                fontSize: '15px',
                fontWeight: 400,
                color: '#02113F',
                opacity: '0.45',
                marginBottom: '0.6vh'
            }
        },
        '&>*:nth-child(4)': {
            '&>*:nth-child(2)': {
                marginTop: 12
            }
        },
        '&>*:not(:last-child)': {
            borderRight: '1px solid #ddd'
        },
        '&>*:last-child': {
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&>*': {
                    margin: '0 3px'
                }
            }
        }
    },
    carouselContainer: {
        width: '100%',
    },
    carouselItem: {
        height: 350,
        padding: '0 6px',
        width: 'auto !important'
    },
    gameImages: {
        margin: '16px 0',

    },
    gameImage: {
        // width: 350,
        borderRadius: "16px",
        background: '#efefef',
    },
    gameDescription: {
        margin: '16px 0',
        '&>*:nth-child(2)': {
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                '&>*': {
                    margin: '0 6px 6px 0'
                }
            }
        },


        '& h6': {
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: 12,
            color: '#02113F',
            opacity: 0.6
        },
        '& p': {
            fontSize: '1rem',
            marginTop: 8,
            color: '#4C505C',
            fontSize: '18px',
            textAlign: 'justify',
        }
    },
    gameTags: {
        '& label': {
            fontWeight: '400',
            fontSize: '15px',
            padding: '10px 8px',
            margin: "0px 10px 10px 0px"
        }
    }
}));

const GameDetailTop = ({ game }) => {

    const [games, setGames] = useState()
    useEffect(() => {
        console.log('unregTop-->', game)
        setGames(game)
    }, [game])

    const settings = {
        autoplay: false,
        infinite: false,
        slidesToShow: 3,
        accessibility: true,
        arrows: true,
        slidesToScroll: 1
    };



    const handleCloseCreateModal = () => {
        setCreateModal(false);
    }

    const handleOpenCreateModal = () => {
        setCreateModal(true);
    }

    const handleChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
    }

    const [inputs, setInputs] = useState({
        name: '',
        role: "",
        phno: "",
        country: "",
        institute: "",
    })

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [createModal, setCreateModal] = useState(false);

    const classes = useStyles();

    const handleSubmit = async () => {
        console.log(inputs)
        try {
            setError(null);
            setLoading(true);
            axios.post(`${API_URL}/leads`, inputs)
                .then(res => {
                    // console.log(res)
                    swal.fire({
                        title: 'Success',
                        text: `We'll contact you soon.`,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    for (const key of Object.keys(inputs)) setInputs(prev => ({ ...prev, [key]: '' }))
                    // setLoading(false);
                }).catch(e => {
                    swal.fire({
                        title: 'Error',
                        text: e.message || e || 'Failed to create. Try again!',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
            setCreateModal(false);
        }
        catch (e) {
            setError(e);
        }
        finally {
            setLoading(false);
        }
    }

    var colors = ["#FFBFBF", "#FFF9BF", "#BFD1FF", "#BFFFC6"]


    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link style={{ color: '#4335D2', textDecoration: 'none' }} to={`/unregistered`}>
                    Game Store / {games?.name} /
                </Link>
                {/* <Typography>  {game?.name}</Typography> */}
            </Breadcrumbs>

            <div className={classes.gameTitle}>
                <div>
                    <img src={games?.image} alt="game" />
                    <div>
                        <div>
                            <Typography variant="h5" >{games?.name}</Typography>
                            <Chip label={games?.subjects[0]} className={classes.chip} />

                            {/* {games.subjects?.map((subject, i, subjects) => 

                             <Chip   label={subject[0]} className={classes.chip}/>
                            )} */}

                        </div>
                        <Typography variant="body1">{games?.short_description}</Typography>
                    </div>
                </div>
                <Button variant="contained" onClick={handleOpenCreateModal} style={{ marginTop: "-150px" }} color="primary">Get in touch</Button>
            </div>

            <Divider className={classes.divider} />

            <Grid container spacing={1} className={classes.gameProperties}>
                <Grid item xs={12} md>
                    <Typography variant="h6">AGE</Typography>
                    <Typography variant="body1">{games?.age} Years Old</Typography>
                    <Typography variant="caption">Grades {games?.grades}</Typography>
                </Grid>
                <Grid item xs={12} md>
                    <Typography variant="h6">MODES</Typography>
                    <Typography className={classes.body1}>Learning</Typography>
                    <span className={classes.caption}>{games?.modes}</span>
                </Grid>
                <Grid item xs={12} md>
                    <Typography variant="h6">TYPE</Typography>
                    <Typography variant="body1" >{games?.type}</Typography>
                    <Typography variant="caption">Grades {games?.grades}</Typography>
                </Grid>
                {games && games.compatibility &&
                    <Grid item xs={12} md>
                        <Typography variant="h6">COMPATIBILITY</Typography>
                        {games?.compatibility === 'IOS' ? <h1 className="light-text"><span class="iconify" style={{ fontSize: '50px', opacity: '0.5' }} data-icon="cib:app-store-ios" data-inline="false"></span><br /></h1>
                            :
                            <h1 className="light-text"> <img width="50px" src={compatibility_icon} /><br /></h1>}


                    </Grid>
                }
                <Grid item xs={12} md>
                    <Typography variant="h6">RATING</Typography>
                    <div>
                        <Typography variant="body1">{games?.rating}</Typography>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/gamedetail/rating.svg`} alt="rating" />
                    </div>
                    {/* <p className="light-text">RATING</p>
                  <h6 className="bold">{game.rating} <i className="mdi mdi-star" style={{ color: '#e75480' }}></i></h6> */}
                    <p className="light-text" style={{ opacity: 0.3 }}>{games?.reviews} ratings</p>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <div className={classes.gameImages}>
                    {game?.upload_media && <Carousel
                        responsive={responsive}
                        containerClass={classes.carouselContainer}
                        itemClass={classes.carouselItem}
                    >
                        {game?.upload_media?.map((g,i)=>
                            <div className={classes.gameImage} key={i}>
                                <img src={g} />
                            </div>
                        )}
                    </Carousel>}
                </div> */}

                    <Slider {...settings} className="scroll">

                        {games?.upload_media?.map((image, i) =>
                        (image.substring(image.length - 3, image.length) == 'mp4' ?
                            <div >
                                <video controls  >
                                    <source src={image} type="video/mp4" />
                                    {/* <source src="movie.ogg" type="video/ogg"> */}
                                </video>
                            </div>
                            :
                            <div><img src={image} alt="Img" /></div>)
                        )}
                    </Slider>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.gameDescription}>
                <Grid item xs={9}>
                    <Typography variant="h6">DESCRIPTION</Typography>
                    <Typography variant="body1">
                        {games?.long_description}
                    </Typography>

                </Grid>
                <Grid item xs={1}>
                    {" "}
                </Grid>
                {games && games.categories &&
                    <Grid item xs={2} className={classes.gameTags}>
                        <Typography variant="h6">TAGS</Typography>
                        {games && games?.categories?.map(category =>
                            <label className="badge" style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }} >{category}</label>

                        )}
                    </Grid>
                }
            </Grid>

            <Dialog
                className={classes.getInTouch}
                open={createModal}
                onClose={handleCloseCreateModal}
                aria-labelledby="get-in-touch-dialog"
                fullWidth={true}
                maxWidth="xs"
            >

                <DialogContent className={classes.createModal}>
                    <div>
                        <Typography variant="h5" style={{ fontWeight: '600' }} color="secondary">Hi, we are glad you are <br /> interested :D</Typography><br />
                        <Typography variant="p" style={{ fontSize: '14px' }} >Please fill in your details, for us to get in touch with you.</Typography>
                    </div><br />
                    <div>
                        <label htmlFor="name">Your Name</label>
                        <TextField placeholder='Sarah' id="name" autoFocus value={inputs.name} InputProps={{ name: 'name' }} onChange={handleChange} fullWidth variant="outlined" />
                    </div><br />
                    <div>
                        <label htmlFor="name">Your Number</label>
                        <TextField placeholder='9876543210' id="phno" type="number" autoFocus value={inputs.phno} InputProps={{ name: 'phno' }} onChange={handleChange} fullWidth variant="outlined" />
                    </div><br />
                    <div>

                        <label htmlFor="country">Your Country</label>
                        <Select fullWidth name='country' variant="outlined" id="country" value={inputs.country} onChange={handleChange} InputProps={{ name: 'country' }} required>
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="India">India</MenuItem>
                            <MenuItem value="Abroad">Abroad</MenuItem>
                        </Select>

                    </div><br />
                    <div>

                        <label htmlFor="role">You are a</label>
                        <Select fullWidth variant="outlined" id="role" value={inputs.role} name='role' onChange={handleChange} InputProps={{ name: 'role' }} required>
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="Teacher">Teacher</MenuItem>
                            <MenuItem value="Owner">Owner</MenuItem>
                        </Select>

                    </div><br />
                    <div>
                        <label htmlFor="institute">Enter Institute Name</label>
                        <TextField placeholder='ABC Group of schools' id="institute" autoFocus value={inputs.institute} InputProps={{ name: 'institute' }} onChange={handleChange} fullWidth variant="outlined" />
                    </div>
                    <br />

                    <div><br />
                        {error && <Alert severity="error">{error}</Alert>}
                    </div>

                    <div>
                        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Submit'}
                        </Button>
                    </div><br />
                    <div>
                        {/* <p style={{textAlign : "center"}}>Contact Sales</p><br/> */}
                        {/* <Button variant="contained" onClick={handleCloseCreateModal}>
                    Cancel
                </Button> */}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default GameDetailTop;
