import axios from 'axios';
    
axios.interceptors.request.use(request => {
  const token = localStorage.getItem('token');
  // request.headers['Authorization'] = `basic ${token}`;
  request.headers['x-auth-token'] =  token;
  return request;
}, function (error) {
  return Promise.reject(error);
});

export default axios;