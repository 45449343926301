import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//common
import Header from '../Header'
import Top from './Top';
import Bottom from './Bottom';

import {getGames} from '../../../redux/actions/gameAction';

const MyGames = (props, {match}) => {

  const dispatch = useDispatch();
  const institute_plan = localStorage.getItem('institute_plan')
  const game = useSelector(state => state.game);
  const allGames = game.games

  const myGames = (institute_plan == 'Advanced') ? allGames || [] : (institute_plan == 'Basic') ? (allGames || []).filter(game => game.plan === 'Basic') : (allGames || []).filter(game => game.plan != 'Advanced')
  

  useEffect(()=>{
    if(!game.games){
      dispatch(getGames());
    }
  },[])

  const [filterGames, setFilterGames] = useState();

  const searchGames = (e) => {

    var xyz = myGames?.filter(game => game.name.toLowerCase().includes(e.toLowerCase()))
    setFilterGames(xyz)
  }

  const cancelSearchGames = (e) => {
    setFilterGames()
  }


  return (
    <div>
        <Header title="My Games" search={searchGames} cancelSearch={cancelSearchGames}/>
        <Top games={myGames || []} {...props}/>
        <Bottom games={filterGames?.length > 0 ? filterGames : filterGames  ?  [] :  myGames}  {...props}/>
    </div>
  );
}

export default MyGames;
