import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Carousel imports
import Carousel from "react-multi-carousel";

//material-ui core imports
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
//material-ui style imports
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import multiDownload from "multi-download";
import Slider from "react-slick";
import axios from "axios";
import { API_URL } from "../../../../config";
import compatibility_icon from "../../Icons/Vector.png";
//common
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1224 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1224, min: 760 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 760, min: 0 },
    items: 1,
  },
};
const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "24px 0",
  },
  chip: {
    backgroundColor: "#BFC6FF",
    marginTop: "0.2vh",
    minHeight: "28px !important",
    minWidth: "62px",
    borderRadius: 4,
    opacity: 1,
    height: "auto",
    "&>*:nth-child(1)": {
      padding: "3px 5px",
    },
  },
  gameTitle: {
    display: "flex",
    width: "100% !important",
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // flexWrap: 'wrap',
    textTransform: "capitalize",
    marginTop: 24,
    "&>div:nth-child(1)": {
      // width : '40%',
      display: "flex",
      alignItems: "center",
      "&>img": {
        width: 80,
        height: 80,
        borderRadius: 12,
      },
      "&>div": {
        "& h5": {
          marginTop: "1vh",
          fontSize: "1.4rem",
          margin: "0 8px 4px 0",
          fontWeight: 500,
        },
        "& p": {
          fontSize: "0.9rem",
          marginTop: 4,
          opacity: 0.6,
          fontSize: 14,
        },
        "&>div": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    "&>button": {
      textTransform: "none",
      fontSize: "16px",
      padding: "5px 20px",
    },
  },
  gameProperties: {
    marginBottom: 8,
    "&>*": {
      textAlign: "center",
      "&>h6": {
        fontSize: "14px",
        fontWeight: 400,
        opacity: 0.6,
        color: "#02113F",
        marginBottom: "0.6vh",
      },
      "&>*:nth-child(2)": {
        fontSize: "18px",
        fontWeight: 500,
        color: "#02113F",
        marginBottom: "0.6vh",
      },
      "&>*:nth-child(3)": {
        fontSize: "15px",
        fontWeight: 400,
        color: "#02113F",
        opacity: "0.45",
        marginBottom: "0.6vh",
      },
    },
    "&>*:nth-child(4)": {
      "&>*:nth-child(2)": {
        marginTop: 12,
      },
    },
    "&>*:not(:last-child)": {
      borderRight: "1px solid #ddd",
    },
    "&>*:last-child": {
      "&>div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&>*": {
          margin: "0 3px",
        },
      },
    },
  },
  carouselContainer: {
    width: "100%",
  },
  carouselItem: {
    height: 350,
    padding: "0 6px",
    width: "auto !important",
  },
  gameImages: {
    margin: "16px 0",
  },
  gameImage: {
    // width: 350,
    borderRadius: "16px",
    background: "#efefef",
  },
  gameDescription: {
    margin: "16px 0",
    "&>*:nth-child(2)": {
      "&>div": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "&>*": {
          margin: "0 6px 6px 0",
        },
      },
    },

    "& h6": {
      fontSize: "16px",
      fontWeight: 500,
      marginBottom: 12,
      color: "#02113F",
      opacity: 0.6,
    },
    "& p": {
      fontSize: "1rem",
      marginTop: 8,
      color: "#4C505C",
      fontSize: "18px",
      textAlign: "justify",
    },
  },
  gameTags: {
    "& label": {
      fontWeight: "400",
      fontSize: "15px",
      padding: "10px 8px",
      margin: "0px 10px 10px 0px",
    },
  },
}));

const GameDetailTop = ({ game }) => {
  var MyGames;

  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(async () => {
    if (localStorage.getItem("MyInstalledGames").split(",")) {

      MyGames = localStorage.getItem("MyInstalledGames").split(",");
      MyGames = MyGames.filter((item, index) => MyGames.indexOf(item) === index);
      setIsInstalled(MyGames.includes(game._id));
    }
  }, [game]);

  const settings = {
    autoplay: false,
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 1,
  };

  // console.log(localStorage.getItem('MyInstalledGames').split(','))

  var colors = ["#FFBFBF", "#FFF9BF", "#BFD1FF", "#BFFFC6"];

  const classes = useStyles();

  const [isDownloaded, setIsDownloaded] = useState(false);

  const downloadFiles = async () => {
    var institute_id = localStorage.getItem("institute");

    var data = { game_id: game._id };

    await axios
      .post(`${API_URL}/institute/install-game/${institute_id}`, data)
      .then((res) => {
        localStorage.setItem("MyInstalledGames", res.data.game);
        let tempGames = localStorage.getItem("MyInstalledGames").split(",");
        tempGames = tempGames.filter((item, index) => tempGames.indexOf(item) === index);
        setIsInstalled(tempGames.includes(game._id));
        multiDownload(game.application_file);
        window.setTimeout(() => {
            setIsDownloaded(true);
        },1400)
      })
      .catch((e) => {
        console.log(e);
      });

    window.setTimeout(() => {
      setIsDownloaded(false);
    }, 20000);
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={`/labs/gamestore`}>
          Game Store / {game?.name}
        </Link>
        {/* <Typography>{game?.name}</Typography> */}
      </Breadcrumbs>

    {isDownloaded && <div className="download-app">
        <img src="/assets/images/mygames/download.png" />
        <p>Click <b>.exe</b> to run the application.</p>
      </div>}

      <div className={classes.gameTitle}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-lg-1">
            <img
              src={game?.image}
              style={{ borderRadius: "12px" }}
              width="80px"
              alt="game"
            />
          </div>

          <div className="col-lg-6">
            <div>
              <Typography variant="h5">{game?.name}</Typography>
              {game.subjects?.slice(0, 3).map((subject, i, subjects) => (
                <Chip
                  style={{ marginRight: "5px" }}
                  label={subject}
                  className={classes.chip}
                />
              ))}
              {/* <Chip style={{marginRight : '5px'}} label={game?.subjects?.[0]} className={classes.chip}/> */}
            </div>
            <div style={{ overflow: "hidden", width: "70%" }}>
              <Typography variant="body1">{game?.short_description}</Typography>
            </div>
          </div>

          {/* <div> */}
          <div className="col-lg-2"></div>

          <div className="col-lg-1">
            {isInstalled == true ? (
              <button
                style={{
                  backgroundColor: "#D23535",
                  border: "0px",
                  color: "white",
                  fontSize: "18px", 
                  padding: "10px 25px",
                  borderRadius: "5px",
                  float: "right",
                }}
                onClick={downloadFiles}
              >
                Uninstall
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "#4335D2",
                  border: "0px",
                  color: "white",
                  fontSize: "18px",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  float: "right",
                }}
                onClick={downloadFiles}
              >
                Install
              </button>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}

      <Divider className={classes.divider} />

      <Grid container spacing={1} className={classes.gameProperties}>
        <Grid item xs={12} md>
          <Typography variant="h6">AGE</Typography>
          <Typography variant="body1">{game?.age} Years Old</Typography>
          <Typography variant="caption">Grades {game?.grades}</Typography>
        </Grid>
        <Grid item xs={12} md>
          <Typography variant="h6">MODES</Typography>
          <Typography variant="body1">Learning</Typography>
          <Typography variant="caption">{game?.modes}</Typography>
        </Grid>
        <Grid item xs={12} md>
          <Typography variant="h6">TYPE</Typography>
          <Typography variant="body1">{game?.type}</Typography>
          <Typography variant="caption">Grades {game?.grades}</Typography>
        </Grid>
        {game.compatibility && (
          <Grid item xs={12} md>
            <Typography variant="h6">COMPATIBILITY</Typography>
            {game.compatibility === "IOS" ? (
              <h1 className="light-text">
                <span
                  class="iconify"
                  style={{ fontSize: "50px", opacity: "0.5" }}
                  data-icon="cib:app-store-ios"
                  data-inline="false"
                ></span>
                <br />
              </h1>
            ) : (
              <h1 className="light-text">
                {" "}
                <img width="50px" src={compatibility_icon} />
                <br />
              </h1>
            )}
            {/* <Typography variant="caption">{game?.compatibility}</Typography> */}
          </Grid>
        )}
        <Grid item xs={12} md>
          <Typography variant="h6">RATING</Typography>
          <div>
            <Typography variant="body1">{game.rating}</Typography>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/gamedetail/rating.svg`}
              alt="rating"
            />
          </div>
          {/* <p className="light-text">RATING</p>
                  <h6 className="bold">{game.rating} <i className="mdi mdi-star" style={{ color: '#e75480' }}></i></h6> */}
          <p className="light-text" style={{ opacity: 0.3 }}>
            {game.reviews} ratings
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Slider {...settings} className="scroll">
            {/* <div>    <ReactPlayer
                    url="https://youtu.be/OBFQkXIW0Mc"
                    id="video"
                    playing
                    width="100%"
                    height="300px"
                    style={{ borderRadius: "15px" }}
                    playIcon={<button className="button-play"><i className="text-light mdi mdi-play-circle"></i></button>}
                    light={true}
                  /></div> */}
            {game.upload_media?.map((image, i) =>
              image.substring(image.length - 3, image.length) == "mp4" ? (
                <div>
                  <video controls>
                    <source src={image} type="video/mp4" />
                    {/* <source src="movie.ogg" type="video/ogg"> */}
                  </video>
                </div>
              ) : (
                <div>
                  <img src={image} alt="Img" />
                </div>
              )
            )}
          </Slider>
          {/* <div className={classes.gameImages}>
                    {game?.upload_media && <Carousel
                        responsive={responsive}
                        containerClass={classes.carouselContainer}
                        itemClass={classes.carouselItem}
                    >
                        {game?.upload_media?.map((g,i)=>
                            <div className={classes.gameImage} key={i}>
                                <img src={g}  height='350vh'width="350vh"/>
                            </div>
                        )}
                    </Carousel>}
                </div> */}
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.gameDescription}>
        <Grid item xs={9}>
          <Typography variant="h6">DESCRIPTION</Typography>
          <Typography variant="body1" style={{ textAlign: "justify" }}>
            {game.long_description}
          </Typography>
          {/* <Typography variant="body1">
                    Get the key insights from 4,500+ bestselling non-fiction books & podcasts that cover leadership, history, psychology & more.
                </Typography> */}
        </Grid>
        <Grid item xs={1} className={classes.gameTags}></Grid>
        {game.categories && (
          <Grid item xs={2} className={classes.gameTags}>
            <Typography variant="h6">TAGS</Typography>
            {game.categories.map((category) => (
              <label
                className="badge"
                style={{
                  marginLeft: "10px",
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                  fontSize: "14px",
                  margin: "10px 10px 10px 0px",
                  padding: "8px",
                }}
              >
                {category}
              </label>
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default GameDetailTop;
