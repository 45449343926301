import { useState, Fragment, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

//material-ui lab imports
import Skeleton from "@material-ui/lab/Skeleton";

//common

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`app-tab-${index}`}
      aria-labelledby={`app-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0",
    "& h5": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: "24px 0",
  },
  chip: {
    marginTop: "0.2vh",
    minHeight: "28px !important",
    minWidth: "62px",
    borderRadius: 4,
    height: "auto",
    "&>*:nth-child(1)": {
      padding: "3px 5px",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "&>*": {
      marginTop: 8,
    },
    "&>*:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      "&>*": {
        margin: "0 4px",
      },
    },
  },
  gameImage: {
    // marginRight : '-25px',
    "&>img": {
      width: "120px",
      height: "120px",
      borderRadius: "16px",
    },
  },
  gameTitle: {
    textTransform: "capitalize",
    display: "flex",
    color: "#02113F",
    fontSize: "24px",
    alignItems: "start",
    "&>*:nth-child(1)": {
      textDecoration: "none",
      color: "#000",
    },
    "&>*:nth-child(2)": {
      marginLeft: 8,
    },
  },
  packagesCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "var(--primary)",
    textTransform: "none",
  },
  packageView: {
    padding: "12px",
    "& h6": {
      color: "var(--text-primary)",
    },
    "& button": {
      color: "var(--primary)",
      textTransform: "none",
      fontSize: "1rem",
    },
    "&>*:nth-child(3)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "&>*": {
        textAlign: "right",
      },
    },
  },
  Description: {
    opacity: "0.6",
    fontSize: "14px",
    color: "#02113F",

    lineHeight: "22px",
  },
}));

const Bottom = ({ match, games }) => {
  const [sortedGames, setSortedGames] = useState([]);

  const [, setUpdates] = useState();
  const forceUpdate = useCallback(() => setUpdates({}), []);

  useEffect(() => {
    setSortedGames(games);
  }, [games]);

  var array = [];
  sortedGames &&
    sortedGames?.map((game) => array.push(game.platform?.toLowerCase()));

  var games_platform = [...new Set(array)];
  console.log("gamessplattt-->", games_platform);

  const displayText = {
    ios: "iOS",
    kinect: "Kinect",
  };

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [packagesModal, setPackagesModal] = useState(false);
  const [app, setApp] = useState("");
  const [filterGames, setFilterGames] = useState([]);

  //   useEffect(() => {
  //     setApp("All")
  // }, [])

  useEffect(() => {
    setApp("All");
    setFilterGames(games);
  }, [games]);

  const handleChangeApp = (event) => {
    console.log("filterddgmss-->", filterGames);
    setApp(event.target.value);
    if (event.target.value.toUpperCase() === "ALL") {
      setFilterGames(games);
    } else {
      console.log("event.target.value-->", event.target.value.toUpperCase());
      var filteredGames = games.filter(
        (game) =>
          game.platform.toUpperCase() === event.target.value.toUpperCase()
      );
      console.log("after filterddgmss-->", filteredGames);
      setFilterGames(filteredGames);
    }
  };

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  const handlePackagesTriggerModal = () => {
    setPackagesModal((prev) => !prev);
  };

  const sort_by = (e) => {
    if (e.target.value == "A-Z") {
      var x = games.sort((a, b) => a.name.localeCompare(b.name));
      // console.log(x)
      setSortedGames(x);
      forceUpdate();
    } else if (e.target.value == "Z-A") {
      var x = games.sort((a, b) => a.name.localeCompare(b.name)).reverse();
      // console.log(x)
      setSortedGames(x);
      forceUpdate();
    }
  };

  var colors = ["#FFBFBF", "#BFE0FF", "#F6BFFF"];

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Typography variant="h5">All Games</Typography>
        <div>
          {/* {console.log('gamesssSorr-->',sortedGames[3].platform)} */}
          <div style={{ minWidth: "5vw" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Filter
              </InputLabel>
              <Select
                style={{
                  width: "160px",
                  backgroundColor: "white",
                  height: "55px",
                }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                value={app}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={"All"}
                onChange={handleChangeApp}
                // handleChange={handleChangeApp}
                label="Discover"
              >
                <MenuItem value="All">All Applications</MenuItem>
                {games_platform.map((game) => (
                  // console.log('gamessorteddd--->',games,games.platform),
                  //   <MenuItem style={{textTransform: 'capitalize'}} value={game}>{game}</MenuItem>
                  <MenuItem value={displayText[game]}>
                    {displayText[game]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* <FormControl variant="outlined">
                    <InputLabel htmlFor="filter">Filter</InputLabel>
                    <Select 
                       style={{width : '12vw'}}
                        value={app} 
                        label="Filter"
                        inputProps={{
                            id: 'filter',
                        }}
                        onChange={handleChangeApp}
                    >
                        <MenuItem value="All Applications" selected>All Applications</MenuItem>
                        <MenuItem value="ios">ios</MenuItem>
                        <MenuItem value="kinnect">Kinnect</MenuItem>
                        {sortedGames.map(games=>
                      <MenuItem style={{textTransform: 'capitalize'}} value={games.platform}>{games.platform}</MenuItem>
                        )}
                    </Select>
                </FormControl> */}
          <FormControl variant="outlined">
            <InputLabel htmlFor="sortBy">Sort by</InputLabel>
            <Select
              value="Recently Installed"
              label="Sort by"
              inputProps={{
                id: "sortBy",
              }}
            >
              <MenuItem value="Recently Installed" selected>
                Recently Installed
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.bottom}>
        <Grid container spacing={3}>
          {console.log("fillll-->", filterGames)}
          {filterGames &&
            filterGames.map((game) => (
              <Grid key={game.id} item xs={12} md={6}>
                <div className="row container">
                  <div className="col-lg-3 lg-12" className={classes.gameImage}>
                    <img src={game.image} alt="game" />
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className={classes.gameTitle}>
                        <div className="ml-3">
                          <Typography variant="h6">
                            {" "}
                            <Link
                              to={`${match.path}/${game._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#02113F !important",
                              }}
                            >
                              {game.name}
                            </Link>
                          </Typography>
                        </div>
                        <div className="">
                          <Chip
                            style={{
                              backgroundColor:
                                colors[
                                  Math.floor(Math.random() * colors.length)
                                ],
                            }}
                            label={game.subjects[0]}
                            className={classes.chip}
                          />
                        </div>
                      </div>
                    </div>

                    <Typography
                      variant="body1"
                      className={classes.Description}
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        height: "85px",
                      }}
                    >
                      {game.long_description.substring(0, 249)}...
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>

      <Dialog
        onClose={handlePackagesTriggerModal}
        aria-labelledby="packages-dialog"
        open={packagesModal}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Packages</Typography>
          <Button
            aria-label="close"
            className={classes.packagesCloseButton}
            onClick={handlePackagesTriggerModal}
          >
            Close
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {["Basic", "Classic", "Elite"].map((p, i) => (
              <Grid key={p} item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  className={classes.packageView}
                  style={{ background: i === 0 ? "#ddd" : "none" }}
                >
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">{p}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">6 Games</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div>
                      {i === 0 ? (
                        <Typography variant="h6">Current plan</Typography>
                      ) : (
                        <Button>Upgrade</Button>
                      )}
                      {i !== 0 && (
                        <Typography variant="body1">$ ****.**</Typography>
                      )}
                      <Typography variant="body1">
                        {i === 0
                          ? "One time purchase"
                          : "for one time purchase"}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Bottom;
