import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//components
import Top from './Top';
import Bottom from './Bottom';

import {getGames, getCustomGames, createCustomGame, editCustomGame, deleteCustomGame} from '../../../../redux/actions/gameAction';
import {getGroups} from '../../../../redux/actions/studentAction';

const ViewMyGame = (props) => {

  // console.log(props);

  const dispatch = useDispatch();
  const game = useSelector(state => state.game);
  const student = useSelector(state => state.student);

  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    if(!game.games){
      dispatch(getGames());
    }
    if(!game.customGames){
      // console.log('checking')
      dispatch(getCustomGames());
    }
    if(!student.groups){
      dispatch(getGroups());
    }
  }, [])

  const handleCreateCustomGame = async (inputs) => {
    await dispatch(createCustomGame(inputs))
  }
  
  const handleEditCustomGame = async (inputs) => {
    await dispatch(editCustomGame(inputs))
  }

  const handleDeleteCustomGame = async (id) => {
    await dispatch(deleteCustomGame(id))
  }

  console.log(game.customGames)
  return (
    <div>
      <Top game={game?.games ? (game?.games?.find(g => g._id===props?.match.params.id) || {}) : {}}/>
      <Bottom deleteCustomGame={handleDeleteCustomGame} createCustomGame={handleCreateCustomGame} editCustomGame={handleEditCustomGame} 
      customGames={game.customGames ? game.customGames.filter(g => g.game._id === props.match.params.id) : []} groups={student.groups || []} game={game.games ? (game.games.find(g => g._id===props.match.params.id) || {}) : {}} {...props}/>
    </div>
  );
}

export default ViewMyGame;
