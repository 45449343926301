import {useState, forwardRef} from 'react';
import {Link} from 'react-router-dom';
import {useEffect} from 'react'

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'; 
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchBar from "material-ui-search-bar";
import customGameImage from '../../Icons/customGame.png'
// import MultiSelect from "@khanacademy/react-multi-select";
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



//material-ui icon imports
import CloseIcon from '@material-ui/icons/Close';
import filter_icon from '../../Icons/filter.png'
import copy_icon from '../../Icons/copy_icon.png'
import delete_icon from '../../Icons/delete_icon.png'
import edit_icon from '../../Icons/edit_icon.png'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

//common
import CreateForm from './Components/CreateForm';
import EditForm from './Components/EditForm';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref}   {...props} />;
  });
  

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    button: {
        textTransform: 'initial'
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: 24,
        color: '#02113F',
        '&>*':{
            marginTop: 8
        },
        '&>*:nth-child(2)':{
            display: 'flex',
            alignItems: 'center',
            '&>*':{
                margin: '0 4px'
            }
        }
    },
    table: {
        '&>*:nth-child(1)':{
            background: '#E4E9F3'
        }
    },
    gameActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&>*':{
            marginRight: 4
        }
    },
    createGame: {
        padding: '24px 48px',
        '& h4':{
            fontWeight: 500
        },
        '&>*:nth-child(1)':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    step : {
        color : '#02113F',
        fontSize : '20px'
    },
    description: {
        color :  '#02113F',
        opacity : 0.7,
        fontSize : '16px',
        letterSpacing : '0.4px',
        marginBottom : '15px'
    },
    column : {
        width : '25%',
        paddingLeft: '30px',
        textAlign : 'left',
    },
    column : {
        width : '25%',
        paddingLeft: '30px',
        textAlign : 'left',
    },
    grades : {
        padding : '10px 10px',
        marginLeft : '-10px',
        borderRadius : '5px',
        '&:hover': {
            background: "#CCD5E8",
         },
    },
    centerAlign : {
        textAlign : 'center',
    }, 
    rightAlign : {
        textAlign : 'center',

    }, success: {
        display : 'none',
        textAlign : 'center',
        color : '#02113F',
        backgroundColor: '#DBF1CA',
        borderRadius : '4px',
        border : '1px solid #5E9B2E',
        padding : '8px 10px',
        marginLeft: '12%',
        width : '35%',
        fontSize : '15px',
        position : 'fixed',
        marginTop : '65vh',
        marginBottom : '10px'

    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewMyGameBottom = ({match, game, groups, customGames, createCustomGame, editCustomGame, deleteCustomGame}) => {

  const classes = useStyles();




  const [page, setPage] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [currentCustomGames, setCurrentCustomGames] = useState()

  useEffect(()=>{
    setCurrentCustomGames(customGames)
  }, [customGames])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenCreateModal = () => {
    setCreateModal(true);
  }

  const handleCloseCreateModal = () => {
    setCreateModal(false);
  }
  
  const handleOpenEditModal = (id) => {
    setEditModal(id);
  }

  const handleCloseEditModal = () => {
    setEditModal(null);
  }

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(id);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(null);
  }

  const handleDeleteCustomGame = async () => {
      
    try{
        setDeleting(true);
        await deleteCustomGame(deleteModal);
        setDeleteModal(null);
    }
    catch(e){
        alert(e);
    }
    finally{
        setDeleting(false);
    }
  }


  const difficulty_level_color = (level) => {
      if (level === "Easy"){
          return '#48CD27'
      } else  if (level === "Medium"){
        return '#272ECD'
    } else {
        return "#CD2727"
    }
  }

    const [open, setOpen] = useState(false);

    const [filterGrades, setFilterGrades] =useState([]);


  const handleChange = (event) => {
      

    if (event.target.checked == true){
        setFilterGrades([...filterGrades, event.target.value])

        var m = filterGrades[0] ? filterGrades[0] : event.target.value

        setCurrentCustomGames(customGames.filter(cg => cg.grades.includes(m)));

        return
    } else if (event.target.checked == false){
        var x = filterGrades.filter(fg => fg != event.target.value)
        setFilterGrades(x)

        if (x.length == 0) {
            setCurrentCustomGames(customGames);
    
        }
    }
  };

  const handleCloseSelect = () => {
    setOpen(false);
  };

  const handleOpenSelect = () => {
    setOpen(true);
  };

  var array = []
  customGames.map((cg) => cg.grades.map((grade) => array.push(grade.toLowerCase()) ))
 
  var grades = [...new Set(array)]



    const searchCustomgames = (e) => {
        if (e == null || e== "" || e == undefined){
            setCurrentCustomGames(customGames)
        }

        var xyz = customGames.filter(cg => cg.name.toLowerCase().includes(e.toLowerCase()))
        setCurrentCustomGames(xyz);
    }


  const cancelSearchCustomGames = (e) => {
    setCurrentCustomGames(customGames)
  }



  return (
    <div className={classes.root}>

    <p className={classes.success} id='success'></p>

        {customGames.length == 0 ? 
        
        <div>

        <div className={classes.top}>

            <Typography variant="h6">Your Custom Games</Typography>
        </div>
    
        <div style={{display : 'flex', flexDirection: 'column', alignItems: 'center', marginTop : '15vh', height :'30vh', justifyContent : 'space-between'}}>
            <img src={customGameImage} height='83px' width='83px'/>
            <h3 className={classes.step}>Create your first game!</h3>
            <p className={classes.description}>Looks like you have not created any custom games yet. The games that you <br/>
            create will be curated here. Need any help? Get started with this <a href="#">tutorial.</a></p>
            <Button color='primary' variant="contained" style={{textTransform : 'none'}} onClick={handleOpenCreateModal} >Create Game</Button>

        </div>

        <Dialog fullScreen open={createModal} onClose={handleCloseCreateModal} TransitionComponent={Transition}>
            <Grid container className={classes.createGame}>
                <Grid item xs={12}>
                    <div>
                        <Typography variant="h4" gutterBottom>Create Game</Typography>
                        <Typography variant="body1">For {game.name}</Typography>
                    </div>
                    <IconButton onClick={handleCloseCreateModal}><CloseIcon/></IconButton>
                </Grid>   

                <Grid item xs={12}>
                    <Divider className={classes.divider}/>
                </Grid>

                <Grid item xs={12}>
                    <CreateForm closeModal={() => setCreateModal(false)} game={game} groups={groups} createCustomGame={createCustomGame} match={match}/>
                </Grid>
            </Grid>
      </Dialog>
      



        </div>

        
        :
        <div>
        <div className={classes.top}>
            <Typography variant="h6">{customGames.length} Custom Games</Typography>
            <div>
                {/* <IconButton>
                    <SearchIcon/>
                </IconButton> */}
                    <SearchBar
                      onChange={(e) => searchCustomgames(e)}
                      onCancelSearch={(e) => cancelSearchCustomGames(e)}
                    />
                <Button variant="contained" color="primary" style={{textTransform : 'none'}} onClick={handleOpenCreateModal}>Create Game</Button>
            </div>
        </div>


        <div className={classes.bottom}>
            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{backgroundColor :'#E4E9F3', opacity : 0.5}}>
                                <TableCell className={classes.column} >Game Name</TableCell>
                                <TableCell  className={classes.column} >
                                     <span onClick={handleOpenSelect} className={classes.grades} >
                                    {/* <button>         */}
                                    <button style={{border : '0px', backgroundColor : 'transparent'}}>
                                    Grades
                                    <img height="10px" style={{marginLeft : '3px'}} src={filter_icon} />
                                    </button>
                                </span>

                                

                                <FormControl sx={{contentVisibility: 'hidden', marginLeft : '-50px', marginTop : '-10px'}} color="success">
                                    {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
                                    <Select
                                    
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    open={open}
                                    value={filterGrades}
                                    onClose={handleCloseSelect}
                                    onOpen={handleOpenSelect}
                                    // input={<OutlinedInput label="Tag" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    // MenuProps={MenuProps}
                                    >
                                    {grades.map((grade) => (
                                        <MenuItem value={grade} >
                                        <Checkbox value={grade} onChange={handleChange} />
                                        <ListItemText primary={grade} />
                                        </MenuItem>
                                    ))} 
                                    </Select>
                                </FormControl>
                                    </TableCell>
                                <TableCell  className={classes.column}>Subjects</TableCell>
                                <TableCell  className={classes.column}>Difficulty Level</TableCell>
                                <TableCell className={classes.rightAlign}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {currentCustomGames?.map(cg => (
                            <TableRow key={cg._id}>
                                <TableCell component="th" scope="row" className={classes.column}>
                                    <Link style={{textDecoration: 'none'}} to={`${match.url}/${cg._id}`}>{cg.name}</Link>
                                </TableCell>
                                <TableCell  className={classes.column}>
                                    {cg.grades.map((grade,key) => 
                                        key == cg.grades.length -1 ?
                                        <span>{grade}</span> : 
                                        <span>{grade}, </span>
                                    )}
                                </TableCell>
                                <TableCell className={classes.column}>
                                {cg.subjects.map((subject,key) => 
                                        key == cg.subjects.length -1 ?
                                        <span>{subject}</span> : 
                                        <span>{subject}, </span>
                                    )}
                                </TableCell>
                                <TableCell className={classes.column}><p style={{height : "10px", width : '10px', backgroundColor : difficulty_level_color(cg.difficulty_level), display : 'inline-block', borderRadius : '50%', marginRight : '10px', marginTop : '5px !important'}}></p>{cg.difficulty_level}</TableCell>
                                <TableCell className={classes.centerAlign}>
                                    <div className={classes.gameActions}>
                                        <span style={{ backgroundColor:"#E2E6F1", height : '30px', width : "30px", borderRadius : '5px' ,marginRight : '10px' }} onClick={()=>handleOpenEditModal(cg._id)}><img style={{marginTop : '7px'}} fontSize="small" src={edit_icon} height='16px'/></span>
                                        {/* <span style={{ backgroundColor:"#E2E6F1", height : '30px', width : "30px", borderRadius : '5px' ,marginRight : '10px' }} > */}
                                            {/* <img style={{marginTop : '7px'}} fontSize="small" src={edit_icon} height='16px'/> */}
                                        {/* </span> */}
                                        {/* <span style={{ backgroundColor:"#E2E6F1", height : '30px', width : "30px", borderRadius : '5px' ,marginRight : '10px' }} ><img src={copy_icon} style={{marginTop : '7px'}}  height='16px'/></span> */}
                                        <span style={{ backgroundColor:"#E2E6F1", height : '30px', width : "30px", borderRadius : '5px' }}  onClick={()=>handleOpenDeleteModal(cg._id)}>
                                            <img height='16px' style={{marginTop : '7px'}}  src={delete_icon}/>
                                        </span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {customGames.length === 0 ? "" :<TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={10}
                    rowsPerPage={10}
                    page={page}
                    onChangePage={handleChangePage}
                /> } 
                    </Paper>

        </div>

        <Dialog
            open={deleteModal}
            onClose={handleCloseDeleteModal}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the custom game <b>{customGames && customGames.find(cg => cg._id===deleteModal)?.name}</b> ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleCloseDeleteModal} color="primary">
                    Cancel
                </Button>
                <Button className={classes.button} disabled={deleting} onClick={handleDeleteCustomGame} color="primary" autoFocus>
                    {deleting ? <CircularProgress size={24}/> : 'Yes, Delete'}
                </Button>
            </DialogActions>
        </Dialog>

        {/*Create custom game modal*/}
        <Dialog fullScreen open={createModal} onClose={handleCloseCreateModal} TransitionComponent={Transition}>
            <Grid container className={classes.createGame}>
                <Grid item xs={12}>
                    <div>
                        <Typography variant="h4" gutterBottom>Create Game</Typography>
                        <Typography variant="body1">For {game.name}</Typography>
                    </div>
                    <IconButton onClick={handleCloseCreateModal}><CloseIcon/></IconButton>
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider}/>
                </Grid>

                <Grid item xs={12}>
                    <CreateForm closeModal={() => setCreateModal(false)} game={game} groups={groups} createCustomGame={createCustomGame} match={match}/>
                </Grid>
            </Grid>
      </Dialog>
      
      {/*Create custom game modal*/}
      <Dialog fullScreen open={editModal} onClose={handleCloseEditModal} TransitionComponent={Transition}>
            <Grid container className={classes.createGame}>
                <Grid item xs={12}>
                    <div>
                        <Typography variant="h4" gutterBottom>Edit Game</Typography>
                        <Typography variant="body1">For {game.name}</Typography>
                    </div>
                    <IconButton onClick={handleCloseEditModal}><CloseIcon/></IconButton>
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider}/>
                </Grid>

                <Grid item xs={12}>
                    <EditForm closeModal={() => setEditModal(null)} game={game} groups={groups} editCustomGame={editCustomGame} match={match} customGame={customGames.find(c => c._id===editModal) || {}} />
                </Grid>
            </Grid>
      </Dialog>
      </div>}
    </div>
  );
}

export default ViewMyGameBottom;
