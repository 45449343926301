import { useState } from "react";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DropzoneArea } from "material-ui-dropzone";
import { Link, useLocation, useHistory } from "react-router-dom";
import multiDownload from "multi-download";

//material-ui icon imports

//common

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "12px 0",
    "& h5": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: "24px 0",
  },
  button: {
    textTransform: "initial",
  },
  drop: {
    width: "63%",
    minHeight: "204px",
  },

  textField: {
    width: "100%",
  },
}));

const AddStudentForm = ({
  mode,
  inputs,
  handleChange,
  handleChangeMode,
  imageChange,
  handleCSV
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            value="end"
            control={
              <Radio
                color="primary"
                checked={mode === "single"}
                value="single"
                onChange={handleChangeMode}
              />
            }
            label="Single Student"
          />
          <FormControlLabel
            value="end"
            control={
              <Radio
                color="primary"
                checked={mode === "bulk"}
                value="bulk"
                onChange={handleChangeMode}
              />
            }
            label="Bulk Upload"
          />
        </Grid>

        <Grid item xs={12}>
          {mode === "single" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="name">Student Name</label>
                <TextField
                  id="name"
                  autoFocus
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={inputs.name}
                  InputProps={{ name: "name" }}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="ph_number">Phone Number</label>
                <TextField
                  id="ph_number"
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={inputs.phone_number}
                  InputProps={{ name: "phone_number" }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="email">Email</label>
                <TextField
                  id="email"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={inputs.email_id}
                  InputProps={{ name: "email_id" }}
                />
                <p
                  id="email_alert"
                  className="text text-danger"
                  style={{ display: "none" }}
                >
                  Please enter a valid email address
                </p>
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: "15vw" }}>
                  <label htmlFor="profile_pic">Profile Picture</label>
                  <br />
                  <br />
                  {/* <input type="file" id="profile_pic" name="profile_pic" onChange={imageChange} InputProps={{name:'profile_pic'}}/> */}
                  <DropzoneArea
                    useChipsForPreview={true}
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    maxFileSize={50000000}
                    dropzoneText={"Drag and drop image or Browse"}
                    onChange={(files) => imageChange(files)}
                    InputProps={{ name: "profile_pic" }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ width: "25vw" }}>
                  <label htmlFor="bulk_file">Bulk File</label>
                  <br />
                  {/* <input type="file" id="bulk_file"/> */}
                  <DropzoneArea
                    useChipsForPreview={true}
                    filesLimit={1}
                    acceptedFiles={[".xlsx"]}
                    maxFileSize={50000000}
                    dropzoneText={"Drag and drop image or Browse"}
                    onChange={(files)=> handleCSV(files)}
                  />
                </div>
              </Grid>
              <a href="https://eduzo.s3.ap-south-1.amazonaws.com/Eduzo+Student+Bulk.csv" >Download Sample Format File</a>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AddStudentForm;
