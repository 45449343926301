
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//common

const useStyles = makeStyles(theme => ({
    main: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    top: {
        width: '90%',
        textAlign: 'center',
        margin: '0 auto 24px auto',
        '&>h1':{
            fontWeight: 800,
            color: '#9996BA',
            margin: '16px 0'
        },
        '&>h3':{
            fontWeight: 500,
            color: '#02113F'
        },
        '&>p':{
            fontWeight: 400,
            margin: '16px 0',
            color: '#02113F'
        }
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 24,
        '&>img':{
            width: 180
        }
    }
}));

const NotFound = ({ history }) => {

    const classes = useStyles();

    return (
        <main className={classes.main}>
            <div className={classes.top}>
                <Typography variant="h1">404</Typography>
                <Typography variant="h3">We are sorry, Page not found</Typography>
                <Typography variant="body1">The page you are looking for might have been removed, or has gone missing.</Typography>

                <Button variant="contained" color="primary" onClick={()=>history.push('/')}>Go back to homepage</Button>
            </div>

            <div className={classes.bottom}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/errorpage/emoji.svg`} alt="error"/>
            </div>
        </main>
    );
};

export default NotFound;
