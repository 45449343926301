import {useState} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

//material-ui lab imports
import Skeleton from '@material-ui/lab/Skeleton';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    chip: {
        marginTop : '0.2vh',
        minHeight : '28px !important',
        minWidth : '62px',
        borderRadius: 4,
        textTransform: 'capitalize',
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '&>*':{
            marginTop: 8
        },
        '&>*:nth-child(2)':{
            display: 'flex',
            alignItems: 'center',
            '&>*':{
                margin: '0 4px'
            }
        }
    },
    gameImage: {
        '&>img':{
            width: '120px',
            height: '120px',
            borderRadius: "12px"

        }
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'start',
        '&>*:nth-child(1)':{
            textDecoration: 'none',
            textTransform : 'capitalize',
            color : '#02113F',
            fontSize : '24px',
            fontWeight : 'normal',
            color: '#000'
        },
        '&>*:nth-child(2)':{
            marginLeft: 8
        }
    },
    Description : {
        opacity : "0.6",
        fontSize : '14px',
        color : "#02113F",

        lineHeight : '22px'
    }
}));

const GameDetailBottom = ({match, similarGames}) => {

  const classes = useStyles();
  var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];


  return (
    <div className={classes.root}>
        <div className={classes.top}>
            <Typography variant="h5">Similar apps you might like</Typography>
        </div>

        <Divider className={classes.divider}/>

       

        <div className={classes.bottom}>
            <Grid container spacing={3}>
                {similarGames && similarGames.slice(0,4).map(game => 
                       <Grid key={game.id} item xs={12} md={6}>
                       <div className="row container">
                       <div className="col-lg-3 lg-12" className={classes.gameImage}>
                           <img src={game.illustration_img} alt="game"/>
                       </div>
                       <div className="col-md-8" >
                          
                       <div className="row">
                       <div className={classes.gameTitle}>
                       <div className="col">

                               <Typography variant="h6" style={{color :'#02113F !important'}} ><a href={`${game._id}`} style={{textDecoration: 'none'}}>{game.name}</a></Typography>
                               </div>
                       <div className="col-sm-2">

                               <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} label={game.subjects[0]}  className={classes.chip}/>
                               </div>
                               </div>
                               </div>

                           <Typography variant="body1" className={classes.Description} style={{textOverflow: 'ellipsis', overflow: 'hidden', height: '85px', }}>
                              {game.long_description.substring(0,249)}...
                           </Typography>
                     
                       </div>
                       </div>
              
                    </Grid>
                )}
            </Grid>
        </div>
    </div>
  );
}

export default GameDetailBottom;
