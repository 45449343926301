import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//common
import Top from './Top';
import Bottom from './Bottom';

import {editCustomGame, getCustomGames, getGames} from '../../../../redux/actions/gameAction';
import { useParams } from 'react-router-dom';
import { getGroups } from '../../../../redux/actions/studentAction';

const ViewCustomGame = (props) => {

  const dispatch = useDispatch();
  const game = useSelector(state => state.game);
  const student = useSelector(state => state.student);

  useEffect(()=>{
    if(!game.customGames){
      dispatch(getCustomGames());
    }
    if(!game.game){
      dispatch(getGames());
    }
    if(!student.groups){
      dispatch(getGroups());
    }
  },[])

  const handleEditCustomGame = async (inputs) => {
    await dispatch(editCustomGame(inputs))
  }

  return (
    <div>
        <Top customGame={game.customGames ? (game.customGames.find(c => c._id===props.match.params.cid) || {}) : {}}
        game={game.games ? (game.games.find(c => c._id===props.match.params.id) || {}) : {}}
        groups={student.groups || []}
        editCustomGame={handleEditCustomGame} 
        {...props}/>
        <Bottom customGame={game.customGames ? (game.customGames.find(c => c._id===props.match.params.cid) || {}) : {}} game={game.games ? (game.games.find(c => c._id===props.match.params.id) || {}) : {}}  {...props}/>
    </div>
  );
}

export default ViewCustomGame;
