import firebase from "firebase";

const config = {
    apiKey: "AIzaSyB5De46TZa-_j4E6QJWFK9ypht1Oolp8_s",
    authDomain: "eduzo-ac473.firebaseapp.com",
    projectId: "eduzo-ac473",
    storageBucket: "eduzo-ac473.appspot.com",
    messagingSenderId: "594614536657",
    appId: "1:594614536657:web:5e1fcabcb247ca932ef8f0",
    measurementId: "G-VX0ZFX8DXJ"
}

firebase.initializeApp(config)

export default firebase