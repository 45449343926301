import {useState} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//material-ui icons
import MenuIcon from '@material-ui/icons/Menu';


const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    header: {
        height: '14vh',
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& h1': {
            fontWeight: 600,
            fontSize: '2rem'
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerToolbar: theme.mixins.toolbar,
    buttonLink: {
        textDecoration: 'none',
        color: '#000'
    },
    button: {
        background: '#fff',
        color: 'var(--primary)'
    },
    logo:{
        width: 120,
        '&>img':{
            width:'100%'
        }
    },
    navBar:{
        margin: '0 24px',
        '&>a':{
            textDecoration: 'none',
            color: '#000',
            margin: '0 24px',
            padding: '6px 12px',
            fontSize: '1.2rem',
            fontWeight: 500,
            borderRadius: 4,
            transition: 'background 200ms ease, color 200ms ease',
            '&:hover':{
                background: '#fff',
                color: '#E93480',
                border : '2px solid #E93480'
            }, 
            '&:focus' : {
                color: '#E93480',
                textDecoration : 'underline'
            }
        }
    },
    mobileNavItem: {
        textDecoration: 'none',
        color: '#000',
        '&:hover,&:focus':{
            color: '#fff',
            '&>*:first-child':{
                background: 'var(--primary)',
            }
        }
    }
}));

const Header = ({ history }) => {

    const classes = useStyles();

    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <header className={classes.header}>
            <div className={classes.logo}>
                {/* <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="eduzo"/> */}
                <img src={`${process.env.PUBLIC_URL}/logo4.png`} alt="eduzo"/>
            </div>

            <Hidden smDown>
                <nav className={classes.navBar}>
                    <a href="#engine">.Engine</a>
                    <a href="#app">.App</a>
                    <a href="/labs">.Lab</a>
                    <Button elevation={0} variant='contained' onClick={()=>history.push('/login')} className={classes.button}>Login</Button>
                </nav>
            </Hidden>

            <Hidden mdUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={()=>setMobileMenu(true)}
                >
                    <MenuIcon />
                </IconButton>

                {/*Mobile navbar*/}
                <Drawer
                    className={classes.drawer}
                    open={mobileMenu}
                    onClose={()=>setMobileMenu(false)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div>
                        <div className={classes.drawerToolbar} />
                        <Divider />
                        <List>
                            {[{
                                name: 'Engine',
                                href: '#engine'
                            },{
                                name: 'App',
                                href: '/'
                            },{
                                name:'Lab',
                                href: '/labs'
                            }].map(item => (
                                <Link key={item.name} to={item.href} className={classes.mobileNavItem}>
                                    {/* <ListItem button> */}
                                        <ListItemText primary={item.name} />
                                    {/* </ListItem> */}
                                </Link>
                            ))}
                        </List>
                        <Divider />

                        <List>
                            <Link to="/login" className={classes.mobileNavItem}>
                                <ListItem button>
                                        <ListItemText primary={'Login'} />
                                </ListItem>
                            </Link>
                        </List>
                    </div>

                </Drawer>
            </Hidden>
        </header>
    );
};

export default Header;
