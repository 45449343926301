import axios from '../axios';
import * as types from '../types';

import {API_URL} from '../../config';
import {getAxiosConfig, generateAxiosError} from '../../helpers';
import setAuthToken from '../utils/setAuthToken'
import swal from 'sweetalert2';

// document.getElementById('success').style.display = 'block';


export const getGames = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
    
    try{
        dispatch({
            type:types.FETCH_GAMES_ERROR,
            payload:null
        })

        const {data}=await axios.get(`${API_URL}/games`);

        // console.log(data)

        dispatch({
            type:types.STORE_GAMES,
            payload: data
        })
    }
    catch(e){
        dispatch({
            type:types.FETCH_GAMES_ERROR,
            payload:generateAxiosError(e)
        })
    }
}


export const getGamesUnregistered = () => async (dispatch) => {

    try{
        dispatch({
            type:types.FETCH_GAMES_ERROR,
            payload:null
        })

        const {data}=await axios.get(`${API_URL}/games/home-page`);

        dispatch({
            type:types.STORE_GAMES,
            payload: data
        })
    }
    catch(e){
        dispatch({
            type:types.FETCH_GAMES_ERROR,
            payload:generateAxiosError(e)
        })
    }
}





export const getCustomGames = (id) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
    
      var institute = localStorage.getItem('institute')

    
    try{
        dispatch({
            type:types.FETCH_CUSTOM_GAMES_ERROR,
            payload:null
        })

        // const body = {
            // game : id,
        // }

        const {data}=await axios.get(`${API_URL}/custom-games/get-all/${institute}` /*,getAxiosConfig({token})*/);

        dispatch({
            type:types.STORE_CUSTOM_GAMES,
            payload: data
        })
    }
    catch(e){
        dispatch({
            type:types.FETCH_CUSTOM_GAMES_ERROR,
            payload:generateAxiosError(e)
        })
    }
}

export const createCustomGame = (inputs) => async (dispatch) => {
    try{
        const {data} = await axios.post(`${API_URL}/custom-games`, inputs)

        if (data){

            document.getElementById('success').innerText = `New game “${data.name}” has been created successfully`;
            document.getElementById('success').style.display = 'block';

            setTimeout(() => {
                document.getElementById('success').style.display = 'none';
            }, 3000)
        }

        dispatch({
            type:types.CREATE_CUSTOM_GAME,
            payload: data
        })
    }
    catch(e){
        throw generateAxiosError(e);
    }
}

export const editCustomGame = (inputs) => async (dispatch) => {
    
    try{
        const {data}=await axios.put(`${API_URL}/custom-games/${inputs._id}`, inputs/*,getAxiosConfig({token})*/);

        dispatch({
            type:types.UPDATE_CUSTOM_GAME,
            payload: data
        })
    }
    catch(e){
        throw generateAxiosError(e);
    }
}

export const deleteCustomGame = (id) => async (dispatch) => {
    
    try{
        const {data}=await axios.delete(`${API_URL}/custom-games/${id}`/*,getAxiosConfig({token})*/);

        dispatch({
            type:types.DELETE_CUSTOM_GAME,
            payload: id
        })
    }
    catch(e){
        throw generateAxiosError(e);
    }
}
