import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PDFDocument, StandardFonts, rgb, degrees } from "pdf-lib";
//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import * as fileDownload from "js-file-download";
//material-ui icon imports
import CloseIcon from "@material-ui/icons/Close";
import FeedbackIcon from '@mui/icons-material/Feedback';
//material-ui lab imports
import Skeleton from "@material-ui/lab/Skeleton";

import { useParams } from "react-router-dom";
import axios from "axios";
import { ReactReduxContext } from "react-redux";
import { CardMedia, Checkbox, Icon } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import { Container } from "react-bootstrap";
import { API_URL } from "../../../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0",
    "& h6": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: "24px 0",
  },
  button: {
    textTransform: "initial",
  },
  chip: {
    borderRadius: 4,
    height: "auto",
    "&>*:nth-child(1)": {
      padding: "3px 5px",
    },
  },
  activity: {
    padding: 12,
    margin: "16px 0",
    "& h5": {
      fontWeight: 600,
    },
  },
  activityBadges: {
    marginTop: 16,
    "&>p": {
      fontWeight: 500,
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "&>*": {
        margin: "8px 8px 0 0",
      },
    },
  },
  activityActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "right",
    justifyContent: "space-between",
    textAlign: "right",
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 24,
    marginBottom: 24,
    "&>img": {
      width: 180,
    },
  },
  top: {
    width: "90%",
    textAlign: "center",
    margin: "0 auto 24px auto",
    "&>h1": {
      fontWeight: 800,
      color: "#9996BA",
      margin: "16px 0",
    },
    "&>h3": {
      fontWeight: 500,
      color: "#02113F",
    },
    "&>p": {
      fontWeight: 400,
      margin: "16px 0",
      color: "#02113F",
    },
  },
  sorryText: {
    marginTop: "30px",
    lineHeight: "1.5",
  },
  imageDiv: {
    "max-width": "300px",
    width: "100%",
    "max-height": "176px",
    height: "100%",
  },
}));

const ViewStudentBottom = ({shareClicked, setShareClicked, feedbackModal, setFeedbackModal, setSelectedReport}) => {
  const classes = useStyles();

  const [gameData, setGameData] = useState([]);
  const [gameScoreData, setGameScoreData] = useState([]);
  const [score, setScore] = useState({ score: 0 });
  const { id, sid } = useParams();

  const token = localStorage.getItem("token");

  function dateFormat(date) {
    const createdDate = new Date(date);
    let monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      monthNames[createdDate.getMonth()] +
      " " +
      createdDate.getDate() +
      " | " +
      createdDate.getHours() +
      ":" +
      createdDate.getMinutes()
    );
  }

  const searchGameSession = (gameData, playGameId) => {
    console.log(gameData, playGameId)
    let found = gameData.filter(
      (item) => item._id === playGameId
    );
    return found.length > 0 ? found[0] : null;
  };

  const searchName = (gameData, sid) => {
    let found = gameData.map((item) => item.Game[0].game[0].students);
    let name;
    found.forEach((item) => {
      item.forEach((childItem) => {
        if (childItem.uid === sid) name = childItem.name;
      });
    });
    // console.log(found);
    return name;
  };

  useEffect(() => {
    console.log(id);
    axios
      .get(
        `https://institute.game.eduzo.com/institute/playgame/session/recentgame/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(async (res) => {
        setGameData(res.data);
        console.log("First", res.data);
        let playgame_ids = [];
        res.data.map((item) => {
          playgame_ids.push(item._id);
        });
        playgame_ids = [...new Set(playgame_ids)];
        console.log(playgame_ids);
        console.log("Student Id", id);
        console.log("check1");
        const sessionScorePromises = [];
        for (let i = 0; i < playgame_ids.length; i++) {
          sessionScorePromises.push(
            axios.get(
              `https://institute.game.eduzo.com/institute/playgame/session/${playgame_ids[i]}`,
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
        const resultsScoresTemp = await axios.all(sessionScorePromises);
        const resultsScoresList = [];
        resultsScoresTemp.forEach((item) => {
          // console.log(item.data);
          item.data.forEach((itemData) => {
            // console.log(itemData.student);
            itemData.student.forEach((student) => {
              if (student.student_id === sid) {
                // console.log(student.student_id == sid );
                // setScore(student);
                resultsScoresList.push({
                  ...student,
                  name: searchName(res.data, sid),
                  playgameId: itemData.playGame,
                  gameData: searchGameSession(res.data, itemData.playGame),
                });
              }
            });
          });
        });
        console.log(resultsScoresList);

        localStorage.setItem("reportCards", JSON.stringify(resultsScoresList));

        setGameScoreData(resultsScoresList);
        // console.log(score)
        console.log("check3");
      });
  }, []);

  const getScore = (playgame_id) => {
    console.log(playgame_id);
  };

  const handleDownload = async (studentId, studentName, score, feedback, game, snapshotImg) => {
    console.log("Game details", studentId, game, snapshotImg);
    const url = "https://eduzo.s3.ap-south-1.amazonaws.com/certificate3.pdf";
    const existingPdfBytes = await fetch(url, {
      referrerPolicy: "no-referrer",
    }).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    let gameImage;
    let gameUrl = game.game.image;
    gameUrl = gameUrl.replace(
      "https://eduzo.s3.ap-south-1.amazonaws.com",
      "https://eduzo.s3.amazonaws.com"
    );
    try {
      const gameImageBytes = await fetch(gameUrl, {
        referrerPolicy: "no-referrer",
      }).then((res) => res.arrayBuffer());
      gameImage = gameUrl.endsWith(".png")
        ? await pdfDoc.embedPng(gameImageBytes)
        : await pdfDoc.embedJpg(gameImageBytes);
      firstPage.drawImage(gameImage, {
        x: width / 4 - 70,
        y: (3 * height) / 4 - 70,
        width: 90,
        height: 90,
        // rotate: degrees(30),
        opacity: 1,
      });
    } catch (err) {
      console.log("error", err);
    }
    //Student Photo
    //API call to get url
    const studentData = await axios.get(`${API_URL}/students/${studentId}`)
    console.log(studentData);
    let studentImageUrl = studentData.data.profile_pic ?
     studentData.data.profile_pic : "https://eduzo.s3.ap-south-1.amazonaws.com/defaultuser.png"

    studentImageUrl = studentImageUrl.replace(
      "https://eduzo.s3.ap-south-1.amazonaws.com",
      "https://eduzo.s3.amazonaws.com"
    );
     try {
      const studentImageBytes = await fetch(studentImageUrl, {
        referrerPolicy: "no-referrer",
      }).then((res) => res.arrayBuffer());
      let studentImage = studentImageUrl.endsWith(".png")
        ? await pdfDoc.embedPng(studentImageBytes)
        : await pdfDoc.embedJpg(studentImageBytes);
      firstPage.drawImage(studentImage, {
        x: (4 * width) / 5 - 20,
        y: (3 * height) / 4 - 70,
        width: 100,
        height: 100,
        // rotate: degrees(30),
        opacity: 1,
      });
    } catch (err) {
      console.log("error", err);
    }
    //Name
    firstPage.drawText(studentName || "Name", {
      x: width / 2 - 50,
      y: height / 2 + 110,
      size: 30,
      //   font: helveticaFont,
      color: rgb(0, 0, 0.4588),
      //   rotate: "degrees(-45)",
    });

    //Game name
    let gameName = game.name
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    firstPage.drawText(gameName || "Homespaces", {
      x: width / 4 + 35,
      y: (3 * height) / 4 - 25,
      size: 30,
      font: timesRomanBold,
      color: rgb(0.247, 0.247, 0.247),
      //   rotate: "degrees(-45)",
    });

    //Game Outcome
    let outcome = game.outcomes
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    firstPage.drawText(outcome || "A Small Line About The Game", {
      x: width / 4 + 40,
      y: (3 * height) / 4 - 50,
      size: 14,
      font: helveticaFont,
      color: rgb(0.287, 0.287, 0.287),
      //   rotate: "degrees(-45)",
    });

    //Objective
    let objective = game.outcomes
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    firstPage.drawText(objective || "A Small Line About The Game", {
      x: width / 4 - 45,
      y: (1 * height) / 2,
      size: 11,
      font: helveticaFont,
      color: rgb(0.95, 0.95, 0.95),
    });

    //Staff Name
    const user = localStorage.getItem("user_name");
    firstPage.drawText(user || "John Doe", {
      x: width / 5 + 35,
      y: (1 * height) / 5 - 40,
      size: 11,
      font: helveticaFont,
      color: rgb(0.247, 0.247, 0.247),
    });

    //Staff Photo
    let staffImageUrl = localStorage.getItem("profile_pic")?
    localStorage.getItem("profile_pic") : "https://eduzo.s3.ap-south-1.amazonaws.com/defaultuser.png"
    staffImageUrl = staffImageUrl.replace(
      "https://eduzo.s3.ap-south-1.amazonaws.com",
      "https://eduzo.s3.amazonaws.com"
    );
     try {
      const staffImageBytes = await fetch(staffImageUrl, {
        referrerPolicy: "no-referrer",
      }).then((res) => res.arrayBuffer());
      let staffImage = staffImageUrl.endsWith(".png")
        ? await pdfDoc.embedPng(staffImageBytes)
        : await pdfDoc.embedJpg(staffImageBytes);
      firstPage.drawImage(staffImage, {
        x: width / 5 - 10,
        y: (1 * height) / 5 - 40,
        width: 30,
        height: 30,
        opacity: 1,
      });
    } catch (err) {
      console.log("error", err);
    }

    //Remarks/Feedback
    firstPage.drawText(`- ${feedback}` || "- Good Feedback", {
      x: width / 5 + 35,
      y: (1 * height) / 5 - 60,
      size: 9,
      font: helveticaFont,
      color: rgb(0.247, 0.247, 0.247),
    });

    //Score
    firstPage.drawText(score.toString() + "/100" || "0/100", {
      x: width / 4 + 36,
      y: height / 2 - 256,
      size: 14,
      //   font: helveticaFont,
      color: rgb(0, 0, 0.4588),
      //   rotate: "degrees(-45)",
    });

    //time
    firstPage.drawText(game.timer.toString() || "03:00", {
      x: width / 2,
      y: height / 2 - 256,
      size: 14,
      //   font: helveticaFont,
      color: rgb(0, 0, 0.4588),
      //   rotate: "degrees(-45)",
    });

    //Snapshot
    if(snapshotImg && snapshotImg.length > 0){
      let snapImage;
      let snapUrl = snapshotImg[snapshotImg.length - 1] || "";
      snapUrl = snapUrl.replace(
        "https://eduzo.s3.ap-south-1.amazonaws.com",
        "https://eduzo.s3.amazonaws.com"
      );
      const snapImgBytes = await fetch(snapUrl, {
        referrerPolicy: "no-referrer",
      }).then((res) => res.arrayBuffer());
      snapImage = gameUrl.endsWith(".png")
        ? await pdfDoc.embedPng(snapImgBytes)
        : await pdfDoc.embedJpg(snapImgBytes);
      firstPage.drawImage(snapImage, {
        x: 3* width / 5 + 46,
        y: (1 * height) / 8 - 30,
        width: 234,
        height: 132,
        rotate: degrees(-15),
        opacity: 1,
      });
    }
    const pdfBytes = await pdfDoc.save();
    fileDownload(pdfBytes, "Certificate.pdf", "application/pdf");
  };

  const handleCloseSelection = () => {
    console.log("Close Selection");
    setShareClicked(!shareClicked)
  }

  const handleFeedback = (item) => {
      //open dialog
      setFeedbackModal(!feedbackModal);
      setSelectedReport(item)
      console.log("selected",item);
  } 

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} sm={11}>
            <Typography variant="h6">Activity Book</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            {shareClicked && <CloseIcon onClick={handleCloseSelection} color="action"></CloseIcon>}
          </Grid>
        </Grid>
        {gameScoreData.length ? (
          gameScoreData.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Paper className={classes.activity}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={1} hidden={!shareClicked} >
                    <Checkbox />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {/* <Skeleton variant="rect" style={{ width: '100%', height: '100%' }} /> */}
                    <div className={classes.imageDiv}>
                      <img
                        src={
                          item.gameData?.snapshotImg?.length > 0
                            ? item.gameData?.snapshotImg[0]
                            : item.gameData?.Game[0]?.game[0]?.custom_game?.game
                                .image
                        }
                        alt=""
                        width="100%"
                        height="100%"
                        style={{ "border-radius": "7px" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" gutterBottom>
                      {item.gameData &&
                        item.gameData.Game[0]?.game[0]?.custom_game.name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {/* {item.gameData && item.gameData.Game[0]?.game[0]?.name} */}
                      | Score: {item.score}/100
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {item.gameData &&
                        item.gameData.Game[0]?.game[0].custom_game?.outcomes}
                    </Typography>

                    <div className={classes.activityBadges}>
                      <Typography variant="body1">BADGES</Typography>
                      <div>
                        <Chip
                          color="primary"
                          label="Max Speed"
                          className={classes.chip}
                        />
                        <Chip
                          color="primary"
                          label="Top Consecutive"
                          className={classes.chip}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.activityActions}>
                    <Typography variant="body2">
                      {dateFormat(item.gameData?.updatedAt)}
                    </Typography>
                    <Button
                      onClick={() =>
                        handleDownload(
                          item.student_id,
                          item?.name,
                          item.score,
                          item.feedback,
                          item.gameData.Game[0]?.game[0]?.custom_game,
                          item.gameData?.snapshotImg
                        )
                      }
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                    >
                      Print
                    </Button>
                    <Button 
                      startIcon={<FeedbackIcon />}
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      onClick={() => handleFeedback(item)}
                    >
                      Feedback
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Container maxWidth="sm">
            <div className={classes.top}>
              <Typography
                variant="h3"
                align="center"
                color="textPrimary"
                className={classes.sorryText}
                gutterBottom
              >
                Sorry, No Result Found.
                <br />
              </Typography>
            </div>
            <div className={classes.bottom}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/errorpage/emoji.svg`}
                alt="error"
              />
            </div>
          </Container>
        )}

        {/* {Array.from({length:6},(_,i) => 
                <Grid item xs={12} key={i}>
                    <Paper className={classes.activity}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <Skeleton variant="rect" style={{width: '100%', height: '100%'}} />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Typography variant="h5" gutterBottom>Number Catcher</Typography>
                                <Typography variant="body1" gutterBottom>Task Completed | Score: 9/10</Typography>
                                <Typography variant="body2" gutterBottom>
                                    Explore what inspires you and bring your creations to life. Whether it’s new worlds, unique communities, fun events, or interesting activities, you can make them in Horizon.
                                </Typography>
                                
                                <div className={classes.activityBadges}>
                                    <Typography variant="body1">BADGES</Typography>
                                    <div>
                                        <Chip color="primary" label="Max Speed" className={classes.chip}/>
                                        <Chip color="primary" label="Top Consecutive" className={classes.chip}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={2} className={classes.activityActions}>
                                <Typography variant="body2">Nov 06 | 13:46</Typography>
                                <Button variant="contained" color="primary" size="small">Print</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )} */}
      </Grid>
    </div>
  );
};

export default ViewStudentBottom;
