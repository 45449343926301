import {Fragment} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '48px 0',
        '& h5':{
            textAlign: 'center',
            fontWeight: 500
        }
    },
    gameDetailsPreview: {
        marginTop: 24,
        '&>*':{
            margin: '8px 0',
            '&>*:nth-child(1)':{
                '&>*:nth-child(1)':{
                    '&>p': {
                        color: '#666',
                        fontWeight: 500
                    }
                }
            }
        }
    },
    question : {
        fontSize: 24,
        lineHeight: 3,
        color: '#02113F'
    }, 
    imageLayout : {
        width:'100%',
        display: 'flex',
        justifyContent : 'space-between',
        alignItems : 'center',

    }
}));

const ViewCustomGameBottom = ({customGame,game}) => {
    console.log(customGame)
    // console.log(game)
  const classes = useStyles();

//   console.log(customGame)

  return (
    <div className={classes.root}>
      <Grid container className={classes.gameDetailsPreview}>

      <Grid item xs={12}>
          
            <Grid style={{display : 'flex', flexDirection:'column', alignItems:  'center'}}>

                {customGame?.questions?.length > 0 ? 
                <span className={classes.question}>Q: {customGame?.questions?.[0]?.question}</span> : 
                ""}
            <div className={classes.imageLayout} >
                <span class="iconify" data-icon="grommet-icons:next" style={{fontSize : '50px', color : '#676F8C', fontWeight : '700'}} data-rotate="180deg"  href="#carouselExampleControls" role="button" data-slide="prev"></span>

                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    
                {/* {game?.illustration_img.map(image => */}
                    
                    <div class="carousel-item active">
                    <img height='316px' width='574px' src={game?.illustration_img ? game?.illustration_img : customGame.game?.illustration_img}/>
                    </div>
                 {/* ) */}
                </div>
                </div>
                    
          <span class="iconify " href="#carouselExampleControls" data-slide="next" style={{fontSize : '50px', color : '#676F8C', fontWeight : '700'}} data-icon="grommet-icons:next"></span>
                    
            </div>
             
                
            </Grid>
          </Grid>
         
        
      </Grid>
    </div>
  );
}

export default ViewCustomGameBottom;
