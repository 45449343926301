import {useState, useEffect, Fragment} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CircularProgress from '@material-ui/core/CircularProgress';

//material-ui lab imports
import Autocomplete from '@material-ui/lab/Autocomplete';

//material-ui icon imports
import AddIcon from '@material-ui/icons/Add';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '12px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    chip: {
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    button: {
        textTransform: 'initial'
    },
    textField: {
        width: '100%'
    },
    submitButton: {
        textTransform: 'initial',
    },
    gameTitlePreview: {
        '&>div':{
            display: 'flex',
            alignItems: 'center',
            '& h5':{
                fontSize: '1.4rem',
                margin: '0 8px 4px 0',
                fontWeight: 500
            },
            '& p':{
                fontSize: '0.9rem',
                marginTop: 4
            },
            marginBottom: 6,
        }
    },
    gamePropertiesPreview: {
        marginBottom: 8,
        '&>*':{
            textAlign: 'center',
            '&>h6':{
                fontSize: '1.1rem',
                fontWeight: 400
            },
            '&>*:nth-child(2)':{
                fontSize: '1rem',
                marginTop: 12,
                fontWeight: 600
            }
        },
        '&>*:not(:last-child)': {
            borderRight: '1px solid #ddd'
        },
    },
    gameDetailsPreview: {
        marginTop: 24,
        '&>*':{
            margin: '8px 0',
            '&>*:nth-child(1)':{
                '&>*:nth-child(1)':{
                    '&>p': {
                        color: '#666',
                        fontWeight: 500
                    }
                }
            }
        }
    },
    formActions: {
        '&>*:not(:first-child)':{
            marginLeft: 12
        }
    }
}));


function getStepContent(step) {
    switch (step) {
        case 0:
            return `The fundamental or essential information about the game topic.`;
        case 1:
            return 'The information specific to game such as subject and level.';
        case 2:
            return `The information specific to question, right and wrong answers.`;
        case 3:
            return `Verify the information entered in this form to ensure its completeness before submitting it.`;
        default:
            return 'Unknown step';
    }
}

let qid = 2;

const EditCustomGameForm = ({match, customGame, game, groups, editCustomGame, closeModal}) => {
 console.log('gamecustommm-->',game,game.customizable, customGame)
  const classes = useStyles();

  const [steps] = useState(game.customizable ? ['Basic Details', 'Game Details', 'Questions & Answers', 'Review'] : ['Basic Details', 'Game Details', 'Review'])
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
      name: '',
      outcomes: '',
      answers: [],
      subjects: [],
      groups: [],
      category: {},
      difficulty_level: 'low',
      questions: [{
          id: 1,
          question: '',
          question_type: 'text',
          right_answers: [],
          wrong_answers: []
      }]
  })

  var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];


  useEffect(()=>{
      setInputs({...customGame, groups: customGame.groups.map(g => g._id)})
  },[])


  const handleChange = (e) => {
      const value = e.target.value;
      if(typeof value==='object' && e.target.name!=='category'){
          setInputs({...inputs, [e.target.name]: [...value]})
      }
      else{
        setInputs({...inputs, [e.target.name]: value})
      }
  }


  const handleAddQuestion = () => {
    setInputs({
        ...inputs, 
        questions: [...inputs.questions, {
            id: qid++,
            question: '',
            question_type: 'text',
            right_answers: []
        }]
    })
  }

  const handleChangeQuestion = (e, id) => {
      const value = e.target.value;
      
      setInputs({
          ...inputs, 
          questions: inputs.questions.map(q => q.id===id ? {
              ...q,
              [e.target.name] : value
          } : q)
      });
  }

  const handleNext = async (e) => {
    e.preventDefault();
    try{
        if(activeStep+1 === steps.length) {
            setLoading(true);
            await editCustomGame({...inputs});
            setLoading(false);
            closeModal();
            return;
        };
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    catch(e){
        alert(e);
    }
    finally{
        setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return(
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <Stepper activeStep={activeStep} orientation="vertical">
                {steps && steps.map((label, index) => (
                    <Step key={label} expanded={true}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                        </StepContent>
                    </Step>
                ))}
                </Stepper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Grid container className={classes.form} spacing={2}>

                    {activeStep===0 ? 
                    <>
                        <Grid item xs={12}>
                            <label htmlFor="custom_name">Game Title</label>
                            <TextField variant="outlined" onChange={handleChange} className={classes.textField} value={inputs.name} InputProps={{id:'name', name:'name'}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="game_outcomes">Description</label>
                            <TextField variant="outlined" multiline rows={2} onChange={handleChange} className={classes.textField} value={inputs.outcomes} InputProps={{id:'outcomes', name:'outcomes'}}/>
                        </Grid>

                        {game.customizable ? 
                            <Grid item xs={12}>
                                <label htmlFor="category">Category</label>
                                <br/>
                                <Select variant="outlined" value={inputs.category} className={classes.textField} onChange={handleChange} name="category" id="category">
                                    {game.categories && game.categories.map(c =>
                                        <MenuItem key={c} value={c}>{c}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        :
                            <Grid item xs={12}>
                                <label htmlFor="category">Choose answers</label>
                                <br/>
                                <Select multiple value={inputs.answers} className={classes.textField} onChange={handleChange} name="answers" variant="outlined" id="category">
                                    {game.options && game.options.map(option =>
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        }
                        
                    </>

                    :activeStep===1 ?

                    <>
                        <Grid item xs={12}>
                            <label htmlFor="grades">Select Group</label>
                            <br/>
                            <Select 
                                multiple 
                                variant="outlined" 
                                value={inputs.groups} 
                                onChange={handleChange}
                                id="groups"
                                name="groups"
                                className={classes.textField}
                            >
                                {groups && groups.map(g =>
                                    <MenuItem value={g._id}>{g.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="grades">Select Subjects</label>
                            <br/>
                            <Select 
                                multiple 
                                variant="outlined" 
                                value={inputs.subjects.length > 0 && inputs.subjects.map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())} 
                                id="subjects"
                                name="subjects"
                                onChange={handleChange}
                                className={classes.textField}
                            >
                                <MenuItem value="Science">Science</MenuItem>
                                <MenuItem value="Maths">Maths</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="level">Select Level</label>
                            <br/>
                            <Select variant="outlined" id="level" value={inputs.difficulty_level} name="difficulty_level" className={classes.textField} onChange={handleChange}>
                                <MenuItem value="Easy">Easy</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="Hard">Hard</MenuItem>
                            </Select>
                        </Grid>
                    
                    </>

                    : (activeStep===2 && game.customizable) ?
                    (inputs.category ?
                    <>
                        <Grid item xs={12}>
                        {inputs.questions.map((q,i) =>
                            <Grid container key={q.id} spacing={2}>
                            {inputs.category && inputs.category.question_types && inputs.category.question_types.length>1 && <Grid item xs={12}>
                                <label htmlFor={`question_type_${i}`}>Question Type</label>
                                <br/>
                                <Select 
                                    variant="outlined" 
                                    value={q.question_type} 
                                    id={`question_type_${i}`}
                                    name="question_type"
                                    onChange = {(e)=>handleChangeQuestion(e, q.id)}
                                    className={classes.textField}
                                >
                                    {game.categories[0] && game.categories[0].question_types.map(type => 
                                        <MenuItem value={type} key={type}>{type}</MenuItem>
                                    )}
                                </Select>
                            </Grid>}

                        <Grid item xs={12}>
                            <label htmlFor={`question_${i}`}>Question</label>
                            <TextField variant="outlined" className={classes.textField} value={q.question} onChange = {(e)=>handleChangeQuestion(e, q.id)} InputProps={{id:`question_${i}`, name:'question'}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor={`right_answers_${i}`}>Right Answers</label>
                            <br/>
                            <Autocomplete
                                multiple
                                id={`right_answers_${i}`}
                                name="right_answers"
                                options={[]}
                                value={q.right_answers || []}
                                onChange={(e, newval, reason) => {
                                    const x = {
                                        target: {
                                            name: "right_answers",
                                            value: newval
                                        }
                                    }
                                    handleChangeQuestion(x, q.id)
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Enter value and press enter"
                                    onKeyDown={e => {
                                    if (e.keyCode === 13 && e.target.value) {
                                        const x = {
                                            target: {
                                                name: "right_answers",
                                                value: [...q.right_answers, e.target.value]
                                            }
                                        }
                                        handleChangeQuestion(x, q.id)
                                    }
                                    }}
                                />
                                )}
                            />
                        </Grid>

                        {<Grid item xs={12}>
                            <label htmlFor={`wrong_answers_${i}`}>Wrong Answers</label>
                            <br/>
                            <Autocomplete
                                multiple
                                id={`wrong_answers_${i}`}
                                name="wrong_answers"
                                options={[]}
                                value={q.wrong_answers || []}
                                onChange={(e, newval, reason) => {
                                    const x = {
                                        target: {
                                            name: "wrong_answers",
                                            value: newval
                                        }
                                    }
                                    handleChangeQuestion(x, q.id)
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Enter value and press enter"
                                    onKeyDown={e => {
                                    if (e.keyCode === 13 && e.target.value) {
                                        const x = {
                                            target: {
                                                name: "wrong_answers",
                                                value: [...q.wrong_answers, e.target.value]
                                            }
                                        }
                                        handleChangeQuestion(x, q.id)
                                    }
                                    }}
                                />
                                )}
                            />
                        </Grid>}

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        </Grid>
                        )}
                        </Grid>

                        {/*inputs.category.multiple_questions && */ <Grid item xs={12}>
                            <Button startIcon={<AddIcon/>} onClick={handleAddQuestion}>Add another question</Button>
                        </Grid>}
                    </>

                    :
                        <Grid item xs={12}>
                            <Typography variant="h6">No category selected</Typography>
                        </Grid>
                    )

                    :

                    <>
                        
                        <Grid item xs={12} className={classes.gameTitlePreview}>
                            <div>
                                <Typography variant="h5">{inputs.name}</Typography>
                                 <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} label={inputs?.subjects?.[0]} className={classes.chip}/>
                            </div>
                            <Typography variant="body1">{inputs.outcomes}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        
                        <Grid container spacing={1} className={classes.gamePropertiesPreview}>
                            <Grid item xs={12} md>
                                <Typography variant="h6">GRADES</Typography>
                                <Typography variant="body1">
                                    {inputs.grades.map(g => 
                                        <span key={g}>{g}, </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">SUBJECTS</Typography>
                                <Typography variant="body1">
                                    {inputs.subjects.map(s => 
                                        <span key={s}>{s}, </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">LEVEL</Typography>
                                <Typography variant="body1">{inputs.difficulty_level}</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">TIME</Typography>
                                <Typography variant="body1">{inputs.timer} mins</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">{inputs.category.customizable===false ? "ANSWERS" : "CATEGORY"}</Typography>
                                <Typography variant="body1">
                                        {inputs.answers.length > 0 ? inputs.answers.map((category, i) => 
                                       inputs.answers.length-1 == i ? category : category + ", "
                                       ) : inputs.category }
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12}>
                            {game.customizable ?
                            <Grid container className={classes.gameDetailsPreview}>
                                {inputs.questions.map((q,i) =>
                                    <Fragment key={q.id}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Question {inputs.category.multiple_questions && i+1}</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>{q.question}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Right answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {q.right_answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>    
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    {q.wrong_answers && <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Wrong answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {q.wrong_answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>    
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>}

                                    <Grid item xs={12}>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                            :
                                <Grid container className={classes.gameDetailsPreview}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {inputs.answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                    
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={8} className={classes.formActions}>
                {activeStep>0 && <Button
                    variant="contained"
                    onClick={handleBack}
                    className={classes.button}
                >
                    Go Back
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24}/>
                    :(steps && activeStep === steps.length - 1) ? 'Update' : 'Next'}
                </Button>
            </Grid>
        </Grid>
    </div>
  )
}

export default EditCustomGameForm;