import {useState, forwardRef} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Dropdown } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import EditForm from '../ViewGame/Components/EditForm';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    buttonLink: {
        textDecoration: 'none',
        padding: 0,
    },
    chip: {
        marginTop : '0.2vh',
        minHeight : '28px !important',
        minWidth : '62px',
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 24,
        '&>div':{
            '& h5':{
                fontSize: '1.4rem',
                color : '#02113F',
                margin: '0 8px 4px 0',
                fontWeight: 500,
                fontSize : '24px',
            },
            '& p':{
                fontSize: '0.9rem',
                marginTop: 4,
                color : '#02113F',
                opacity: 0.6,
                fontSize: '16px',
                lineHeight: '20px'

            },
            '&>div':{
                display: 'flex',
                alignItems: 'center',
            }
        }
    },
    gameProperties: {
        marginBottom: 8,
        '&>*':{
            textAlign: 'center',
            '&>h6':{
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '18px',
                color: '#02113F',
                opacity: 0.6,
            },
            '&>*:nth-child(2)':{
                fontSize: '1rem',
                marginTop: 16,
                fontWeight: 500,
                color: '#02113F'
            }
        },
        '&>*:not(:last-child)': {
            borderRight: '1px solid #ddd'
        },
    },
    playDemoModal: {
        '& h5':{
            fontWeight: 500
        },
        '&>*':{
            margin: '12px 0'
        },
        '&>div:nth-child(4)':{
            margin: '36px 0 12px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&>*':{
                width: '48%'
            }
        }
    },
    createGame: {
        padding: '24px 48px',
        '& h4':{
            fontWeight: 500
        },
        '&>*:nth-child(1)':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    // formControl: {
    //     margin: theme.spacing(1),
    //     minWidth: 120,
    //   },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ViewCustomGameTop = ({match, customGame, game, groups, editCustomGame}) => {
    console.log(customGame, game, groups)
    const [anchorEl, setAnchorEl] = useState(null);
    const [editModal, setEditModal] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

  const classes = useStyles();

  const [playDemoModal, setPlayDemoModal] = useState(false);

  const handleClosePlayDemoModal = () => {
    setPlayDemoModal(false);
  }
  
  const handleOpenPlayDemoModal = () => {
    setPlayDemoModal(true);
  }

  const handleOpenEditModal = (id) => {
    setEditModal(id);
  }

  const handleCloseEditModal = () => {
    setEditModal(null);
  }
  var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];


  return (
    <div className={classes.root}>
        
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={`/labs/my-games`}>
                My Games
            </Link>
            <Link color="inherit" to={`/labs/my-games/${match.params.id}`}>
                {customGame?.game && customGame?.game.name}
            </Link>
            <Typography></Typography>
        </Breadcrumbs>
        
        <div className={classes.gameTitle}>
        {/* <div className="row"> */}

        <div className="col-sm-6">

            {/* <Dropdown>
                    <Dropdown.Toggle id="dropdownMenuOutlineButton2">
                      <i className="mdi mdi-dots-horizontal">...</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item  style={{color : "#02113F !important"}} >Send Reminder</Dropdown.Item>
                      <Dropdown.Item  style={{color : "#02113F !important"}}  >Update</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                <div>
                    <Typography variant="h5">{customGame?.name}</Typography>
                    <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} label={customGame?.subjects?.[0]}  className={classes.chip}/>

                </div>
                <Typography variant="body1">{customGame?.game?.short_description}</Typography>
            </div>
            <div className="row">
            <div className="col-sm-6">
            <ButtonGroup>
                <Button style={{textTransform : 'none', color: '#02113F', fontSize :'16px', width : '150px'}} onClick={()=>handleOpenEditModal(match.params.cid)}>Edit Game</Button>
            </ButtonGroup>
            </div>
            <div className="col-sm-1">

            <ButtonGroup>

            <Button style={{fontSize : '16px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            ...
            </Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            <MenuItem onClick={handleOpenPlayDemoModal} >Play Demo</MenuItem>
            </Menu>
            </ButtonGroup>
            </div>
            </div>
            {/* </div> */}


        </div>

        <Divider className={classes.divider}/>

        <Grid container spacing={1} className={classes.gameProperties}>
            <Grid item xs={12} md>
                <Typography variant="h6">GRADES</Typography>
                <Typography variant="body1">
                    {/* {customGame.grades?.map((g,i) =>
                        <span key={g}>{g}</span>
                    )} */}
                     {customGame?.grades?.map((grade,key) => 
                        key == customGame.grades.length -1 ?
                        <span>{grade}</span> : 
                        <span>{grade}, </span>
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography variant="h6">SUBJECTS</Typography>
                <Typography variant="body1">
                    {/* {customGame.subjects?.map((s,i) =>
                        <span key={s}>{s}{i!==customGame.subjects?.length-1 && ', '}</span>
                    )} */}
                    {customGame?.subjects?.map((subject,key) => 
                                        key == customGame.subjects.length -1 ?
                                        <span>{subject}</span> : 
                                        <span>{subject}, </span>
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography variant="h6">LEVEL</Typography>
                <Typography variant="body1">{customGame?.difficulty_level}</Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography variant="h6">TIME</Typography>
                <Typography variant="body1">{customGame?.timer} mins</Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography variant="h6">CATEGORY</Typography>
                <Typography variant="body1">{customGame?.category ? customGame?.category : 
                customGame?.answers?.map((c, i) => customGame?.answers?.length-1 === i ? c + " " : c+", ") }</Typography>
            </Grid>
        </Grid>
        {/*edit game modal*/}
        <Dialog fullScreen open={editModal} onClose={handleCloseEditModal} TransitionComponent={Transition}>
                <Grid container className={classes.createGame}>
                    <Grid item xs={12}>
                        <div>
                            <Typography variant="h4" gutterBottom>Edit Game</Typography>
                            <Typography variant="body1">For {game.name}</Typography>
                        </div>
                        <IconButton onClick={handleCloseEditModal}><CloseIcon/></IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider className={classes.divider}/>
                    </Grid>

                    <Grid item xs={12}>
                        <EditForm closeModal={() => setEditModal(null)} game={game} groups={groups} editCustomGame={editCustomGame} match={match} customGame={customGame} />
                    </Grid>
                </Grid>
        </Dialog>
        <Dialog 
            open={playDemoModal} 
            onClose={handleClosePlayDemoModal} 
            fullWidth={true}
            maxWidth="xs"
        >
        
            <DialogContent className={classes.playDemoModal}>
            <div>
                <Typography variant="h5" color="secondary">Play Demo</Typography>
            </div>
            <div>
                <label htmlFor="name">Student Name</label>
                <TextField id="name" autoFocus fullWidth variant="outlined"/>
            </div>
            <div>
                <label htmlFor="group">Group</label>
                <TextField id="group" fullWidth variant="outlined"/>
            </div>

            <div>
                <Button variant="contained" color="primary" style={{textTransform : 'none', fontSize : '18px'}} onClick={handleClosePlayDemoModal}>
                    Play
                </Button>
                <Button variant="contained" onClick={handleClosePlayDemoModal}  style={{textTransform : 'none',  fontSize : '18px'}}>
                    Cancel
                </Button>
            </div>
            </DialogContent>
        </Dialog>
    </div>
  );
}

export default ViewCustomGameTop;
