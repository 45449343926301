import { useState } from 'react'
import { Link } from 'react-router-dom'

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

//material-ui icons
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'

const drawerWidth = 0
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& h1': {
      fontWeight: 600,
      fontSize: '2rem',
    },
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '&>*:nth-child(2)': {
        marginLeft: 36,
      },
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '&>div>div': {
      padding: theme.spacing(4),
      margin: '8px 0',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  buttonLink: {
    textDecoration: 'none',
    color: '#000',
  },
  button: {
    background: '#fff',
    color: 'var(--primary)',
  },
  logo: {
    width: 120,
    '&>img': {
      width: '100%',
    },
  },
  right: {
    margin: '0 24px',
    '&>a': {
      textDecoration: 'none',
      color: '#000',
      margin: '0 24px',
      padding: '6px 12px',
      fontSize: '1.2rem',
      fontWeight: 500,
      borderRadius: 4,
      transition: 'background 200ms ease, color 200ms ease',
      '&:hover,&:focus': {
        background: 'var(--primary)',
        color: '#fff',
      },
    },
    '&>*:nth-child(2)': {
      background: 'linear-gradient(93.23deg, #4335D2 13.26%, #E93480 100%)',
      borderRadius: 4,
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      textTransform: 'initial',
    },
  },
  navItems: {
    margin: '8px 0 0 0',
  },
  navItem: {
    textDecoration: 'none',
    color: '#000',
    '&>*:first-child': {
      backgroundColor: 'transparent',
      padding: '12px 0',
      transition: 'color 150ms ease',
      '&>*:first-child': {
        fontSize: '1rem',
      },
    },
    '&:hover,&:focus': {
      '&>*:first-child': {
        color: 'var(--primary)',
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fff',
  },
}))

const Header = ({ history }) => {
  const classes = useStyles()

  const [mobileMenu, setMobileMenu] = useState(false)

  return (
    <div className={classes.root}>
      <nav>
        {/*Mobile navbar*/}
        <Drawer
          className={classes.drawer}
          open={mobileMenu}
          onClose={() => setMobileMenu(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          anchor='left'>
          <div>
            <div>
              <div>
                <div className={classes.logo}>
                  {/* <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt='eduzo' /> */}
                  <img src={`${process.env.PUBLIC_URL}/logo4.svg`} alt='eduzo' />
                </div>
              </div>
              <List className={classes.navItems}>
                {[
                  {
                    name: 'Home',
                    href: '#engine',
                  },
                  {
                    name: 'Game Store',
                    href: '/',
                  },
                  {
                    name: 'My Games',
                    href: '/',
                  },
                  {
                    name: 'Students',
                    href: '/',
                  },
                  {
                    name: 'Manage',
                    href: '/',
                  },
                  {
                    name: 'Play Game',
                    href: '/',
                  },
                  {
                    name: 'Help Center',
                    href: '/',
                  },
                ].map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classes.navItem}>
                    <ListItem button>
                      <Typography variant='h6'>{item.name}</Typography>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </div>
          </div>
        </Drawer>
      </nav>

      <main className={classes.content}>
        <header className={classes.header}>
          <div className={classes.left}>
            <Select value='Discover' variant='outlined'>
              <MenuItem value='Discover' selected>
                Discover
              </MenuItem>
              <MenuItem value='Math'>Math</MenuItem>
              <MenuItem value='Science'>Science</MenuItem>
              <MenuItem value='History'>History</MenuItem>
              <MenuItem value='English'>English</MenuItem>
              <MenuItem value='Creativity'>Creativity</MenuItem>
              <MenuItem value='Social'>Social</MenuItem>
            </Select>
          </div>

          <div className={classes.right}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button>Basic Plan</Button>
          </div>

          <Hidden mdUp>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='end'
              onClick={() => setMobileMenu(true)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </header>
      </main>
    </div>
  )
}

export default Header
