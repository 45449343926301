import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//common
import Header from "../Header";
import Top from "./Top";
import Bottom from "./Bottom";
import { getNotifications } from "../../../redux/actions/studentAction";

// import {getGames} from '../../../redux/actions/gameAction';

const Games = (props, { title, isMobile }) => {
  const [newNote, setNewNote] = useState();
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const notifications = useSelector((state) => state.student.notifications);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      dispatch(
        getNotifications({
          institute: localStorage.getItem("institute"),
          user_id: user._id,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (notifications) {
      console.log(notifications.find((note) => note.read));
      const notes = notifications.find((note) => note.read);
      setNewNote(notes ? false : true);
    }
  }, [notifications]);

  const [filterGames, setFilterGames] = useState();

  const searchGames = (e) => {
    var xyz = game.games?.filter((game) =>
      game.name.toLowerCase().includes(e.toLowerCase())
    );
    setFilterGames(xyz);
  };

  const cancelSearchGames = (e) => {
    setFilterGames();
  };

  console.log(newNote);

  const [Subject, setSubjects] = useState("");

  const handleSubjects = (event) => {
    setSubjects(event.target.value);

    if (event.target.value == "All") {
      setFilterGames(game.game);
      return;
    }

    var abc = game.games?.filter((game) =>
      game.subjects
        .map((subject) => subject.toLowerCase())
        .includes(event.target.value)
    );
    console.log(abc);
    setFilterGames(abc);
  };

  return (
    <div>
      <Header
        search={searchGames}
        cancelSearch={cancelSearchGames}
        handleChange={handleSubjects}
        subject={Subject}
        newNote={newNote}
      />
      <Top games={game.games || []} {...props} />
      <Bottom
        games={
          filterGames?.length > 0 ? filterGames : filterGames ? [] : game.games
        }
        {...props}
      />
    </div>
  );
};

export default Games;
