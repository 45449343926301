import React, { useEffect, useState, Fragment, forwardRef } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Divider,
  Grid,
  IconButton,
  Slide,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import CreateForm from "../../components/Dashboard/Groups/Students/Components/CreateForm";
import ShareMemoryTable from "../../components/Dashboard/Groups/Students/Components/ShareMemoryTable";
import user_image from "../../components/Dashboard/Icons/user.png";
import group_image from "../../components/Dashboard/Icons/group_image.png";
import SearchBar from "material-ui-search-bar";
import { deleteGroup, getGroups } from "../../redux/actions/studentAction";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../config/index.js";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import swal from "sweetalert2";
import { DropzoneArea } from "material-ui-dropzone";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import validator from "validator";
import delete_icon from "../../components/Dashboard/Icons/delete_icon.png";
import edit_icon from "../../components/Dashboard/Icons/edit_icon.png";
import share_icon from "../../components/Dashboard/Icons/network-communication 1.png";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`app-tab-${index}`}
      className="tab_content"
      aria-labelledby={`app-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    className: "tab_head",
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0",
    "& h5": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: "24px 0",
  },
  button: {
    textTransform: "initial",
    marginTop: "10%",
    textTransform: "initial",
    border: "1px solid #D5D9E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "11px 56px",
  },
  deletePopup1: {
    position: "absolute",
    width: "500px",
    height: "198px",
    left: "470px",
    // top: '291px',
    // position: absolute;
    background: " #FFFFFF",
    // boxShadow: '0px 16px 64px -6px rgba(27, 27, 31, 0.05)',
    borderRadius: "16px",
  },
  deletePopup2: {
    position: "absolute",
    // width: '500px',
    // height: '198px',
    // left: '494px',
    // top: '291px',
    fontFamily: "Circular Std",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#4E5879",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 24,
    "&>*": {
      marginTop: 8,
    },
    "&>*:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      "&>*": {
        margin: "0 4px",
      },
    },
  },
  table: {
    "&>*:nth-child(1)": {
      background: "#E4E9F3",
    },
  },
  gameActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>*": {
      marginRight: 4,
    },
  },
  createModal: {
    "& h5": {
      fontWeight: 500,
    },
    "&>div:nth-child(2)": {
      margin: "24px 0 12px 0",
    },
    "&>form": {
      "&>div:nth-child(1)": {
        marginBottom: 24,
      },
      "&>div:nth-child(3)": {
        margin: "12px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "&>*": {
          width: "48%",
        },
      },
    },
  },
  column: {
    width: "25%",
    paddingLeft: "30px",
    textAlign: "left",
  },
  centerAlign: {
    textAlign: "center",
  },
  rightAlign: {
    textAlign: "center",
  },
  icons: {
    backgroundColor: "#E2E6F1",
    height: "30px",
    width: "30px",
    borderRadius: "5px",
    marginRight: "10px",
  },
}));

const PlayGame = (group) => {
  // console.log(group);
  const [playOrPause, setPlayOrPause] = useState(true);
  const [currentGame, setCurrentGame] = useState();
  const [currentStudents, setCurrentStudents] = useState();
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);

  var institute_id = localStorage.getItem("institute");
  const group_id = useParams();

  useEffect(() => {
    if (!student.groups && institute_id) {
      dispatch(getGroups(institute_id));
    }
  }, []);

  useEffect(() => {
    setCurrentGroup(group.group);
  }, [group]);

  const [shareMemoryModal, setShareMemoryModal] = useState(false);
  const [shareMemoryConfirmationModal, setShareMemoryConfirmationModal] =
    useState(false);

  const [value, setValue] = useState(0);

  const historyValue = useHistory();
  function getQueryVariable(variable) {
    var query = historyValue.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
  }
  useEffect(() => {
    if (getQueryVariable("value") == 1) {
      setValue(1);
    }
  }, []);

  const handleChange_tab = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [mode, setMode] = useState("single");
  const [inputs, setInputs] = useState({
    name: "",
    email_id: "",
    phone_number: "",
    profile_pic: "",
  });
  const [createModal, setCreateModal] = useState(false);
  const [resumeModal, setResumeModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteGroupModal, setDeleteGroupModal] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [recentGame, setRecentGame] = useState([]);
  const [csv, setCSV] = useState();
  const [playGame, setPlayGame] = useState([]);
  const history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeMode = (e) => {
    setMode(e.currentTarget.value);
  };

  const handleOpenCreateModal = () => {
    setCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModal(false);
  };

  const handleOpenResumeModal = () => {
    setResumeModal(true);
  };

  const handleCloseResumeModal = () => {
    setResumeModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    if (group) {
      setSelectedStudent({ ...group.group.students.find((s) => s._id === id) });
      setDeleteModal(id);
    }
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(null);
    setSelectedStudent({});
  };

  const handleOpenDeleteGroupModal = (id) => {
    // if (group) {
    //   setSelectedStudent({ ...group.group.students.find((s) => s._id === id) });
    //   setDeleteModal(id);
    // }
    setDeleteGroupModal(id);
  };

  const handleCloseDeleteGroupModal = () => {
    setDeleteGroupModal(null);
    // setSelectedStudent({});
  };

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleOpenShareMemoryModal = () => {
    setShareMemoryModal(true);
  };

  const handleCloseShareMemoryModal = () => {
    setShareMemoryModal(false);
  };

  const handleOpenShareMemoryConfirmationModal = () => {
    setShareMemoryModal(false);
    setShareMemoryConfirmationModal(true);
  };

  const handleCloseShareMemoryConfirmationModal = () => {
    setShareMemoryConfirmationModal(false);
  };

  const imageChange = async (files) => {
    setInputs({ ...inputs, profile_pic: files[0] });
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    if (inputs.email_id) {
      if (validator.isEmail(inputs.email_id) == false) {
        //   swal.fire({
        //     title: 'Error',
        //     text: "Please enter a valid email address",
        //     icon: 'error',
        //     confirmButtonText: 'Ok'
        // });

        document.getElementById("email_alert").style.display = "block";
        return;
      }
    }

    var allData = inputs;
    allData.group = group.match.params.id;

    setLoading(true);
    if (inputs.profile_pic && inputs.profile_pic != "") {
      // console.log('profile_pic')

      let data = new FormData();
      data.append("image", inputs.profile_pic);

      await axios
        .post(`${API_URL}/custom-games/upload`, data)
        .then((result) => {
          allData.profile_pic = result.data;
        });
    }

    console.log(allData);

    axios
      .post(`${API_URL}/students`, {
        ...allData,
        institute: localStorage.getItem("institute"),
      })
      .then((res) => {
        setLoading(false);
        // console.log(res)
        setCreateModal(false);
        swal
          .fire({
            title: "Success",
            text: "Student added successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(function () {
            window.location.reload();
          });
        for (const key of Object.keys(inputs))
          setInputs((prev) => ({ ...prev, [key]: "" }));
        // setLoading(false);
      })
      .catch((e) => {
        swal.fire({
          title: "Error",
          text: e.message || e || "Failed to create. Try again!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });

    // try{
    //     setError(null);
    //     setLoading(true);
    //     await group.createStudent({...allData,group: group.match.params.id});
    //     setCreateModal(false);
    //     setDeleteModal(null);
    //     setSelectedStudent({})
    //     setInputs({
    //       name:'',
    //       email_id:'',
    //       phone_number:'',
    //       profile_pic : ''
    //     })
    // }
    // catch(e){
    //     setError(e);
    // }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await group.deleteStudent(
        group && group.group.students.find((s) => s._id === deleteModal)
      );
      setDeleteModal(false);
    } catch (e) {
      alert(e);
    } finally {
      setDeleting(false);
    }
  };

  const handleGroupDelete = async () => {
    try {
      setDeleting(true);
      // dispatch(getGroups(institute_id));
      dispatch(deleteGroup(currentGroup));
      setDeleteGroupModal(false);
    } catch (e) {
      alert(e);
    } finally {
      setDeleting(false);
    }
  };

  const [currentGroup, setCurrentGroup] = useState();

  // var currentGroup = group.group

  const searchStudent = (e) => {
    if (e == null || e == "" || e == undefined) {
      setCurrentGroup(group.group);
    }

    var xyz = group.group?.students.filter((student) =>
      student.name.toLowerCase().includes(e.toLowerCase())
    );
    setCurrentGroup({ ...currentGroup, students: xyz });
  };

  const cancelSearchStudent = (e) => {
    setCurrentGroup(group.group);
  };

  const [currentStudent, setCurrentStudent] = useState({});
  const [currentEditGroup, setCurrentEditGroup] = useState({});

  const editStudent = (student) => {
    console.log(student);
    setCurrentStudent(student);
  };

  const editGroup = (group) => {
    console.log("gropppp-->", typeof group, group);
    setCurrentEditGroup(group);
  };
  // console.log('editttt-->',currentEditGroup);
  const [editData, setEditData] = useState({});
  const [editGroupData, setEditGroupData] = useState({});

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleEditGroupChange = (e) => {
    setEditGroupData({ ...editGroupData, [e.target.name]: e.target.value });
  };

  const imageEditChange = async (files) => {
    // console.log(files)
    let data = new FormData();
    data.append("image", files[0]);
    await axios.post(`${API_URL}/custom-games/upload`, data).then((result) => {
      setEditData({ ...editData, profile_pic: result.data });
    });
  };

  const imageEditGroupChange = async (files) => {
    // console.log(files)
    let data = new FormData();
    data.append("image", files[0]);
    await axios.post(`${API_URL}/custom-games/upload`, data).then((result) => {
      setEditGroupData({ ...editGroupData, group_img: result.data });
      console.log("editgroupp-->", editGroupData);
    });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${API_URL}/students/${currentStudent?._id}`, editData)
      .then((res) => {
        console.log(res);
        swal
          .fire({
            title: "Success",
            text: "Student Updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(function () {
            window.location.reload();
          });
        // for(const key of Object.keys(edit_user)) set_edit_user(prev => ({...prev, [key]:''}))
        // setLoading(false);
      })
      .catch((e) => {
        swal.fire({
          title: "Error",
          text: e.message || e || "Failed to Update. Try again!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleEditGroupSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${API_URL}/groups/${currentEditGroup?._id}`, editGroupData)
      .then((res) => {
        console.log(res);
        swal
          .fire({
            title: "Success",
            text: "Student Updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(function () {
            window.location.reload();
          });
        // for(const key of Object.keys(edit_user)) set_edit_user(prev => ({...prev, [key]:''}))
        // setLoading(false);
      })
      .catch((e) => {
        swal.fire({
          title: "Error",
          text: e.message || e || "Failed to Update. Try again!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  // axios.get(`http://localhost:5000/playgame/session/${response.data[0]._id}`)
  // await axios.get(`http://localhost:5000/playgame/session/recentgame/${currentGroup._id}`)

  const handleResumeGame = async () => {
    await axios
      .get(`${API_URL}/playgame/session/recentgame/${currentGroup._id}`)
      .then((response) => {
        if (response.data.length === 0) {
          history.push(`/labs/play-game2/${currentGroup?._id}`);
        }
        console.log("resss-->", response.data[0]._id);
        setRecentGame(response.data);
        axios
          .get(`${API_URL}/playgame/session/${response.data[0]._id}`)
          .then((res1) => {
            console.log("res11-->", res1.data);
            setPlayGame(res1.data);

            //process the boolean varaible
            var allCompleted = getallStudentGameStatus(res1.data);
            if (allCompleted) {
              // alert("Move to old approch");
              history.push(`/labs/play-game2/${currentGroup?._id}`);
            } else {
              handleOpenResumeModal();
            }
          });
      })
      .catch(() => {
        console.log("SHOULD OT COME -------->");
        setRecentGame([]);
      });
  };

  useEffect(() => {
    console.log("recenttGamee--->", recentGame);
    console.log("playGamee--->", playGame);
  }, [recentGame, playGame]);

  var getallStudentGameStatus = (studentData) => {
    var allCompleted = true;
    studentData.forEach((stu) => {
      console.log("Stu -->", stu);
      stu?.student?.forEach((s) => {
        allCompleted = allCompleted && s.isCompleted === "true";
      });
    });
    return allCompleted;
  };
  const handleNewGame = () => {
    {
      console.log(
        "currr-->",
        currentGroup,
        `/labs/play-game2/${currentGroup?._id}`
      );
    }
    //  <Redirect to={`/labs/play-game2/${currentGroup?._id}`}/>
    //  <Link to={`/labs/play-game2/${currentGroup?._id}`}>New Game</Link>
    history.push(`/labs/play-game2/${currentGroup?._id}`);
    // <Link to={`{${group.match.url}/${currentGroup._id}`} group_id={currentGroup._id} group={group} className='add_student_btn'>New Game</Link>
  };

  const handleResumeGame1 = async () => {
    // history.push(`/labs/play-game2/${currentGroup?._id}`)
    history.push({
      pathname: `/labs/play-game3/${currentGroup?._id}`,
      state: { isKinnectRequired: true, recentGame, playGame },
    });
  };

  const handleCSV = (file) => {
    setCSV(file?.length && file[0]);
  };

  const handleBulkUpload = async () => {
    try {
      setLoading(true);
      const fd = new FormData();
      fd.append("csv", csv);
      fd.append("group", group.match.params.id);
      const res = await axios.post(`${API_URL}/students/bulk`, fd);
      let failed = 0,
        success = 0;
      if (res.data.isError) {
        failed = res.data.data.length;
        success = res.data.studentDoc.length;
        let names = res.data.data.map((i) => i.name).join();

        swal.fire({
          title: `Success: ${success} Failed: ${failed}`,
          text: "Some students failed",
          icon: "warning",
          confirmButtonText: "Try Again",
          footer: `<p>Try removing already added students from the sheet: ${names}</p>`,
        });
      } else {
        success = res.data.studentDoc.length;
        swal.fire({
          title: `Success: ${success} Failed: ${failed}`,
          text: "Students Uploaded",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
      currentGroup.students = [...currentGroup.students, res.data.studentDoc];
      setCurrentGroup({ ...currentGroup });
      setLoading(false);
      setCreateModal(false);
    } catch (err) {
      setLoading(false);
      setCreateModal(false);
      console.log(err.response);
      swal.fire({
        title: "Error",
        text: err?.response?.data || "Error Occured",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  const [sessionDetail, setsessionDetail] = useState([]);
  const [currentSession, setCurrentSession] = useState();
  const [activeGameModal, setActiveGameModal] = useState(false);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log(id);
    axios
      .get(
        `https://institute.game.eduzo.com/institute/playgame/session/recentgame/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setsessionDetail(res.data);
        console.log("sessionDetail");
        console.log(res.data);
      });
  }, []);

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const playOrPauseGame = async () => {
    setStopDisable(true);
    setActiveGameModal(false);
    setPlayOrPause(!playOrPause);
    // alert("Paused");
    console.log(playOrPause);
    const play = await axios.patch(
      // `https://institute.game.eduzo.com/institute/playgame/session/stopGame/${currentSessionId}`,
      `https://institute.game.eduzo.com/institute/playgame/session/gameControl/${currentSession._id}`,
      { play: playOrPause, stop: false },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    await axios
      .get(
        `https://institute.game.eduzo.com/institute/playgame/session/recentgame/${currentGroup._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setsessionDetail(res.data);
        setActiveGameModal(false);
      });
  };

  const snapshotGame = async () => {
    alert("snapshot taken");
    const snap = await axios.patch(
      // `https://institute.game.eduzo.com/institute/playgame/session/stopGame/${currentSessionId}`,
      `https://institute.game.eduzo.com/institute/playgame/session/gameControl/${currentSession._id}`,
      { snapshot: true },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    await axios
      .get(
        `https://institute.game.eduzo.com/institute/playgame/session/recentgame/${currentGroup._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setsessionDetail(res.data);
        setActiveGameModal(false);
      });
  };

  const stopGame = async (id) => {
    console.log("Stop called");
    setStopDisable(false);
    const gameStop = await axios.patch(
      // `https://institute.game.eduzo.com/institute/playgame/session/stopGame/${currentSessionId}`,
      `https://institute.game.eduzo.com/institute/playgame/session/gameControl/${currentSession._id}`,
      { stop: true, play: false },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log(gameStop);
    await axios
      .get(
        `https://institute.game.eduzo.com/institute/playgame/session/recentgame/${currentGroup._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setsessionDetail(res.data);
        setActiveGameModal(false);
      });
  };

  const [stopDisable, setStopDisable] = useState(false);

  return (
    <div
      className="play_game"
      style={{ marginTop: "-25px", marginLeft: "-25px" }}
    >
      <Dialog
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        maxWidth="sm"
        fullWidth={true}
      >
        {/* <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove <b>{deleteModal && selectedStudent.name}</b> ?
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button className={classes.button} onClick={handleCloseDeleteModal} color="primary">
                    Cancel
                </Button>
                <Button className={classes.button} onClick={handleDelete} color="primary" autoFocus disabled={deleting}>
                    {deleting ? <CircularProgress size={24}/> : 'Yes, Delete'}
                </Button>
            </DialogActions> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b style={{ justifyContent: "space-between" }}>
              <span className={classes.deletePopup2}>
                Are you sure you want to remove{" "}
                {deleteModal && selectedStudent.name}?
              </span>
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#4335D2" }}
            className={classes.button}
            onClick={handleDelete}
            autoFocus
            disabled={deleting}
          >
            {deleting ? (
              <CircularProgress size={24} />
            ) : (
              <span style={{ color: "#FFFFFF" }}>Yes</span>
            )}
          </Button>
          <Button
            className={classes.button}
            onClick={handleCloseDeleteModal}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete group modal  */}

      <Dialog
        open={deleteGroupModal}
        onClose={handleCloseDeleteGroupModal}
        maxWidth="sm"
        fullWidth={true}
      >
        {/* <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove <b>{deleteModal && selectedStudent.name}</b> ?
                </DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button className={classes.button} onClick={handleCloseDeleteModal} color="primary">
                    Cancel
                </Button>
                <Button className={classes.button} onClick={handleDelete} color="primary" autoFocus disabled={deleting}>
                    {deleting ? <CircularProgress size={24}/> : 'Yes, Delete'}
                </Button>
            </DialogActions> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b style={{ justifyContent: "space-between" }}>
              <span className={classes.deletePopup2}>
                Are you sure you want to remove this group?
              </span>
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: "#4335D2" }}
            className={classes.button}
            onClick={handleGroupDelete}
            autoFocus
            disabled={deleting}
          >
            {deleting ? (
              <CircularProgress size={24} />
            ) : (
              <span style={{ color: "#FFFFFF" }}>Yes</span>
            )}
          </Button>
          <Button
            className={classes.button}
            onClick={handleCloseDeleteGroupModal}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* Share Memory modal  */}
      <Dialog
        open={shareMemoryModal}
        onClose={handleCloseShareMemoryModal}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent className={classes.createModal}>
          <div>
            <ShareMemoryTable group={group} />
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                fontSize: "16px",
                padding: "7px 60px",
                marginRight: "10px",
              }}
              onClick={handleOpenShareMemoryConfirmationModal}
            >
              Share
            </Button>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "white",
                fontSize: "16px",
                border: "1px solid lightgrey",
                boxShadow: "0px 0px 0px 0px white",
                padding: "7px 55px",
              }}
              onClick={handleCloseShareMemoryModal}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Share Memory confirmation */}
      <Dialog
        open={shareMemoryConfirmationModal}
        onClose={handleCloseShareMemoryConfirmationModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent className={classes.createModal}>
          <div>
            <Typography variant="h5" color="secondary">
              Are you sure ?
            </Typography>
          </div>

          <div>
            <Typography variant="h6">
              Are you sure you want to share the memory for the selected
              students ?
            </Typography>
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseShareMemoryConfirmationModal}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              onClick={handleCloseShareMemoryConfirmationModal}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/*Add Student modal*/}
      <Dialog
        open={createModal}
        onClose={handleCloseCreateModal}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent className={classes.createModal}>
          <div>
            <Typography variant="h5" color="secondary">
              Add Student
            </Typography>
          </div>

          <form onSubmit={handleCloseCreateModal}>
            <CreateForm
              mode={mode}
              inputs={inputs}
              handleCSV={handleCSV}
              handleChange={handleChange}
              imageChange={imageChange}
              handleChangeMode={handleChangeMode}
            />
            <div>{error && <Alert severity="error">{error}</Alert>}</div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) =>
                  mode === "bulk" ? handleBulkUpload() : handleSubmit(e)
                }
                style={{ textTransform: "none" }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  "Add"
                )}
              </Button>
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                onClick={handleCloseCreateModal}
              >
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/*Add ResumeGame modal*/}
      <Dialog
        open={resumeModal}
        onClose={handleCloseResumeModal}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent className={classes.createModal}>
          <div>
            <Typography variant="h5" color="secondary">
              Game
            </Typography>
          </div>

          {/* <form onSubmit={handleCloseCreateModal}> */}
          <Grid item xs={6} sm={12}>
            <div className="row">
              <div className="col-4">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "initial",
                    fontSize: "16px",
                    padding: "7px 33px",
                    marginRight: "10px",
                  }}
                  onClick={handleResumeGame1}
                >
                  ResumeGame
                </Button>
              </div>
              {/* <Link to={`{${group.match.url}/${currentGroup._id}`} group_id={currentGroup._id} group={group} className='add_student_btn'> */}
              <div className="col-4">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "initial",
                    fontSize: "16px",
                    padding: "7px 44px",
                    marginLeft: "35px",
                    marginRight: "10px",
                  }}
                  onClick={handleNewGame}
                >
                  NewGame
                </Button>
              </div>
              {/* </Link> */}
            </div>
          </Grid>
          {/* </form> */}
        </DialogContent>
      </Dialog>
      {/* <div className='breadcrumb_title'> */}

      {/*Edit Student modal*/}

      <form>
        <div className="custom_modal modal" id="edit_user_modal">
          <div className="modal-dialog modal-dialog-centered modal0sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-custom-content">
                  <h3 className="modal_title">Edit Student</h3>

                  <div className="form-group">
                    <label htmlFor="">Student Name</label>
                    <input
                      defaultValue={currentStudent.name}
                      onChange={handleEditChange}
                      type="text"
                      placeholder="sarah"
                      name="name"
                      id="name"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="">Phone Number</label>
                    <input
                      defaultValue={currentStudent.phone_number}
                      onChange={handleEditChange}
                      type="number"
                      // maxLength = "10"
                      placeholder="99999-99999"
                      name="phone_number"
                      id="phone_number"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="">Email Address</label>
                    <input
                      defaultValue={currentStudent.email_id}
                      onChange={handleEditChange}
                      type="text"
                      placeholder="sarah@abcschools.in"
                      // value=''
                      name="email_id"
                      id="email_id"
                      className="form-control"
                    />
                  </div>
                  <div style={{ width: "15vw" }}>
                    <label htmlFor="image">Profile Picture</label>
                    <br />
                    <br />
                    {/* <input type="file" onChange={imageEditChange} id="image"/>
                     */}
                    <DropzoneArea
                      style={{ fontSize: "12px" }}
                      useChipsForPreview={true}
                      filesLimit={1}
                      acceptedFiles={["image/*"]}
                      dropzoneText={"Drag and drop image or Browse"}
                      onChange={(files) => imageEditChange(files)}
                    />
                  </div>

                  <div className="row button_container">
                    <button
                      type="submit"
                      onClick={handleEditSubmit}
                      className=" btn-login btn-blue"
                    >
                      Submit
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn-login btn-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/*Edit Group modal*/}

      <form>
        <div className="custom_modal modal" id="edit_group_modal">
          <div className="modal-dialog modal-dialog-centered modal0sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-custom-content">
                  <h3 className="modal_title">Edit Group</h3>

                  <div className="form-group">
                    <label htmlFor="">Group Name</label>
                    <input
                      defaultValue={currentEditGroup?.name}
                      onChange={handleEditGroupChange}
                      type="text"
                      // placeholder="sarah"
                      name="name"
                      id="name"
                      className="form-control"
                    />
                  </div>

                  {/* <div className='form-group'>
                  <label htmlFor=''>Phone Number</label>
                  <input
                  defaultValue={currentStudent.phone_number} onChange={handleEditChange} 
                    type='number'
                    // maxLength = "10"
                    placeholder="99999-99999"
                    name='phone_number'
                    id='phone_number'
                    className='form-control'
                  />
                </div>

                <div className='form-group'>
                  <label htmlFor=''>Email Address</label>
                  <input
                  defaultValue={currentStudent.email_id} onChange={handleEditChange} 
                    type='text'
                    placeholder="sarah@abcschools.in"
                    // value=''
                    name='email_id'
                    id='email_id'
                    className='form-control'
                  />
                </div>  */}
                  <div style={{ width: "15vw" }}>
                    <label htmlFor="image">Profile Picture</label>
                    <br />
                    <br />
                    {/* <input type="file" onChange={imageEditChange} id="image"/>
                     */}
                    <DropzoneArea
                      style={{ fontSize: "12px" }}
                      useChipsForPreview={true}
                      filesLimit={1}
                      acceptedFiles={["image/*"]}
                      dropzoneText={"Drag and drop image or Browse"}
                      onChange={(files) => imageEditGroupChange(files)}
                    />
                  </div>

                  <div className="row button_container">
                    <button
                      type="submit"
                      onClick={handleEditGroupSubmit}
                      className=" btn-login btn-blue"
                    >
                      Submit
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn-login btn-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={`/labs/groups`}>
          Groups / {currentGroup?.name}/
        </Link>
        {/* <Typography>{currentGroup?.name}</Typography> */}
      </Breadcrumbs>

      {/* </div> */}
      <div className="grade_application_holder">
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <div className="application_holder">
              <img
                style={{ borderRadius: "10%", backgroundColor: "white" }}
                src={
                  currentGroup?.group_img && currentGroup?.group_img != ""
                    ? currentGroup?.group_img
                    : group_image
                }
                className="application_icon"
                alt="group_image"
              />
              <Button
                onClick={() => editGroup(currentGroup)}
                data-toggle="modal"
                data-target="#edit_group_modal"
              >
                <span className={classes.icons}>
                  <img
                    style={{ marginTop: "7px" }}
                    fontSize="small"
                    src={edit_icon}
                    height="16px"
                  />
                </span>
              </Button>
              <Button
                onClick={() => handleOpenDeleteGroupModal(currentGroup._id)}
                data-toggle="modal"
              >
                <span className={classes.icons}>
                  <img
                    style={{ marginTop: "7px" }}
                    fontSize="small"
                    src={delete_icon}
                    height="16px"
                  />
                </span>
              </Button>

              <div className="application_content">
                <h3>{currentGroup?.name}</h3>
                <p>0 Application</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="text-right">
              {/* <Link to={`/labs/play-game2/${currentGroup?._id}`} group={group} className='add_student_btn'> */}
              {/* <Link to={`{${group.match.url}/${currentGroup._id}`} group_id={currentGroup._id} group={group} className='add_student_btn'> */}
              {/* <button className='play_game_btn' onClick={handleOpenResumeModal}>Play Game</button> */}
              <button className="play_game_btn" onClick={handleResumeGame}>
                Play Game
              </button>
              {/* <button className='play_game_btn' >Play Game</button> */}

              {/* </Link> */}
              {/* <Button variant="outlined" onClick={handleOpenShareMemoryModal}>
                Share Memory
              </Button> */}
            </div>
          </Grid>
        </Grid>

        <div className="tab-holder">
          <Tabs
            className="custom_tabs"
            value={value}
            onChange={handleChange_tab}
            aria-label="app tab"
          >
            <Tab
              label="Students"
              style={{ fontWeight: value === 0 ? "700" : "500" }}
              {...tabProps(0)}
            />
            <Tab
              label="Sessions"
              style={{ fontWeight: value === 1 ? "700" : "500" }}
              {...tabProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={1}>
            <div className="table_container">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <h3 className="session_title">Sessions</h3>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <h3 className="search_icon text-right">
                    <span class="fa fa-search"></span>
                  </h3>
                </Grid>
              </Grid>

              <div className="table-holder">
                {/* <table className="table  table-borderless">
                  <thead>
                    <tr>
                      <td className={classes.column}>Game Name</td>
                      <td className={classes.column}>Grade</td>
                      <td className={classes.column}>Group</td>
                      <td className={classes.column}>Students</td>
                      <td className={classes.column}>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sessionDetail.length ? (
                        sessionDetail.map((session, index) => (
                          session.Game.map((item, index) => (
                            <tr>
                              <td>
                                <a href='#'> {item.custom_game.game.name} </a>
                              </td>
                              <td className='bold'>{item.custom_game.grades}</td>
                              <td className='bold'>{item.custom_game.subjects}</td>
                              <td>{item.students}</td>
                              <td className='bold'>
                                <span className='dot'></span>
                                {session.isComplete ? "Completed" : "InProgress"}
                              </td>
                            </tr>
                          ))
                        ))
                      ) : (" ")
                    }
                  </tbody>
                </table> */}

                <Dialog
                  fullScreen
                  open={activeGameModal}
                  TransitionComponent={Transition}
                  onClose={() => setActiveGameModal(false)}
                  style={{ display: activeGameModal ? "block" : "none" }}
                >
                  <Grid container className={classes.createGame}>
                    <Row className="w-100 p-4">
                      <Col sm={6}>
                        <Typography variant="h4" gutterBottom>
                          Active Game
                        </Typography>
                      </Col>
                      <Col className="text-right">
                        <IconButton onClick={() => setActiveGameModal(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Col>
                    </Row>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>

                    <Col sm={12}>
                      <p>
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs. The passage is attributed to an unknown
                        typesetter in the 15th century who is thought to have{" "}
                      </p>
                    </Col>

                    <Row className="w-100 p-4">
                      <Col sm={3}>
                        <div className="d-flex flex-column">
                          <label className="mb-2">Game</label>
                          <TextField
                            variant="outlined"
                            value={currentGame?.name}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="d-flex flex-column">
                          <label className="mb-2">Students</label>
                          <TextField
                            variant="outlined"
                            value={currentStudents?.map((s) => s.name)}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="d-flex align-item-center justify-content-start mt-4">
                          {playOrPause ? (
                            <img
                              src="/assets/images/homepage/play.svg"
                              onClick={() => playOrPauseGame()}
                              style={
                                stopDisable
                                  ? {
                                      cursor: "pointer",
                                      pointerEvents: "none",
                                      opacity: 0.7,
                                    }
                                  : { cursor: "pointer" }
                              }
                            />
                          ) : (
                            <img
                              src="/assets/images/homepage/play.svg"
                              onClick={() => playOrPauseGame()}
                              style={
                                stopDisable
                                  ? {
                                      cursor: "pointer",
                                      pointerEvents: "none",
                                      opacity: 0.7,
                                    }
                                  : { cursor: "pointer" }
                              }
                            />
                          )}
                          <img
                            src="/assets/images/homepage/screenshot.svg"
                            onClick={() => snapshotGame()}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <img
                            src="/assets/images/homepage/stop.svg"
                            onClick={() => stopGame()}
                            style={
                              !stopDisable
                                ? {
                                    cursor: "pointer",
                                    pointerEvents: "none",
                                    opacity: 0.7,
                                  }
                                : { cursor: "pointer" }
                            }
                          />
                          <Button
                            style={{
                              backgroundColor: "#4234D3",
                              color: "white",
                              height: "45px",
                              marginTop: "5px",
                            }}
                          >
                            Show QR Code
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 pl-4 mt-5">
                      <Col sm={3}>
                        <div className="d-flex flex-column">UPCOMING GAMES</div>
                      </Col>
                    </Row>
                    <Row className="w-100 p-4">
                      <Col sm={3}>
                        <div className="d-flex flex-column">
                          <TextField variant="outlined" />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="d-flex flex-column">
                          <TextField variant="outlined" />
                        </div>
                      </Col>
                      <Col sm={4}></Col>
                    </Row>
                  </Grid>
                </Dialog>
                <Paper>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          style={{ backgroundColor: "#E4E9F3", opacity: 0.5 }}
                        >
                          <TableCell className={classes.column}>
                            Game Name
                          </TableCell>
                          <TableCell className={classes.column}>
                            Grade
                          </TableCell>
                          <TableCell className={classes.column}>
                            Group
                          </TableCell>
                          <TableCell className={classes.column}>
                            Students
                          </TableCell>
                          <TableCell className={classes.rightAlign}>
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sessionDetail.length ? (
                          sessionDetail.map((session, index) =>
                            session.Game.map(
                              (item, index) => (
                                console.log(item),
                                (
                                  <TableRow key={index}>
                                    <TableCell
                                      className={classes.column}
                                      onClick={() => {
                                        setActiveGameModal(!session.isComplete);
                                        setCurrentSession(session);
                                        setCurrentGame(
                                          item.game[0].custom_game.game
                                        );
                                        setCurrentStudents(
                                          item.game[0]?.students
                                        );
                                        setStopDisable(!session.stop);
                                      }}
                                    >
                                      {item &&
                                        item?.game[0].custom_game &&
                                        item.game[0].custom_game.game.name}
                                    </TableCell>
                                    <TableCell className={classes.column}>
                                      {item &&
                                        item?.game[0]?.custom_game &&
                                        item.game[0].custom_game.grades}
                                    </TableCell>
                                    <TableCell className={classes.column}>
                                      {item &&
                                        item?.game[0]?.custom_game &&
                                        item.game[0].custom_game.subjects}
                                    </TableCell>
                                    <TableCell className={classes.column}>
                                      {item &&
                                        item?.game[0]?.students.map((s) => (
                                          <p>{s.name}</p>
                                        ))}
                                    </TableCell>
                                    <TableCell
                                      className={classes.column}
                                      style={{ color: "green" }}
                                    >
                                      <span className="dot"></span>
                                      {session.isComplete
                                        ? "Completed"
                                        : session.stop
                                        ? "Stopped"
                                        : "InProgress"}
                                    </TableCell>
                                  </TableRow>
                                )
                              )
                            )
                          )
                        ) : (
                          <TableRow style={{ "text-align": "center" }}>
                            {" "}
                            <TableCell
                              style={{ width: "100%", "text-align": "center" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {sessionDetail?.length === 0 ? (
                    ""
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={10}
                      rowsPerPage={10}
                      page={page}
                      onChangePage={handleChangePage}
                    />
                  )}
                </Paper>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <div className="table_container">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6}>
                  <h3 className="session_title">
                    {currentGroup?.students?.length} Students
                  </h3>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <h3 className="search_icon text-right">
                    <div className="row">
                      <div className="col-sm-6">
                        <SearchBar
                          onChange={(e) => searchStudent(e)}
                          onCancelSearch={(e) => cancelSearchStudent(e)}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenCreateModal}
                          style={{
                            textTransform: "none",
                            padding: "8px 16px",
                            color: "white !important",
                          }}
                        >
                          + Add Student
                        </Button>
                      </div>
                    </div>
                  </h3>
                </Grid>
              </Grid>

              <div className="table-holder">
                <Paper>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          style={{ backgroundColor: "#E4E9F3", opacity: 0.5 }}
                        >
                          <TableCell className={classes.column}>
                            Student Name
                          </TableCell>
                          <TableCell className={classes.column}>
                            Phone
                          </TableCell>
                          <TableCell className={classes.column}>
                            Email
                          </TableCell>
                          <TableCell className={classes.column}>
                            Last Played
                          </TableCell>
                          <TableCell className={classes.rightAlign}>
                            Actions
                          </TableCell>

                          {/* <td className={classes.column}>Student Name</td>
                      <td  className={classes.column}>Phone</td>
                      <td  className={classes.column}>Email</td>
                      <td  className={classes.column}>Last Played</td>
                      <td  className={classes.rightAlign}>Actions</td> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentGroup?.students ? (
                          currentGroup?.students
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((student, index) => (
                            <TableRow key={index}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.column}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      borderRadius: "20px",
                                      marginRight: "10px",
                                    }}
                                    src={
                                      student?.profile_pic &&
                                      student?.profile_pic != ""
                                        ? student?.profile_pic
                                        : user_image
                                    }
                                    className="student_image"
                                    alt=""
                                  />
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={`${group.match.url}/${student._id}`}
                                  >
                                    {student?.name}
                                  </Link>
                                </span>
                              </TableCell>
                              <TableCell className={classes.column}>
                                {student?.phone_number}
                              </TableCell>
                              <TableCell className={classes.column}>
                                {student?.email_id}
                              </TableCell>
                              <TableCell className={classes.column}>
                                Esther Howard
                              </TableCell>
                              <TableCell className={classes.centerAlign}>
                                <div className={classes.gameActions}>
                                  <Button
                                    onClick={() => editStudent(student)}
                                    data-toggle="modal"
                                    data-target="#edit_user_modal"
                                  >
                                    <span className={classes.icons}>
                                      <img
                                        style={{ marginTop: "7px" }}
                                        fontSize="small"
                                        src={edit_icon}
                                        height="16px"
                                      />
                                    </span>
                                  </Button>
                                  <Button>
                                    <span className={classes.icons}>
                                      <img
                                        src={share_icon}
                                        style={{ marginTop: "7px" }}
                                        height="16px"
                                      />
                                    </span>
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleOpenDeleteModal(student._id)
                                    }
                                  >
                                    <span
                                      className={classes.icons}
                                      onClick={() =>
                                        handleOpenDeleteModal(student._id)
                                      }
                                    >
                                      <img
                                        height="16px"
                                        style={{ marginTop: "7px" }}
                                        src={delete_icon}
                                      />
                                    </span>
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow style={{ "text-align": "center" }}>
                            {" "}
                            <TableCell
                              style={{ width: "100%", "text-align": "center" }}
                            >
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {currentGroup?.students?.length === 0 ? (
                    ""
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={10}
                      rowsPerPage={10}
                      page={page}
                      onChangePage={handleChangePage}
                    />
                  )}
                </Paper>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PlayGame);
