// import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

//material-ui style imports
import { makeStyles } from '@material-ui/core/styles';


//common
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1224, min: 760 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 760, min: 0 },
      items: 1
    }
};

const useStyles = makeStyles(() => ({
    divider: {
        margin: '24px 0'
    },
    chip: {
        marginTop : '0.2vh',
        minHeight : '28px !important',
        minWidth : '62px',
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    buttonLink: {
        textDecoration: 'none',
        color: '#000'
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 24,
        '&>div:nth-child(1)':{
            display: 'flex',
            width : '70%',
            alignItems: 'center',
            '&>img':{
                width: 80,
                marginRight: 20,
                borderRadius:12
            },
            '&>div':{
                '& h5':{
                    fontSize: '1.4rem',
                    margin: '0 8px 4px 0',
                    fontWeight: 500,
                    textTransform: 'capitalize'
                },
                '& p':{
                    width : '80%',
                    fontSize: '0.9rem',
                    marginTop: 4,
                    color : '#02113F',
                    opacity : '0.6',
                    fontSize : '16px'
                },
                '&>div':{
                    display: 'flex',
                    alignItems: 'center',
                }
            }
        }
    },
}));

const ViewGameTop = ({game}) => {

    console.log(game)

//   var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];


  const classes = useStyles();

  return (
    <div>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={`/labs/my-games`}>
                My Games
            </Link>
            <Link color="inherit" to={`/labs/gamestore/${game._id}`}>
            <Typography>{game?.name}</Typography>
            </Link>
        </Breadcrumbs>

        <div className={classes.gameTitle}>
            <div>
                <img src={game?.image} alt="game"/>
                <div>
                    <div>
                        <Typography variant="h5">{game?.name}</Typography>
                        <Chip style={{backgroundColor: '#FFBFBF', textTransform : 'capitalize'}} label={game?.subjects?.[0]}  className={classes.chip}/>
                        {/* {game.subjects?.map((subject, i, subjects) => 
                            <Chip color="primary" style={{marginRight : '5px'}} label={subject} className={classes.chip}/>
                            )} */}
                    </div>
                    <Typography variant="body1">{game?.short_description}</Typography>
                </div>
            </div>
            <Link to={`/labs/gamestore/${game?._id}`} className={classes.buttonLink}><Button variant="outlined" style={{textTransform : 'none', padding : '5px 20px', fontSize : '16px'}}>View Game Details</Button></Link>
        </div>

        <Divider className={classes.divider}/>
    </div>
  );
}

export default ViewGameTop;
