import {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

//components
import Top from './Top';
import Bottom from './Bottom';
import Header from '../../../Header';
import eduzo_logo from '../../../Icons/Eduzo.labs.png'
import eduzo_logo1 from '../../../Icons/eduzo_logo1.png'



import {getGamesUnregistered} from '../../../../../redux/actions/gameAction';

const UnregisteredGameDetail = (props) => {
  const dispatch = useDispatch();
  const game = useSelector(state => state.game);

  useEffect(()=>{
    if(!game.games){
      dispatch(getGamesUnregistered());
    }
  },[])

  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  },[props.match.params.id])

  return (
    <div style={{padding : '50px'}}>
      {/* <Header /> */}
      {/* <img src={eduzo_logo} height={'28px'} width={'180px'} style={{marginTop : '1vh', marginRight : '3vh'}} /> */}
      {/* <img src={eduzo_logo1} height={'28px'} width={'180px'} style={{marginTop : '1vh', marginRight : '3vh'}} /> */}
      <Top game={game.games?.find(g => g._id===props.match.params.id) || {} }/>
      {/* <Bottom similarGames={game.games?.filter(g => g._id!==props.match.params.id) || []} {...props}/> */}
    </div>
  );
}

export default UnregisteredGameDetail;
