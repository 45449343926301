import { Link } from "react-router-dom";

//Carousel imports
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SearchBar from "material-ui-search-bar";

//common
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1224 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1224, min: 760 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 760, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& h5": {
      fontWeight: 600,
    },
  },
  knowMoreButton: {
    height: 42,
    width: 140,
  },

  linkButton: {
    textDecoration: "none",
    // padding: '5px 25px',
  },
  carouselContainer: {
    width: "100%",
  },
  carouselItem: {
    padding: "0 6px",
    width: "auto !important",
  },
  games: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0",
    width: "102%",
  },
  game: {
    width: 325,
    height: 325,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 24,
    margin: "0px 15px 0px 0px",
    "&>div:nth-child(1)": {
      color: "#fff",
      "&>h6": {
        fontWeight: 500,
        marginBottom: 5,
        textTransform: "capitalize",
      },
      "&>p": {
        fontWeight: 300,
        opacity: "0.8",
      },
    },
    "&>div:nth-child(2)": {
      "&>*:nth-child(1)>*:nth-child(1)": {
        background: "#fff",
        padding: 8,
        borderRadius: 12,
        textTransform: "initial",
        fontWeight: 600,
      },
    },
  },
}));

const Top = ({ match, games }) => {
  //     var background = ['linear-gradient(155deg, rgb(68, 38, 90, 1) 20%, rgb(68, 38, 90, 0.8) 40%, rgb(68, 38, 90, 0.6) 70%, rgba(68, 38, 90, 0.4)100%)' ,
  //     'linear-gradient(155deg, rgb(29,57,104, 1) 20%, rgb(29,57,104, 0.8) 40%, rgb(29,57,104, 0.6) 70%, rgba(29,57,104, 0.4)100%)',
  //     'linear-gradient(155deg, rgb(82,76,109, 1) 20%, rgb(82,76,109, 0.8) 40%, rgb(82,76,109, 0.6) 70%, rgba(82,76,109, 0.4)100%)',
  //    ]

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Featured Games</Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.games}>
            <Carousel
              responsive={responsive}
              containerClass={classes.carouselContainer}
              itemClass={classes.carouselItem}
            >
              {games.map((g) => (
                <div
                  className={classes.game}
                  // style={{backgroundImage:`${background[Math.floor(Math.random() * background.length)]} ,
                  style={{
                    backgroundImage: ` 
                                url('${g.illustration_img}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    boxShadow: "0px 8px 32px -6px rgba(29, 57, 104, 0.3)",
                  }}
                >
                  <div>
                    <Typography variant="h6">{g.name}</Typography>
                    <Typography variant="body1">
                      {g.short_description}
                    </Typography>
                  </div>

                  <div>
                    <Link
                      to={`${match.path}/${g._id}`}
                      className={classes.linkButton}
                    >
                      <Button className={classes.knowMoreButton}>
                        Know More
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Top;
