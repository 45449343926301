import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,} from "../types";
  
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
};

export function authReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case USER_LOADED:
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          user: payload,
        };
      case LOGIN_SUCCESS:
        localStorage.setItem("token", payload.token);
        localStorage.setItem("institute", payload.user.institute);
        localStorage.setItem("institute_plan", payload.user.institute_plan);
        localStorage.setItem("institute_name", payload.user.institute_name);
        localStorage.setItem("user_name", payload.user.name);
        localStorage.setItem("profile_pic", payload.user.profile_pic);
        localStorage.setItem("institute_code", payload.user.institute_code);
        localStorage.setItem("phone_number", payload.user.phone_number);
        localStorage.setItem("email_id", payload.user.email_id);
        localStorage.setItem("MyInstalledGames", payload.user.game);
        localStorage.setItem("expiry_date", payload.user.expiry_date);

        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false,
        };
      case LOGIN_FAIL:
      case AUTH_ERROR:
      case LOGOUT:
        console.log("LOGIN_FAIL")
        localStorage.removeItem("token");
        localStorage.removeItem("institute");
        localStorage.removeItem("institute_plan");
        localStorage.removeItem("institute_name");
        localStorage.removeItem("user_name");        
        localStorage.removeItem("profile_pic");    
        localStorage.removeItem("institute_code"); 
        localStorage.removeItem("MyInstalledGames")   
        localStorage.removeItem("expiry_date")       
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false,
        };
      default:
        return state;
    }
  }
  


  