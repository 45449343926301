import {useState, useEffect} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';




//material-ui icon imports
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert2';
import axios from 'axios';
import {API_URL} from '../../../config/index';
import { useLocation } from 'react-router';


//common

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    '& h5':{
      fontWeight: 600
    },
    '&>*':{
      margin: '12px 0 '
    }
  },
  divider: {
    margin: '24px 0'
  },

  
  package: {
    margin : '0px 20px 0px 0px',
    borderRadius : '12px',
    '&>div:nth-child(1)':{
      display: 'flex',
      '&>div':{
        marginLeft: 24,
        '& span':{
          fontWeight: 600,
          fontSize: '1.2rem'
        }
      }
    },
    '&>*:nth-child(2)': {
      margin: '12px 0'
    },
    '&>*:nth-child(3)':{
      width: '100%',
      textTransform: 'none'
    },
    padding: '16px'
  },
  activePlanButton: {
    background: 'linear-gradient(93.23deg, #4335D2 13.26%, #E93480 100%)',
    color: '#fff',
    borderRadius: '8px',
    padding: '8px',
    fontSize: '16px'
  },

  getInTouchButton: {
    // background: 'linear-gradient(93.23deg, #4335D2 13.26%, #E93480 100%)',
    color: '#02113F',
    borderRadius: '8px',
    padding: '8px',
    fontSize: '16px'
    
  },


  getInTouch: {
    minHeight: '35vh',
    // borderRadius : '20px',
    // marginTop: '5vh',
    padding : "30px",
    '&>div:nth-child(1)':{

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>h5':{
        fontWeight: 600,
      },
    },
    '&>*:nth-child(3)':{
        margin: '36px 0'
    },
    '&>*:nth-child(4)':{
        width: '100%',
        marginBottom: 24
    }
  }
}));

const PackagesTop = ({history}) => {

  const location = useLocation();

  var currentPlan = localStorage.getItem('institute_plan')
  // var phone_number = localStorage.getItem('phone_number')
  // var email_id = localStorage.getItem('email_id')
  // var institute_name = localStorage.getItem('institute_name')
  // var user_name = localStorage.getItem('user_name')

  const [inputs, setInputs] = useState({
    startTime : "",
    endTime : "",
    phno : localStorage.getItem('phone_number'),
    email_id : localStorage.getItem('email_id'),
    institute : localStorage.getItem('institute_name'),
    name : localStorage.getItem('user_name'),
  })


  const changeTime = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value})
    // console.log(inputs)
  }

  const [isUnRegistered, setIsUnregistered] = useState(false);

  useEffect(() => {
    if(location.pathname === '/labs/unregistered/packages'){
      setIsUnregistered(true);
    }
  },[location])

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    // console.log(inputs)
  try{
      setError(null);
      setLoading(true);
      axios.post(`${API_URL}/leads`, inputs)
      .then(res => {
        handleCloseGetInTouchDialog()
          // console.log(res)
          swal.fire({
              title: 'Success',
              text: `We'll contact you soon.`,
              icon: 'success',
              confirmButtonText: 'Ok'
          });
          for(const key of Object.keys(inputs)) setInputs(prev => ({...prev, [key]:''}))
          // setLoading(false);
      }).catch(e => {
          swal.fire({
              title: 'Error',
              text: e.message || e || 'Failed to create. Try again!',
              icon: 'error',
              confirmButtonText: 'Ok'
          });
      });
      // setCreateModal(false);
  }
  catch(e){
      setError(e);
  }
  finally{
      setLoading(false);
  }
  }
  const [createModal, setCreateModal] = useState(false);

  const classes = useStyles();

  const [getInTouchDialog, setGetInTouchDialog] = useState(false);

  const handleCloseGetInTouchDialog = () => {
    setGetInTouchDialog(false);
  }

  const handleOpenGetInTouchDialog = () => {
    setGetInTouchDialog(true);
  }

  const handleCloseCreateModal = () => {
    setCreateModal(false);
    }

    const handleOpenCreateModal = () => {
    setCreateModal(true);
    }

  const handleChange = (e) => {
    setInputs({...inputs, [e.target.name] : e.target.value})
}

  return (
    <Grid container className={classes.container} style={{ margin: isUnRegistered && '0px 100px 0px 100px', width: isUnRegistered && '90%' }}>
      <Grid item xs={12}>
        <IconButton onClick={()=>history.goBack()}><ArrowBackIcon/></IconButton>
      </Grid>
      
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>Flexible Packages</Typography>
        <Typography variant="body1">Choose a plan that works best for you and your team</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.package} elevation={3}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/assets/images/packages/basic.svg`} alt="basic plan"/>
                <div>
                  <Typography variant="h6">Basic</Typography>
                  <Typography variant="body1">₹ <span>XXX</span>/user</Typography>
                </div>
              </div>
              <List className="blur">
              <hr style={{opacity:0.3}}/>

                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="All basic games for Primary" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flexible Teams" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="5TB Storage" />
                </ListItem>
              </List>
              {currentPlan === "Basic" ?
              <Button variant="outlined"  className={classes.activePlanButton}>Current Plan</Button> :
              <Button variant="outlined" className={classes.getInTouchButton} onClick={handleOpenGetInTouchDialog}>Get in touch</Button>
            }
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.package} elevation={3}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/assets/images/packages/standard.svg`} alt="standard plan"/>
                <div>
                  <Typography variant="h6">Intermediate</Typography>
                  <Typography variant="body1">₹ <span>XXX</span>/user</Typography>
                </div>
              </div>
              <List  className="blur">
              <hr style={{opacity:0.3}} />

                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="All basic games for Primary" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flexible Teams" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="5TB Storage" />
                </ListItem>
              </List>

              {currentPlan === "Intermediate" ?
              <Button variant="outlined"  className={classes.activePlanButton}>Current Plan</Button> :
              <Button variant="outlined" className={classes.getInTouchButton}  onClick={handleOpenGetInTouchDialog}>Get in touch</Button>
            }

              {/* <Button variant="outlined" onClick={handleOpenGetInTouchDialog}>Get in touch</Button> */}
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.package} elevation={3}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/assets/images/packages/premium.svg`} alt="premium plan"/>
                <div>
                  <Typography variant="h6">Advanced</Typography>
                  <Typography variant="body1">₹ <span>XXX</span>/user</Typography>
                </div>
              </div>
              <List className="blur">
              <hr style={{opacity:0.3}}/>

                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="All basic games for Primary" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flexible Teams" />
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="5TB Storage" />
                </ListItem>
              </List>

              {currentPlan === "Advanced" ?
              <Button variant="outlined"  className={classes.activePlanButton}>Current Plan</Button> :
              <Button variant="outlined" className={classes.getInTouchButton}  onClick={handleOpenGetInTouchDialog} >Get in touch</Button>
            }
              {/* <Button variant="outlined" onClick={handleOpenGetInTouchDialog}>Get in touch</Button> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider}/>
      </Grid>

      {/* <Dialog 
     
        className={classes.getInTouch} 
        open={getInTouchDialog} 
        onClose={handleCloseGetInTouchDialog} 
        aria-labelledby="get-in-touch-dialog"
        // fullWidth={true}
        maxWidth="sm"
      >
        
        <DialogContent className={classes.getInTouch}  >
    
          <div  >
            <Typography variant="h5" color="secondary">Hi, we are glad you are<br/> interested :D</Typography>
            <IconButton onClick={handleCloseGetInTouchDialog}><CloseIcon  style={{fontSize: '35px', fontWeight : '600', color: 'black'}}/></IconButton>
          </div>
          <Typography style={{marginTop : '2vh', color: '#02113F'}} variant="body1">Please choose a suitable time, for us to get in touch with you.</Typography>
          <Typography style={{marginTop : '3vh' , marginBottom : '2vh', color: '#02113F'}} variant="body1">Time Range</Typography>
          <div className="row">
            <div className="col-md-3">
          <TextField
            id="time"
            label="From"
            type="time"
            name = "startTime"
            onChange={changeTime}
            value={inputs.startTime}
            defaultValue="11:30"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            sx={{ width: 150 }}
          />
      </div>
      <div className="col-md-3">
        <TextField
        id="time"
        value={inputs.endTime}
        label="To"
        type="time"
        name = "endTime"
        onChange={changeTime}
        defaultValue="14:30"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: 150 }}
      />
      </div>
      </div>
          <button  style={{fontWeight:'700',backgroundColor : '#4335D2', border : '0px', padding : '10px 20px', color : 'white' , fontSize : '18px', borderRadius : '5px', width : '100%'}} onClick={handleSubmit}  
          // onClick={handleCloseGetInTouchDialog}
          >
            Submit
          </button>
        </DialogContent>
      </Dialog> */}

          <Dialog 
          
          className={classes.getInTouch} 
          open={getInTouchDialog} 
          onClose={handleCloseGetInTouchDialog} 
          aria-labelledby="get-in-touch-dialog"
          // fullWidth={true}
          maxWidth="sm"
        >
        
        <DialogContent className={classes.createModal}>
            <div>
                <Typography variant="h5" style={{fontWeight : '600'}} color="secondary">Hi, we are glad you are <br/> interested :D</Typography><br/>
                <Typography variant="p"  style={{fontSize : '14px'}} >Please fill in your details, for us to get in touch with you.</Typography>
            </div><br/>
            <div>
                <label htmlFor="name">Your Name</label>
                <TextField placeholder='Sarah' id="name" autoFocus value={inputs.name} InputProps={{name:'name'}} onChange={handleChange} fullWidth variant="outlined"/>
            </div><br/>
            <div>
                <label htmlFor="name">Your Number</label>
                <TextField placeholder='9876543210' id="phno" type="number" autoFocus value={inputs.phno} InputProps={{name:'phno'}} onChange={handleChange} fullWidth variant="outlined"/>
            </div><br/>
            <div>

                <label htmlFor="country">Your Country</label>
                <Select fullWidth name='country' variant="outlined" id="country" value={inputs.country} onChange={handleChange}  InputProps={{name:'country'}} required>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="India">India</MenuItem>
                <MenuItem value="Abroad">Abroad</MenuItem>
                </Select>

            </div><br/>
            <div>

                <label htmlFor="role">You are a</label>
                <Select fullWidth variant="outlined" id="role" value={inputs.role} name='role' onChange={handleChange}  InputProps={{name:'role'}} required>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Teacher">Teacher</MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
                </Select>

            </div><br/>
            <div>
                <label htmlFor="institute">Enter Institute Name</label>
                <TextField  placeholder='ABC Group of schools' id="institute" autoFocus value={inputs.institute} InputProps={{name:'institute'}} onChange={handleChange} fullWidth variant="outlined"/>
            </div>
            <br/>

            <div><br/>
                {error && <Alert severity="error">{error}</Alert>}
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth disabled={loading}>
                    {loading ? <CircularProgress size={24}/> : 'Submit'}
                </Button>
                </div><br/>
                <div>
                    {/* <p style={{textAlign : "center"}}>Contact Sales</p><br/> */}
                {/* <Button variant="contained" onClick={handleCloseCreateModal}>
                    Cancel
                </Button> */}
            </div>
            </DialogContent>
        </Dialog>
    </Grid>
  );
}

export default PackagesTop;
