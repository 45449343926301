import * as types from '../types';


const initialState = {
    loading:false,
    error:false,
    fetchError: null,
    fetchCustomGamesError: null
}



export const gameReducer = (state=initialState, action) => {

    switch(action.type){

        case types.STORE_GAMES:
            return {...state, games:action.payload}

        case types.FETCH_GAMES_ERROR:
            return {...state, fetchError: action.payload}
            

        case types.STORE_CUSTOM_GAMES:
            return {...state, customGames:action.payload}

        case types.CREATE_CUSTOM_GAME:
            return {...state, customGames:state.customGames ? [...state.customGames, action.payload] : [action.payload]}
            
        case types.UPDATE_CUSTOM_GAME:
            return {...state, customGames:state.customGames ? state.customGames.map(cg => cg._id===action.payload._id ? {...action.payload} : cg) : null}

        case types.DELETE_CUSTOM_GAME:
            return {...state, customGames:state.customGames ? state.customGames.filter(cg => cg._id!==action.payload) : null}

        case types.FETCH_CUSTOM_GAMES_ERROR:
            return {...state, fetchCustomGamesError: action.payload}

        default:
            return state;
    }
}