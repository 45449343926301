//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

//common

const useStyles = makeStyles((theme) => ({
  main: {
    background: "var(--primary)",
    padding: "48px 16px",
    color: "#fff",
  },
  content: {
    width: "90%",
    margin: "0 auto",
    "& ul": {
      listStyleType: "none",
      "& li": {
        margin: "24px 0",
        fontWeight: 300,
      },
      "& a": {
        textDecoration: "none",
        color: "#fff",
      },
    },
  },
  logo: {
    width: 120,
    marginBottom: 48,
    "&>img": {
      width: "100%",
    },
  },
  link: {
    fontWeight: "200",
    fontSize: "16px",
    opacity: "0.8",
    color: "#F6F8FF",
    fontFamily: "Manrope, sans-serif",
    "& a": {
      fontWeight: "200",
      fontSize: "16px",
      opacity: "1",
      color: "#F6F8FF",
      fontFamily: "Manrope, sans-serif",
    },
  },
  list: {
    padding: "0 !important",
  },
}));

const Footer = ({}) => {
  const classes = useStyles();

  return (
    <footer className={classes.main}>
      <Grid container className={classes.content}>
        <Grid item xs={12} md={3}>
          <div className={classes.logo}>
            <img src={`${process.env.PUBLIC_URL}/logo-white.svg`} alt="eduzo" />
            {/* <img src={`${process.env.PUBLIC_URL}/logo4.png`} alt="eduzo"/> */}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Information</Typography>
          <ul className={classes.list}>
            <li className={classes.link}>
              <a href="#">About</a>
            </li>
            <li className={classes.link}>
              <a href="#">Blog</a>
            </li>
            <li className={classes.link}>
              <a href="#">Careers</a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Contact Us</Typography>
          <ul className={classes.list}>
            <li className={classes.link}>info@eduzo.com</li>
            <li className={classes.link}>+91 2K82651689</li>
            <li className={classes.link}>+91 2K82651689</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Information</Typography>
          <ul className={classes.list}>
            <li className={classes.link}>
              <a href="#">Privacy Policy</a>
            </li>
            <li className={classes.link}>
              <a
                target="_blank"
                href="https://www.termsandconditionsgenerator.com/live.php?token=CxyStIiPhNQiGbwFHTUMbW9qbC6Paqvc"
              >
                Terms & Conditions
              </a>
            </li>
            <li className={classes.link}>
              <a href="#">Disclaimer</a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
