
import {Link} from 'react-router-dom';
import React, { useEffect, useState, Fragment } from 'react'
//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import group_image from '../Icons/group_image.png';
import random7 from '../Icons/random.png';
import random1 from '../Icons/random1.png';
import random2 from '../Icons/random2.png';
import random3 from '../Icons/random3.png';
import random4 from '../Icons/random4.png';
import random5 from '../Icons/random5.png';
import random6 from '../Icons/random6.png';

import edit_icon from '../../../components/Dashboard/Icons/edit_icon.png'



//material-ui lab imports
import AvatarGroup from '@material-ui/lab/AvatarGroup';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    buttonLink: {
        textDecoration: 'none',
        padding: 0,
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    group: {
        borderRadius: 16,
        width: '100%',
        textAlign: 'left',
        height: '130px',
        '&>*:first-child':{
            padding: '12px 0',
            width: '100%',
            border: '1px solid #B8C0D8',
            boxSizing: 'border-box',
            borderRadius: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px',
            '& h6':{
                fontWeight: 600
            },
            '&>*:nth-child(1)':{
                '&>*':{
                    padding: '4px 0',
                },
                '&>*:nth-child(2)':{
                    display: 'flex',
                    alignItems: 'center',
                    '&>div':{
                        marginRight: 8,
                    }
                }
            }
        }
    }
}));

const GroupsBottom = ({match, groups, fetchError}) => {
 console.log('groupsss--->',groups)
  const classes = useStyles();
  const [currentGroup, setCurrentGroup] = useState()
  const [editGroupData, setEditGroupData] = useState({})

  var randomImages = [random7,random1,random2,random3,random4,random5,random6];  
  
//   useEffect(() => {
//     setCurrentGroup(groups._id)
//   },[groups])

  const handleEditGroupChange = (e) => {
    setEditGroupData({...editGroupData, [e.target.name]:e.target.value});
  }

  return (
    <div className={classes.root}>
                        {/* {console.log('grupzzz-->',groups)} */}
        <Grid container spacing={3}>
            {fetchError ?
                <Grid item xs={12}>
                    <Typography>Failed to fetch groups</Typography>
                </Grid>
            :groups ? 
            (groups?.length===0 ?
                <Grid item xs={12}>
                    <center><Typography>No groups found</Typography></center>
                </Grid>
            :groups?.map(g =>
                <Grid key={g._id} item xs={12} sm={12} md={6}>
                    <MuiLink underline='none' component={Link} to={`${match.url}/${g._id}/students`}>
                        <ButtonBase className={classes.group}>
                            <Paper>
                                <div>
                                    <Typography variant="h6">{g.name}</Typography>
                                    <div>
                                        <AvatarGroup>
                                            {g.students.map(s=>  
                                                // <Avatar key={s._id} className={classes.avatar}>{s?.name?.substring(0,1)}</Avatar>
                                                <Avatar key={s._id} className={classes.avatar}><img  style={{height: s?.profile_pic && "25px",width:s?.profile_pic && "55px"}}src={s?.profile_pic} />{!s.profile_pic && (s.name?.substring(0, 1).toUpperCase()) }</Avatar>
                                            )}
                                        </AvatarGroup>
                                        <Typography variant="body2">{g.students.length} Students</Typography>
                                    </div>
                                    <Typography variant="body2">0 Applications</Typography>
                                </div>
                                <div>
                               {/* <img style={{height : '90px', width : '90px', borderRadius : "45px"}} src={g.group_img && g.group_img !== "" ? g.group_img : group_image} /> */}
                                 <img style={{height : '90px', width : '90px', borderRadius : "45px"}} src={g.group_img && g.group_img !== "" ? g.group_img :  randomImages[Math.floor(Math.random() * randomImages.length)]} />
                               {/* <Button  data-toggle='modal' data-target='#edit_user_modal'>
                                      <span   className={classes.icons}  >
                                      <img style={{marginTop : '7px'}} fontSize="small" src={edit_icon} height='16px'/></span>
                                    </Button> */}
                                    </div>
                            </Paper>
                        </ButtonBase>
                    </MuiLink>
                </Grid>
                ))
            :
                <Grid item xs={12}>
                    <center><CircularProgress/></center>
                </Grid>
            }
        </Grid>
    </div>
  );
}

export default GroupsBottom;
