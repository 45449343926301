import {useState, Fragment} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChipInput from 'material-ui-chip-input'

//material-ui lab imports
import Autocomplete from '@material-ui/lab/Autocomplete';

//material-ui icon imports
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';

//common

const useStyles = makeStyles((theme) => ({

    root: {
        margin: '12px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    chip: {
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    button: {
        textTransform: 'initial'
    },
    textField: {
        width: '100%'
    },
    submitButton: {
        textTransform: 'initial',
    },
    gameTitlePreview: {
        '&>div':{
            display: 'flex',
            alignItems: 'center',
            '& h5':{
                fontSize: '1.4rem',
                margin: '0 8px 4px 0',
                fontWeight: 500
            },
            '& p':{
                fontSize: '0.9rem',
                marginTop: 4
            },
            marginBottom: 6,
        }
    },
    gamePropertiesPreview: {
        marginBottom: 8,
        '&>*':{
            textAlign: 'center',
            '&>h6':{
                fontSize: '1.1rem',
                fontWeight: 400
            },
            '&>*:nth-child(2)':{
                fontSize: '1rem',
                marginTop: 12,
                fontWeight: 600
            }
        },
        '&>*:not(:last-child)': {
            borderRight: '1px solid #ddd'
        },
    },
    gameDetailsPreview: {
        marginTop: 24,
        '&>*':{
            margin: '8px 0',
            '&>*:nth-child(1)':{
                '&>*:nth-child(1)':{
                    '&>p': {
                        color: '#666',
                        fontWeight: 500
                    }
                }
            }
        }
    },
    formActions: {
        '&>*:not(:first-child)':{
            marginLeft: 12
        }
    }
}));


function getStepContent(step) {
    switch (step) {
        case 0:
            return `Lorem Ipsum is simply dummy text of the printing and typesetting industry`;
        case 1:
            return 'Lorem Ipsum is simply dummy text of the printing and typesetting industry';
        case 2:
            return `Lorem Ipsum is simply dummy text of the printing and typesetting industry`;
        case 3:
            return `Lorem Ipsum is simply dummy text of the printing and typesetting industry`;
        default:
            return 'Unknown step';
    }
}

let qid = 2;

const CreateCustomGameForm = ({match, groups, createCustomGame, closeModal}) => {
// const CreateCustomGameForm = ({match, groups, createCustomGame, closeModal}) => {

    var game_not_customizable = {"tags":[],
    "categories":[],
    "upload_media":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103244807.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244810.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244811.jpeg"],
    "application_file":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103245142.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245143.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245144.jpeg"],
    "name":"Roller Coaster",
    "subjects":["Maths","English","Hindi"],
    "short_description":"Short Description for this game",
    "long_description":"Long Description for this game",
    "illustration_img":"https://eduzo.s3.ap-south-1.amazonaws.com/1627103244482.jpeg",
    "image":"https://eduzo.s3.amazonaws.com/1627103243671.jpeg",
    "age":"19-21",
    "modes":"Quest",
    "type":"Individual",
    "rating":"45",
    "reviews":"67",
    "plan":"Basic",
    "customizable":'no',
    "platform":"IOS",
    "version":"V 1.2.0.8.8",
    "status":"Active",
    "createdAt":{"$date":{"$numberLong":"1626862809777"}},
    "updatedAt":{"$date":{"$numberLong":"1626862809778"}},
    "__v":{"$numberInt":"0"},
    "compatibility":"IOS"}

    var game_partial_customizable = {"tags":[],
    "categories":["Online game",],
    "upload_media":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103244807.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244810.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244811.jpeg"],
    "application_file":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103245142.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245143.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245144.jpeg"],
    "name":"Roller Coaster",
    "subjects":["Maths","English","Hindi"],
    "short_description":"Short Description for this game",
    "long_description":"Long Description for this game",
    "illustration_img":"https://eduzo.s3.ap-south-1.amazonaws.com/1627103244482.jpeg",
    "image":"https://eduzo.s3.amazonaws.com/1627103243671.jpeg",
    "age":"19-21",
    "modes":"Quest",
    "type":"Individual",
    "rating":"45",
    "reviews":"67",
    "plan":"Basic",
    "customizable":'partially',
    "platform":"IOS",
    "version":"V 1.2.0.8.8",
    "status":"Active",
    "createdAt":{"$date":{"$numberLong":"1626862809777"}},
    "updatedAt":{"$date":{"$numberLong":"1626862809778"}},
    "__v":{"$numberInt":"0"},
    "compatibility":"IOS"}

    var game_fully_customizable = {"tags":[],
    "categories":["Online game", "hrtdjfygkuh"],
    "upload_media":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103244807.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244810.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103244811.jpeg"],
    "application_file":["https://eduzo.s3.ap-south-1.amazonaws.com/1627103245142.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245143.jpeg","https://eduzo.s3.ap-south-1.amazonaws.com/1627103245144.jpeg"],
    "name":"Roller Coaster",
    "subjects":["Maths","English","Hindi"],
    "short_description":"Short Description for this game",
    "long_description":"Long Description for this game",
    "illustration_img":"https://eduzo.s3.ap-south-1.amazonaws.com/1627103244482.jpeg",
    "image":"https://eduzo.s3.amazonaws.com/1627103243671.jpeg",
    "age":"19-21",
    "modes":"Quest",
    "type":"Individual",
    "rating":"45",
    "reviews":"67",
    "plan":"Basic",
    "customizable":'fully',
    "platform":"IOS",
    "version":"V 1.2.0.8.8",
    "status":"Active",
    "createdAt":{"$date":{"$numberLong":"1626862809777"}},
    "updatedAt":{"$date":{"$numberLong":"1626862809778"}}, 
    "__v":{"$numberInt":"0"},
    "compatibility":"IOS"}

    const [game, setGame] = useState(game_not_customizable);

    const gameChange =(e) => {
        setGame(eval(e.target.value))
        {game?.customizable === 'no' ? setSteps(['Basic Details', 'Game Details', 'Review'] )
         :setSteps(['Basic Details', 'Game Details', 'Questions & Answers', 'Review'] )
    }
    }
  const classes = useStyles();
  

//   var game = {customizable : true}

  const [steps, setSteps] = useState(game?.customizable === 'no' ? ['Basic Details', 'Game Details', 'Review'] : ['Basic Details', 'Game Details',  'Questions & Answers','Review'])
//   const [steps] = useState(['Basic Details', 'Game Details', 'Questions & Answers', 'Review'])
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  

  const [inputs, setInputs] = useState({
      name: '',
      outcomes: '',
      answers: [],
      subjects: [],
      groups: [],
      categories: [],
      difficulty_level: 'low',
      questions: [{
          id: 1,
          question: '',
          question_type: 'text',
          right_answers: [],
          wrong_answers: []
      }]
  })


  const handleChange = (e) => {
      const value = e.target.value;
      if(typeof value==='object' && e.target.name!=='category'){
          setInputs({...inputs, [e.target.name]: [...value]})
      }
      else{
        setInputs({...inputs, [e.target.name]: value})
      }
  }


  const handleAddQuestion = () => {
    setInputs({
        ...inputs, 
        questions: [...inputs.questions, {
            id: qid++,
            question: '',
            question_type: 'text',
            right_answers: []
        }]
    })
  }

  const handleChangeQuestion = (e, id) => {
      const value = e.target.value;
      
      setInputs({
          ...inputs, 
          questions: inputs.questions.map(q => q.id===id ? {
              ...q,
              [e.target.name] : value
          } : q)
      });
  }

  const handleNext = async (e) => {
    e.preventDefault();
    try{
        if(activeStep+1 === steps.length) {
            setLoading(true);
            // await createCustomGame({...inputs, groups: inputs.groups.map(g => g._id), game:match.params.id});
            setLoading(false);
            // closeModal();
            return;
        };
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    catch(e){
        alert(e);
    }
    finally{
        setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return(
    <div className={classes.root} style={{backgroundColor : "#FBFBFE", height : '100vh' , padding : '2vw'}}>
        <div style={{display : 'flex', flexDirection : "row" , justifyContent : "space-between"}}>
            <div style={{padding : '0.5vw'}}>
                <p style={{fontSize : '26px', fontWeight : '500'}}>Create Game </p> 
                <p style={{fontSize : '14px', opacity : '0.4' , fontWeight : '500', marginTop : '1vh'}}>For the Zooday </p> 
                </div>
            {/* <div>{''}</div> */}
            <div  style={{padding : '0.5vw'}}><span class="iconify" style={{fontSize : '40px'}} data-icon="clarity:close-line" data-inline="false"></span></div>
        </div>
        <br/>
        <hr/>
        <br/>
        <br/>
        <Grid container spacing={3} >
            <Grid item xs={12} sm={4} >
                <Stepper activeStep={activeStep} orientation="vertical" style={{backgroundColor : '#FBFBFE'}}>
                {steps && steps?.map((label, index) => (
                    <Step key={label} expanded={true}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                        </StepContent>
                    </Step>
                ))}
                </Stepper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Grid container className={classes.form} spacing={2}>

                    {activeStep===0 ? 
                    <>
                        <Grid item xs={7}>
                            <label htmlFor="custom_name">Custom Name</label>
                            <TextField variant="outlined" style={{backgroundColor : 'white'}} onChange={handleChange} className={classes.textField} value={inputs.name} InputProps={{id:'name', name:'name'}}/>
                        </Grid>

                        <Grid item xs={9}>
                            <label htmlFor="game_outcomes">Outcomes of the game</label>
                            <TextField style={{backgroundColor : 'white'}} variant="outlined" multiline rows={2} onChange={handleChange} className={classes.textField} value={inputs.outcomes} InputProps={{id:'outcomes', name:'outcomes'}}/>
                        </Grid>

                        {game?.customizable != 'no'? 
                            <Grid item xs={7}>
                                <label htmlFor="categories">Categories</label>
                                <br/>
                                <Select style={{backgroundColor : 'white'}} variant="outlined" value={inputs.categories} className={classes.textField} onChange={handleChange} name="categories" id="categories">
                                    {game?.categories.map(c =>
                                        <MenuItem  value={c} >{c}</MenuItem>
                                    )}
                                </Select>
                            </Grid>
                        :<Fragment>
                            <Grid item xs={7}>
                                <label htmlFor="category">Choose answers</label>
                                <br/>
                                {/* <Select multiple value={inputs.answers} className={classes.textField} onChange={handleChange} name="answers" variant="outlined" id="category" name="answers">
                                    {game?.options && game?.options.map(option =>
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    )}
                                </Select> */}

                                <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="answers" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        value={['tag', 'tag']}
                                        style={{backgroundColor : 'white', width : "100%" }}
                                        // defaultValue={inputs?.categories}
                                        // onChange={(chips) => handleChangeCategories(chips)}
                                    />
                            </Grid>
                            <Grid xs={3}>
                                <p style={{textAlign :'center', color : '#6471A0', fontWeight :'500', textDecoration : 'underline', marginTop : '5vh'}}>View all</p>
                            </Grid>
                            </Fragment>
                        }
                        
                    </>

                    :activeStep===1 ?

                    <>
                        <Grid item xs={7}>
                            <label htmlFor="grades">Select Grades</label>
                            <br/>
                            <ChipInput 
                            
                            style={{backgroundColor : 'white', width : "100%"}}
                                    variant="outlined"
                                    placeholder ="   Type and Enter"
                                        name="grades" 
                                        
                                        value={['tag', 'tag']}
                                        // defaultValue={inputs?.categories}
                                        // onChange={(chips) => handleChangeCategories(chips)}
                                    />
                            {/* <Select 
                                multiple 
                                variant="outlined" 
                                value={inputs.groups} 
                                onChange={handleChange}
                                id="groups"
                                name="groups"
                                className={classes.textField}
                            >
                                {groups && groups.map(g =>
                                    <MenuItem value={g}>{g.name}</MenuItem>
                                )}
                            </Select> */}
                        </Grid>

                        <Grid item xs={7} >
                            <label htmlFor="grades">Select Subjects</label>
                            <br/>
                            {/* <Select 
                                multiple 
                                variant="outlined" 
                                value={inputs.subjects} 
                                id="subjects"
                                name="subjects"
                                onChange={handleChange}
                                className={classes.textField}
                            >
                                <MenuItem value="Science">Science</MenuItem>
                                <MenuItem value="Maths">Maths</MenuItem>
                            </Select> */}

                                <ChipInput 
                                    style={{width : "100%"}}
                                    placeholder ="   Type and Enter"
                                        name="subjects" 
                                        color='primary'
                                        style={{backgroundColor : 'white', width : "100%"}}
                                        variant="outlined"
                                        value={['tag', 'tag']}
                                        // defaultValue={inputs?.categories}
                                        // onChange={(chips) => handleChangeCategories(chips)}
                                    />
                        </Grid>
                        <br/>

                        <Grid item xs={7}>
                            <label htmlFor="level">Select Level</label>
                            <br/>
                            <Select variant="outlined" style={{backgroundColor : 'white'}} value="Lorem ipsum" id="level" value={inputs.difficulty_level} name="difficulty_level" className={classes.textField} onChange={handleChange}>
                                <MenuItem value="Low">Low</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="High">High</MenuItem>
                            </Select>
                        </Grid>
                    
                    </>

                    : (activeStep===2 && game?.customizable != 'no') ?
                    (game?.categories ?
                    <>
                        <Grid item xs={12}>
                        {inputs?.questions.map((q,i) =>
                        <Grid container key={q?.id} spacing={2}>
                        {/* {inputs.category && inputs.category.question_types.length>1 && <Grid item xs={12}> */}
                        {game?.customizable === 'fully' && <Grid item xs={12}>

                            <label htmlFor={`question_type_${i}`}>Question Type</label>
                            <br/>
                            <Select variant="outlined" style={{backgroundColor : 'white'}} value="Lorem ipsum" id="level" value={inputs.question_type} name="question_type" className={classes.textField} onChange={handleChange}>
                                <MenuItem value="Image">Image</MenuItem>
                                <MenuItem value="Medium">Missing</MenuItem>
                                <MenuItem value="High">Audio</MenuItem>
                            </Select>

                            {/* <Select 
                                variant="outlined" 
                                value={q?.question_type} 
                                id={`question_type_${i}`}
                                name="question_type"
                                onChange = {(e)=>handleChangeQuestion(e, q?.id)}
                                className={classes.textField}
                            >
                                {game?.categories[0] && game?.categories[0].question_types.map(type => 
                                    <MenuItem value={type} key={type}>{type}</MenuItem>
                                )}
                            </Select> */}
                        </Grid>}

                        <Grid item xs={12}>
                            <label htmlFor={`question_${i}`}>Question</label>
                            <TextField variant="outlined" className={classes.textField} value={q?.question} onChange = {(e)=>handleChangeQuestion(e, q?.id)} InputProps={{id:`question_${i}`, name:'question'}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor={`right_answers_${i}`}>Right Answers</label>
                            <br/>
                            <ChipInput 
                            
                            style={{backgroundColor : 'white', width : "100%"}}
                                    variant="outlined"
                                    placeholder ="Type and Enter"
                                        name="right_answers" 
                                        value={['tag', 'tag']}
                                        
                                        // defaultValue={inputs?.categories}
                                        // onChange={(chips) => handleChangeCategories(chips)}
                                    />
                            {/* <Autocomplete
                                multiple
                                id={`right_answers_${i}`}
                                name="right_answers"
                                options={[]}
                                value={q.right_answers || []}
                                onChange={(e, newval, reason) => {
                                    const x = {
                                        target: {
                                            name: "right_answers",
                                            value: newval
                                        }
                                    }
                                    handleChangeQuestion(x, q.id)
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Enter value and press enter"
                                    onKeyDown={e => {
                                    if (e.keyCode === 13 && e.target.value) {
                                        const x = {
                                            target: {
                                                name: "right_answers",
                                                value: [...q?.right_answers, e.target.value]
                                            }
                                        }
                                        handleChangeQuestion(x, q?.id)
                                    }
                                    }}
                                />
                                )}
                            /> */}
                        </Grid>
                        {game?.customizable  === 'no' || game?.customizable  === 'fully' ? <></>:
                        <Grid item xs={12}>
                            <label htmlFor={`wrong_answers_${i}`}>Wrong Answers</label>
                            <br/>
                            <ChipInput style={{backgroundColor : 'white', width : "100%"}}
                                    variant="outlined"
                                    placeholder ="Type and Enter"
                                        name="wrong_answers" 
                                        value={['tag', 'tag']}
                                        
                                        // defaultValue={inputs?.categories}
                                        // onChange={(chips) => handleChangeCategories(chips)}
                                    />
                            {/* <Autocomplete
                                multiple
                                id={`wrong_answers_${i}`}
                                name="wrong_answers"
                                options={[]}
                                value={q?.wrong_answers || []}
                                onChange={(e, newval, reason) => {
                                    const x = {
                                        target: {
                                            name: "wrong_answers",
                                            value: newval
                                        }
                                    }
                                    handleChangeQuestion(x, q.id)
                                }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Enter value and press enter"
                                    onKeyDown={e => {
                                    if (e.keyCode === 13 && e.target.value) {
                                        const x = {
                                            target: {
                                                name: "wrong_answers",
                                                value: [...q?.wrong_answers, e.target.value]
                                            }
                                        }
                                        handleChangeQuestion(x, q?.id)
                                    }
                                    }}
                                />
                                )}
                            /> */}
                        </Grid>
                        }

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        </Grid>
                        )}
                        </Grid>

                        {game?.categories.length  === 2 && <Grid item xs={12}>
                            <Button startIcon={<AddIcon/>} onClick={handleAddQuestion}>Add another question</Button>
                        </Grid>}
                    </>

                    :
                        <Grid item xs={12}>
                            <Typography variant="h6">No category selected</Typography>
                        </Grid>
                    )

                    :

                    <>
                        
                        <Grid item xs={12} className={classes.gameTitlePreview}>
                            <div>
                                <Typography variant="h5">{inputs.name}</Typography>
                                <Chip color="secondary" label="English" className={classes.chip}/>
                            </div>
                            <Typography variant="body1">{inputs.outcomes}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        
                        <Grid container spacing={1} className={classes.gamePropertiesPreview}>
                            <Grid item xs={12} md>
                                <Typography variant="h6">GRADES</Typography>
                                <Typography variant="body1"> 1, 2
                                    {/* {inputs.groups.map(g => 
                                        <span key={g._id}>{g.name}, </span>
                                    )} */}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">SUBJECTS</Typography>
                                <Typography variant="body1">
                                    {/* {inputs.subjects.map(s => 
                                        <span key={s}>{s}, </span>
                                    )} */} Science
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">LEVEL</Typography>
                                <Typography variant="body1">{inputs.difficulty_level}</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">TIME</Typography>
                                <Typography variant="body1">10 mins</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">CATEGORY</Typography>
                                <Typography variant="body1">Goat, Horse, Sheep</Typography>
                            </Grid>
                            {/* <Grid item xs={12} md>
                                <Typography variant="h6">{inputs.category.customizable===false ? "ANSWERS" : "CATEGORY"}</Typography>
                                <Typography variant="body1">
                                    {inputs.category.name ? 
                                        inputs.category.name 
                                    :inputs.answers ? inputs.answers.map(a => 
                                        <span key={a}>{a}, </span>)
                                    :"-"
                                    }
                                </Typography>
                            </Grid> */}
                        </Grid>
                        
                        <Grid item xs={12}>
                            {game?.customizable != 'no' ?
                            <Grid container className={classes.gameDetailsPreview}>
                                {inputs.questions.map((q,i) =>
                                    <Fragment key={q.id}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Question {inputs?.categories?.multiple_questions && i+1}</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>{q.question}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Right answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {/* {q.right_answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>    
                                                    )} */}
                                                    a,e,i,o,u
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    {q?.wrong_answers && <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Wrong answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {/* {q?.wrong_answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>    
                                                    )} */}
                                                    f,h,p,l,q
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>}

                                    {/* <Grid item xs={12}>
                                        <Divider className={classes.divider}/>
                                    </Grid> */}
                                    </Fragment>
                                )}
                            </Grid>
                            : <></>
                                // <Grid container className={classes.gameDetailsPreview}>
                                //     <Grid item xs={12}>
                                //         <Grid container>
                                //             <Grid item xs={3}>
                                //                 <Typography variant="body1" gutterBottom>Answers</Typography>
                                //             </Grid>
                                //             <Grid item xs={9}>
                                //                 <Typography variant="body1" gutterBottom>
                                //                     {inputs.answers.map(ans =>
                                //                         <span key={ans}>{ans}, </span>
                                //                     )}
                                //                 </Typography>
                                //             </Grid>
                                //         </Grid>
                                //     </Grid>
                                // </Grid>
                            }
                        </Grid>
                    </>
                    
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={8} className={classes.formActions}>
                {activeStep>0 && <Button
                    variant="contained"
                    onClick={handleBack}
                    className={classes.button}
                >
                    Go Back
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24}/>
                    :(steps && activeStep === steps.length - 1) ? 'Create' : 'Next Step'}
                </Button>
            </Grid>
        </Grid>
        
        <select onChange={e => gameChange(e)}>
            <option value='game_not_customizable'>Zoo</option>
            <option value='game_partial_customizable'>Infinite Runner</option>
            <option value='game_fully_customizable'>Vertical Catcher</option>
        </select>
    </div>
  )
}

export default CreateCustomGameForm;