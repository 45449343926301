import {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

//components
import Top from './Top';
import Bottom from './Bottom';

import {getGames} from '../../../../redux/actions/gameAction';

const GameDetail = (props) => {
  const dispatch = useDispatch();
  const game = useSelector(state => state.game);

  useEffect(()=>{
    if(!game.games){
      dispatch(getGames());
    }
  },[]);

  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  },[props.match.params.id])

  return (
    <div>
      <Top game={game.games?.find(g => g._id===props.match.params.id) || {} }/>
      <Bottom similarGames={game.games?.filter(g => g._id!==props.match.params.id) || []} {...props}/>
    </div>
  );
}

export default GameDetail;
