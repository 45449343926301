// import { React , useEffect, useState, Fragment  } from 'react'
// //material-ui core imports

// import ButtonGroup from '@material-ui/core/ButtonGroup';

// import Chip from '@material-ui/core/Chip';
// import TextField from '@material-ui/core/TextField';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// // import {useState, forwardRef} from 'react';
// import SearchBar from "material-ui-search-bar";
// import {Link, useHistory, useParams} from 'react-router-dom';
// import Checkbox from '@material-ui/core/Checkbox';
// // import user_image from "../../Components/Dashboard/Icons/user.png"
// import user_image from '../../components/Dashboard/Icons/user.png'

// //material-ui core imports
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import ViewCustomGameBottom from "../../components/Dashboard/MyGames"
// import ViewCustomGameTop from "../../components/Dashboard/MyGames"

// //material-ui icon imports
// import CloseIcon from '@material-ui/icons/Close';
// import SearchIcon from '@material-ui/icons/Search';
// import EditIcon from '@material-ui/icons/Edit';
// import CopyIcon from '@material-ui/icons/FileCopy';
// import DeleteIcon from '@material-ui/icons/DeleteForever';
// import { getCustomGames } from '../../redux/actions/gameAction';
// import swal from 'sweetalert2';

// import {useDispatch, useSelector} from 'react-redux';
// import Box from '@material-ui/core/Box'
// import {getGames} from '../../redux/actions/gameAction';
// import {getGroups} from '../../redux/actions/studentAction'
// import ViewMyGame from '../../components/Dashboard/MyGames'
// import axios from 'axios';
// import { API_URL } from '../../config';
// import back_arrow from '../../components/Dashboard/Icons/back_arrow.png'

// export const useStyles = makeStyles((theme) => ({
//   root: {

//       marginTop: '-20px',
//       '& h5':{
//           fontWeight: 500
//       }
//   },
//   divider: {
//     margin: '24px 0'
// },
// chip: {
//   marginTop : '0.2vh',
//   minHeight : '28px !important',
//   minWidth : '62px',
//   borderRadius: 4,
//   height: 'auto',
//   '&>*:nth-child(1)':{
//       padding:'3px 5px'
//   }
// },
// buttonLink: {
//     textDecoration: 'none',
//     color: '#000'
// },
// gameTitle: {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   flexWrap: 'wrap',
//   marginTop: 24,
//   '&>div':{
//       '& h5':{
//           fontSize: '1.4rem',
//           color : '#02113F !important',
//           margin: '0 8px 4px 0',
//           fontWeight: 500,
//           fontSize : '24px',
//       },
//       '& p':{
//           fontSize: '0.9rem',
//           marginTop: 4,
//           color : '#02113F',
//           opacity: 0.6,
//           fontSize: '16px',
//           lineHeight: '20px'

//       },
//       '&>div':{
//           display: 'flex',
//           alignItems: 'center',
//       }
//   }
// },
// divider: {
//   margin: '24px 0'
// },
// button: {
//   textTransform: 'initial'
// },
// top: {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   flexWrap: 'wrap',
//   marginBottom: 24,
//   '&>*':{
//       marginTop: 8
//   },
//   '&>*:nth-child(2)':{
//       display: 'flex',
//       alignItems: 'center',
//       '&>*':{
//           margin: '0 4px'
//       }
//   }
// },
// table: {
//   '&>*:nth-child(1)':{
//       background: '#E4E9F3'
//   }
// },
// gameActions: {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   '&>*':{
//       marginRight: 4
//   }
// },
// createModal: {
//   '& h5':{
//       fontWeight: 500
//   },
//   '&>div:nth-child(2)':{
//       margin: '24px 0 12px 0'
//   },
//   '&>form':{
//       '&>div:nth-child(1)':{
//           marginBottom: 24,
//       },
//       '&>div:nth-child(3)':{
//           margin: '12px 0',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between',
//           '&>*':{
//               width: '48%'
//           }
//       }
//   }
// },
// createModal: {
//     '& h5':{
//         fontWeight: 500
//     },
//     '&>div:nth-child(2)':{
//         margin: '24px 0 12px 0'
//     },
//     '&>div:nth-child(3)':{
//         margin: '36px 0 12px 0',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         '&>*':{
//             width: '48%'
//         }
//     }
// },
//   divider: {
//       margin: '24px 0'
//   },
//   button: {
//       textTransform: 'initial'
//   },
//   top: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'space-between',
//       flexWrap: 'wrap',
//       marginBottom: 24,
//       '&>*':{
//           marginTop: 8
//       },
//       '&>*:nth-child(2)':{
//           display: 'flex',
//           alignItems: 'center',
//           '&>*':{
//               margin: '0 4px'
//           }
//       }
//   },
//   gameDetailsPreview: {
//       marginTop: 24,
//       '&>*':{
//           margin: '8px 0',
//           '&>*:nth-child(1)':{
//               '&>*:nth-child(1)':{
//                   '&>p': {
//                       color: '#666',
//                       fontWeight: 500
//                   }
//               }
//           }
//       }
//   },

//   table: {
//       '&>*:nth-child(1)':{
//           background: '#E4E9F3'
//       }
//   },
//   gameActions: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       '&>*':{
//           marginRight: 4
//       }
//   },
//   createGame: {
//       padding: '24px 48px',
//       '& h4':{
//           fontWeight: 500
//       },
//       '&>*:nth-child(1)':{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between'
//       }
//   },
// divider: {
//     margin: '24px 0'
// },
// buttonLink: {
//     textDecoration: 'none',
//     padding: 0,
// },
// gameProperties: {
//   marginBottom: 8,
//   '&>*':{
//       textAlign: 'center',
//       '&>h6':{
//           fontSize: '1rem',
//           fontWeight: 400,
//           lineHeight: '18px',
//           color: '#02113F',
//           opacity: 0.6,
//       },
//       '&>*:nth-child(2)':{
//           fontSize: '1rem',
//           marginTop: 16,
//           fontWeight: 500,
//           color: '#02113F'
//       }
//   },
//   '&>*:not(:last-child)': {
//       borderRight: '1px solid #ddd'
//   },
// },
// playDemoModal: {
//     '& h5':{
//         fontWeight: 500
//     },
//     '&>*':{
//         margin: '12px 0'
//     },
//     '&>div:nth-child(4)':{
//         margin: '36px 0 12px 0',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         '&>*':{
//             width: '48%'
//         }
//     }
//   },
//     question : {
//         fontSize: 24,
//         lineHeight: 3,
//         color: '#02113F'
//     },
//     imageLayout : {
//         width:'100%',
//         display: 'flex',
//         justifyContent : 'space-between',
//         alignItems : 'center',

//     }
// }));

// const PlayGame2 = (group,) => {
//   console.log(group);
//   let history = useHistory();
//   const [step,setStep] = useState(1)
//   const dispatch = useDispatch();
//   const [selectedGame,setSelectedGame] = useState();
//   const [customGames,setCustomGames] = useState([]);
//   const [group_students,setgroup_students] = useState();
//   const [selectedCustomGame, setSelectedCustomGame] = useState({});
//   const [game_done, setGame_done] = useState(false)
//   const [counter, setCounter] = useState(60);
//   const [recentGame,setRecentGame]= useState([]);
//   const [playGame,setPlayGame]= useState([]);
//   const[kinect_resume,setKinectResume] = useState([]);
//   // const [timer, setTimer] =
//   const[kinect_unique_id,setKinect_unique_id] = useState(Math.floor(Math.random() * 1000000));

//   useEffect(async()=>{
//     if(!game.games){
//       await dispatch(getGames());
//     }
//     if(!game.customGames){
//       await dispatch(getCustomGames());
//     }
//     if(!students.groups){
//       await dispatch(getGroups(localStorage.getItem('institute')))
//     }
//     // if(!recentGame)
//   },[])

//   // useEffect(() => {
//   //   //axios.get(`${API_URL}/playgame/session/recentgame/:group_id`)
//   //   axios.get(`http://localhost:5000/playgame/session/recentgame/${currentGroup._id}`)
//   //       .then(response => {
//   //         setRecentGame(response.data)
//   //       }).catch(() => {
//   //         setRecentGame([]);
//   //     })
//   //       console.log('recnntgammmm-->',recentGame)
//   // }, []);

//   const game = useSelector(state => state.game);
//   const students = useSelector(state => state.student);

//   const institute_plan = localStorage.getItem('institute_plan')
//   const allGames = game.games;
//   const allCustomGames = game.customGames;

//   const myGames = (institute_plan == 'Advanced') ? allGames || [] : (institute_plan == 'Basic') ? (allGames || []).filter(game => game.plan === 'Basic') : (allGames || []).filter(game => game.plan != 'Advanced')

//   useEffect(() => {

//     setFilterGames(myGames)
//   },[allGames])

//   const submit_students = () => {
//     console.log(inputs)
//     set_selected_students([])
//     setStudent_count(0)

//     // setInputs(inputs)
//   }

//   useEffect(()=>{
//     setCurrentCustomGames(allCustomGames)
//   }, [allCustomGames])

//   const submit = () =>{
//     setStep(1)
//     setGame_done(true)
//     console.log(inputs)
//   }

//   const [QRcode,setQRcode] = useState();

//   const getQRcode = async() => {

//     var qrGameData = inputs

//       const res = await axios.post(`${API_URL}/qr-code` , qrGameData);
//       setQRcode(res.data)
//       console.log('qrrcodee-->',QRcode);
//   }

//   const getKinectCode = async() => {
//     console.log('gruppp--->>',group_students);
//   //  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

//    const headers = {
//     'unique-id': kinect_unique_id,
//     'group-id' :group_students[0]?.group,
//   }
//     var KinectGameData = inputs
//     const res = await axios.post(`${API_URL}/kinect-code` , KinectGameData, {headers: headers});

//   }

//   const myGames1 = (allGames || [])?.filter(
//     (game) => game.plan === institute_plan
//   );

//   const filteredGames = (inputs) => {
//     let index= 0;
//       inputs.forEach((i, idx) => {
//         console.log("i---> ", i, Object.keys(i).length === 0)
//         if(Object.keys(i).length !== 0) {
//           index = idx;
//         }
//       });
//       console.log("Index: ", index);
//       var gameName = inputs[index]?.custom_game?.game?.name;
//       console.log("gameName: ", gameName)
//       if(gameName === undefined) {
//         console.log("myGames 1: ", myGames1)
//         return myGames1;
//       }
//       return (myGames1 || [])?.filter(
//         (game) => game.name === gameName
//       );
//   }

//   const [filterGames, setFilterGames] = useState();

//   const searchGames = (e) => {

//     var xyz = myGames?.filter(game => game.name.toLowerCase().includes(e.toLowerCase()))
//     setFilterGames(xyz)
//   }

//   const cancelSearchGames = (e) => {
//     setFilterGames()
//   }

//   // const myGames = (allGames || [])?.filter(game => game.plan === institute_plan);

//   const selectGame = (e,game)=>{
//     e.preventDefault();

//     var xyz = document.getElementsByClassName('gamecard')
//     Array.from(xyz).forEach(element => {
//       element.style.border = "0px"
//     });
//     e.currentTarget.style.border = '3px solid #6FD540'
//    setSelectedGame(game)

//   }
//   var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];

//   const nextStep =async () => {

//     if(!selectedGame ){
//       alert('please select a game')
//       return
//     }

//     const selectedCustomGames = allCustomGames?.filter(x => x.game?._id === selectedGame._id)
//     setCustomGames(selectedCustomGames)
//     setCurrentCustomGames(selectedCustomGames)
//     setStep(2)
//   }

//   const reviewGame = async() =>{
//     if(!inputs[modal_number].custom_game ){
//       alert('please select a custom game')
//       return
//     }
//     // setSelectedGame()
//     setStep(3)
//   }

//   const selectCustomGame = async(e) => {
//     const selected_custom_game = customGames?.filter(game => game._id === e.target.value)

//     inputs[modal_number].custom_game = selected_custom_game[0]
//     // inputs
//     setInputs(inputs)
//   }

//   const classes = useStyles();

//   const [playDemoModal, setPlayDemoModal] = useState(false);

//   const handleClosePlayDemoModal = () => {
//     setPlayDemoModal(false);
//   }

//   const handleOpenPlayDemoModal = () => {
//     setPlayDemoModal(true);
//   }

//   const [selected_students, set_selected_students]= useState([])

//   const select_student =(e,i) => {

//     if (!inputs[i].students ) {inputs[i].students = []}

//     var temp_array = inputs[i].students;

//     if (e.target.checked === true) {
//       setStudent_count(student_count+1)

//       temp_array = [...temp_array, (e.target.value)]
//       inputs[i].students = temp_array
//     }
//     else if((e.target.checked === false)) {
//       setStudent_count(student_count-1)

//       const selectedStu = temp_array?.filter(a => {
//         if (a === e.target.value) return false;
//         return true;
//       });
//       inputs[i].students = selectedStu

//     }
//     setInputs((inputs) => inputs)
//   }

//   const [student_count, setStudent_count] = useState(0)

//   var students_group;

//   useEffect(async() => {
//     students_group = students?.groups?.find(x => x._id === group.match.params.id)
//     setgroup_students(students_group?.students)
//     setCurrentGroup(students_group?.students)
//     // await res()

//   },[students])

//   const [currentGroup, setCurrentGroup] = useState([])
//   console.log('currenttgrupp==>',currentGroup)
//   console.log('grppstuuu',group_students)
//   useEffect(async()=> {
//     // await axios.get(`${API_URL}/playgame/session/recentgame/:group_id`)
//     console.log('===================================>', group_students);
//     group_students && await axios.get(`http://localhost:5000/playgame/session/recentgame/${group_students[0].group}`)
//         .then(response => {
//           console.log('resss---------->',response.data);
//           setRecentGame(response.data)
//           var playGameData = response.data;
//           axios.get(`http://localhost:5000/playgame/session/${response.data[0]._id}`)
//             .then(res1 => {
//               console.log('res11-->',res1.data)
//               setPlayGame(res1.data)
//               var allCompleted = getallStudentGameStatus(res1.data);
//               alert('allcmpltd-->'+ allCompleted);
//               if(allCompleted) {
//                 alert("Move to new approch");
//               } else {
//                 alert("Move to kinnect approch");
//                 alert(JSON.stringify(playGameData))
//                  kinnectCodeCall(playGameData)
//               }

//             })
//         })
//         .catch(() => {
//           console.log("SHOULD NOT COME -------->")
//           setRecentGame([]);
//       });
//     },[group_students])
//   console.log('recentgmmmmm--->',recentGame)

// // useEffect(() => {
// //   console.log('recenttGamee--->',recentGame);
// //   console.log('playGamee--->',playGame);
// // },[recentGame,playGame])

// var getallStudentGameStatus =(studentData) => {
//     var allCompleted = true;
//     studentData.forEach((stu) => {
//       console.log("Stu -->", stu);
//       stu?.student?.forEach((s) => {
//         console.log("s ===-->", s);
//         allCompleted = allCompleted && s.isCompleted === "true";
//       })
//     })
//     return allCompleted;
// }

// var kinnectCodeCall = (playGameData) => {
//   console.log('kinn-->', playGameData);
//   // alert(JSON.stringify(playGameData[0].Game[0].custom_game.game.compatibility));

//   setKinectResume(playGameData[0].Game[0].custom_game.game.compatibility);
//   playGameData[0].Game[0].custom_game.game.compatibility && playGameData[0].Game[0].custom_game.game.compatibility === 'IOS' ? getQRcode() : getKinectCode()
//   // if(inputs[0].custom_game?.game.compatibility === 'IOS' ){
//   //   getQRcode()
//   // }else {

//   // }
// }

// console.log('kinect_resumeeee--->',kinect_resume);

//   const Count =(e)=> {
//     // console.log(e.target.checked);
//     if (e.target.checked == true) {
//         setStudent_count(student_count + 1)
//     } else if (e.target.checked == false) {
//         setStudent_count(student_count - 1)
//     }
//   }

//   const searchStudent = (e) => {

//     console.log(e)

//     console.log('grppstuuu',group_students)
//     console.log(currentGroup)

//     if (e == null || e== "" || e == undefined){
//     setCurrentGroup(group_students)
//     }

//     var xyz = group_students?.filter(student => student.name.toLowerCase().includes(e.toLowerCase()))

//     setCurrentGroup(xyz);

//   }

//   const cancelSearchStudent = (e) => {
//     console.log(e)

//     setCurrentGroup(group_students)
//     // setCurrentGroup(group.group)
//   }

//   const [page, setPage] = useState(0);
//   const [createModal, setCreateModal] = useState(false);
//   const [deleteModal, setDeleteModal] = useState(null);
//   const [editModal, setEditModal] = useState(null);
//   const [deleting, setDeleting] = useState(false);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleOpenCreateModal = () => {
//     setCreateModal(true);
//   }

//   const handleCloseCreateModal = () => {
//     setCreateModal(false);
//   }

//   const handleOpenEditModal = (id) => {
//     setEditModal(id);
//   }

//   const handleCloseEditModal = () => {
//     setEditModal(null);
//   }

//   const handleOpenDeleteModal = (id) => {
//     setDeleteModal(id);
//   }

//   const handleCloseDeleteModal = () => {
//     setDeleteModal(null);
//   }
//   const [inputs, setInputs] = useState([{}]);

//   const [modal_number, set_modal_number] = useState(0)

//   const change_modal_number = (i) => {
//     set_modal_number(i)

//   }

//   const add_game = () => {

//     if(inputs.length === 10){
//       alert('You cannot create a more than 10 games list')
//       return
//     }
//     var new_inputs = inputs.concat([{}])

//     setInputs(new_inputs)

//     console.log(inputs)

//   }

//   // console.log(inputs)

//   const handleDeleteCustomGame = async () => {

//     try{
//         setDeleting(true);
//         // await deleteCustomGame(deleteModal);
//         setDeleteModal(null);
//     }
//     catch(e){
//         alert(e);
//     }
//     finally{
//         setDeleting(false);
//     }
//   }

//   const [kinnectCode, setKinnectCode] = useState('')

//   const kinnect_code = (e) => {
//     setKinnectCode(e.target.value)
//   }

//   const kinnect_code_submit = async() =>{

//     var data = { kinnectCode : kinnectCode}

//     axios.post(`${API_URL}/verify/kinect-code` , data)
//     .then(res => {
//         swal.fire({
//             title: 'Success',
//             text: 'Game started successfully',
//             icon: 'success',
//             confirmButtonText: 'Ok'
//         })
//         // .then(function () {
//         //       window.location = "/labs/gamestore";
//         //   })

//     }).catch(e => {
//         swal.fire({
//             title: 'Error',
//             text: e.message || e || 'Failed to create. Try again!',
//             icon: 'error',
//             confirmButtonText: 'Ok'
//         })
//     });
//   }

//   const difficulty_level_color = (level) => {
//     if (level === "Easy"){
//         return '#48CD27'
//     } else  if (level === "Medium"){
//       return '#272ECD'
//   } else {
//       return "#CD2727"
//   }
// }

// // allCustomGames

// const [currentCustomGames, setCurrentCustomGames] = useState(allCustomGames)

// const searchCustomgames = (e) => {
//   if (e == null || e== "" || e == undefined){
//       setCurrentCustomGames(customGames)
//   }

//       var xyz = customGames.filter(student => student.name.toLowerCase().includes(e.toLowerCase()))
//       setCurrentCustomGames(xyz);
// }

//   const cancelSearchCustomGames = (e) => {
//   setCurrentCustomGames(customGames)
//   }

//   // var kinect_unique_id = Math.floor(Math.random() * 1000000)

//   return (
//     <div className='play_game_full'>
//       {/* {console.log('gruppp--->>',group_students[0]?.group)} */}
//       <div className='main_title_holder'>
//        <div>
//           <div><h3>Play Game</h3>
//             <p>at Grade 06 | Secondary</p>
//           </div>
//           {/* <div style={{marginLeft:"50%"}}>Kinnect Code:{kinect_unique_id}</div> */}
//         </div>
//         <hr />
//         <p className='title_below_description'>
//           Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
//           laying out print, graphic or web designs. The passage is attributed to
//           an unknown typesetter in the 15th century who is thought to have{' '}
//         </p>
//         <Link className='close_btn'  to={`/labs/groups/${group.match?.params.id}/students`} >
//           <span className='fa fa-times' ></span>
//         </Link>
//       </div>

//       {/* {inputs.length === 0 ?  <div className='game_container'>
//         <div className='game_detail_holder'>
//           <div className='game-btn-holder'>
//             <p>Game</p>
//             <button
//               type='button'
//               onClick={()=> modal_number(0)}
//               data-toggle='modal'
//               data-target='#select_game'
//               className='game-btn'>
//               {game_done ?  selectedCustomGame.name :"Select Game"}

//             </button>
//           </div>
//           <div className='game-btn-holder'>
//             <p>Students</p>
//             <button
//               type='button'
//               data-toggle='modal'
//               data-target='#select_students'
//               className='game-btn'>
//               { selected_students.length !== 0 ? selected_students : "Select Students"}

//             </button>
//           </div>
//         </div>
//         <Link href='#' className='add_game_btn' onClick={add_game} style={{border : "0px"}}>
//           + Add Game
//         </Link>
//       </div> :   */}
//       <div className='game_container'>

//       {inputs?.map((x, i)  =>

//         <div className='game_detail_holder'>
//           <div className='game-btn-holder'>
//             <p>Game</p>
//             <button
//               type='button'
//               data-toggle='modal'
//               onClick={()=> change_modal_number(i)}
//               data-target='#select_game'
//               className='game-btn'>
//               {inputs[i].custom_game?.name ? inputs[i].custom_game?.name :"Select Game"}

//             </button>
//           </div>
//           <div className='game-btn-holder'>
//             <p>Students</p>
//             <button
//               type='button'
//               data-toggle='modal'
//               onClick={()=> change_modal_number(i)}
//               data-target={`#select_students${i}`}
//               className='game-btn'>
//               { inputs[i]?.students && inputs[i]?.students.length != 0 ?
//                inputs[i].students.map((name, key) =>
//                key == inputs[i].students.length -1 ? <span>{name}</span> :
//                <span>{name}, </span>) : "Select Students"
//                }
//               {/* Selected Students */}
//             </button>
//           </div>
//         </div>
//       )}
//        <Button className='add_game_btn' onClick={add_game} style={{border : "0px"}}>
//           + Add Game
//         </Button>

//       {/* } */}
//       </div>

//       {inputs[0].custom_game?.game.compatibility && <Fragment>
//       {inputs[0].custom_game?.game.compatibility === 'IOS' ?
//       <button type='button'
//               data-toggle='modal' onClick={getQRcode} data-target='#start_game_qr_code' href='#' className='start_game_btn btn-lg'>
//         Start Game
//       </button> :

//       <button type='button'
//               data-toggle='modal' onClick={getKinectCode} data-target='#start_game_kinect' href='#' className='start_game_btn btn-lg'>
//         Start Game
//       </button> }
//       </Fragment>}

//       {/* {<Fragment>
//         {kinect_resume === 'IOS'  ?
//       <button type='button'
//               data-toggle='modal' onClick={getQRcode} data-target='#start_game_qr_code' href='#' className='start_game_btn btn-lg'>
//         Start Game
//       </button> :

//       <button type='button'
//               data-toggle='modal' onClick={getKinectCode} data-target='#start_game_kinect' href='#' className='start_game_btn btn-lg'>
//         Start Game
//       </button> }
//       </Fragment> } */}

//       {inputs.map((x, i) =>
//       <div className='custom_modal modal' id={`select_students${i}`}>
//         <div className='modal-dialog modal-dialog-centered modal-lg '>
//           <div className='modal-content'>
//             <div className='modal-body'>

//               <div className='modal-custom-content'>
//               <div  style={{display : 'flex', justifyContent: 'space-between'}}>
//                     <Typography variant="h5" color="secondary">Select Students</Typography>
//                     <SearchBar style={{backgroundColor : 'white'}}
//                         // onChange={(e) => search(e)}
//                         // onCancelSearch={(e) => cancelSearch(e)}
//                         // cancelSearchGame

//                       onChange={(e) => searchStudent(e)}
//                       onCancelSearch={(e) => cancelSearchStudent(e)}
//                       />
//                 </div>
//               <div className={classes.top}>
//             <Typography variant="h6"><p className={classes.selected}>{student_count > 1 ? student_count + ' students' : student_count +  ' student'} selected</p></Typography>
//             {/* <div>
//                 <IconButton>
//                     <SearchIcon/>
//                 </IconButton>
//                 <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>Add Student</Button>
//             </div> */}
//         </div>

//         <div className={classes.bottom}>

//         <Paper>
//                     <TableContainer>
//                         <Table className={classes.table}>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell padding="checkbox">
//                                         <Checkbox color="primary"/>
//                                     </TableCell>
//                                     <TableCell align="left">Student Name</TableCell>
//                                     <TableCell align="left">Phone</TableCell>
//                                     <TableCell align="left">Email</TableCell>
//                                     <TableCell align="left">Last Played</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                             {currentGroup?.map(student=>
//                                 <TableRow>
//                                     <TableCell padding="checkbox">
//                                         <Checkbox onChange={e=>select_student(e,i)} color="primary" value={student.name}/>
//                                     </TableCell>
//                                     <TableCell component="th" scope="row">
//                                     <span style={{display : 'flex', alignItems: 'center'}}>
//                                   <img
//                                   style = {{height : "30px", width : "30px", borderRadius : "20px", marginRight : '10px'}}
//                                     src={student?.profile_pic && student?.profile_pic != "" ? student?.profile_pic : user_image}
//                                     className='student_image'
//                                     alt=''
//                                   />
//                                         {student.name}
//                                         </span>
//                                     </TableCell>
//                                     <TableCell align="left">{student?.phone_number}</TableCell>
//                                     <TableCell align="left">{student?.email_id}</TableCell>
//                                     <TableCell align="left">Molecule Builder</TableCell>
//                                 </TableRow>
//                             )}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                     <TablePagination
//                         rowsPerPageOptions={[10]}
//                         component="div"
//                         count={20}
//                         rowsPerPage={10}
//                         page={page}
//                         onChangePage={handleChangePage}
//                     />
//                 </Paper>

// {/*
//             <Paper>
//                 <TableContainer>
//                     <Table className={classes.table} aria-label="simple table">
//                         <TableHead>
//                             <TableRow>
//                             <TableCell align="left"></TableCell>
//                                 <TableCell align="left">Student Name</TableCell>
//                                 <TableCell align="left">Phone</TableCell>
//                                 <TableCell align="left">Email</TableCell>
//                                 <TableCell align="left">Last Played</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                         {group_students && group_students.map(student => (
//                             <TableRow key={student._id}>
//                                      <TableCell component="th" scope="row">
//                               <input name='student' onChange={e=>select_student(e,i)} value={student.name} type="checkbox" defaultChecked={false}/>
//                               </TableCell>
//                                 <TableCell component="th" scope="row">
//                                     <Link >{student.name}</Link>
//                                     <Link to={`${match.url}/${student._id}`}>{student.name}</Link>

//                                 </TableCell>

//                                 <TableCell align="left">{student.phone_number}</TableCell>
//                                 <TableCell align="left">{student.email_id}</TableCell>
//                                 <TableCell align="left">Molecule Builder</TableCell>

//                             </TableRow>
//                         ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <TablePagination
//                     rowsPerPageOptions={[10]}
//                     component="div"
//                     count={20}
//                     rowsPerPage={10}
//                     page={page}
//                     onChangePage={handleChangePage}
//                 />
//             </Paper> */}

//         </div>

//         <div style={{width : '20vw', display : 'flex' , flexDirection : 'row',  marginTop : '2vh'}}>
//         <button onClick={submit_students} type='submit'  data-dismiss='modal'  className=' btn-login btn-blue'>
//                     Choose
//                   </button>
//                   <button
//                   style={{marginLeft : '1vw'}}
//                     data-dismiss='modal'
//                     type='button'
//                     className=' btn-login btn-white'>
//                     Cancel
//                   </button>
//                   </div>
//                 </div>
//                 </div>
//                 </div>

//                 </div>

//                 </div>
//                             )}

//       {/* Modal start */}
//       <div className='custom_modal modal' id='select_game'>
//         <div className='modal-dialog modal-dialog-centered modal-lg '>
//           <div className='modal-content'>
//             <div className='modal-body'>
//             {(step === 1) ? (
//               <div className='modal-custom-content'>

//                 <div className='modal_title-holder'>
//                   <Grid container spacing={3}>
//                     <Grid item sm={4}>
//                       <h3 className='modal_title'>Select Game</h3>
//                     </Grid>

//                     <Grid item sm={8}>
//                       <div className='filter_container'>
//                         {/* <h3 className='filter search_icon text-right'>
//                           <span class='fa fa-search'></span>
//                         </h3> */}
//                           <SearchBar style={{backgroundColor : 'white', marginRight : '10px'}}
//                         onChange={(e) => searchGames(e)}
//                         onCancelSearch={(e) => cancelSearchGames(e)}
//                         cancelSearchGame
//                       />
//                         <select name='' style={{width: '40%'}} class='filter form-control' id=''>
//                           <option>Filter: All Applications</option>
//                         </select>
//                         <select name='' style={{width: '40%'}} class='filter form-control' id=''>
//                           <option>Sort by: Recently Installed</option>
//                         </select>
//                       </div>
//                     </Grid>
//                     <Grid item sm={12}>
//                       <hr class='modal_divider' />
//                     </Grid>
//                   </Grid>
//                 </div>
//                 <div className='game_card_holder'>
//                   <Grid container spacing={3}>
//                   {console.log("inputs when popup: ",inputs)}
//                       {console.log("inputs game name: ",inputs[0]?.custom_game?.game?.name)}
//                       {/* {alert(JSON.stringify(inputs[0].name))} */}
//                       {console.log(filteredGames(inputs))}
//                       {filteredGames(inputs).map((game, i) => (
//                         <Grid
//                           className="game-card-selection"
//                           item
//                           sm={3}
//                           xs={12}
//                         >
//                           <label className="modal_game_container">
//                             <div
//                               htmlFor="game1"
//                               onClick={(e) => selectGame(e, game, i)}
//                               className="gamecard"
//                             >
//                               {/* <input
//                             type='radio'
//                             name=''
//                             id='game1'
//                             className='gameinput'
//                             name='game'
//                           /> */}
//                               <div className="image">
//                                 <img src={game.illustration_img} alt="" />
//                                 <span className="fa fa-check-circle checkmark"></span>
//                               </div>
//                               <div className="game_content">
//                                 <h3>{game.name}</h3>
//                                 <p style={{ wordWrap: "break-word" }}>
//                                   {game.subjects}
//                                 </p>
//                               </div>
//                             </div>
//                           </label>
//                         </Grid>
//                       ))}
//                   </Grid>
//                 </div>

//                 <div className='row game_button_container'>
//                   <button onClick={nextStep} type='submit' className=' btn-login btn-blue'>
//                     Next
//                   </button>
//                   <button
//                     data-dismiss='modal'
//                     type='button'
//                     className=' btn-login btn-white'>
//                     Cancel
//                   </button>
//                 </div>
//               </div> ) :

//               <div>  {(step===2) ? ( <div>
//                 <h2 style={{color : "#E93480"}}><img src={back_arrow} onClick={() => setStep(step-1)} style={{height : '20px', width : '20px', marginRight : '10px'}}/>Select Custom Game from "{selectedGame?.name}"</h2>
//                   <Divider className={classes.divider}/>

//                  <div className={classes.top}>

//                  <div  style={{display : 'flex', flexDirection: 'row', width:'100%', justifyContent: 'space-between'}}>
//                     <Typography variant="h5" >{customGames?.length} Custom Games</Typography>
//                     <SearchBar
//                       onChange={(e) => searchCustomgames(e)}
//                       onCancelSearch={(e) => cancelSearchCustomGames(e)}
//                     />
//                 </div>
//             {/* <Typography variant="h6">{customGames?.length} Custom Games</Typography> */}
//             {/* <div>
//                 <IconButton>
//                     <SearchIcon/>
//                 </IconButton>
//                 <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>Create Game</Button>
//             </div> */}
//         </div>

//         <div className={classes.bottom}>
//             <Paper>
//                 <TableContainer>
//                     <Table className={classes.table} aria-label="simple table">
//                         <TableHead>
//                             <TableRow>
//                             <TableCell align="left"></TableCell>
//                                 <TableCell align="left">Game Name</TableCell>
//                                 <TableCell align="left">Grades</TableCell>
//                                 <TableCell align="left">Subjects</TableCell>
//                                 <TableCell align="left">Difficulty Level</TableCell>

//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                         {currentCustomGames?.map(cg => (
//                             <TableRow key={cg._id}>
//                                <TableCell component="th" scope="row">
//                                     <input name='custom_game' onChange={e=>selectCustomGame(e)} value={cg._id} type="radio"/>
//                                 </TableCell>
//                                 <TableCell component="th" scope="row">
//                                     {cg.name}
//                                 </TableCell>
//                                 <TableCell align="left">
//                                 {cg?.grades?.map((grade,key) =>
//                         key == cg.grades.length -1 ?
//                         <span>{grade}</span> :
//                         <span>{grade}, </span>
//                     )}
//                                 </TableCell>
//                                 <TableCell align="left">
//                                 {cg?.subjects?.map((subject,key) =>
//                                         key ==  cg.subjects.length -1 ?
//                                         <span>{subject}</span> :
//                                         <span>{subject}, </span>
//                     )}
//                                 </TableCell>
//                                 <TableCell >

//                                 <p style={{height : "10px", width : '10px', backgroundColor : difficulty_level_color(cg.difficulty_level), display : 'inline-block', borderRadius : '50%', marginRight : '10px', marginTop : '5px !important'}}></p>
//                                 {cg?.difficulty_level}

//                                 </TableCell>
//                                 {/* <TableCell align="center">
//                                     <div className={classes.gameActions}>
//                                         <IconButton onClick={()=>handleOpenEditModal(cg._id)}><EditIcon fontSize="small"/></IconButton>
//                                         <IconButton><CopyIcon fontSize="small"/></IconButton>
//                                         <IconButton onClick={()=>handleOpenDeleteModal(cg._id)}>
//                                             <DeleteIcon fontSize="small"/>
//                                         </IconButton>
//                                     </div>
//                                 </TableCell> */}
//                             </TableRow>
//                         ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>

//                 <TablePagination
//                     rowsPerPageOptions={[10]}
//                     component="div"
//                     count={20}
//                     rowsPerPage={10}
//                     page={page}
//                     onChangePage={handleChangePage}
//                 />
//             </Paper>
//         </div> <div className='row game_button_container'>

//         <Button variant="contained" color="primary" style={{textTransform : 'none', fontSize :'16px', marginRight : '10px'}} onClick={reviewGame}>
//                             Choose Game
//                     </Button>
//                     <Button variant="contained"  data-dismiss='modal' style={{textTransform : 'none', backgroundColor : 'white', fontSize :'16px', border : '1px solid lightgrey', boxShadow : '0px 0px 0px 0px white'}}>
//                             Cancel
//                     </Button>

//                   {/* <button onClick={reviewGame} type='submit' style={{color : 'white' , boxShadow : 'none !important'}} className='btn btn-primary'>
//                     Choose Game
//                   </button>
//                   <button
//                     data-dismiss='modal'
//                     type='button'
//                     className='btn btn-light'>
//                     Cancel
//                   </button> */}
//                 </div></div>) :  <div className={classes.root}>
//                 <h2 style={{color : "#E93480"}}><img src={back_arrow} onClick={() => setStep(step-1)}  style={{height : '20px', width : '20px', marginRight : '10px'}}/>Preview "{inputs[modal_number]?.custom_game?.name}"</h2>

//                 <Divider className={classes.divider}/>

//         <div className={classes.gameTitle}>

//             <div>
//                 <div>
//                     <Typography variant="h5">{inputs[modal_number]?.custom_game?.name}</Typography>
//                     <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}}  label={inputs[modal_number]?.custom_game?.subjects} className={classes.chip}/>

//                     {/* <Chip color="primary" className={classes.chip}/> */}
//                 </div>
//                 <Typography variant="body1">{inputs[modal_number]?.custom_game?.outcomes}</Typography>
//             </div>
//             {/* <ButtonGroup>
//                 <Button onClick={handleOpenPlayDemoModal}>Play Demo</Button>
//             </ButtonGroup> */}
//         </div>

//         <Divider className={classes.divider}/>

//         <Grid container spacing={1} className={classes.gameProperties}>
//             <Grid item xs={12} md>
//                 <Typography variant="h6">GRADES</Typography>
//                 <Typography variant="body1">
//                     {inputs[modal_number]?.custom_game?.grades.map((g,i) =>
//                         <span key={g._id}>{g}{i!==inputs[modal_number]?.custom_game?.grades.length-1 && ', '}</span>
//                     )}
//                 </Typography>
//             </Grid>
//             <Grid item xs={12} md>
//                 <Typography variant="h6">SUBJECTS</Typography>
//                 <Typography variant="body1">
//                     {inputs[modal_number]?.custom_game?.subjects.map((s,i) =>
//                         <span key={s}>{s}{i!==inputs[modal_number]?.custom_game?.subjects.length-1 && ', '}</span>
//                     )}
//                 </Typography>
//             </Grid>
//             <Grid item xs={12} md>
//                 <Typography variant="h6">LEVEL</Typography>
//                 <Typography variant="body1">{inputs[modal_number]?.custom_game?.difficulty_level}</Typography>
//             </Grid>
//             <Grid item xs={12} md>
//                 <Typography variant="h6">TIME</Typography>
//                 <Typography variant="body1">10 mins</Typography>
//             </Grid>
//             <Grid item xs={12} md>
//                 <Typography variant="h6">CATEGORY</Typography>
//                 <Typography variant="body1">{inputs[modal_number]?.custom_game?.category}</Typography>
//             </Grid>
//         </Grid>
//         <div className={classes.root}>
//         <Grid container className={classes.gameDetailsPreview}>

// <Grid item xs={12}>

//       <Grid style={{display : 'flex', flexDirection:'column', alignItems:  'center'}}>
//         <br/>
//           <span className={classes.question}>Q: {inputs[modal_number]?.custom_game?.questions[0].question}</span>
//           <br/>
//       <div className={classes.imageLayout} >
//           <span class="iconify" data-icon="grommet-icons:next" style={{fontSize : '50px', color : '#676F8C', fontWeight : '700'}} data-rotate="180deg"  href="#carouselExampleControls" role="button" data-slide="prev"></span>

//           <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
//           <div class="carousel-inner">

//           {/* {game?.illustration_img.map(image => */}

//               <div class="carousel-item active">
//               <img height='316px' width='574px' src={selectedGame?.illustration_img}/>
//               </div>
//            {/* ) */}
//           </div>
//           </div>

//     <span class="iconify " href="#carouselExampleControls" data-slide="next" style={{fontSize : '50px', color : '#676F8C', fontWeight : '700'}} data-icon="grommet-icons:next"></span>

//       </div>

//       </Grid>
//     </Grid>
//       {/* <Grid container className={classes.gameDetailsPreview}>

//       <Grid item xs={12}>
//             <Grid style={{display : 'flex', justifyContent : 'center'}}>
//               <img height="316px" width="574" src={selectedGame?.illustration_img}/>
//             </Grid>
//           </Grid> */}
//         {/* {inputs[modal_number]?.custom_game.game?.customizable ?
//         inputs[modal_number]?.custom_game?.questions.map((q,i) =>
//             <Fragment key={q.id}>
//             {q.question && <Grid item xs={12}>
//                 <Grid container>
//                     <Grid item xs={3}>
//                         <Typography variant="body1" gutterBottom>Question {inputs[modal_number]?.custom_game?.category && inputs[modal_number]?.custom_game?.category.multiple_questions && i+1}</Typography>
//                     </Grid>
//                     <Grid item xs={9}>
//                         <Typography variant="body1" gutterBottom>{q.question}</Typography>
//                     </Grid>
//                 </Grid>
//             </Grid>}

//             {q.right_answers && q.right_answers.length>0 && <Grid item xs={12}>
//                 <Grid container>
//                     <Grid item xs={3}>
//                         <Typography variant="body1" gutterBottom>Right answers</Typography>
//                     </Grid>
//                     <Grid item xs={9}>
//                         <Typography variant="body1" gutterBottom>
//                             {q.right_answers?.map((ans, index) =>
//                                 <span key={ans}>{ans}{index!==q.right_answers.length-1 && ', '}</span>
//                             )}
//                         </Typography>
//                     </Grid>
//                 </Grid>
//             </Grid>}

//             {q.wrong_answers && <Grid item xs={12}>
//                 <Grid container>
//                     <Grid item xs={3}>
//                         <Typography variant="body1" gutterBottom>Wrong answers</Typography>
//                     </Grid>
//                     <Grid item xs={9}>
//                         <Typography variant="body1" gutterBottom>
//                             {q.wrong_answers?.map((ans,index) =>
//                                 <span key={ans}>{ans}{index!==q.wrong_answers.length-1 && ', '}</span>
//                             )}
//                         </Typography>
//                     </Grid>
//                 </Grid>
//             </Grid>}

//             <Grid item xs={12}>
//                 <Divider className={classes.divider}/>
//             </Grid>
//             </Fragment>
//         ):
//           <Grid item xs={12}>
//             <Grid container>
//               <Grid item xs={3}>
//                 <Typography variant="body1" gutterBottom>Answers</Typography>
//               </Grid>
//               <Grid item xs={9}>
//                 <Typography variant="body1" gutterBottom>
//                   {selectedCustomGame.answers?.map((ans,index) =>
//                     <span key={ans}>{ans}{index!==selectedCustomGame.answers.length-1 && ', '}</span>
//                   )}
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Grid>
//         } */}
//       </Grid>
//       <Divider className={classes.divider}/>

//     </div>

//     <div className='row game_button_container'>

//     <Button variant="contained" color="primary"  data-dismiss='modal' style={{textTransform : 'none', fontSize :'16px', marginRight : '10px'}} onClick={submit}>
//                             Choose Game
//                     </Button>
//                     <Button variant="contained"  data-dismiss='modal' style={{textTransform : 'none', backgroundColor : 'white', fontSize :'16px', border : '1px solid lightgrey', boxShadow : '0px 0px 0px 0px white'}}>
//                             Cancel
//                     </Button>

//                 </div>
//     </div>}</div>}
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* MOdel End */}

//       <div class="modal fade bd-example-modal" id ="start_game_qr_code" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
//   <div class="modal-dialog modal-md">
//     <div class="modal-content" style={{padding:"20px"}}>
//       <br/>
//       <h1  style={{color : "#E93480"}}>QR Code</h1><br/>
//       <h3>Scan this code with your IOS devices to play the game</h3><br/>
//       <img src={QRcode} />
//     </div>
//   </div>
// </div>

// <div class="modal fade bd-example-modal" id ="start_game_kinect" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
//   <div class="modal-dialog modal-md">
//     <div class="modal-content" style={{padding:"20px"}}>
//       <br/>
//       <h1  style={{color : "#E93480"}}>Kinect</h1><br/>
//       <h3>Enter the code to connect with kinect</h3><br/>

// <div class="input-group mb-3">
//   <input type="text" class="form-control" onChange={kinnect_code} placeholder="Enter Details" aria-label="Recipient's username" aria-describedby="basic-addon2" />
//   <div class="input-group-append">
//     <button class="btn btn-primary"  style={{color : "white"}} onClick={kinnect_code_submit} type="button">Connect</button>
//   </div>
// </div>
//     </div>
//   </div>
// </div>
// {/* {<Fragment>
//   {console.log('funnnpopp')}
// {kinect_resume === 'IOS' ?
//  <div class="modal fade bd-example-modal" id ="start_game_qr_code" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
//  <div class="modal-dialog modal-md">
//    <div class="modal-content" style={{padding:"20px"}}>
//      <br/>
//      <h1  style={{color : "#E93480"}}>QR Code</h1><br/>
//      <h3>Scan this code with your IOS devices to play the game</h3><br/>
//      <img src={QRcode} />
//    </div>
//  </div>
// </div> :
//  <div class="modal fade bd-example-modal" id ="start_game_kinect" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
//  <div class="modal-dialog modal-md">
//    <div class="modal-content" style={{padding:"20px"}}>
//      <br/>
//      <h1  style={{color : "#E93480"}}>Kinect</h1><br/>
//      <h3>Enter the code to connect with kinect</h3><br/>

// <div class="input-group mb-3">
//  <input type="text" class="form-control" onChange={kinnect_code} placeholder="Enter Details" aria-label="Recipient's username" aria-describedby="basic-addon2" />
//  <div class="input-group-append">
//    <button class="btn btn-primary"  style={{color : "white"}} onClick={kinnect_code_submit} type="button">Connect</button>
//  </div>
//   </div>
//    </div>
//  </div>
// </div>
// }
// </Fragment>} */}

//     </div>
//   )
// }

// export default PlayGame2

import { React, useEffect, useState, Fragment } from "react";
//material-ui core imports

import ButtonGroup from "@material-ui/core/ButtonGroup";

import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import {useState, forwardRef} from 'react';
import SearchBar from "material-ui-search-bar";
import { Link, useHistory, useParams } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
// import user_image from "../../Components/Dashboard/Icons/user.png"
import user_image from "../../components/Dashboard/Icons/user.png";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ViewCustomGameBottom from "../../components/Dashboard/MyGames";
import ViewCustomGameTop from "../../components/Dashboard/MyGames";

//material-ui icon imports
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { getCustomGames } from "../../redux/actions/gameAction";
import swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { getGames } from "../../redux/actions/gameAction";
import { getGroups } from "../../redux/actions/studentAction";
import ViewMyGame from "../../components/Dashboard/MyGames";
import axios from "axios";
import { API_URL } from "../../config";
import back_arrow from "../../components/Dashboard/Icons/back_arrow.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-20px",
    "& h5": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: "24px 0",
  },
  chip: {
    marginTop: "0.2vh",
    minHeight: "28px !important",
    minWidth: "62px",
    borderRadius: 4,
    height: "auto",
    "&>*:nth-child(1)": {
      padding: "3px 5px",
    },
  },
  buttonLink: {
    textDecoration: "none",
    color: "#000",
  },
  gameTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 24,
    "&>div": {
      "& h5": {
        fontSize: "1.4rem",
        color: "#02113F !important",
        margin: "0 8px 4px 0",
        fontWeight: 500,
        fontSize: "24px",
      },
      "& p": {
        fontSize: "0.9rem",
        marginTop: 4,
        color: "#02113F",
        opacity: 0.6,
        fontSize: "16px",
        lineHeight: "20px",
      },
      "&>div": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  divider: {
    margin: "24px 0",
  },
  button: {
    textTransform: "initial",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 24,
    "&>*": {
      marginTop: 8,
    },
    "&>*:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      "&>*": {
        margin: "0 4px",
      },
    },
  },
  table: {
    "&>*:nth-child(1)": {
      background: "#E4E9F3",
    },
  },
  gameActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>*": {
      marginRight: 4,
    },
  },
  createModal: {
    "& h5": {
      fontWeight: 500,
    },
    "&>div:nth-child(2)": {
      margin: "24px 0 12px 0",
    },
    "&>form": {
      "&>div:nth-child(1)": {
        marginBottom: 24,
      },
      "&>div:nth-child(3)": {
        margin: "12px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "&>*": {
          width: "48%",
        },
      },
    },
  },
  createModal: {
    "& h5": {
      fontWeight: 500,
    },
    "&>div:nth-child(2)": {
      margin: "24px 0 12px 0",
    },
    "&>div:nth-child(3)": {
      margin: "36px 0 12px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>*": {
        width: "48%",
      },
    },
  },
  divider: {
    margin: "24px 0",
  },
  button: {
    textTransform: "initial",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 24,
    "&>*": {
      marginTop: 8,
    },
    "&>*:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      "&>*": {
        margin: "0 4px",
      },
    },
  },
  gameDetailsPreview: {
    marginTop: 24,
    "&>*": {
      margin: "8px 0",
      "&>*:nth-child(1)": {
        "&>*:nth-child(1)": {
          "&>p": {
            color: "#666",
            fontWeight: 500,
          },
        },
      },
    },
  },

  table: {
    "&>*:nth-child(1)": {
      background: "#E4E9F3",
    },
  },
  gameActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>*": {
      marginRight: 4,
    },
  },
  createGame: {
    padding: "24px 48px",
    "& h4": {
      fontWeight: 500,
    },
    "&>*:nth-child(1)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  divider: {
    margin: "24px 0",
  },
  buttonLink: {
    textDecoration: "none",
    padding: 0,
  },
  gameProperties: {
    marginBottom: 8,
    "&>*": {
      textAlign: "center",
      "&>h6": {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#02113F",
        opacity: 0.6,
      },
      "&>*:nth-child(2)": {
        fontSize: "1rem",
        marginTop: 16,
        fontWeight: 500,
        color: "#02113F",
      },
    },
    "&>*:not(:last-child)": {
      borderRight: "1px solid #ddd",
    },
  },
  playDemoModal: {
    "& h5": {
      fontWeight: 500,
    },
    "&>*": {
      margin: "12px 0",
    },
    "&>div:nth-child(4)": {
      margin: "36px 0 12px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>*": {
        width: "48%",
      },
    },
  },
  question: {
    fontSize: 24,
    lineHeight: 3,
    color: "#02113F",
  },
  imageLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const PlayGame2 = (group) => {
  console.log(group);

  let history = useHistory();

  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [selectedGame, setSelectedGame] = useState();
  const [customGames, setCustomGames] = useState([]);
  const [group_students, setgroup_students] = useState();
  const [selectedCustomGame, setSelectedCustomGame] = useState({});
  const [game_done, setGame_done] = useState(false);

  useEffect(async () => {
    if (!game.games) {
      await dispatch(getGames());
    }
    if (!game.customGames) {
      await dispatch(getCustomGames());
    }
    if (!students.groups) {
      await dispatch(getGroups(localStorage.getItem("institute")));
    }
  }, []);

  const game = useSelector((state) => state.game);
  const students = useSelector((state) => state.student);

  const institute_plan = localStorage.getItem("institute_plan");
  const allGames = game.games;
  const allCustomGames = game.customGames;

  const myGames =
    institute_plan == "Advanced"
      ? allGames || []
      : institute_plan == "Basic"
      ? (allGames || []).filter((game) => game.plan === "Basic")
      : (allGames || []).filter((game) => game.plan != "Advanced");

  useEffect(() => {
    setFilterGames(myGames);
  }, [allGames]);

  const submit_students = () => {
    console.log(inputs);
    set_selected_students([]);
    setStudent_count(0);

    // setInputs(inputs)
  };

  useEffect(() => {
    setCurrentCustomGames(allCustomGames);
  }, [allCustomGames]);

  const submit = () => {
    setStep(1);
    setGame_done(true);
    console.log(inputs);
  };

  const [QRcode, setQRcode] = useState();

  const getQRcode = async () => {
    const headers = {
      "group-id": group_students[0]?.group,
    };
    var qrGameData = inputs;

    const res = await axios.post(`${API_URL}/qr-code`, qrGameData, {
      headers: headers,
    });
    setQRcode(res.data);
  };

  const getKinectCode = async () => {
    console.log("gruppp--->>", group_students);
    //  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

    const headers = {
      // 'unique-id': kinect_unique_id,
      "group-id": group_students[0]?.group,
    };
    var KinectGameData = [
      {
        ...inputs[0],
        custom_game: {
          ...inputs[0].custom_game,
          groupId: group_students[0]?.group,
        },
        students: currentGroup
          .filter((f) => inputs[0].students.includes(f.name))
          .map((m) => ({ name: m.name, uid: m._id })),
      },
    ];

    console.log("KinectGameData", KinectGameData);
    const res = await axios.post(
      `${API_URL}/kinect-code`,
      { game: KinectGameData },
      { headers: headers }
    );
  };

  const myGames1 = (allGames || [])?.filter(
    (game) => game.plan === institute_plan
  );

  const filteredGames = (inputs) => {
    let index = 0;
    inputs.forEach((i, idx) => {
      console.log("i---> ", i, Object.keys(i).length === 0);
      if (Object.keys(i).length !== 0) {
        index = idx;
      }
    });
    console.log("Index: ", index);
    var gameName = inputs[index]?.custom_game?.game?.name;
    console.log("gameName: ", gameName);
    if (gameName === undefined) {
      console.log("myGames 1: ", myGames1);
      return myGames1;
    }
    return (myGames1 || [])?.filter((game) => game.name === gameName);
  };

  const [filterGames, setFilterGames] = useState();

  const searchGames = (e) => {
    var xyz = myGames?.filter((game) =>
      game.name.toLowerCase().includes(e.toLowerCase())
    );
    setFilterGames(xyz);
  };

  const cancelSearchGames = (e) => {
    setFilterGames();
  };

  // const myGames = (allGames || [])?.filter(game => game.plan === institute_plan);

  const selectGame = (e, game) => {
    e.preventDefault();

    var xyz = document.getElementsByClassName("gamecard");
    Array.from(xyz).forEach((element) => {
      element.style.border = "0px";
    });
    e.currentTarget.style.border = "3px solid #6FD540";
    setSelectedGame(game);
  };
  var colors = ["#FFBFBF", "#BFE0FF", "#F6BFFF"];

  const nextStep = async () => {
    if (!selectedGame) {
      alert("please select a game");
      return;
    }
    console.log(selectedGame);
    const selectedCustomGames = allCustomGames?.filter(
      (x) => x.game?._id === selectedGame._id
    );
    setCustomGames(selectedCustomGames);
    setCurrentCustomGames(selectedCustomGames);
    setStep(2);
  };

  const reviewGame = async () => {
    if (!inputs[modal_number].custom_game) {
      alert("please select a custom game");
      return;
    }
    // setSelectedGame()
    setStep(3);
  };

  const selectCustomGame = async (e) => {
    const selected_custom_game = customGames?.filter(
      (game) => game._id === e.target.value
    );

    inputs[modal_number].custom_game = selected_custom_game[0];
    // inputs
    setInputs(inputs);
  };

  const classes = useStyles();

  const [playDemoModal, setPlayDemoModal] = useState(false);

  const handleClosePlayDemoModal = () => {
    setPlayDemoModal(false);
  };

  const handleOpenPlayDemoModal = () => {
    setPlayDemoModal(true);
  };

  const [selected_students, set_selected_students] = useState([]);

  const select_student = (e, i) => {
    if (!inputs[i].students) {
      inputs[i].students = [];
    }

    var temp_array = inputs[i].students;

    if (e.target.checked === true) {
      setStudent_count(student_count + 1);

      temp_array = [...temp_array, e.target.value];
      inputs[i].students = temp_array;
    } else if (e.target.checked === false) {
      setStudent_count(student_count - 1);

      const selectedStu = temp_array?.filter((a) => {
        if (a === e.target.value) return false;
        return true;
      });
      inputs[i].students = selectedStu;
    }
    setInputs((inputs) => inputs);
  };

  const [student_count, setStudent_count] = useState(0);

  var students_group;

  useEffect(() => {
    students_group = students?.groups?.find(
      (x) => x._id === group.match.params.id
    );

    setgroup_students(students_group?.students);

    setCurrentGroup(students_group?.students);
  }, [students]);

  const [currentGroup, setCurrentGroup] = useState([]);

  const Count = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked == true) {
      setStudent_count(student_count + 1);
    } else if (e.target.checked == false) {
      setStudent_count(student_count - 1);
    }
  };

  const searchStudent = (e) => {
    console.log(e);

    console.log(group_students);
    console.log(currentGroup);

    if (e == null || e == "" || e == undefined) {
      setCurrentGroup(group_students);
    }

    var xyz = group_students?.filter((student) =>
      student.name.toLowerCase().includes(e.toLowerCase())
    );

    setCurrentGroup(xyz);
  };

  const cancelSearchStudent = (e) => {
    console.log(e);

    setCurrentGroup(group_students);
    // setCurrentGroup(group.group)
  };

  const [page, setPage] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenCreateModal = () => {
    setCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModal(false);
  };

  const handleOpenEditModal = (id) => {
    setEditModal(id);
  };

  const handleCloseEditModal = () => {
    setEditModal(null);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(null);
  };
  const [inputs, setInputs] = useState([{}]);

  const [modal_number, set_modal_number] = useState(0);

  const change_modal_number = (i) => {
    set_modal_number(i);
  };

  const add_game = () => {
    if (inputs.length === 10) {
      alert("You cannot create a more than 10 games list");
      return;
    }
    var new_inputs = inputs.concat([{}]);

    setInputs(new_inputs);

    console.log(inputs);
  };

  // console.log(inputs)

  const handleDeleteCustomGame = async () => {
    try {
      setDeleting(true);
      // await deleteCustomGame(deleteModal);
      setDeleteModal(null);
    } catch (e) {
      alert(e);
    } finally {
      setDeleting(false);
    }
  };

  const [kinnectCode, setKinnectCode] = useState("");

  const kinnect_code = (e) => {
    setKinnectCode(e.target.value);
  };
  const params = useParams();
  const kinnect_code_submit = async () => {
    var data = { kinnectCode: kinnectCode };

    axios
      .post(`${API_URL}/verify/kinect-code`, data)
      .then((res) => {
        swal
          .fire({
            title: "Success",
            text: "Game started successfully",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(function () {
            window.location = `/labs/groups/${params.id}/students?value=1`;
          });
      })
      .catch((e) => {
        swal.fire({
          title: "Error",
          text: e.message || e || "Failed to create. Try again!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const difficulty_level_color = (level) => {
    if (level === "Easy") {
      return "#48CD27";
    } else if (level === "Medium") {
      return "#272ECD";
    } else {
      return "#CD2727";
    }
  };

  // allCustomGames

  const [currentCustomGames, setCurrentCustomGames] = useState(allCustomGames);

  const searchCustomgames = (e) => {
    if (e == null || e == "" || e == undefined) {
      setCurrentCustomGames(customGames);
    }

    var xyz = customGames.filter((student) =>
      student.name.toLowerCase().includes(e.toLowerCase())
    );
    setCurrentCustomGames(xyz);
  };

  const cancelSearchCustomGames = (e) => {
    setCurrentCustomGames(customGames);
  };

  return (
    <div className="play_game_full">
      <div className="main_title_holder">
        <h3>Play Game</h3>
        <p>at Grade 06 | Secondary</p>
        <hr />
        <p className="title_below_description">
          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
          laying out print, graphic or web designs. The passage is attributed to
          an unknown typesetter in the 15th century who is thought to have{" "}
        </p>
        <Link
          className="close_btn"
          to={`/labs/groups/${group.match?.params.id}/students`}
        >
          <span className="fa fa-times"></span>
        </Link>
      </div>

      {/* {inputs.length === 0 ?  <div className='game_container'>
        <div className='game_detail_holder'>
          <div className='game-btn-holder'>
            <p>Game</p>
            <button
              type='button'
              onClick={()=> modal_number(0)}
              data-toggle='modal'
              data-target='#select_game'
              className='game-btn'>
              {game_done ?  selectedCustomGame.name :"Select Game"}
              
            </button>
          </div>
          <div className='game-btn-holder'>
            <p>Students</p>
            <button
              type='button'
              data-toggle='modal'
              data-target='#select_students'
              className='game-btn'>
              { selected_students.length !== 0 ? selected_students : "Select Students"}
              
            </button>
          </div>
        </div>
        <Link href='#' className='add_game_btn' onClick={add_game} style={{border : "0px"}}>
          + Add Game
        </Link>
      </div> :   */}
      <div className="game_container">
        {inputs?.map((x, i) => (
          <div className="game_detail_holder">
            <div className="game-btn-holder">
              <p>Game</p>
              <button
                type="button"
                data-toggle="modal"
                onClick={() => change_modal_number(i)}
                data-target="#select_game"
                className="game-btn"
              >
                {inputs[i].custom_game?.name
                  ? inputs[i].custom_game?.name
                  : "Select Game"}
              </button>
            </div>
            <div className="game-btn-holder">
              <p>Students</p>
              <button
                type="button"
                data-toggle="modal"
                onClick={() => change_modal_number(i)}
                data-target={`#select_students${i}`}
                className="game-btn"
              >
                {inputs[i]?.students && inputs[i]?.students.length != 0
                  ? inputs[i].students.map((name, key) =>
                      key == inputs[i].students.length - 1 ? (
                        <span>{name}</span>
                      ) : (
                        <span>{name}, </span>
                      )
                    )
                  : "Select Students"}
                {/* Selected Students */}
              </button>
            </div>
          </div>
        ))}
        {/* <Link href='#' className='add_game_btn' onClick={add_game} style={{border : "0px"}}>
          + Add Game
        </Link> */}
        <Button
          className="add_game_btn"
          onClick={add_game}
          style={{ border: "0px" }}
        >
          + Add Game
        </Button>

        {/* } */}
      </div>

      {inputs[0].custom_game?.game.compatibility && (
        <Fragment>
          {inputs[0].custom_game?.game.compatibility === "IOS" ? (
            <button
              type="button"
              data-toggle="modal"
              onClick={getQRcode}
              data-target="#start_game_qr_code"
              href="#"
              className="start_game_btn btn-lg"
            >
              Start Game
            </button>
          ) : (
            <button
              type="button"
              data-toggle="modal"
              onClick={getKinectCode}
              data-target="#start_game_kinect"
              href="#"
              className="start_game_btn btn-lg"
            >
              Start Game
            </button>
          )}
        </Fragment>
      )}

      {inputs.map((x, i) => (
        <div className="custom_modal modal" id={`select_students${i}`}>
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-custom-content">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h5" color="secondary">
                      Select Students
                    </Typography>
                    <SearchBar
                      style={{ backgroundColor: "white" }}
                      // onChange={(e) => search(e)}
                      // onCancelSearch={(e) => cancelSearch(e)}
                      // cancelSearchGame

                      onChange={(e) => searchStudent(e)}
                      onCancelSearch={(e) => cancelSearchStudent(e)}
                    />
                  </div>
                  <div className={classes.top}>
                    <Typography variant="h6">
                      <p className={classes.selected}>
                        {student_count > 1
                          ? student_count + " students"
                          : student_count + " student"}{" "}
                        selected
                      </p>
                    </Typography>
                    {/* <div>
                <IconButton>
                    <SearchIcon/>
                </IconButton>
                <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>Add Student</Button>
            </div> */}
                  </div>

                  <div className={classes.bottom}>
                    <Paper>
                      <TableContainer>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox color="primary" />
                              </TableCell>
                              <TableCell align="left">Student Name</TableCell>
                              <TableCell align="left">Phone</TableCell>
                              <TableCell align="left">Email</TableCell>
                              <TableCell align="left">Last Played</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentGroup?.map((student) => (
                              <TableRow>
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onChange={(e) => select_student(e, i)}
                                    color="primary"
                                    value={student.name}
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "20px",
                                        marginRight: "10px",
                                      }}
                                      src={
                                        student?.profile_pic &&
                                        student?.profile_pic != ""
                                          ? student?.profile_pic
                                          : user_image
                                      }
                                      className="student_image"
                                      alt=""
                                    />
                                    {student.name}
                                  </span>
                                </TableCell>
                                <TableCell align="left">
                                  {student?.phone_number}
                                </TableCell>
                                <TableCell align="left">
                                  {student?.email_id}
                                </TableCell>
                                <TableCell align="left">
                                  Molecule Builder
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={10}
                        rowsPerPage={10}
                        page={page}
                        onChangePage={handleChangePage}
                      />
                    </Paper>

                    {/*           
            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell align="left"></TableCell>
                                <TableCell align="left">Student Name</TableCell>
                                <TableCell align="left">Phone</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Last Played</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {group_students && group_students.map(student => (
                            <TableRow key={student._id}>
                                     <TableCell component="th" scope="row">
                              <input name='student' onChange={e=>select_student(e,i)} value={student.name} type="checkbox" defaultChecked={false}/>
                              </TableCell>
                                <TableCell component="th" scope="row">
                                    <Link >{student.name}</Link>
                                    <Link to={`${match.url}/${student._id}`}>{student.name}</Link>
                                </TableCell>
                                
                                <TableCell align="left">{student.phone_number}</TableCell>
                                <TableCell align="left">{student.email_id}</TableCell>
                                <TableCell align="left">Molecule Builder</TableCell>
                           
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={20}
                    rowsPerPage={10}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </Paper> */}
                  </div>

                  <div
                    style={{
                      width: "20vw",
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2vh",
                    }}
                  >
                    <button
                      onClick={submit_students}
                      type="submit"
                      data-dismiss="modal"
                      className=" btn-login btn-blue"
                    >
                      Choose
                    </button>
                    <button
                      style={{ marginLeft: "1vw" }}
                      data-dismiss="modal"
                      type="button"
                      className=" btn-login btn-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Modal start */}
      <div className="custom_modal modal" id="select_game">
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <div className="modal-body">
              {step === 1 ? (
                <div className="modal-custom-content">
                  <div className="modal_title-holder">
                    <Grid container spacing={3}>
                      <Grid item sm={4}>
                        <h3 className="modal_title">Select Game</h3>
                      </Grid>

                      <Grid item sm={8}>
                        <div className="filter_container">
                          {/* <h3 className='filter search_icon text-right'>
                          <span class='fa fa-search'></span>
                        </h3> */}
                          <SearchBar
                            style={{
                              backgroundColor: "white",
                              marginRight: "10px",
                            }}
                            onChange={(e) => searchGames(e)}
                            onCancelSearch={(e) => cancelSearchGames(e)}
                            cancelSearchGame
                          />
                          <select
                            name=""
                            style={{ width: "40%" }}
                            class="filter form-control"
                            id=""
                          >
                            <option>Filter: All Applications</option>
                          </select>
                          <select
                            name=""
                            style={{ width: "40%" }}
                            class="filter form-control"
                            id=""
                          >
                            <option>Sort by: Recently Installed</option>
                          </select>
                        </div>
                      </Grid>
                      <Grid item sm={12}>
                        <hr class="modal_divider" />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="game_card_holder">
                    <Grid container spacing={3}>
                      {console.log("inputs when popup: ", inputs)}
                      {console.log(
                        "inputs game name: ",
                        inputs[0]?.custom_game?.game?.name
                      )}
                      {/* {alert(JSON.stringify(inputs[0].name))} */}
                      {console.log(filteredGames(inputs))}
                      {filteredGames(inputs).map((game, i) => (
                        <Grid
                          className="game-card-selection"
                          item
                          sm={3}
                          xs={12}
                        >
                          <label className="modal_game_container">
                            <div
                              htmlFor="game1"
                              onClick={(e) => selectGame(e, game, i)}
                              className="gamecard"
                            >
                              {/* <input
                            type='radio'
                            name=''
                            id='game1'
                            className='gameinput'
                            name='game'
                          /> */}
                              <div className="image">
                                <img src={game.illustration_img} alt="" />
                                <span className="fa fa-check-circle checkmark"></span>
                              </div>
                              <div className="game_content">
                                <h3>{game.name}</h3>
                                <p style={{ wordWrap: "break-word" }}>
                                  {game.subjects}
                                </p>
                              </div>
                            </div>
                          </label>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  <div className="row game_button_container">
                    <button
                      onClick={nextStep}
                      type="submit"
                      className=" btn-login btn-blue"
                    >
                      Next
                    </button>
                    <button
                      data-dismiss="modal"
                      type="button"
                      className=" btn-login btn-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {" "}
                  {step === 2 ? (
                    <div>
                      <h2 style={{ color: "#E93480" }}>
                        <img
                          src={back_arrow}
                          onClick={() => setStep(step - 1)}
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Select Custom Game from "{selectedGame?.name}"
                      </h2>
                      <Divider className={classes.divider} />
                      <div className={classes.top}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h5">
                            {customGames?.length} Custom Games
                          </Typography>
                          <SearchBar
                            onChange={(e) => searchCustomgames(e)}
                            onCancelSearch={(e) => cancelSearchCustomGames(e)}
                          />
                        </div>
                        {/* <Typography variant="h6">{customGames?.length} Custom Games</Typography> */}
                        {/* <div>
                <IconButton>
                    <SearchIcon/>
                </IconButton>
                <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>Create Game</Button>
            </div> */}
                      </div>
                      <div className={classes.bottom}>
                        <Paper>
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">Game Name</TableCell>
                                  <TableCell align="left">Grades</TableCell>
                                  <TableCell align="left">Subjects</TableCell>
                                  <TableCell align="left">
                                    Difficulty Level
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {currentCustomGames?.map((cg) => (
                                  <TableRow key={cg._id}>
                                    <TableCell component="th" scope="row">
                                      <input
                                        name="custom_game"
                                        onChange={(e) => selectCustomGame(e)}
                                        value={cg._id}
                                        type="radio"
                                      />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {cg.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {cg?.grades?.map((grade, key) =>
                                        key == cg.grades.length - 1 ? (
                                          <span>{grade}</span>
                                        ) : (
                                          <span>{grade}, </span>
                                        )
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {cg?.subjects?.map((subject, key) =>
                                        key == cg.subjects.length - 1 ? (
                                          <span>{subject}</span>
                                        ) : (
                                          <span>{subject}, </span>
                                        )
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <p
                                        style={{
                                          height: "10px",
                                          width: "10px",
                                          backgroundColor:
                                            difficulty_level_color(
                                              cg.difficulty_level
                                            ),
                                          display: "inline-block",
                                          borderRadius: "50%",
                                          marginRight: "10px",
                                          marginTop: "5px !important",
                                        }}
                                      ></p>
                                      {cg?.difficulty_level}
                                    </TableCell>
                                    {/* <TableCell align="center">
                                    <div className={classes.gameActions}>
                                        <IconButton onClick={()=>handleOpenEditModal(cg._id)}><EditIcon fontSize="small"/></IconButton>
                                        <IconButton><CopyIcon fontSize="small"/></IconButton>
                                        <IconButton onClick={()=>handleOpenDeleteModal(cg._id)}>
                                            <DeleteIcon fontSize="small"/>
                                        </IconButton>
                                    </div>
                                </TableCell> */}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={10}
                            rowsPerPage={10}
                            page={page}
                            onChangePage={handleChangePage}
                          />
                        </Paper>
                      </div>{" "}
                      <div className="row game_button_container">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                          onClick={reviewGame}
                        >
                          Choose Game
                        </Button>
                        <Button
                          variant="contained"
                          data-dismiss="modal"
                          style={{
                            textTransform: "none",
                            backgroundColor: "white",
                            fontSize: "16px",
                            border: "1px solid lightgrey",
                            boxShadow: "0px 0px 0px 0px white",
                          }}
                        >
                          Cancel
                        </Button>

                        {/* <button onClick={reviewGame} type='submit' style={{color : 'white' , boxShadow : 'none !important'}} className='btn btn-primary'>
                    Choose Game
                  </button>
                  <button
                    data-dismiss='modal'
                    type='button'
                    className='btn btn-light'>
                    Cancel
                  </button> */}
                      </div>
                    </div>
                  ) : (
                    <div className={classes.root}>
                      <h2 style={{ color: "#E93480" }}>
                        <img
                          src={back_arrow}
                          onClick={() => setStep(step - 1)}
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                          }}
                        />
                        Preview "{inputs[modal_number]?.custom_game?.name}"
                      </h2>

                      <Divider className={classes.divider} />

                      <div className={classes.gameTitle}>
                        <div>
                          <div>
                            <Typography variant="h5">
                              {inputs[modal_number]?.custom_game?.name}
                            </Typography>
                            <Chip
                              style={{
                                backgroundColor:
                                  colors[
                                    Math.floor(Math.random() * colors.length)
                                  ],
                              }}
                              label={
                                inputs[modal_number]?.custom_game?.subjects
                              }
                              className={classes.chip}
                            />

                            {/* <Chip color="primary" className={classes.chip}/> */}
                          </div>
                          <Typography variant="body1">
                            {inputs[modal_number]?.custom_game?.outcomes}
                          </Typography>
                        </div>
                        {/* <ButtonGroup>
                <Button onClick={handleOpenPlayDemoModal}>Play Demo</Button>
            </ButtonGroup> */}
                      </div>

                      <Divider className={classes.divider} />

                      <Grid
                        container
                        spacing={1}
                        className={classes.gameProperties}
                      >
                        <Grid item xs={12} md>
                          <Typography variant="h6">GRADES</Typography>
                          <Typography variant="body1">
                            {inputs[modal_number]?.custom_game?.grades.map(
                              (g, i) => (
                                <span key={g._id}>
                                  {g}
                                  {i !==
                                    inputs[modal_number]?.custom_game?.grades
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <Typography variant="h6">SUBJECTS</Typography>
                          <Typography variant="body1">
                            {inputs[modal_number]?.custom_game?.subjects.map(
                              (s, i) => (
                                <span key={s}>
                                  {s}
                                  {i !==
                                    inputs[modal_number]?.custom_game?.subjects
                                      .length -
                                      1 && ", "}
                                </span>
                              )
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <Typography variant="h6">LEVEL</Typography>
                          <Typography variant="body1">
                            {
                              inputs[modal_number]?.custom_game
                                ?.difficulty_level
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <Typography variant="h6">TIME</Typography>
                          <Typography variant="body1">10 mins</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <Typography variant="h6">CATEGORY</Typography>
                          <Typography variant="body1">
                            {inputs[modal_number]?.custom_game?.category}
                          </Typography>
                        </Grid>
                      </Grid>
                      <div className={classes.root}>
                        <Grid container className={classes.gameDetailsPreview}>
                          <Grid item xs={12}>
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <br />
                              <span className={classes.question}>
                                Q:{" "}
                                {
                                  inputs[modal_number]?.custom_game
                                    ?.questions[0].question
                                }
                              </span>
                              <br />
                              <div className={classes.imageLayout}>
                                <span
                                  class="iconify"
                                  data-icon="grommet-icons:next"
                                  style={{
                                    fontSize: "50px",
                                    color: "#676F8C",
                                    fontWeight: "700",
                                  }}
                                  data-rotate="180deg"
                                  href="#carouselExampleControls"
                                  role="button"
                                  data-slide="prev"
                                ></span>

                                <div
                                  id="carouselExampleControls"
                                  class="carousel slide"
                                  data-ride="carousel"
                                >
                                  <div class="carousel-inner">
                                    {/* {game?.illustration_img.map(image => */}

                                    <div class="carousel-item active">
                                      <img
                                        height="316px"
                                        width="574px"
                                        src={selectedGame?.illustration_img}
                                      />
                                    </div>
                                    {/* ) */}
                                  </div>
                                </div>

                                <span
                                  class="iconify "
                                  href="#carouselExampleControls"
                                  data-slide="next"
                                  style={{
                                    fontSize: "50px",
                                    color: "#676F8C",
                                    fontWeight: "700",
                                  }}
                                  data-icon="grommet-icons:next"
                                ></span>
                              </div>
                            </Grid>
                          </Grid>
                          {/* <Grid container className={classes.gameDetailsPreview}>
      <Grid item xs={12}>
            <Grid style={{display : 'flex', justifyContent : 'center'}}>
              <img height="316px" width="574" src={selectedGame?.illustration_img}/>
            </Grid>
          </Grid> */}
                          {/* {inputs[modal_number]?.custom_game.game?.customizable ? 
        inputs[modal_number]?.custom_game?.questions.map((q,i) =>
            <Fragment key={q.id}>
            {q.question && <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="body1" gutterBottom>Question {inputs[modal_number]?.custom_game?.category && inputs[modal_number]?.custom_game?.category.multiple_questions && i+1}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" gutterBottom>{q.question}</Typography>
                    </Grid>
                </Grid>
            </Grid>}
            
            {q.right_answers && q.right_answers.length>0 && <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="body1" gutterBottom>Right answers</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" gutterBottom>
                            {q.right_answers?.map((ans, index) =>
                                <span key={ans}>{ans}{index!==q.right_answers.length-1 && ', '}</span>    
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>}
            
            {q.wrong_answers && <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="body1" gutterBottom>Wrong answers</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" gutterBottom>
                            {q.wrong_answers?.map((ans,index) =>
                                <span key={ans}>{ans}{index!==q.wrong_answers.length-1 && ', '}</span>    
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid item xs={12}>
                <Divider className={classes.divider}/>
            </Grid>
            </Fragment>
        ):
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="body1" gutterBottom>Answers</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1" gutterBottom>
                  {selectedCustomGame.answers?.map((ans,index) =>
                    <span key={ans}>{ans}{index!==selectedCustomGame.answers.length-1 && ', '}</span>    
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        } */}
                        </Grid>
                        <Divider className={classes.divider} />
                      </div>

                      <div className="row game_button_container">
                        <Button
                          variant="contained"
                          color="primary"
                          data-dismiss="modal"
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                          onClick={submit}
                        >
                          Choose Game
                        </Button>
                        <Button
                          variant="contained"
                          data-dismiss="modal"
                          style={{
                            textTransform: "none",
                            backgroundColor: "white",
                            fontSize: "16px",
                            border: "1px solid lightgrey",
                            boxShadow: "0px 0px 0px 0px white",
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* MOdel End */}

      <div
        class="modal fade bd-example-modal"
        id="start_game_qr_code"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content" style={{ padding: "20px" }}>
            <br />
            <h1 style={{ color: "#E93480" }}>QR Code</h1>
            <br />
            <h3>Scan this code with your IOS devices to play the game</h3>
            <br />
            <img src={QRcode} />
          </div>
        </div>
      </div>

      <div
        class="modal fade bd-example-modal"
        id="start_game_kinect"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content" style={{ padding: "20px" }}>
            <br />
            <h1 style={{ color: "#E93480" }}>Kinect</h1>
            <br />
            <h3>Enter the code to connect with kinect</h3>
            <br />

            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                onChange={kinnect_code}
                placeholder="Enter Details"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  style={{ color: "white" }}
                  onClick={kinnect_code_submit}
                  type="button"
                >
                  Connect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayGame2;
