//Common types
export const NOT_LOADING = "NOT_LOADING";
export const IS_LOADING = "IS_LOADING";

//Auth types
export const SET_AUTHENTICATED_USER = "SET_AUTHENTICATED_USER";
export const REMOVE_AUTHENTICATED_USER = "REMOVE_AUTHENTICATED_USER";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//Admin types
export const STORE_ADMIN_DATA = "STORE_ADMIN_DATA";
export const DESTROY_ADMIN_DATA = "DESTROY_ADMIN_DATA";
export const ADMIN_CHECK_ERROR = "ADMIN_CHECK_ERROR";

//Games types
export const FETCH_GAMES_ERROR = "FETCH_GAMES_ERROR";
export const STORE_GAMES = "STORE_GAMES";

//recent/resume games

//Custom game types
export const FETCH_CUSTOM_GAMES_ERROR = "FETCH_CUSTOM_GAMES_ERROR";
export const STORE_CUSTOM_GAMES = "STORE_CUSTOM_GAMES";
export const CREATE_CUSTOM_GAME = "CREATE_CUSTOM_GAME";
export const UPDATE_CUSTOM_GAME = "UPDATE_CUSTOM_GAME";
export const DELETE_CUSTOM_GAME = "DELETE_CUSTOM_GAME";

//Group types
export const FETCH_GROUPS_ERROR = "FETCH_GROUPS_ERROR";
export const STORE_GROUPS = "STORE_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";

//Student types
export const CREATE_STUDENT = "CREATE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";

export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION";
