import * as types from "../types";

const initialState = {
  loading: false,
  error: false,
  fetchError: null,
  fetchGroupsError: null,
};

export const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_GROUPS:
      return { ...state, groups: action.payload };

    case types.CREATE_GROUP:
      return {
        ...state,
        groups: state.groups ? [...state.groups, action.payload] : null,
      };

    case types.UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g.id === action.payload.id ? { ...action.payload } : g
            )
          : null,
      };

    case types.DELETE_GROUP:
      return {
        ...state,
        groups: state.groups
          ? state.groups.filter((g) => g.id !== action.payload.id)
          : null,
      };

    case types.FETCH_GROUPS_ERROR:
      return { ...state, fetchGroupsError: action.payload };

    case types.CREATE_STUDENT:
      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g._id === action.payload.group
                ? { ...g, students: [...g.students, action.payload] }
                : g
            )
          : null,
      };

    case types.UPDATE_STUDENT:
      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g._id === action.payload.group
                ? {
                    ...g,
                    students: g.students.map((s) =>
                      s._id === action.payload._id ? { ...action.payload } : s
                    ),
                  }
                : g
            )
          : null,
      };

    case types.DELETE_STUDENT:
      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g._id === action.payload.group
                ? {
                    ...g,
                    students: g.students.filter(
                      (s) => s._id !== action.payload._id
                    ),
                  }
                : g
            )
          : null,
      };
    case types.SAVE_NOTIFICATION:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};
