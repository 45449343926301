import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//common
import Header from '../../Header';
import Top from './Top';
import Bottom from './Bottom';

import {getGamesUnregistered} from '../../../../redux/actions/gameAction';

const UnregisteredGameStore = (props) => {

  const dispatch = useDispatch();
  const game = useSelector(state => state.game);

  useEffect(()=>{
    if(!game.games){
      dispatch(getGamesUnregistered());
    }
  },[])


  const [filterGames, setFilterGames] = useState();

  const searchGames = (e) => {

    var xyz = game.games?.filter(game => game.name.toLowerCase().includes(e.toLowerCase()))
    setFilterGames(xyz)
  }

  const cancelSearchGames = (e) => {
    setFilterGames()
  }

  const [Subject, setSubjects] = useState("");

  const handleSubjects = (event) => {
    setSubjects(event.target.value);

    if (event.target.value == "All"){
      setFilterGames(game.game)
      return
    }

    var abc = game.games?.filter(game => game.subjects.map(subject => subject.toLowerCase()).includes(event.target.value))
    console.log(abc)
    setFilterGames(abc)
  }


  return (
    <div style={{padding : '50px', background: 'linear-gradient(180deg, #FDFDFE 0%, #F5F7FE 100%)'}}>

      <Header  search={searchGames} cancelSearch={cancelSearchGames} handleChange={handleSubjects} style={{marginTop: "-43px",marginLeft: "49%",minWidth: "20vw"}} subject={Subject}/>
      <Top  games={game.games || []}  {...props}/>
      <Bottom games={filterGames?.length > 0 ? filterGames : filterGames  ?  [] :  game.games} {...props}/>
      </div>
  );
}

export default UnregisteredGameStore;
