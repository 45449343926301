import React, { useEffect, useState, Fragment } from 'react'
import { Header, Top, Main, Footer } from '../../components/Home'
import { Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Grid from '@material-ui/core/Grid'
import Header1 from '../../components/Dashboard/Header'
import { Link } from 'react-router-dom'


const HelpCenter = () => {
  const Accordion = withStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      backgroundColor: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion)

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary)

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails)

  const [expanded, setExpanded] = useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <>
      <div className='help_desk'>
        <div className='page_title_holder'>
          {/* <h3 className='page_title'>Help Center</h3> */}
          <Header1 title="Help Center" />
        </div>
        <div className='page_sub_title'>
          <h5>Frequently asked questions</h5> 
          <p>Here are some of the frequently asked questions</p>
        </div>
        <div className='accordion_holder'>
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}>
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'>
              <h3>How do I sign up for Eduzo.Games for my Institution ?</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Kindly contact sales at +91 9600128710. We will arrange  for a demo session and we can explain the pricing plans 
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}>
            <AccordionSummary
              aria-controls='panel2d-content'
              id='panel2d-header'>
              <h3>How many games does the package contain? </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div>
              <p>
              We have 3 types of games.<br/><br/>
              Experience game: These games let the kids learn by experiencing as one among the characters in the game. They get to interact with the Virtual characters and learn along with them. We use Broadcast Augmented Reality technology to achieve the same. 
              <br/><br/>
              Customisable  game : These are game templates where one can create N number of games based on the curriculum requirement across subjects like math, science, geography, English, History. 
              <br/><br/>
              Concept game : These are games designed to teach concepts. For example Starting from the Walk in universe, kids will get to explore celestial bodies, then they'll learn about Stars and then Gravity Based on their knowledge graph. 
              <br/><br/>

              There are x number of games and each game has different levels. There are also templates using which 100’s of games can be instantly created according to the requirement. 

              </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}>
            <AccordionSummary
              aria-controls='panel3d-content'
              id='panel3d-header'>
              <h3>Can I have a demo before I purchase?</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
              Yes. We can have online or In-person demo based on your location. Kindly contact sales. 
              </p>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}>
            <AccordionSummary
              aria-controls='panel4d-content'
              id='panel3d-header'>
              <h3>How long is the subscription period?</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
              1 year
              </p>
            </AccordionDetails>
          </Accordion>

          <Accordion
            square
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}>
            <AccordionSummary
              aria-controls='panel4d-content'
              id='panel3d-header'>
              <h3>How is the content connected to Curriculum?</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>
              We have games connected to following curriculum CBSE,ICSE,IB,NGSS across grades 1,2,3,4
              </p>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className='contact_support'>
          <div className='page_sub_title'>
            <h5>Contact Support</h5>
          </div>
          <div className='contact_support_holder'>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <div className='contact_support_card'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/mail.svg`}/>
                  <div className='content'>
                    <p>
                      For any assistance or enquiry please contact us at{" "}
                      <a href='mailto:info@eduzo.com'>info@eduzo.com</a>
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} sm={3}>
                <div className='contact_support_card'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/call.svg`}/>
                  <div className='content'>
                    <p>
                      Call us at this tollfree number<br/>
                      <a style={{textDecoration: 'none'}} href='tel:9600128710'>+91 9600128710</a>
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} sm={3}>
                <div className='contact_support_card'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/bookmark.svg`}/>
                  <div className='content'>
                    <p>
                      Get familiar with the ar.labs<br/> products and their features
                      using<br/>
                      <a href='/commingsoon'>ar.labs docs</a> 
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} sm={3}>
                <div className='contact_support_card'>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/helpcenter/message.svg`}/>
                  <div className='content'>
                    <p>
                      Chat with us for anything<br/> immediate.{' '}
                      <a href='#'>Go to chat</a>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpCenter
