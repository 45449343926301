import * as types from '../types';


const initialState = {
    loading:false,
    error:false,
    fetchError: null
}

export const mainReducer = (state=initialState, action) => {

    switch(action.type){

        default:
            return state;
    }
}