import axios from "../axios";
import * as types from "../types";

import { API_URL } from "../../config";
import { getAxiosConfig, generateAxiosError } from "../../helpers";

export const getGroups = (institute_id) => async (dispatch) => {
  // console.log(institute_id)
  try {
    dispatch({
      type: types.FETCH_GROUPS_ERROR,
      payload: null,
    });
    if (institute_id) {
      const { data } = await axios.get(
        `${API_URL}/groups/institute/${institute_id}` /*,getAxiosConfig({token})*/
      );

      dispatch({
        type: types.STORE_GROUPS,
        payload: data,
      });
    }
  } catch (e) {
    dispatch({
      type: types.FETCH_GROUPS_ERROR,
      payload: generateAxiosError(e),
    });
  }
};

export const createGroup = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${API_URL}/groups`, inputs);

    dispatch({
      type: types.CREATE_GROUP,
      payload: data,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const updateGroup = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/groups/${inputs._id}`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.UPDATE_GROUP,
      payload: inputs,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const deleteGroup = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${API_URL}/groups/${inputs._id}`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.DELETE_GROUP,
      payload: inputs.id,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const createStudent = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/students`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.CREATE_STUDENT,
      payload: data,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const updateStudent = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/students/${inputs._id}`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.UPDATE_STUDENT,
      payload: inputs,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const deleteStudent = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${API_URL}/students/${inputs._id}` /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.DELETE_STUDENT,
      payload: inputs,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const getNotifications = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/get-notifications`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.SAVE_NOTIFICATION,
      payload: data,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const markNotificationRead = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/mark-notifications`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.SAVE_NOTIFICATION,
      payload: data,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};

export const markAllNotificationRead = (inputs) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/mark-all-notifications`,
      inputs /*,getAxiosConfig({token})*/
    );

    dispatch({
      type: types.SAVE_NOTIFICATION,
      payload: data,
    });
  } catch (e) {
    throw generateAxiosError(e);
  }
};
