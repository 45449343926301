import { Fragment, useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "../../config/firebase";

//material-ui lab imports
import Alert from "@material-ui/lab/Alert";

//common
import { authLogin } from "../../redux/actions/authAction";
import axios from "axios";
import { API_URL } from "../../config/index";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "100vh",
    position: "relative",
    "&>*": {
      width: "90%",
      margin: "0 auto",
    },
    "&>*:nth-child(1)": {
      transform: "translateY(36px)",
    },
  },
  left: {
    // [theme.breakpoints.down('sm')]: {
    //     display: 'none'
    // },
    margin: "36px 0",
    "& h1": {
      fontSize: "2.2rem",
      fontWeight: 500,
      margin: "36px 0 0 0",
      wordSpacing: 4,
    },
  },
  right: {
    padding: "24px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    padding: "24px 0",
  },
  formHeader: {
    "& h2": {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
    margin: "0 0 24px 0",
    "&>div": {
      display: "flex",
      alignItems: "center",
    },
  },
  formField: {
    display: "flex",
    flexDirection: "column",
    "&>*": {
      width: "100%",
      margin: "3px 0",
    },
  },
  formActions: {
    "&>*": {
      width: "100%",
      marginTop: 8,
    },
    "&>*:nth-child(2)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 12,
      "&>a": {
        textDecoration: "none",
        color: "#02113F",
      },
    },
  },
  bottom: {
    width: "90%",
    height: 280,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
    bottom: 0,
    left: 0,
    "&>img": {
      width: "100%",
      height: "130%",
    },
  },
}));

const Login = ({ authLogin, auth }) => {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [step, setStep] = useState(1);
  const [inputs, setInputs] = useState({
    phone_number: "",
    code: "",
    otp: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      window.location.href.includes("phone_number") &&
      window.location.href.split("=")[1].length == 10
    ) {
      phoneSignIn(window.location.href.split("=")[1]);
    }
  }, [window.location.href.split("=")[1]]);

  if (auth) {
    return <Redirect to="/labs/gamestore" />;
  }

  async function phoneSignIn(phone_number) {
    var number = phone_number ? phone_number : inputs.phone_number;

    await axios
      .get(`${API_URL}/instructors/phonenumber/${number}`)
      .then((response) => {
        setUserName(response.data.name);
      })
      .catch(() => {
        setUserName("");
      })
      .finally(() => {});
    const recaptcha = new firebase.auth.RecaptchaVerifier("send_otp", {
      size: "invisible",
    });
    const phoneNumber = `+91${number}`;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptcha)
      .then((confirmationResult) => {
        setStep(2);
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const verifyOtp = async () => {
    if (inputs.code === "" || inputs.otp === "") {
      alert("Please fill all the required details");
    }

    try {
      if (step === 2) {
        const confirmationResult = window.confirmationResult;

        let otp = inputs.otp;
        confirmationResult
          .confirm(otp)
          .then((result) => {
            const user = {
              phone_number: result.user.phoneNumber.substring(3),
              code: inputs.code,
            };
            authLogin(user);
          })
          .catch((error) => {
            alert("Otp not valid");
          });
      } else {
        history.push("/labs");
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const UnregisteredLogin = () => {
    if (inputs.otp === "") {
      alert("Please fill all the required details");
      return;
    }

    try {
      if (step === 2) {
        const confirmationResult = window.confirmationResult;

        let otp = inputs.otp;
        confirmationResult
          .confirm(otp)
          .then((result) => {
            localStorage.setItem(
              "unregistered_user_phone_number",
              inputs.phone_number
            );
            window.location = "/unregistered/";
          })
          .catch((error) => {
            alert("Otp not valid");
          });
      } else {
        history.push("/unregistered");
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className={classes.main}>
      <Grid container justify="space-between">
        <Grid item xs={12} md={7} className={classes.left}>
          <div>
            <div className={classes.logo}>
              {/* <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="eduzo"/> */}
              <img src={`${process.env.PUBLIC_URL}/logo4.png`} alt="eduzo" />
            </div>
            <Hidden smDown>
              <Typography variant="h1">
                Enable your students to learn through play.
              </Typography>
            </Hidden>
          </div>
        </Grid>
        <Grid item xs={12} md={5} className={classes.right}>
          {/* <form> */}
          <Card>
            <Grid
              container
              className={classes.form}
              spacing={1}
              justify="center"
            >
              <Grid item xs={10} className={classes.formHeader}>
                {step === 1 ? (
                  <Typography variant="h2" color="secondary">
                    Login to your account
                  </Typography>
                ) : (
                  <div>
                    <IconButton onClick={() => setStep(1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h2" color="secondary">
                      Hi {userName} :D
                    </Typography>
                  </div>
                )}
              </Grid>

              {step === 1 ? (
                <Fragment>
                  <Grid item xs={10} className={classes.formField}>
                    <label htmlFor="phone_number">Enter Phone Number</label>
                    <TextField
                      variant="outlined"
                      value={inputs.phone_number}
                      onChange={handleChange}
                      InputProps={{ name: "phone_number", id: "phone_number" }}
                      placeholder="Eg: +91 99999 99999"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {error && <Alert severity="error">{error}</Alert>}
                  </Grid>

                  <Grid item xs={10} className={classes.formActions}>
                    <Button
                      type="submit"
                      color="primary"
                      id="send_otp"
                      onClick={() => phoneSignIn()}
                      variant="contained"
                      disabled={loading}
                    >
                      Send OTP
                    </Button>
                    <div>
                      {/* <a href="#">Contact Sales</a> */}
                      <a
                        style={{ textDecoration: "none", color: "#02113F" }}
                        target="_blank"
                        href="https://www.termsandconditionsgenerator.com/live.php?token=CxyStIiPhNQiGbwFHTUMbW9qbC6Paqvc"
                      >
                        <span
                          style={{ textDecoration: "none", color: "#02113F" }}
                        >
                          Terms & Conditions
                        </span>
                      </a>
                    </div>
                  </Grid>
                </Fragment>
              ) : (
                <>
                  {userName === "" ? (
                    <Fragment>
                      <Grid item xs={10} className={classes.formField}>
                        <label htmlFor="otp">
                          Enter OTP sent to {inputs.phone_number}
                        </label>
                        <TextField
                          variant="outlined"
                          value={inputs.otp}
                          onChange={handleChange}
                          InputProps={{ name: "otp", id: "otp" }}
                          placeholder="000000"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {error && <Alert severity="error">{error}</Alert>}
                      </Grid>

                      <Grid item xs={10} className={classes.formActions}>
                        <Button
                          type="submit"
                          color="primary"
                          id="login"
                          onClick={UnregisteredLogin}
                          variant="contained"
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={20} /> : "Login"}
                        </Button>
                        <div>
                          {/* <a href="#">Contact Sales</a> */}
                          <a
                            style={{ textDecoration: "none", color: "#02113F" }}
                            target="_blank"
                            href="https://www.termsandconditionsgenerator.com/live.php?token=CxyStIiPhNQiGbwFHTUMbW9qbC6Paqvc"
                          >
                            <span
                              style={{
                                textDecoration: "none",
                                color: "#02113F",
                              }}
                            >
                              {" "}
                              Terms & Conditions{" "}
                            </span>
                          </a>
                        </div>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item xs={10} className={classes.formField}>
                        <label htmlFor="code">Enter School Code</label>
                        <TextField
                          variant="outlined"
                          value={inputs.code}
                          onChange={handleChange}
                          InputProps={{ name: "code", id: "code" }}
                          placeholder="ABS1029"
                        />
                      </Grid>

                      <Grid item xs={10} className={classes.formField}>
                        <label htmlFor="otp">
                          Enter OTP sent to {inputs.phone_number}
                        </label>
                        <TextField
                          variant="outlined"
                          value={inputs.otp}
                          onChange={handleChange}
                          InputProps={{ name: "otp", id: "otp" }}
                          placeholder="000000"
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {error && <Alert severity="error">{error}</Alert>}
                      </Grid>

                      <Grid item xs={10} className={classes.formActions}>
                        <Button
                          type="submit"
                          color="primary"
                          id="login"
                          onClick={verifyOtp}
                          variant="contained"
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={20} /> : "Login"}
                        </Button>
                        <div>
                          {/* <a href="#">Contact Sales</a> */}
                          <a
                            style={{ textDecoration: "none", color: "#02113F" }}
                            target="_blank"
                            href="https://www.termsandconditionsgenerator.com/live.php?token=CxyStIiPhNQiGbwFHTUMbW9qbC6Paqvc"
                          >
                            <span
                              style={{
                                textDecoration: "none",
                                color: "#02113F",
                              }}
                            >
                              {" "}
                              Terms & Conditions
                            </span>
                          </a>
                        </div>
                      </Grid>
                    </Fragment>
                  )}
                </>
              )}

              {/* <Grid item xs={8} className={classes.formHelpers}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Remember me"
                                    />
                                    <Link to="/">Forgot password?</Link>
                                </Grid> */}

              {/* <Grid item xs={10}>
                                    {error && <Alert severity="error">{error}</Alert>}
                                </Grid>
                                        
                                <Grid item xs={10} className={classes.formActions}>
                                    <Button type="submit" color="primary" id='login' variant="contained" disabled={loading}>
                                        {loading ? <CircularProgress size={20}/> : 'Login'}
                                    </Button>
                                    <div>
                                        <a href="#">Contact Sales</a>
                                        <a href="#">Terms & Conditions</a>
                                    </div>
                                </Grid> */}
            </Grid>
          </Card>
          {/* </form> */}
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>

      <Hidden smDown>
        <div className={classes.bottom}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/login/shapes.svg`}
            alt="shapes"
          />
        </div>
      </Hidden>
    </main>
  );
};

Login.propTypes = {
  authLogin: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { authLogin })(Login);

// import {useState} from 'react';
// import {Link, useHistory, Redirect} from 'react-router-dom';
// import {useDispatch} from 'react-redux';
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

// //material-ui core imports
// import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import Hidden from '@material-ui/core/Hidden';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import Card from '@material-ui/core/Card';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import IconButton from '@material-ui/core/IconButton';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import CircularProgress from '@material-ui/core/CircularProgress';

// //material-ui lab imports
// import Alert from '@material-ui/lab/Alert';

// //common
// import {authLogin, authLoginEmail} from '../../redux/actions/authAction';
// import axios from 'axios';
// import {API_URL} from '../../config/index';

// const useStyles = makeStyles(theme => ({
//     main: {
//         minHeight: '100vh',
//         position: 'relative',
//         '&>*':{
//             width: '90%',
//             margin: '0 auto'
//         },
//         '&>*:nth-child(1)':{
//             transform: 'translateY(36px)'
//         }
//     },
//     left: {
//         // [theme.breakpoints.down('sm')]: {
//         //     display: 'none'
//         // },
//         margin: '36px 0',
//         '& h1':{
//             fontSize: '2.2rem',
//             fontWeight: 500,
//             margin: '36px 0 0 0',
//             wordSpacing: 4
//         }
//     },
//     right: {
//         padding: '24px 0',
//         [theme.breakpoints.down('sm')]: {
//             width: '100%'
//         },
//     },
//     form:{
//         padding: '24px 0'
//     },
//     formHeader: {
//         '& h2':{
//             fontSize: '1.4rem',
//             fontWeight: 500
//         },
//         margin: '0 0 24px 0',
//         '&>div':{
//             display: 'flex',
//             alignItems: 'center',
//         }
//     },
//     formField: {
//         display: 'flex',
//         flexDirection: 'column',
//         '&>*':{
//             width: '100%',
//             margin: '3px 0'
//         }
//     },
//     formActions: {
//         '&>*':{
//             width: '100%',
//             marginTop: 8,
//         },
//         '&>*:nth-child(2)':{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             marginTop: 12,
//             '&>a':{
//                 textDecoration: 'none',
//                 color: '#02113F'

//             }
//         }
//     },
//     bottom: {
//         width:'90%',
//         height: 280,
//         overflow: 'hidden',
//         position: 'absolute',
//         zIndex: -1,
//         bottom:0,
//         left:0,
//         '&>img':{
//             width: '100%',
//             height: '130%'
//         }
//     }
// }));

// const Login = ({ authLogin, auth, authLoginEmail }) => {

//     const classes = useStyles();
//     const history = useHistory();

//     // const dispatch = useDispatch();

//     const [userName, setUserName] = useState();
//     // const [U, setUserName] = useState();
//     const [userError, setUserError] = useState();
//     const [step, setStep] = useState(1);
//     const [inputs, setInputs] = useState({
//         phone_number: '',
//         code: '',
//         password : "",
//         // otp: ''
//     });
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const handleChange = (e) => {
//         setInputs({...inputs, [e.target.name]:e.target.value});

//     }

//     if (auth) {
//         return <Redirect to="/labs" />;
//       }

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         await axios.get(`${API_URL}/instructors/phonenumber/${inputs.phone_number}`)
//         .then(response => {
//             // console.log(userName)
//             // console.log(response.data)
//           setUserName(response.data.name);

//         })
//         .catch(() => {
//             setUserName('')
//             setStep(1)
//             alert("No user found with this contact number");
//              window.location.reload()

//         })
//         .finally(() => {
//         //   setIsFetching(false)
//         })

//         if (inputs.code === "" || inputs.password === ""){
//             setStep(2)
//             return
//         }

//         try{
//             setError(null);
//             setLoading(true);
//             console.log(inputs)
//             if(step===2) {
//                 // console.log("checking")
//             authLoginEmail(inputs)
//             }
//             else{
//                 history.push("/labs")
//             }
//         }
//         catch(e){
//             // alert('Password not correct')
//             setError(e);
//         }
//         finally{
//             setLoading(false);
//         }
//         //history.push('/students');
//     }

//     return (
//         <main className={classes.main}>
//             <Grid container justify="space-between">
//                 <Grid item xs={12} md={7} className={classes.left}>
//                     <div>
//                         <div className={classes.logo}>
//                             <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="eduzo"/>
//                         </div>
//                         <Hidden smDown>
//                             <Typography variant="h1">For every student, every class room. Real results.</Typography>
//                         </Hidden>
//                     </div>
//                 </Grid>
//                 <Grid item xs={12} md={5} className={classes.right}>
//                     <form onSubmit={handleSubmit} method="post">
//                         <Card>
//                             <Grid container className={classes.form} spacing={1} justify="center">
//                                 <Grid item xs={10} className={classes.formHeader}>
//                                     {step===1 ?
//                                         <Typography variant="h2" color="secondary">
//                                             Login to your account
//                                         </Typography>
//                                     :
//                                     <div>
//                                         <IconButton onClick={()=>setStep(1)}><ArrowBackIcon/></IconButton>
//                                         <Typography variant="h2" color="secondary">
//                                             Hi {userName} :D
//                                         </Typography>
//                                     </div>
//                                     }
//                                 </Grid>

//                                 {step===1 ?
//                                     <Grid item xs={10} className={classes.formField}>
//                                         <label htmlFor="phone_number">Enter Phone Number</label>
//                                         <TextField variant='outlined' type='number' value={inputs.phone_number} onChange={handleChange} InputProps={{name:'phone_number', id:'phone_number', maxLength:6}} placeholder='Eg: +91 99999 99999' inputProps = {{maxLength:10}}/>
//                                     </Grid>
//                                 :
//                                 <>
//                                     <Grid item xs={10} className={classes.formField}>
//                                         <label htmlFor="code">Enter School Code</label>
//                                         <TextField variant='outlined' value={inputs.code} onChange={handleChange} InputProps={{name:'code', id:'code'}} placeholder='ABS1029'/>
//                                     </Grid>
//                                     {/* <Grid item xs={10} className={classes.formField}>
//                                         <label htmlFor="otp">Enter OTP sent to xxx9230</label>
//                                         <TextField variant='outlined' value={inputs.otp} onChange={handleChange} InputProps={{name:'otp', id:'otp'}} placeholder='0000' type="number"/>
//                                     </Grid> */}
//                                     <Grid item xs={10} className={classes.formField}>
//                                         <label htmlFor="password">Enter your password</label>
//                                         <TextField variant='outlined' value={inputs.password} onChange={handleChange} InputProps={{name:'password', id:'password'}} placeholder='password'/>
//                                     </Grid>
//                                 </>
//                                 }

//                                 {/* <Grid item xs={8} className={classes.formHelpers}>
//                                     <FormControlLabel
//                                         control={<Checkbox />}
//                                         label="Remember me"
//                                     />
//                                     <Link to="/">Forgot password?</Link>
//                                 </Grid> */}

//                                 <Grid item xs={10}>
//                                     {error && <Alert severity="error">{error}</Alert>}
//                                 </Grid>

//                                 <Grid item xs={10} className={classes.formActions}>
//                                     <Button type="submit" color="primary" variant="contained" disabled={loading}>
//                                         {loading ? <CircularProgress size={20}/> : 'Login'}
//                                     </Button>
//                                     <div>
//                                         <a href="#">Contact Sales</a>
//                                         <a href="#">Terms & Conditions</a>
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </Card>
//                     </form>
//                 </Grid>

//                 <Grid item xs={12}></Grid>

//             </Grid>

//             <Hidden smDown>
//                 <div className={classes.bottom}>
//                     <img src={`${process.env.PUBLIC_URL}/assets/images/login/shapes.svg`} alt="shapes"/>
//                 </div>
//             </Hidden>
//         </main>
//     );
// };

// Login.propTypes = {
//     authLogin: PropTypes.func.isRequired,
//     authLoginEmail : PropTypes.func.isRequired,
//     // isAuthenticated: PropTypes.bool,
//   };

//   const mapStateToProps = (state) => ({
//     auth: state.auth.isAuthenticated,
//   });

// export default connect(mapStateToProps, { authLogin, authLoginEmail })(Login);
