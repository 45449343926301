import {useState} from 'react';
import {Link} from 'react-router-dom';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

//material-ui lab imports
import Skeleton from '@material-ui/lab/Skeleton';

//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '24px 0',
        '& h5':{
            fontWeight: 500
        }
    },
    divider: {
        margin: '24px 0'
    },
    chip: {
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '&>*':{
            marginTop: 8
        },
        '&>*:nth-child(2)':{
            display: 'flex',
            alignItems: 'center',
            '&>*':{
                margin: '0 4px'
            }
        }
    },
    gameImage: {
        '&>img':{
            width: '100%'
        }
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'start',
        '&>*:nth-child(1)':{
            textDecoration: 'none',
            color: '#000'
        },
        '&>*:nth-child(2)':{
            marginLeft: 8
        }
    },
}));

const GameDetailBottom = ({match, similarGames}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
        <div className={classes.top}>
            <Typography variant="h5">Similar apps you might like</Typography>
        </div>

        <Divider className={classes.divider}/>

        <div className={classes.bottom}>
            <Grid container spacing={3}>
                {similarGames.map(g =>
                    <Grid key={g._id} item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} className={classes.gameImage}>
                                <img src={g.image} alt="game"/>
                            </Grid>
                            <Grid item xs={9}>
                                <div className={classes.gameTitle}>
                                    <Link to={`/labs/gamestore/${g._id}`}><Typography variant="h6">{g.name}</Typography></Link>
                                    <Chip color="primary" label="English" className={classes.chip}/>
                                </div>
                                <Typography variant="body1">
                                    {g.short_description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    </div>
  );
}

export default GameDetailBottom;
