import {useState} from 'react';
import ReactPlayer from 'react-player/file';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MobileStepper from '@material-ui/core/MobileStepper';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MenuItem from '@material-ui/core/MenuItem';

//common

const useStyles = makeStyles(theme => ({
    main: {
    },
    banner: {
        height: '86vh',
        background: '#ebebeb',
        width: '100%',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/homepage/top.svg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&>h4':{
            width:'90%',
            fontWeight:500,
            textAlign: 'center',
            letterSpacing: "0.6px"
        },
        '&>*':{
            margin: '16px 0'
        },
        '& a':{
            textDecoration: 'none'
        }, 
        '& button':{
            padding: '10px 25px',
            fontSize: '17px',
            textTransform: 'none'
        }, 
       
    },
    inputTypeNumber :{
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        fontSize: '20px'
    },
    dot : {
        position: 'absolute',
        background: '#CEC9FF !important',
        width : '24px',
        height : '24px',
        borderRadius : '12px',
        marginLeft : '-90vw',
        marginTop : '-70vh'
    },

    content: {
        padding: '64px 0',
        minHeight: '80vh',
        '& h6':{
            fontFamily : 'Manrope, sans-serif',
            fontSize : '32px !important',
            fontWeight:600,
            letterSpacing : '0.5px',
            color: '#4E4B69',
        },
        '&>*:not(:first-child)':{
            marginTop: 36,
            '&>p':{
                marginTop: 12
            }
        },
        '&>*:nth-child(3)':{
            '&>p':{
                transform: 'translateY(-22px)'
            }
        },
    },
    
    getAccessInput: {
        padding: '24px 0',        
        '&>div':{
            position: 'relative',

            width: 'min(480px, 90%)',
            '&>*:nth-child(1)':{
                height: '6vh',
                padding: '0px 0px 0px 15px',
                width: '100%',
                opacity: 0.5,
                border: '1px solid #8A90A3',
                borderRadius : '12px'
            },
            '&>*:nth-child(2)':{
                position: 'absolute',
                top: '50%',
                padding : '4px  15px',
                borderRadius : '6px',
                fontSize : '16px',
                textTransform: 'none',
                // transform: '',
                transform: 'translateY(-50%) translateX(15%)',
                right: 8 
            }
        }
    },


    body1Text: {
        fontSize:'20px',
        fontFamily : 'Manrope, sans-serif',
    },

    formControl: {
        width: '100%',
        '&>*:nth-child(2)':{
            width: '100%',
            marginTop: '8px'
        }
    },
    registerModalTitle: {
        textAlign: 'center'
    },
    registerForm:{
        margin: '24px 0',
    },
    registerFormActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
        '&>*': {
            margin: '0 18px'
        }
    },
    registerFormFooter: {
        marginTop: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stepper: {
        margin: '6px auto'
    },
    thanksView: {
        textAlign: 'center',
        margin: '24px 0',
        '&>h5':{
            fontWeight: 500,
        }
    }
}));

const Home = ({  }) => {

    const classes = useStyles();

    const [registerModal, setRegisterModal] = useState(false);
    const [registerStep, setRegisterStep] = useState(0);

    const handleRegisterCancel = () => {
        setRegisterModal(false);
        setRegisterStep(0);
    }

    const Login =()=> {
        var phone_number = document.getElementById('phone_number').value;

        if( phone_number.length != 10 ) {
            alert('Please enter a valid 10 digit indian phone number')
            return;
        }

        window.location.href = `/Login?phone_number=${phone_number}`;
    }

    const handleRegister = (e) => {
        e.preventDefault();
        if(registerStep===0){
            setRegisterStep(1);
            return;
        }
        else if(registerStep===1){
            setRegisterStep(2);
            return;
        }
        else{
            setRegisterModal(false);
        }
    }

    return (
        <main className={classes.main}>
            <div className={classes.banner}>
                <span className={classes.dot}></span>
                <Typography variant="h4">World's first fully interactive<br/>immersive lab :D</Typography>
                <a href="#knowmore">
                    <Button color="primary" variant="contained" elevation={0}>Know more about Eduzo</Button>
                </a>
                {/* <ReactPlayer 
                    url='https://test-videos.co.uk/vids/bigbuckbunny/webm/vp8/360/Big_Buck_Bunny_360_10s_1MB.webm' 
                    controls={true} 
                    light={true}
                    width='100%'
                    height='100%'
                /> */}
            </div>
            <Grid container className={classes.content} id="knowmore" align="center" justify="space-around">
                {/* <Hidden mdDown>
                    <Grid item xs={12} md={6}>
                        
                    </Grid>
                </Hidden> */}

                <Grid item xs={12}>
                    <Typography variant="h6">
                        Immersive content delivered with no device<br/>in the hand of kids
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/molecule.svg`} alt="customizable games"/>
                    <Typography variant="body1" className={classes.body1Text}>
                        <span style={{color:'#E93480'}}>Fully customizable</span><br/>games by the teachers
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/infinity.svg`} alt="unlimited gameplays"/>
                    <Typography variant="body1" className={classes.body1Text}>
                        Create <span style={{color:'var(--primary)'}}>unlimited</span><br/>gameplays
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/puzzle.svg`} alt="one play"/>
                    <Typography variant="body1" className={classes.body1Text}>
                        Your <span style={{color:'#E93480'}}>entire team</span> can join for<br/>the purchase of one plan
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.getAccessInput}>
                    <div>
                        <input variant='outlined' placeholder='Enter phone number' className={classes.inputTypeNumber} type="number" id='phone_number'/>
                        <Button variant='contained' color='primary' onClick={()=>Login()} >Get Access</Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{backgroundColor : "#F6F5FF", Height : '2vh', Width : '100vw', color : "#F6F5FF"}}>{" d"}</div>

            <Dialog 
                onClose={handleRegisterCancel}
                aria-labelledby="register-dialog" 
                open={registerModal}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <Typography variant="h6" className={classes.registerModalTitle}>{registerStep===0 ? 'Tell us about yourselves' : registerStep===1 ? 'Almost there' : ''}</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleRegister} className={classes.registerForm}>
                        {registerStep === 0 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <label htmlFor="country">You are from</label>
                                        <Select
                                            variant='outlined'
                                            id="country"
                                        >
                                            <MenuItem value={10}>India</MenuItem>
                                            <MenuItem value={20}>England</MenuItem>
                                            <MenuItem value={30}>China</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <label htmlFor="profession">You are a...</label>
                                        <Select
                                            variant='outlined'
                                            id="profession"
                                        >
                                            <MenuItem value={10}>Student</MenuItem>
                                            <MenuItem value={20}>Teacher</MenuItem>
                                            <MenuItem value={30}>Parent</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <label htmlFor="instituteName">Enter your institute name</label>
                                        <TextField variant='outlined' id="instituteName"/>
                                    </div>
                                </Grid>
                            </Grid>
                        :registerStep===1 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <label htmlFor="phNumber">Your phone number</label>
                                        <TextField variant='outlined' id="phNumber" type="number"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                            />
                                        }
                                        label="I would like to get more info about this product"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                            />
                                        }
                                        label="I would like to view the package details"
                                    />
                                </Grid>
                                
                            </Grid>
                        :
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.thanksView}>
                                    <DoneAllIcon/>
                                </Grid>
                                <Grid item xs={12} className={classes.thanksView}>
                                    <Typography variant="h5">Thank you for your interest!</Typography>
                                    <Typography variant="h5">We will get back to you shortly</Typography>
                                </Grid>
                            </Grid>
                        }
                        {registerStep<2 && <div className={classes.registerFormFooter}>
                            <MobileStepper
                                variant="dots"
                                color="secondary"
                                steps={2}
                                position="static"
                                activeStep={registerStep}
                            />
                        </div>}
                        <div className={classes.registerFormActions}>
                            {registerStep === 1 && <Button onClick={()=>setRegisterStep(0)} variant='contained'>Go back</Button>}
                            <Button type="submit" variant='contained' color='secondary'>{registerStep === 0 ? 'Next' : registerStep===1 ? 'Submit' : 'Close'}</Button>
                        </div>
                        {registerStep<2 && <div className={classes.registerFormFooter}>
                            <a href="#">Already a member?</a>
                        </div>}
                    </form>
                </DialogContent>
            </Dialog>
        </main>
        
    );
};

export default Home;
