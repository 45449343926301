

export const formatDate = (date) => {
  if(date){
    date = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  }
  else{
      return "N/A"
  }
}

export const formatDateAndTime = (date) => {
  if(date){
    date = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  }
  else{
      return "N/A"
  }
}

// export const getAxiosConfig = ({contentType, token}) => {
//   if(token && contentType){
//     return {
//       headers:{
//         'Authorization': `Basic ${token}`,
//       },
//       'Content-Type': contentType
//     }
//   }
//   else if(token){
//     return {
//       headers:{
//         'Authorization': `Basic ${token}`,
//       },
//     }
//   }
//   else{
//     return {
//       'Content-Type': contentType
//     }
//   }
// }

export const validateErrors = async (errors, setErrors) => {
  let hasError = false;
  for(const key of Object.keys(errors)){
      if(errors[key].error !== null) hasError=true;
      else if(!errors[key].touched){
          await setErrors(prev=>({...prev, [key]:{touched:true, error: 'This field is required'}}))
          hasError = true;
      }
  }
  return hasError;
}

export const sortData = (array, param) => {
  if(param==='none' || !param) return array;
  const [parameter, type] = param.split('-');
  return array.sort((a,b) => {
    let param1 = a[parameter], param2 = b[parameter];
    if(typeof a[parameter] === 'string'){
      param1 = a[parameter].toLowerCase();
      param2 = b[parameter].toLowerCase();
    }
    if(type==='asc'){
      return param2 < param1 ? 1 : -1;
    }
    else{
      return param2 > param1 ? 1 : -1;
    }
  })
}

export const generateAxiosError = (err) => {
  if(err.response){
    return err.response.data || err.response
  }
  else{
    return err.message || err
  }
}

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

// Capitalize
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

