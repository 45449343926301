import {useState, Fragment} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
// import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropzoneArea } from 'material-ui-dropzone';
import { API_URL } from '../../../../../config';
import axios from 'axios';
import InputAdornment from '@material-ui/core/InputAdornment';
import timer_icon from '../../../Icons/timer.png'
// import './createform.css'

//material-ui lab imports
// import Autocomplete from '@material-ui/lab/Autocomplete';

//material-ui icon imports
import AddIcon from '@material-ui/icons/Add';
import { createCustomGame1 } from '../../../../../redux/actions/gameAction'
import ChipInput from 'material-ui-chip-input'

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';


//common

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '12px 0',
        '& h5':{
            fontWeight: 500
        },
    },
    divider: {
        margin: '24px 0'
    },
    num: {
        alignItems:"center",
    },
    chip: {
        marginTop : '0.2vh',
        textTransform: 'capitalize',
        minHeight : '28px !important',
        minWidth : '62px',
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    button: {
        textTransform: 'initial'
    },
    textField: {
        width: '100%',
        paddingTop: 20
    },
    
    submitButton: {
        textTransform: 'initial',
    },
    gameTitlePreview: {
        '&>div':{
            display: 'flex',
            alignItems: 'center',
            '& h5':{
                fontSize: '1.4rem',
                color : '#02113F',
                margin: '0 8px 4px 0',
                fontWeight: 500,
                fontSize : '24px',
                textTransform : 'capitalize'
            },
            '& p':{
                fontSize: '0.9rem',
                marginTop: 4,
                color : '#02113F',
                opacity: 0.6,
                fontSize: '16px',
                lineHeight: '20px'
            },
            marginBottom: 6,
        }
    },
    gamePropertiesPreview: {
        // marginBottom: 8,
        // '&>*':{
        //     textAlign: 'center',
        //     '&>h6':{
        //         fontSize: '1.1rem',
        //         fontWeight: 400
        //     },
        //     '&>*:nth-child(2)':{
        //         fontSize: '1rem',
        //         marginTop: 12,
        //         fontWeight: 600
        //     }
        // },
        // '&>*:not(:last-child)': {
        //     borderRight: '1px solid #ddd'
        // },
        marginBottom: 8,
        '&>*':{
            textAlign: 'center',
            '&>h6':{
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '18px',
                color: '#02113F',
                opacity: 0.6,
            },
            '&>*:nth-child(2)':{
                fontSize: '1rem',
                marginTop: 16,
                fontWeight: 500,
                color: '#02113F'
            }
        },
        '&>*:not(:last-child)': {
            borderRight: '1px solid #ddd'
        },
    },
    gameDetailsPreview: {
        marginTop: 24,
        '&>*':{
            margin: '8px 0',
            '&>*:nth-child(1)':{
                color: '#02113F',
                fontWeight: 400,
                opacity : 1,
                '&>*:nth-child(1)':{
                    '&>p': {
                        
                        fontWeight: 400,
                        opacity : 0.7,
                        lineHeight :'20px'
                    }
                }
            }
        }
    },
    formActions: {
        '&>*:not(:first-child)':{
            marginLeft: 12
        }
    },
    active: {
        color: 'blue'
    }
}));


function getStepContent(step) {
    switch (step) {
        case 0:
            return `The fundamental or essential information about the game topic.`;
        case 1:
            return 'The information specific to game such as subject and level.';
        case 2:
            return `The information specific to question, right and wrong answers.`;
        case 3:
            return 'Verify the information entered in this form to ensure its completeness before submitting it.';
        default:
            return 'Unknown step';
    }
}


const CreateCustomGameForm = ({match, game, groups, createCustomGame, closeModal}) => {

//   console.log(game)
  const classes = useStyles();

  const [steps] = useState(game.customizable !== 'Zoo' ? ['Basic Details', 'Game Details', 'Questions & Answers', 'Review'] : ['Basic Details', 'Game Details', 'Review'])
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState( game.customizable != 'Zoo' ? {
      institute:localStorage.getItem('institute'),
      name: '',
      outcomes: '',
      answers: [],
      subjects: [],
      groups: [],
      grades : [],
      category: '',
      difficulty_level: '',
      timer : '5',
      questions: [{
          id: 1,
          question: '',
          question_type: 'text',
          link : "",
          missing : [],
          right_answers: [],
          wrong_answers: []
      }]
  } : 
  {
    institute:localStorage.getItem('institute'),
    name: '',
    outcomes: '',
    answers: [],
    subjects: [],
    groups: [],
    grades : [],
    category: '',
    difficulty_level: '',
    timer : ''
  })


  var colors = ['#FFBFBF', '#BFE0FF' , '#F6BFFF'];

  var animals = [
                {name : "Dog", time : "2"},
                {name :"Cat" , time: "3"},
                {name :"Horse", time : "4"},
                {name :"Sheep" , time: "5"},
                {name :"Camel" , time: "6"},
                {name :"Elephant" , time: "7"},
                {name :"Cow" , time: "8"},
                {name :"Cheetah" , time: "9"},
                {name :"Lion" , time: "10"},
    ]

  const handleAddChips = (e, target) => {
      var array;
      if (target === 'subjects'){
          array = inputs.subjects
      } else if (target === 'grades'){
        array = inputs.grades
    }
    else if (target === 'answers'){
        setInputs({ ...inputs, [target] : e.map(x => {return x.name}) })
        return

    }
      setInputs({ ...inputs, [target] : [...array, e] })
  }

  const handleDeleteChips = (e, target) => {
    var array;
    if (target === 'subjects'){
        array = inputs.subjects
    } else if (target === 'grades'){
        array = inputs.grades
    }
    else if (target === 'answers'){
        array = inputs.answers
    }
    setInputs({ ...inputs, [target] : array.filter(value  => value != e) })
  }


const addWrongAnswers = (e) => {
    if (inputs.category == 'Numbers'){
        if (isNaN(e) == true){
            return
        }
    } 
    
    if (inputs.category == 'Alphabets'){
        if (isNaN(e) != true){
            return
        }
    }

    // console.log(inputs)
    setInputs({ ...inputs, ['questions']: inputs?.questions.map(x => { return {...x, wrong_answers : [...x.wrong_answers, e]}}) })
}
const deleteWrongAnswers = (e) => {
    console.log(inputs)
    setInputs({ ...inputs, ['questions']: inputs.questions.map(x => { return {...x, wrong_answers : x.wrong_answers.filter(value  => value != e)}}) })
}

const addRightAnswers = (e) => {
    if (inputs.category == 'Numbers'){
        if (isNaN(e) == true){
            return
        }
    } 
    
    if (inputs.category == 'Alphabets'){
        if (isNaN(e) != true){
            return
        }
    }

    // console.log(inputs)
    // setInputs({ ...inputs, ['questions']: inputs?.questions.map(x => { return {...x, right_answers : [ e]}}) })
    setInputs({ ...inputs, ['questions']: inputs?.questions.map(x => { return {...x, right_answers : [...x.right_answers, e]}}) })
}
const deleteRightAnswers = (e) => {
    console.log(inputs)
    setInputs({ ...inputs, ['questions']: inputs.questions.map(x => { return {...x, right_answers : x.right_answers.filter(value  => value != e)}}) })
}

const handleChange = (e) => {
    const value = e.target.value;
    if(typeof value==='object' && e.target.name!=='category'){
        setInputs({...inputs, [e.target.name]: [...value]})
    }
    else{
    setInputs({...inputs, [e.target.name]: value})
    }
}

const [qnumber, setQnumber] = useState(2)


const handleAddQuestion = () => {
    setQnumber(qnumber+1)
setInputs({
    ...inputs, 
    questions: [...inputs.questions, {
        id: qnumber,
        question: '',
        link : '',
        question_type: 'text',
        missing : [],
        right_answers: []
    }]
})
}

const handleChangeQuestion = (e, id) => {
    const value = e.target.value;
    
    setInputs({
        ...inputs, 
        questions: inputs.questions.map(q => q.id===id ? {
            ...q,
            [e.target.name] : value
        } : q)
    });
}

const singleFileChangedHandler =(files,id) => {
    let data = new FormData();
    data.append("image", files[0]);
    axios.post(`${API_URL}/custom-games/upload`, data).then((response) => {
        console.log(response);
        setInputs({
            ...inputs, 
            questions: inputs.questions.map(q => q.id===id ? {
                ...q,
                'link' : response.data
                // 'link' : files[0]

            } : q)
        });
    })
}

const addRightAnswers_multiple = (e, id) => {

    if (inputs.category == 'Numbers'){
        if (isNaN(e) == true){
            return
        }
    } 
    
    if (inputs.category == 'Alphabets'){
        if (isNaN(e) != true){
            return
        }
    }

    const value = e.substring(0,8)
    setInputs({
        ...inputs, 
        questions: inputs.questions.map(q => q.id===id ? {
            ...q,
            'right_answers' : [...q.right_answers , value]
        } : q)
    });
}

const deleteRightAnswers_multiple = (e, id) => {
    const value = e
    setInputs({
        ...inputs, 
        questions: inputs.questions.map(q => q.id===id ? {
            ...q,
            'right_answers' : q.right_answers.filter(x => x != value)
        } : q)
    });
}

const addMissing = (e, id) => {
    if(isNaN(e) == true){
        return
    }
    const value = e
    // console.log(inputs)
    setInputs({
        ...inputs, 
        questions: inputs.questions.map(q => q.id===id ? {
            ...q,
            'missing' : [...q.missing , value]
        } : q)
    });
}

const deleteMissing = (e, id) => {
    const value = e
    setInputs({
        ...inputs, 
        questions: inputs.questions.map(q => q.id===id ? {
            ...q,
            'missing' : q.missing.filter(x => x != value)
        } : q)
    });
}


const handleNext = async (e) => {
    e.preventDefault();
    console.log(inputs)

    if (game.customizable == 'Zoo'){
    if (inputs.answers.length > 0) {
        var total = 0;
        for (var i =0 ; i<animals.length ; i++){
            // total += parseInt(inputs.answers[i].time)
            if (inputs.answers.includes(animals[i].name)){
                total += parseInt(animals[i].time)
            }
        }
        console.log(total)
        setInputs({...inputs, 'timer' : total})
    }
    }

    if (game.customizable != 'Zoo'){

        var question_time = inputs.difficulty_level == "Easy" ? 2.5 : inputs.difficulty_level == "Medium" ? 2: 1 ;

        if (inputs.questions.length > 0) {
                var total = parseInt(inputs.questions.length) * question_time
            console.log(total)
    
            setInputs({...inputs, 'timer' : total})
        }
        }

        // if (game.customizable == 'Catcher'){

        //     // var question_time = inputs.difficulty_level == "Easy" ? 2.5 : inputs.difficulty_level == "Medium" ? 2: 1 ;
        //     // const value = e.substring(0,8)
        //     setInputs({
        //         ...inputs, 
        //         questions: inputs.questions.map(q => q.right_answers ? {
        //             ...q,
        //             'right_answers' : stringConvert(q.right_answers)
        //         } : q)
        //     });

        //     const stringConvert = (r) =>{
        //         let convert = r.join(",")
        //         return convert;
        //     }
        // }
 


    try{
        if(activeStep+1 === steps.length) {
            
            setLoading(true);
            await createCustomGame({...inputs, groups: inputs.groups.map(g => g._id), game:match.params.id});
            setLoading(false);
            closeModal();
            return;
        };
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    catch(e){
        alert(e);
    }
    finally{
        setLoading(false);
    }
};

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return(
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <Stepper activeStep={activeStep} orientation="vertical">
                {steps && steps.map((label, index) => (
                    <Step key={label} expanded={true} classes={{ active: classes.active }}>
                        <StepLabel  StepIconProps={{
                            classes: {
                                active: classes.active,
                                text:   classes.text,
                            }
                        }}>{label}</StepLabel>
                        <StepContent>
                                <Typography  className={classes.num}>{getStepContent(index)}</Typography>
                        </StepContent>
                    </Step>
                ))}
                </Stepper>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Grid container className={classes.form} spacing={2}>

                    {activeStep===0 ? 
                    <>
                        <Grid item xs={12}>
                            <label htmlFor="custom_name" className="mb-3">Game Title</label>
                            <TextField variant="outlined" onChange={handleChange} className={classes.textField} style={{marginTop: '10px'}} value={inputs.name} InputProps={{id:'name', name:'name'}}/>
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="game_outcomes">Description</label>
                            <TextField variant="outlined"  multiline rows={2} onChange={handleChange} style={{marginTop: '10px'}} className={classes.textField} value={inputs.outcomes} InputProps={{id:'outcomes', name:'outcomes'}}/>
                        </Grid>

                        {game.customizable != 'Zoo'? 
                            <Grid item xs={12}>
                                <label htmlFor="category">Category</label>

                                <Select variant="outlined" value={inputs.category} className={classes.textField} style={{marginTop: '10px'}} onChange={handleChange} name="category" id="category">
                                    {/* {game.categories && game.categories.map(c => */}
                                        <MenuItem value='Alphabets'>Alphabets</MenuItem>
                                        <MenuItem value='Numbers'>Numbers</MenuItem>

                                    {/* )} */}
                                </Select>
                            </Grid>
                        :
                            <Grid item xs={12}>
                                <label htmlFor="category">Choose Animals</label>
                                <br/>
                                <Autocomplete
                                    multiple
                                    
                                    // freeSolo
                                    id="tags-outlined"
                                    options={animals}
                                    getOptionLabel={(option) => option.name}
                                    value = {animals.filter(x => inputs?.answers.includes(x.name))}
                                    onChange={(chips, value) => handleAddChips(value, "answers")}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                    <TextField
                                    style={{backgroundColor : 'white', width : "100%" , marginTop: '10px', height : '30px'}}
                                        {...params}
                                        
                                        // label="filterSelectedOptions"
                                        // placeholder="Favorites"
                                    />
                                    )}
                                />
                            
                                 {/* <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="answers" 
                                        variant="outlined"
                                        dataSource={['foo', 'bar']}
                                        openOnFocus
                                        // dataSourceConfig
                                        // value={inputs.answers}
                                        disableUnderline={true} 
                                        style={{backgroundColor : 'white', width : "100%" }}
                                        // onAdd={(chips) => handleAddChips(chips, "answers")}
                                        // onDelete={(chips) => handleDeleteChips(chips, "answers")}
                                      
                                    /> */}
                            </Grid>
                        }
                        
                    </>

                    :activeStep===1 ?

                    <>
                        <Grid item xs={12}>
                            <label htmlFor="grades">Select Grades</label>
                            <br/>
                            <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="grades" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        value={inputs.grades}
                                        style={{backgroundColor : 'white', width : "100%", marginTop: '10px' }}
                                        onAdd={(chips) => handleAddChips(chips, "grades")}
                                        onDelete={(chips) => handleDeleteChips(chips, "grades")}

                                    />
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="grades">Select Subject</label>
                            <br/>
                             <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="subjects" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        value={inputs.subjects}
                                        style={{backgroundColor : 'white', width : "100%", marginTop: '10px' }}
                                        onAdd={(chips) => handleAddChips(chips, "subjects" )}
                                        onDelete={(chips) => handleDeleteChips(chips , "subjects" )}

                                    />
                        </Grid>

                        <Grid item xs={12}>
                            <label htmlFor="level">Select Level</label>
                            <br/>
                            <Select variant="outlined" style={{marginTop: '10px'}} value="Lorem ipsum" id="level" value={inputs.difficulty_level} name="difficulty_level" className={classes.textField} onChange={handleChange}>
                                <MenuItem value="Easy">Easy</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="Hard">Hard</MenuItem>
                            </Select>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <label htmlFor="timer">Set Timer <span style={{fontSize: '12px', opacity : '0.6'}}>(minutes)</span></label>
                            <TextField variant="outlined" type='number' placeholder="15" onChange={handleChange} className={classes.textField} value={inputs.timer} InputProps={{id:'timer', name:'timer', endAdornment: <InputAdornment position="end"><img src={timer_icon} height="30vh"/></InputAdornment>,}} />

                        </Grid> */}
                    
                    </>

                    : (activeStep===2 && game.customizable != "Zoo"  ?
                    (inputs.category ?
                    <>
                        <Grid item xs={12}>
                        {inputs.questions?.map((q,i) =>
                        <Grid container key={q.id} spacing={2}>
                            {/* // {inputs.category && inputs.category.question_types.>1 && <Grid item xs={12}>  */}
                        {inputs.category && game.customizable=== "Catcher" && <Grid item xs={12}> 
                        


                            <label htmlFor={`question_type_${i}`}>Question Type</label>
                            <br/>
                            <Select variant="outlined"  onChange = {(e)=>handleChangeQuestion(e, q.id)} value={q.question_type} id="level" name="question_type" className={classes.textField}>
                                <MenuItem value="Image">Image</MenuItem>
                                {/* <MenuItem value="Missing">Missing</MenuItem> */}
                                <MenuItem value="Audio">Audio</MenuItem>
                                <MenuItem value="Text">Text</MenuItem>
                                <MenuItem value="Missing">Missing</MenuItem>

                            </Select>
                        </Grid>}    

                        <Grid item xs={12}>
                            <label htmlFor={`question_${i}`}>Question</label>
                            <TextField variant="outlined" className={classes.textField} value={q.question} onChange = {(e)=>handleChangeQuestion(e, q.id)} InputProps={{id:`question_${i}`, name:'question'}}/>
                        </Grid>

                        {q.question_type === "Image" ? 
                        <div style={{ width : '20vw', marginLeft : '8px'}}>
                        <label >Upload Image</label>
                        <DropzoneArea
                        useChipsForPreview={true}
                        maxFileSize={100000000}
                        filesLimit = {1}
                        fileSize
                        acceptedFiles={['image/*']}
                        dropzoneText={"Drag and drop image or Browse"}
                        onChange={(files)=>singleFileChangedHandler(files, q.id)}
                        />
                        </div>
                         :      q.question_type === "Audio"  ?
                          <div style={{ width : '20vw', marginLeft : '8px'}}>
                        <label >Upload Audio</label>

                         <DropzoneArea
                         useChipsForPreview={true}
                         filesLimit = {1}
                         maxFileSize={100000000}
                         acceptedFiles={['audio/*']}
                         dropzoneText={"Drag and drop audio file or Browse"}
                         onChange={(files)=>singleFileChangedHandler(files, q.id)}
                         />
                         </div> :<></>}

                        <Grid item xs={12}>
                                    {game.customizable === 'Catcher' ?   

                                    <Fragment>
                                    <label htmlFor={`right_answers_${i}`}>Missing</label>
                                    <br/>
                                    <ChipInput  
                                    placeholder ={q.question_type != "Missing" ? 'Disabled' : 'Type and Enter'}
                                        name="missing" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        disabled={q.question_type != "Missing"}
                                        style={{backgroundColor : 'white', width : "100%" , opacity : q.question_type != "Missing" ? "0.6" : '1'}}
                                        value={q.missing}
                                        onAdd = {(e)=>addMissing(e, q.id)} 
                                        onDelete = {(e)=>deleteMissing(e, q.id)} 
                                    
                                    /> 
                                    <h6 style={{opacity:0.3}}>Numbers only.</h6> 
                            
                                    <br/>
                                    <label htmlFor={`right_answers_${i}`}>Right Answers</label>
                                    <br/>
                                    <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="answers" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        style={{backgroundColor : 'white', width : "100%" }}
                                        value={q.right_answers}
                                        onAdd = {(e)=>addRightAnswers_multiple(e, q.id)} 
                                        onDelete = {(e)=>deleteRightAnswers_multiple(e, q.id)} 
                                      
                                    />
                                    {/* <br/> */}
                                    <h6 style={{opacity:0.3}}>Max 8 characters per chip only.</h6> </Fragment>: 
                                    <Fragment>
                                    <label htmlFor={`right_answers_${i}`}>Right Answers</label>
                                    <br/>
                                    <ChipInput  
                                            placeholder ="Type and Enter"
                                                name="answers" 
                                                variant="outlined"
                                                disableUnderline={true} 
                                                style={{backgroundColor : 'white', width : "100%" }}
                                                value={q.right_answers}
                                                onAdd = {(e)=>addRightAnswers(e)} 
                                                onDelete = {(e)=>deleteRightAnswers(e)} 
                                                // onChange={(chips) => handleChangeChips_Right_answers(chips)}
                                              
                                            /> </Fragment>}
                        </Grid>

                        {inputs.category && game.customizable!="Catcher" && <Grid item xs={12}>
                            <label htmlFor={`wrong_answers_${i}`}>Wrong Answers</label>
                            <br/>
                            <ChipInput  
                                    placeholder ="Type and Enter"
                                        name="answers" 
                                        variant="outlined"
                                        disableUnderline={true} 
                                        value={q.wrong_answers}
                                        style={{backgroundColor : 'white', width : "100%" }}
                                        // onChange={(chips) => handleChangeChips_Wrong_answers(chips)}
                                         onAdd= {(e)=>addWrongAnswers(e)} 
                                         onDelete = {(e)=>deleteWrongAnswers(e)} 
                                    />
                        </Grid>}

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        </Grid>
                        )}
                        </Grid>

                        {game.customizable === 'Catcher' && <Grid item xs={12}>
                            <Button startIcon={<AddIcon/>} onClick={handleAddQuestion}>Add another question</Button>
                        </Grid>}
                    </>

                    :
                        <Grid item xs={12}>
                            <Typography variant="h6">No category selected</Typography>
                        </Grid>
                    )

                    :

                    <>
                        
                        <Grid item xs={12} className={classes.gameTitlePreview}>
                            <div>
                                <Typography variant="h5">{inputs.name}</Typography>
                                <Chip style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)]}} label={inputs?.subjects?.[0]}  className={classes.chip}/>

                            </div>
                            <Typography variant="body1">{inputs.outcomes} </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        
                        <Grid container spacing={1} className={classes.gamePropertiesPreview}>
                            <Grid item xs={12} md>
                                <Typography variant="h6">GRADES</Typography>
                                <Typography variant="body1">
                                {inputs?.grades?.map((grade,key) => 
                                        key == inputs.grades.length -1 ?
                                        <span>{grade}</span> : 
                                        <span>{grade}, </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">SUBJECTS</Typography>
                                <Typography variant="body1">
                                {inputs?.subjects?.map((subject,key) => 
                                        key == inputs.subjects.length -1 ?
                                        <span>{subject}</span> : 
                                        <span>{subject}, </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">LEVEL</Typography>
                                <Typography variant="body1">{inputs.difficulty_level}</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                <Typography variant="h6">TIME</Typography>
                                <Typography variant="body1">{inputs.timer} mins</Typography>
                            </Grid>
                            <Grid item xs={12} md>
                                {/* <Typography variant="h6">{inputs.category.customizable===false ? "ANSWERS" : "CATEGORY"}</Typography> */}
                                <Typography variant="h6">CATEGORY</Typography>

                                <Typography variant="body1">
                                    {inputs.answers.length > 0 ? inputs.answers.map((category, i) => 
                                       inputs.answers.length-1 == i ? category : category + ", "
                                       ) : inputs.category }
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12}>
                            {game.customizable  != "Zoo" ?
                            <Grid container className={classes.gameDetailsPreview}>
                                {inputs.questions.map((q,i) =>
                                    <Fragment key={q.id}>
                                        <span style={{color:'#D3D3D9', fontSize : '14px', fontWeight : '900'}}> Question no. {i+1}</span>
                                    {q.question_type == 'Image' ?
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Image</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <img height={'118px'} width={'200px'}  src={q.link}/>
                                            </Grid>
                                        </Grid>
                                    </Grid> :  q.question_type == 'Audio' ?
                                       <Grid item xs={12}>
                                       <Grid container>
                                           <Grid item xs={3}>
                                               <Typography variant="body1" gutterBottom>Audio</Typography>
                                           </Grid>
                                           <Grid item xs={9}>
                                           <audio src={q.link} controls />
                                           </Grid>
                                       </Grid>
                                   </Grid>
                                     : <></>}
                                    
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Question {inputs.category.multiple_questions && i+1}</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>{q.question}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    {game.customizable == "Catcher"&& q.missing != "" &&
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Missing</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {q.missing.map((missing,key) =>
                                                        key === q.missing.length -1 ? 
                                                        <span key={key}>{missing} </span>  :  
                                                        <span key={key}>{missing}, </span>   
                                                    )}
                                                    {/* {q.right_answers} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Right answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {q.right_answers.map((ans,key) =>
                                                        key === q.right_answers.length -1 ? 
                                                        <span key={key}>{ans} </span>  :  
                                                        <span key={key}>{ans}, </span>   
                                                    )}
                                                    {/* {q.right_answers} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    {game.customizable != "Catcher"&& 
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Wrong answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {q.wrong_answers.map((ans,key) =>
                                                       key === q.wrong_answers.length -1 ? 
                                                       <span key={key}>{ans} </span>  :  
                                                       <span key={key}>{ans}, </span>  
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                            }

                                    <Grid item xs={12}>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                            :
                                <Grid container className={classes.gameDetailsPreview}>
                                    <Grid item xs={12}>
                                        {/* <Grid container>
                                            <Grid item xs={3}>
                                                <Typography variant="body1" gutterBottom>Answers</Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography variant="body1" gutterBottom>
                                                    {inputs.answers.map(ans =>
                                                        <span key={ans}>{ans}, </span>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                    
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={8} className={classes.formActions}>
                {activeStep>0 && <Button
                    variant="contained"
                    onClick={handleBack}
                    className={classes.button}
                >
                    Go Back
                </Button>}
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={loading}
                >
                    {loading == true ? <CircularProgress size={24}/>
                    :(steps && activeStep === steps.length - 1) ? 'Create' : 'Next'}
                </Button>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateCustomGameForm;