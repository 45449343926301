import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';

//material-ui core imports
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';

//material-ui style imports
import { makeStyles } from '@material-ui/core/styles';
import { useSelector ,useDispatch} from 'react-redux';
import { getGroups } from '../../../../../redux/actions/studentAction';
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import ShareMemoryTable from '../Components/ShareMemoryTable';
import ShareMemoryReportTable from '../Components/ShareMemoryReportTable';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@material-ui/core';
import axios from "../../../../../redux/axios";
import { API_URL } from '../../../../../config';


const useStyles = makeStyles(() => ({
    divider: {
        margin: '24px 0'
    },
    chip: {
        borderRadius: 4,
        height: 'auto',
        '&>*:nth-child(1)':{
            padding:'3px 5px'
        }
    },
    buttonLink: {
        textDecoration: 'none',
        color: '#000'
    },
    avatar: {
        width: 80,
        height:80
    },
    gameTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 24,
        '&>div:nth-child(1)':{
            display: 'flex',
            alignItems: 'center',
            '&>*:nth-child(1)':{
               // width: 64,
                marginRight: 16
            },
            '&>div':{
                '& h5':{
                    fontSize: '1.4rem',
                    margin: '0 8px 4px 0',
                    fontWeight: 500
                },
                '& p':{
                    fontSize: '0.9rem',
                    marginTop: 4
                },
                '&>div':{
                    display: 'flex',
                    alignItems: 'center',
                }
            }
        }
    },
    createModal: {
        '& h5':{
            fontWeight: 500
        },
        '&>div:nth-child(2)':{
            margin: '24px 0 12px 0'
        },
        '&>div:nth-child(3)':{
            margin: '36px 0 12px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&>*':{
                width: '48%'
            }
        }
    }
}));

const ViewStudentTop = ({match,groups,getGroups, shareClicked, setShareClicked, feedbackModal, setFeedbackModal, selectedReport}) => {

  var institute_id = localStorage.getItem('institute')
    console.log("Loaded")
    useEffect(()=>{
        if(!groups && institute_id){
            getGroups(institute_id);
        }
        setReportCards(JSON.parse(localStorage.getItem("reportCards")));

        const getData = async () => {
            await axios.get(`${API_URL}/feedback`)
            .then(response => {
                console.log(response);
                setFeedbackList(response.data);
            })}
        getData()
    },[groups])

    var group = groups?.filter(group=> group._id === match.params.id)

    group = group ? group[0] : [];
    
    group = Object.assign({}, group); 
    console.log(group)
    var student = group.students?.filter(student => student._id === match.params.sid)

    student = student ? student[0] : [];
    
    student = Object.assign({}, student  );
    console.log(group) 

  const classes = useStyles();
  
  const [shareMemoryConfirmationModal, setShareMemoryConfirmationModal] = useState(false);

  const [reportCards, setReportCards] = useState([false]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [addFeedbackClicked, setAddFeedbackClicked] = useState(true);
  const [isExistingFeedback, setIsExistingFeedback] = useState(true);
  const [newFeedback, setNewFeedback] = useState("");
  const handleCancelFeedbackModal = () => {
    setFeedbackModal(false);
  };

  const handleCloseFeedbackModal = async () => {
    setFeedbackModal(false);
    //DO API call
    if(!isExistingFeedback){
        const data = {
            feedback: newFeedback,
            Institute: institute_id,
            group: group._id
        }
        const result = await axios.post(`${API_URL}/feedback`, data);
        console.log(result);
    }
    //Do api call to save feedback in sessionCheck table along with score
    let data = {
        "feedback": newFeedback,
        "studentId": selectedReport.student_id,
        "playGameId": selectedReport.playgameId
      }
      console.log(data);
        const result = await axios.post(`${API_URL}/playgame/session/savefeedback`, data);
        console.log(result);
        window.location.reload();
  };

  const handleOpenShareMemoryConfirmationModal = () => {
    setShareClicked(true);
    // setShareMemoryConfirmationModal(true);
  }

  const handleCloseShareMemoryConfirmationModal = () => {
    setShareClicked(!shareClicked);
    // setShareMemoryConfirmationModal(false);
  }

  const handleShareSelection = () => {

  }

  const handleAddNewFeedback = () => {
    setAddFeedbackClicked(false);
  }

  const handleAddNewFeedbackText = (e) => {
    if(e && e.target){
        setIsExistingFeedback(false)

        console.log(e.target.value);
        setNewFeedback(e.target.value);
    } else {
        setIsExistingFeedback(true)
        console.log(e)
        setNewFeedback(e)
    }
   
  }
  
  return (
    <div>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={`/labs/groups`}>
                Groups 
            </Link>
            <Link color="inherit" to={`/labs/groups/${match.params.id}/students`}>
                {group.name}
            </Link>
            <Typography></Typography>
        </Breadcrumbs>

        <div className={classes.gameTitle}>
            <div>
                <Avatar className={classes.avatar} src={student.profile_pic} >{!student.profile_pic && (student.name?.substring(0, 1).toUpperCase()) }</Avatar>
                <div>
                    <div>
                        <Typography variant="h5">{student.name}</Typography>
                    </div>
                    <Typography variant="body1">{student.phone_number} | {student.email_id}</Typography>
                </div>
            </div>
            
            { !shareClicked ? <Button variant="contained" color="primary" onClick={handleOpenShareMemoryConfirmationModal} disabled={shareClicked}>Share Memory</Button>
            : <Button variant="contained" color="primary" onClick={handleShareSelection} hidden={!shareClicked}>Send</Button>}

        </div>

        <Divider className={classes.divider}/>

        {/*Share Memory confirmation*/}
        <Dialog 
            open={shareMemoryConfirmationModal} 
            onClose={handleCloseShareMemoryConfirmationModal}
            fullWidth={true}
            maxWidth="sm"
        >
        
            <DialogContent className={classes.createModal}>
                <div>
                    <Typography variant="h5" color="secondary">Are you sure ?</Typography>
                </div>
                <div>
                    <ShareMemoryReportTable group={group} reportCards={reportCards}/>
                </div>
                <div>
                    <Typography variant="h6">Are you sure you want to share the memory for Ralph Edwards ?</Typography>
                </div>
                
                <div>
                    <Button variant="contained" color="primary" onClick={handleCloseShareMemoryConfirmationModal}>
                            Yes
                    </Button>
                    <Button variant="contained" onClick={handleCloseShareMemoryConfirmationModal}>
                            Cancel
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
        
         {/*Feedback dialog*/}
        <Dialog 
            open={feedbackModal} 
            onClose={handleCloseFeedbackModal}
            fullWidth={true}
            maxWidth="sm"
        >
        
            <DialogContent className={classes.createModal}>
                <div>
                    <Typography variant="h5" color="secondary">Add Feedback</Typography>
                </div>
                <Autocomplete
                    options={feedbackList.map(item => item.feedback)}
                    id="feedback-autocomplete"
                    onChange={(e, value)=> handleAddNewFeedbackText(value)}
                    renderInput={(params) => (
                    <TextField {...params} label="Select" variant="standard" />
                    )}
                />
                <div>
                    { addFeedbackClicked && <Button startIcon={<AddIcon/>} onClick={handleAddNewFeedback}>Add another Feedback</Button> }
                    { !addFeedbackClicked && <TextField variant="standard" onChange = {(e)=>handleAddNewFeedbackText(e)}/>}
                </div>

                <div>
                    <Button variant="contained" color="primary" onClick={handleCloseFeedbackModal}>
                            Yes
                    </Button>
                    <Button variant="contained" onClick={handleCancelFeedbackModal}>
                            Cancel
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    </div>
  );
}

ViewStudentTop.propTypes = {
    getGroups: PropTypes.func.isRequired,
    // groups : PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    groups: state.student.groups,
});

export default connect(mapStateToProps , { getGroups})(ViewStudentTop);