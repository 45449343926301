import { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";

//material-ui icon imports
import SearchIcon from "@material-ui/icons/Search";
import { logout } from "../../redux/actions/authAction";
import discover_icon from "../Dashboard/Icons/Group.png";
import math_icon from "../Dashboard/Icons/Group (1).png";
import science_icon from "../Dashboard/Icons/Group (2).png";
import history_icon from "../Dashboard/Icons/Group (3).png";
import english_icon from "../Dashboard/Icons/Group (4).png";
import { Icon } from "@material-ui/core";
import social_icon from "../Dashboard/Icons/Group (6).png";
import creativity_icon from "../Dashboard/Icons/Group (5).png";
import SearchBar from "material-ui-search-bar";
import { Fragment } from "react";
import { getGames } from "../../redux/actions/gameAction";

import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import NotificationsIcon from "@material-ui/icons/Notifications";
import eduzo_logo from "./Icons/Eduzo.labs.png";
// import Select from '@material-ui/core/Select';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: 24,
  },
  title: {
    fontWeight: 600,
  },
  linkButton: {
    textDecoration: "none",
    color: "#fff",
    padding: 0,
    width: 200,
  },
  appBar: {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  headerActions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formControl1: {
    // minWidth: '55vw',
    marginTop: "-43px",
    marginLeft: "49%",
  },
  left: {
    // width: '1000px !important'
    // display: 'flex',
    // height: '60%',
    // width: 'min(50%,480px)',
    // padding: '8px 0',
    // background: '#fff',
    // borderRadius: '4px',
    // position: 'relative',
    //     '&>input':{
    //         outline: 'none',
    //         border: 'none',
    //         background: 'inherit',
    //         borderRadius: '4px',
    //         width: '100%',
    //         height: '100%',
    //         padding: '0 12px',
    //         border: 'none',
    //     },
  },
  right: {
    "&>*:nth-child(2)>*:nth-child(1)": {
      background: "linear-gradient(93.23deg, #4335D2 13.26%, #E93480 100%)",
      borderRadius: 4,
      margin: "0 0 0 16px",
      color: "#fff",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      textTransform: "initial",
    },
  },
  headerUserActions: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    color: "#fff",
    textTransform: "none",
    "& *": {
      margin: "0 3px",
    },
  },
}));

export default function DashboardHeader({
  title,
  isMobile,
  search,
  cancelSearch,
  handleChange,
  Subject,
  showLogout,
  newNote,
}) {
  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);

  var icons = [
    discover_icon,
    math_icon,
    history_icon,
    history_icon,
    english_icon,
  ];

  useEffect(() => {
    if (!game.games) {
      dispatch(getGames());
    }
  }, []);

  var array = [];
  game.games?.map((game) =>
    game.subjects.map((subject) => array.push(subject.toLowerCase()))
  );

  var subjects = [...new Set(array)];

  // console.log(window.location.pathname)

  const institute_plan = localStorage.getItem("institute_plan");
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const handle_logout = () => {
    dispatch(logout());
  };

  console.log("location, history", newNote);

  return (
    <div className={classes.root}>
      <div className={`${classes.appBar} ${!isMobile && classes.appBarShift}`}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerActions}>
            <div className="p-0">
              <Typography variant="h5" className={classes.title}>
                {title}
              </Typography>

              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-outlined-label">Subjects</InputLabel> */}
                  {location?.pathname?.includes("gamestore") ? (
                    <Select
                      style={{
                        width: "180px",
                        backgroundColor: "white",
                        height: "55px",
                        textTransform: "capitalize",
                      }}
                      id="demo-simple-select-outlined"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      value={Subject}
                      defaultValue={"All"}
                      inputProps={{ width: 180 }}
                      onChange={handleChange}
                      SelectProps={{
                        MenuProps: {
                          className: { width: 100 },
                        },
                      }}
                      label={Subject}
                    >
                      <MenuItem value="All">
                        <img
                          src={icons[Math.floor(Math.random() * icons.length)]}
                          style={{
                            width: "16px",
                            marginTop: "3px",
                            marginRight: "10px",
                          }}
                        />
                        Discover
                      </MenuItem>
                      {subjects.map((subject) => (
                        <MenuItem
                          style={{ textTransform: "capitalize" }}
                          value={subject}
                        >
                          <img
                            src={
                              icons[Math.floor(Math.random() * icons.length)]
                            }
                            style={{
                              width: "16px",
                              marginTop: "3px",
                              marginRight: "10px",
                            }}
                          />
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </FormControl>
              </div>
            </div>
            {/* <div className={classes.right}> */}
            <div className="row px-3 align-items-center">
              <div className="mr-3">
                {window.location.pathname.includes("/unregistered/") ||
                window.location.pathname.includes("/manage") ||
                window.location.pathname.includes("/help-center") ? (
                  <span></span>
                ) : (
                  <SearchBar
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => search(e)}
                    onCancelSearch={(e) => cancelSearch(e)}
                    cancelSearchGame
                  />
                )}
              </div>

              {window.location.pathname === "/labs/my-games" ||
              window.location.pathname === "/labs/gamestore" ||
              window.location.pathname === "/labs/manage" ||
              window.location.pathname === "/labs/help-center" ? (
                <div className="mt-3">
                  <Icon>
                    <Link
                      to={"/labs/notifications"}
                      className="position-relative"
                    >
                      <div className={newNote ? "notification-dot" : ""}></div>
                      <NotificationsIcon style={{ color: "#D3D3D9" }} />
                    </Link>
                  </Icon>
                </div>
              ) : (
                <></>
              )}
              <div className={""}>
                {/* <div className='col-sm-5 mt-1'> */}
                <div className={classes.right}>
                  <p></p>
                  {/* {window.location.pathname === "/unregistered" ||
                  window.location.pathname.includes("/unregistered") ? (
                    <Link
                      to="/unregistered/packages"
                      className={classes.linkButton}
                    >
                      <Button>Choose Plan</Button>
                    </Link>
                  ) : (
                    <Link to="/labs/packages" className={classes.linkButton}>
                      <Button>{institute_plan} Plan</Button>
                    </Link>
                  )} */}
                  {/* {window.location.pathname === '/unregistered' || window.location.pathname.includes('/unregistered')   ? <Link to="/labs/packages" className={classes.linkButton} ><Button>Choose Plan</Button></Link> : <Link to="/labs/packages" className={classes.linkButton}><Button>{institute_plan} Plan</Button></Link>} */}
                  {/* {window.location.pathname === '/unregistered' || window.location.pathname.includes('/unregistered/') ? '' : <Link ><button style={{color : 'white' , backgroundColor : 'red', border : '0px', padding : '10px', borderRadius : '5px', marginLeft : '10px'}} onClick={handle_logout}>Logout</button></Link>} */}
                  {/* <Link ><button style={{color : 'white' , backgroundColor : 'red', border : '0px', padding : '10px', borderRadius : '5px', marginLeft : '10px'}} onClick={handle_logout}>Logout</button></Link> */}
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </div>
    </div>
  );
}
