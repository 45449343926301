import {useState} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import {API_URL} from '../../../config/index.js'
import { DropzoneArea } from 'material-ui-dropzone';


//material-ui lab imports
import Alert from '@material-ui/lab/Alert';

//common

const useStyles = makeStyles(() => ({
    root: {
        '& h6':{
            fontWeight: 400
        }
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    createModal: {
        '& h5':{
            fontWeight: 500
        },
        '&>*':{
            margin: '12px 0'
        },
        '&>div:nth-child(2)':{
            margin: '24px 0 12px 0'
        },
        '&>div:nth-child(3)':{
            marginBottom: 24,
        },
        '&>div:nth-child(5)':{
            margin:' 12px 0 6px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&>*':{
                width: '48%'
            }
        }
    }
}));

const GroupsTop = ({groups, createGroup}) => {

  const classes = useStyles();

  const [createModal, setCreateModal] = useState(false);
  const [inputs, setInputs] = useState({
      Institute : localStorage.getItem('institute'),
      name:'',
      group_img : ''
  })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseCreateModal = () => {
      setCreateModal(false);
  }

  const imageChange = async(files) => {
       
    setInputs({ ...inputs, group_img: files[0]})

  }
  
  const handleOpenCreateModal = () => {
    setCreateModal(true);
  }

  const handleChange = (e) => {
      setInputs({...inputs, [e.target.name] : e.target.value})
  }

  const handleSubmit = async () => {

    var allData = inputs;

    setLoading(true);

    if (inputs.group_img && inputs.group_img != ""){
    //   console.log('profile_pic')

      let data = new FormData();
      data.append("image", inputs.group_img );
  
      await axios.post(`${API_URL}/custom-games/upload`, data).then((result) => {
        //   console.log(result.data);
        allData.group_img = result.data;
      })

  }
    try{
        setError(null);
        setLoading(true);
        await createGroup(allData);
        setCreateModal(false);
    }
    catch(e){
        setError(e);
    }
    finally{
        setLoading(false);
    }
  }

  return (
    <div className={classes.root}>
        <div className={classes.top}>
            <Typography variant="h6">{groups ? groups.length : 0} Groups</Typography>
            <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>Add Group</Button>
        </div>

        <Dialog 
            className={classes.getInTouch} 
            open={createModal} 
            onClose={handleCloseCreateModal} 
            aria-labelledby="get-in-touch-dialog"
            fullWidth={true}
            maxWidth="xs"
        >
        
            <DialogContent className={classes.createModal}>
            <div>
                <Typography variant="h5" color="secondary">Add Group</Typography>
            </div>
            <div>
                <label htmlFor="name">Group Name</label>
                <TextField id="name" autoFocus value={inputs.name} InputProps={{name:'name'}} onChange={handleChange} fullWidth variant="outlined"/>
            </div>
            <div>
            <div   style={{width : '15vw',}}  >

                <label htmlFor="image">Group Image</label><br/>
                {/* <input type="file" onChange={imageChange} id="image"/> */}
                <       DropzoneArea
                        useChipsForPreview={true}
                        filesLimit = {1}
                        acceptedFiles={['image/*']}
                        dropzoneText={"Drag and drop image or Browse"}
                        onChange={(files)=>imageChange(files)}
                        />
            </div>
            </div>

            <div>
                {error && <Alert severity="error">{error}</Alert>}
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={20} color='inherit'/>  : 'Add'}
                </Button>
                <Button variant="contained" onClick={handleCloseCreateModal}>
                    Cancel
                </Button>
            </div>
            </DialogContent>
        </Dialog>
    </div>
  );
}

export default GroupsTop;
