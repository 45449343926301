import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getGroups,
  createStudent,
  deleteStudent,
} from "../../../../redux/actions/studentAction";
import PlayGame from "../../../../pages/PlayGame/PlayGame";

const Students = (props) => {
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    if (!student.groups) {
      dispatch(getGroups());
    }
  }, []);

  const handleCreateStudent = async (inputs) => {
    await dispatch(
      createStudent({ ...inputs, institute: localStorage.getItem("institute") })
    );
  };

  const handleDeleteStudent = async (inputs) => {
    await dispatch(deleteStudent(inputs));
  };

  return (
    <div>
      {/* <Top group={student.groups ? (student.groups.find(g => g._id=== props.match?.params.id) || {}) : {}}  /> */}
      {/* <Bottom deleteStudent={handleDeleteStudent} createStudent={handleCreateStudent} group={student.groups ? (student.groups.find(g => g._id===props.match?.params.id) || {}) : {}} {...props}/> */}
      <PlayGame
        group={
          student.groups
            ? student.groups.find((g) => g._id === props.match?.params.id) || {}
            : {}
        }
        deleteStudent={handleDeleteStudent}
        createStudent={handleCreateStudent}
      />
    </div>
  );
};

export default Students;
