import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

const AppProvider = ({ children }) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4335D2'
      },
      secondary: {
        main: '#E93480'
      }
    },
    typography: {
      fontFamily: '"Circular Std", Roboto, sans-serif',
    },
  });


  return (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
  );
};

export default AppProvider;
