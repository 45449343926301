import {Fragment} from 'react';
import { Header, Top, Main, Footer } from '../../components/Home';

const HomePage = (props) => {

    return (
        <Fragment>
            <Header {...props}/>
            <Top {...props}/>
            <Main {...props}/>
            <Footer {...props}/>
        </Fragment>
        
    );
};

export default HomePage;
