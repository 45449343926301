
//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import Skeleton from '@material-ui/lab/Skeleton';
import {useState, useEffect} from 'react';

import {API_URL} from '../../config/index';
import axios from 'axios';

// import {getGames} from '../../redux/actions/gameAction';

//common

const useStyles = makeStyles(theme => ({
    content: {
        padding: '24px 0',
        width: '90%',
        margin: '0 auto',
        '&>*':{
            margin: '24px 0'
        },
    },
    top: {
        '&>*:first-child>*':{
            margin: '16px 0 36px 0',
            '&>h5':{
                fontSize : '34px',
                fontWeight:700,
            },
            '& img':{
                transform: 'translateX(-72px)',
                width: '90%'
            },
            '& p':{
                fontSize : '22px',
                fontWeight : '400',
                fontFamily : 'Manrope, sans-serif',
                letterSpacing : '0.6px',
                opacity : '0.8'

            }
        },
    },
    section: {
        justifyContent: 'space-between',
        '&>*:nth-child(2)':{
            width: '100%',
            '&>img':{
                width: '100%',
            }
        },
        '&>*:nth-child(1)':{
            marginTop: 36
        },
        '& h6':{
            fontWeight: 600,
            margin: '8px 0',
            fontSize : '24px'
        },
        '& p':{
            fontWeight : '300',
            margin: '8px 0',
            fontSize : '20px', 
            opacity: '0.6',
            fontFamily : 'Manrope, sans-serif',
            lineHeight : '30px', 
            letterSpacing : "0.4px"
        }
    },
    alternateSection: {
        '&>*:nth-child(2)':{
            order: 1,
            [theme.breakpoints.down('sm')]: {
                order: 2,
            },
        },
        '&>*:nth-child(1)':{
            order: 2,
            [theme.breakpoints.down('sm')]: {
                order: 1,
            },
        },
    },
    games:{
        margin: '36px 0',
        '& h5':{
            fontWeight: 600,
            marginBottom: 16
        },
        '& button':{
            marginTop: 24,
            fontSize: "18px",
            textTransform: 'none',
            padding : '10px 35px',
            borderRadius: '10px',
            '& a':{
            color : 'white',
            textDecoration : 'none'
            }
        }
    },

    stepper : {
        marginLeft : '-5vw',
        height : "50vh",
        '& Stepcontent':{
            marginBottom : '30px'
        }

    },
    stepper1: {

        fontSize : '20px',

        '& svg':{
            color: '#E93480 !important'
        },
        '& >* span':{
            color: '#000000 !important',
            fontSize : '16px !important',
        },
        '& >* p':{
            color: '#000000 !important',
            fontSize : '20px !important',
        }
    },
    stepper2: {

        '& >* span':{
        color: '#000000 !important',
        fontSize : '16px !important',
        },
        '& >* p':{
            color: '#000000 !important',
            fontSize : '20px !important',
        }
    }

}));

const Main = ({}) => {


    const [games , setGames] = useState([]);

    useEffect(async() => {
        await axios.get(`${API_URL}/games/home-page`)
        .then(response => {
        setGames(response.data);
        // console.log(response.data)
    
        })
        .catch(() => {
            setGames([]);
        })
      }, []); 

    const classes = useStyles();

    return (
        <main className={classes.main}>
            <Grid container className={classes.content} id="knowmore">

                <Grid item xs={12} className={classes.top}>

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                Easy Steps
                            </Typography>
                            <p  >
                                All-in-one platform where you can customise games, track progress, evaluate and <br/> even share the memory with the students and parents
                            </p>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/steps.svg`} alt="steps"/>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Stepper orientation="vertical" className={classes.stepper}>
                                <Step active={true} className={classes.stepper1}>
                                    <StepLabel>STEP 01</StepLabel>
                                    <StepContent>
                                        <Typography>Create: Lorem Ipsum is simply dummy text of.</Typography>
                                    </StepContent>
                                </Step>
                                <Step expanded={true} className={classes.stepper2}>
                                    <StepLabel>STEP 02</StepLabel>
                                    <StepContent>
                                        <Typography>Customize: Lorem Ipsum is simply dummy text.</Typography>
                                    </StepContent>
                                </Step>
                                <Step expanded={true} className={classes.stepper2}>
                                    <StepLabel>STEP 03</StepLabel>
                                    <StepContent>
                                        <Typography>Student Mapping: Lorem Ipsum is simply dummy.</Typography>
                                    </StepContent>
                                </Step>
                                <Step expanded={true} className={classes.stepper2}>
                                    <StepLabel>STEP 04</StepLabel>
                                    <StepContent>
                                        <Typography>Share Memory: Lorem Ipsum is simply dummy.</Typography>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.section}>
                        <Grid item xs={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/experience-icon.svg`} alt="experience"/>
                            <Typography variant="h6">
                                Experience
                            </Typography>
                            <p >
                                There is nothing more long lasting than <br/>learning from experiences. These are <br/> bound to leave a lasting memories
                            </p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/experience-image.svg`} alt="experience"/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={`${classes.section} ${classes.alternateSection}`}>
                        <Grid item xs={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/customizable-icon.svg`} alt="experience"/>
                            <Typography variant="h6">
                                Customizable
                            </Typography>
                            <p >
                                Cover almost any topic or subject with <br/> our highly customizable games. You set <br/> the questions and you set the answers 
                            </p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/customizable-image.svg`} alt="experience"/>
                        </Grid>
                    </Grid>
                </Grid>

                
                <Grid item xs={12}>
                    <Grid container className={classes.section} spacing={0}>
                        <Grid item xs={12} md={4}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/concept-icon.svg`} alt="experience"/>
                            <Typography variant="h6">
                                Concept based
                            </Typography>
                            <p>
                                Cover each subject based concepts <br/> with these specially crafted games
                            </p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/homepage/concept-image.svg`} alt="experience"/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                {games && 
                    <Grid container className={classes.games} spacing={1} justify="center" alignItems="center">
                        <Typography variant="h4">Games</Typography>
                        <Grid item xs={12}></Grid>
                        <br/>
                        {games.slice(0,8).map((g,i) => 
                            <Grid key={i} item xs={12} sm={4} md={3} >
                                 <div className={classes.game} style={{backgroundImage:`url('${g.illustration_img}')`,
                              backgroundRepeat : 'no-repeat',backgroundSize : 'cover',height : '40vh', borderRadius : '25px'}} >
                                <div>
                                    <Typography style={{color : 'white', padding : '20px 0px 0px 20px'}} variant="h6">{g.name}</Typography>
                                    <Typography  style={{color : 'white', padding : '10px 0px 0px 20px'}} variant="body1">
                                        {g.short_description}
                                    </Typography>
                                </div>

                                <div>
                                    <Link className={classes.linkButton}><Button></Button></Link>
                                </div>
                            </div>
                            </Grid>
                        )}
                        <Grid item xs={12}></Grid>
                        <Button variant='contained' color='primary'><Link to={'/login'} >View All</Link></Button>
                    </Grid>
                    }
                </Grid>
                
            </Grid>
        </main>
        
    );
};

export default Main;
