import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  top: {
    width: '90%',
    textAlign: 'center',
    margin: '0 auto 24px auto',
    '&>h1': {
      fontWeight: 800,
      color: '#9996BA',
      margin: '16px 0'
    },
    '&>h3': {
      fontWeight: 500,
      color: '#02113F'
    },
    '&>p': {
      fontWeight: 400,
      margin: '16px 0',
      color: '#02113F'
    }
  },

  logo: {
    maxWidth: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    width: "50%"
  },
  heroContent: {
    // padding: theme.spacing(8, 0, 6),
    // background: "#f8f9ff",
    // width: "100vw",
    // height: "100vh",
    // "box-sizing": "border-box",
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  comingSoon: {
    marginTop: "30px",
    lineHeight: "1.5"
  },
  btn: {
    margin: "auto",
    color: "#fff",
    "background-color": "#4335D2",
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 24,
    '&>img': {
      width: 180
    }
  }
}));

function CommingSoon() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <img src="https://institute.game.eduzo.com/logo4.png" alt="logo" className={classes.logo} />
        <hr />
        <div className={classes.bottom}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/errorpage/emoji.svg`} alt="error" />
        </div>
        <div className={classes.top}>
        <Typography
          // component="h1"
          variant="h3"
          align="center"
          color="textPrimary"
          className={classes.comingSoon}
          gutterBottom
        >
          COMING SOON
          <br />
        </Typography>       
        <Button className={classes.btn} variant="contained" color="primary" onClick={() => history.push('/')}>Go back to homepage</Button>
        </div>
      </Container>
    </div>
  )
}

export default CommingSoon

