import { useState, React } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

//material-ui core imports
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

//material-ui icon imports
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HomeIcon from "./Icons/Home";
import PlayGamesIcon from "@material-ui/icons/SportsEsports";
import GroupsIcon from "@material-ui/icons/SupervisedUserCircle";
import StudentsIcon from "./Icons/Students";
import GamestoreIcon from "./Icons/Gamestore";
import MyGamesIcon from "./Icons/MyGames";
import ManageIcon from "./Icons/Manage";
import HelpCenterIcon from "./Icons/HelpCenter";
import user_icon from "./Icons/user.png";
import {logout} from '../../redux/actions/authAction'
import { useDispatch, useSelector } from "react-redux";


import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: 'green !important'
  },
  userList: {
    // backgroundColor: "blue !important"
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px 8px 8px',
    background: '#F9EAEA !important',
    borderRadius: '6px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    background: "#fff",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0, 0, 0),
    width: "72%",
    margin: "0 auto",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
    "&>h5": {
      fontWeight: 500,
    },
  },
  logo: {
    width: 120,
    "& img": {
      width: "100%",
    },
  },
  navLinks: {
    margin: 0,
    padding: 0,
  },
  navLink: {
    textDecoration: "none",
    color: "#000",
  },
  navItem: {
    borderRadius: "6px",
    width: "86%",
    margin: "12px auto",
    "& span": {
      fontWeight: 500,
    },
    "&>*:nth-child(1)": {
      marginRight: 8,
    },
    transition: "background 140ms linear, color 140ms linear",
  },
  navItemActive: {
    //background: '#ece9fc',
    "& span": {
      color: "var(--text-primary)",
    },
    "&:nth-child(1) svg": {
      "& path": {
        stroke: "var(--text-primary)",
        fill: "none",
      },
    },
    "& svg": {
      "& path": {
        fill: "var(--text-primary)",
      },
    },
  },
}));

export default function DashboardHeader({ isMobile }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isUnRegistered = location.pathname.includes('unregistered');

  const user = localStorage.getItem("user_name");

  // console.log(localStorage.getItem('profile_pic'))

  const profile_pic =
    localStorage.getItem("profile_pic") == "" ||
    localStorage.getItem("profile_pic") == "undefined" ||
    localStorage.getItem("profile_pic") == undefined
      ? user_icon
      : localStorage.getItem("profile_pic");

  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleItemOnClick = (e) => {
    setLogout(e);
    setItemSelected(true);
  };

  const handleItemOnClick1 = (e) => {
    setLogout(false);
    setItemSelected(false);
  };

  const [logoutAction, setLogout] = useState(null);
  const [itemSelected,setItemSelected] = useState(false);

  const openMenu = Boolean(logoutAction);

  const navItems = [
    // {
    //   to: '/labs',
    //   name: 'Home',
    //   icon: HomeIcon,
    // },
    {
      to: `${isUnRegistered ? '/unregistered/gamestore' : '/labs/gamestore'}`,
      name: "Game Store",
      icon: GamestoreIcon,
    },
    {
      to: `${isUnRegistered ? '#' : '/labs/my-games'}`,
      name: "My Games",
      icon: MyGamesIcon,
    },
    {
      to: `${isUnRegistered ? '#' : '/labs/groups'}`,
      name: "Students",
      icon: StudentsIcon,
    },
    {
      to: `${isUnRegistered ? '#' : '/labs/manage'}`,
      name: "Manage",
      icon: ManageIcon,
    },
    {
      to: `${isUnRegistered ? '/labs/help-center' : '/labs/help-center'}`,
      name: "Help Center",
      icon: HelpCenterIcon,
    },
  ];
 

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={`${classes.menuButton} ${!isMobile && classes.hide}`}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={isMobile ? open : true}
        classes={{
          paper: `${classes.drawerPaper} drawer-menu`,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.logo}>
            <Link to="/labs/gamestore">
              {/* <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="eduzo" /> */}
              <img src={`${process.env.PUBLIC_URL}/logo4.png`} alt="eduzo" />
            </Link>
          </div>
          {isMobile && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          )}
        </div>
        <Modal open={isOpen}>
          <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              padding: '30px',
              borderRadius: 20,
              backgroundColor: 'white',
              textAlign: 'center'
          }}>
            
             <h3> Oops.. Sorry, you are not allowed to do that. Get in touch with Us.</h3>
           
            <div  className="d-flex justify-content-between align-items-center flex-row mt-5">
              <Button 
                variant="contained"  
                data-dismiss='modal' 
                className="primary-btn"
                onClick={() => {
                  history.push('/unregistered/packages');
                  setIsOpen(false);
                }}
              >
                  Contact us
              </Button>
              <Button 
                variant="contained"  
                data-dismiss='modal' 
                className="primary-btn"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                  Not now
              </Button>
            </div>
          </div>
        </Modal>
        <List className={classes.navLinks}>
          {navItems.map(({ icon: Icon, ...item }, index) => (
            <ListItem
              button
              disabled={item.disabled}
              key={item.name}
              className={`${classes.navItem} ${
              location.pathname.match(item.to) ?  classes.navItemActive : ""
              }`}
              onClick={() => {
                if(isUnRegistered){
                  if(item.to === '#'){
                    setOpen(false);
                    setIsOpen(true);
                  }                
                }
                  setOpen(false);
                  history.push(item.to);
                
              }}
            >
              {/* <Icon fontSize='small' /> */}
              <Icon />
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>

        <List style={{ position: 'fixed', bottom: '5%' }}>
          {<ListItem selected={itemSelected} onClick={(e) => handleItemOnClick(e.currentTarget)} classes={{selected: classes.userList}}>
            {/* <div style={{display: 'flex',flexDirection: 'row',alignItems: 'center',padding: '8px 16px 8px 8px',background: '#F9EAEA',borderRadius: '6px'}}>
            <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "25px",
                marginRight: "15px",
              }}
              src={profile_pic}
            />
            {user}</div> */}
            {/* <button style={{color : 'white' , backgroundColor : 'red', border : '0px', padding : '7px', borderRadius : '7px'}} onClick={handle_logout}>Logout</button> */}
           {/* :  */}
           <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "25px",
                marginRight: "15px",
              }}
              src={profile_pic}
            />
            {user}
            </ListItem>
          }
        </List>

        <Menu
          id="basic-menu"
          style={{ left: '20px' }}
          anchorEl={logoutAction}
          open={openMenu}
          // onClose={() => setLogout(false)}
          onClose={()=>handleItemOnClick1()}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="material-ui-menu-item" onClick={() => dispatch(logout())}><img src={"/assets/images/login/logout.svg"} /> <p>Logout</p></MenuItem>
        </Menu>
      </Drawer>
    </div>
  );
}
