import { useState, useEffect } from 'react'
import {Gamestore, GameDetail , MyGames, ViewCustomGame,ViewGame, Groups, Students, ViewStudent} from '../components/Dashboard';
import Notifications from '../components/Dashboard/Gamestore/notifications'
import {
  DashboardHome,
  DashboardGamestore,
  DashboardMyGames,
  DashboardStudents,
  DashboardGroups,
  DashboardManage,
  DashboardManage2,
  DashboardPackages,
  HelpCenter,
  PlayGame,
  PlayGame2,
  PlayGame3,
} from '../pages'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles'

//common
import { Header, Drawer } from '../components/Dashboard'
import UnregisteredGameStore from '../components/Dashboard/Unregistered/Gamestore';
import UnregisteredGameDetail from '../components/Dashboard/Unregistered/Gamestore/GameDetail';


const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '25px',
    backgroundImage: 'linear-gradient(180deg, #FDFDFE 0%, #F5F7FE 100%)',
    minHeight : '100vh'
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    // marginTop: 12,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    // overflow: 'hidden',
  },
}))

const DashboardLayout = ({ window , logout}) => {
  const classes = useStyles()
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 770 ? true : false
  )

  useEffect(() => {
    window.addEventListener('resize', resizeListener, true)
    return () => {
      window.removeEventListener('resize', resizeListener, true)
    }
  }, [])

  const resizeListener = () => {
    if (window.innerWidth < 770) setIsMobile(true)
    else if (window.innerWidth >= 770) setIsMobile(false)
  }


  return (
    <main className={classes.main}>
      {/* <Header isMobile={isMobile}/> */}
      {/* <Drawer isMobile={isMobile} /> */}
      {window.location.pathname === '/labs/unregistered/packages'  ? '' : <Drawer isMobile={isMobile} />}
      <div
        
        className={`${classes.content} ${!isMobile && classes.contentShift}`}>
     
                
               
             
        <Switch>
          <Route
            path={`/labs`}
            render={({ match }) => (
              <Switch>
                {/* <PrivateRoute
                  exact
                  path={`${match.path}/students`}
                  component={DashboardStudents}
                /> */}
                <PrivateRoute
                exact
                  path={`${match.path}/groups`}
                  component={Groups}
                />
                  <PrivateRoute
                exact
                  path={`${match.path}/notifications`}
                  component={Notifications}
                />
                <PrivateRoute
                exact
                  path={`${match.path}/groups/:id/students`}
                  component={Students}
                />
                <PrivateRoute
                exact
                  path={`${match.path}/groups/:id/students/:sid`}
                  component={ViewStudent}
                />
                <PrivateRoute
                  exact
                  path={`${match.path}/manage`}
                  component={DashboardManage2}
                />

                <PrivateRoute
                exact
                  path={`${match.path}/gamestore/:id`}
                  component={GameDetail}
                />
                <PrivateRoute
                exact
                  path={`${match.path}/gamestore`}
                  component={Gamestore}
                />
              
                <PrivateRoute
                exact
                  path={`${match.path}/my-games`}
                  component={MyGames}
                />
                <PrivateRoute
                exact
                  path={`${match.path}/my-games/:id`}
                  component={ViewGame}
                />
                <PrivateRoute
                exact
                  path={`${match.path}/my-games/:id/:cid`}
                  component={ViewCustomGame}
                />
                <Route
                  exact
                  path={`${match.path}/packages`}
                  component={DashboardPackages}
                />

            

{/* <Route  path={`${match.path}/:id`} component={GameDetail}/>
          <Route  path={`${match.path}`} component={Gamestore}/> */}
                <PrivateRoute
                exact
                  path={`${match.path}/help-center`}
                  component={HelpCenter}
                />
                <PrivateRoute 
                
                  path={`${match.path}/play-game/:id`} 
                  component={PlayGame} 
                  />
                <PrivateRoute 
                exact
                path={`${match.path}`} 
                component={DashboardHome} 
                />
                <Redirect to='/404' />
              </Switch>
            )}
          />
          <Route 
              exact 
              component={UnregisteredGameStore} 
              path={`/unregistered/gamestore`}
          />
          <Route 
              exact 
              path={`/unregistered/gamestore/:id`}
              component={UnregisteredGameDetail} 
          />
          <Route 
              exact 
              path={`/unregistered/packages`}
              component={DashboardPackages} 
          />
          <Redirect to='/404' />
        </Switch>
      </div>
    </main>
  )
}


export default connect (null )(DashboardLayout)
