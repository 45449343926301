import {useState, useEffect} from 'react';

//material-ui core imports
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import user_image from "../../../Icons/user.png"
import { useSelector } from 'react-redux';
import SearchBar from "material-ui-search-bar";

//material-ui icon imports

//common

const useStyles = makeStyles(() => ({
    root: {
        margin: '12px 0',
    },
    table: {
        '&>*:nth-child(1)':{
            background: '#E4E9F3'
        }
    },selected : {
        fontSize: '18px',
        lineHeight: '23px',
        marginBottom : '15px',
    color: '#02113F'
    }
}));

const ShareMemoryReportTable = ({group, reportCards}) => {


  useEffect(() => {
    setCurrentGroup(group.group)
  },[group])

  console.log({reportCards})
  const classes = useStyles();
  
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [student_count, setStudent_count] = useState(0)
  const [currentGroup, setCurrentGroup] = useState()


  const Count =(e)=> {
    // console.log(e.target.checked);
    if (e.target.checked == true) {
        setStudent_count(student_count + 1)
    } else if (e.target.checked == false) {
        setStudent_count(student_count - 1)
    } 
 
  }

  const searchStudent = (e) => {
    if (e == null || e== "" || e == undefined){
    setCurrentGroup(group.group)
    }

    var xyz = group.group?.students.filter(student => student.name.toLowerCase().includes(e.toLowerCase()))
    setCurrentGroup({...currentGroup, students : xyz});

  }

  const cancelSearchStudent = (e) => {
    setCurrentGroup(group.group)
  }

  return(
      
    <div className={classes.root}>
          <div  style={{display : 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" color="secondary">Share Memory</Typography>
                    <SearchBar style={{backgroundColor : 'white'}}
                        // onChange={(e) => search(e)}
                        // onCancelSearch={(e) => cancelSearch(e)}
                        // cancelSearchGame

                      onChange={(e) => searchStudent(e)}
                      onCancelSearch={(e) => cancelSearchStudent(e)}
                      />
                </div>
        <p className={classes.selected}>{student_count > 1 ? student_count + ' students' : student_count +  ' student'} selected</p>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" >
                                        <Checkbox color="primary"/>
                                    </TableCell>
                                    <TableCell align="left">Student Name</TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Last Played</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentGroup?.students?.map(student=>
                                <TableRow>
                                    <TableCell padding="checkbox" >
                                        <Checkbox onClick={Count} color="primary"/>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                    <span style={{display : 'flex', alignItems: 'center'}}>
                                  <img
                                  style = {{height : "30px", width : "30px", borderRadius : "20px", marginRight : '10px'}}
                                    src={student?.profile_pic && student?.profile_pic != "" ? student?.profile_pic : user_image} 
                                    className='student_image'
                                    alt=''
                                  />
                                        {student.name}
                                        </span>
                                    </TableCell>
                                    <TableCell align="left">{student.phone_number}</TableCell>
                                    <TableCell align="left">{student.email_id}</TableCell>
                                    <TableCell align="left">Molecule Builder</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={10}
                        rowsPerPage={10}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                </Paper>
            </Grid>
        </Grid>
    </div>
  )
}

export default ShareMemoryReportTable;